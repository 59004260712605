var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.account && _vm.dialogActive ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('components.thg.thgBillingCsvSelection.updateCreditorDialog'),
      "isDialogActive": _vm.dialogActive,
      "max-width": "600",
      "leftDisabled": _vm.creatingCreditor,
      "rightDisabled": _vm.accountDto && !_vm.accountDto.userId && !_vm.accountDto.partnerId,
      "rightLoading": _vm.creatingCreditor
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.dialogActive = false;
      },
      "rightClick": _vm.updateCreditor
    }
  }, [_c('v-row', {
    staticClass: "ml-1 mr-1 mt-2"
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": _vm.$t('components.thg.thgBillingCsvSelection.creditorNumber'),
      "outlined": "",
      "rounded": "",
      "value": _vm.account.accountNumber,
      "disabled": ""
    }
  })], 1), _c('v-row', {
    staticClass: "ml-1 mr-1 mt-2"
  }, [_c('thg-billing-creditor-selection-user-list', {
    attrs: {
      "userId": _vm.accountDto.userId
    },
    on: {
      "selected": _vm.userIdSelection,
      "update:userId": function updateUserId($event) {
        return _vm.$set(_vm.accountDto, "userId", $event);
      },
      "update:user-id": function updateUserId($event) {
        return _vm.$set(_vm.accountDto, "userId", $event);
      }
    }
  }), _c('thg-billing-creditor-selection-partner-list', {
    attrs: {
      "partnerId": _vm.accountDto.partnerId
    },
    on: {
      "selected": _vm.partnerIdSelection,
      "update:partnerId": function updatePartnerId($event) {
        return _vm.$set(_vm.accountDto, "partnerId", $event);
      },
      "update:partner-id": function updatePartnerId($event) {
        return _vm.$set(_vm.accountDto, "partnerId", $event);
      }
    }
  })], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }