






































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { BillingModule } from "@/store/modules/billing.store";
import Card from "../utility/Card.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({ components: { Debug, Card, LatestEntriesCardEmpty } })
export default class ThgBillingSelectionItemBillingResult extends DarkModeHighlightMixin {
  @Prop()
  billing!: ThgBillingViewmodelGen;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  isReadyForBilling = false;
  isNotifyUsers = false;

  locked = true;

  publishDialog = false;

  goToUserDetail() {
    new GoToHelper(this.$router).goToUserDetail(this.billing.userId);
  }

  async publish() {
    try {
      await BillingModule.publish({
        billingNumber: this.billing.billingNumber,
        publish: this.isReadyForBilling,
        notifyUsers: this.isNotifyUsers
      });
      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
    } catch (e) {
      this.$toast.error((e as any).message);
    }
    this.publishDialog = false;

    await BillingModule.getBilling(this.billing.billingNumber);
    if (this.$route.params.i) {
      BillingModule.addToCreatedBillings({
        index: parseInt(this.$route.params.i),
        createdBilling: BillingModule.createdBilling
      });
    }
  }
}
