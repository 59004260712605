















































import AffiliateUpdateDialog from "@/components/thg/AffiliateUpdateDialog.vue";
import { IAffiliateType } from "@/lib/interfaces/affiliate/IAffiliateType";
import { affiliateTypeMap } from "@/lib/utility/affiliateTypeMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgAffiliateViewmodelGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import Card from "../utility/Card.vue";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    AffiliateUpdateDialog,
    Card
  }
})
export default class AffiliateDetailInformationCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedAffiliate!: ThgAffiliateViewmodelGen;

  affiliateTypeMap = affiliateTypeMap;

  get affiliateUrl(): string {
    const baseUrl = PartnerModule.partner.settings?.urls[0] || "";

    return "https://" + baseUrl + "/#/?code=" + this.selectedAffiliate.code;
  }

  getUserById(id: string): IAdminUser | undefined {
    return AdminUserPaginationModule.maps.id.get(id)[0];
  }

  get userName(): string {
    if (this.selectedAffiliate.userId) {
      return this.getUserById(this.selectedAffiliate.userId)?.userName || this.selectedAffiliate.userId;
    }
    return "";
  }

  affiliateType(affiliateType: any): IAffiliateType {
    return (
      this.affiliateTypeMap.get(affiliateType) || {
        type: "components.thg.affiliateTypeMap.undefinedType",
        description: "components.thg.affiliateTypeMap.undefinedDescription"
      }
    );
  }
}
