var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('m-header', {
    attrs: {
      "title": _vm.title,
      "chips": _vm.chips,
      "actions": _vm.actions
    },
    on: {
      "actionClicked": _vm.processAction,
      "alertClicked": _vm.processAction
    }
  }), _vm.loading ? _c('v-progress-linear', {
    attrs: {
      "indeterminate": ""
    }
  }) : _vm._e(), _c('m-detail-view-grid', {
    scopedSlots: _vm._u([{
      key: "details",
      fn: function fn() {
        return [_c('m-detail-table', {
          attrs: {
            "title": _vm.$t('about'),
            "i18nPath": "objects.efahrer",
            "item": _vm.value,
            "copyClipboard": false,
            "config": _vm.detailTableConfig
          },
          on: {
            "actionClicked": _vm.processAction
          },
          scopedSlots: _vm._u([{
            key: "slot.order.created_at",
            fn: function fn() {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.simpleDoubleDigitDate(new Date(_vm.value.order.created_at).toISOString())) + " ")]), _c('span', [_c('br'), _vm._v(" " + _vm._s(_vm.formatHoursAndMinutes(new Date(_vm.value.order.created_at))) + " ")])];
            },
            proxy: true
          }, {
            key: "slot.order.status",
            fn: function fn() {
              return [_vm.value.order.status ? _c('v-chip', {
                attrs: {
                  "dark": "",
                  "small": ""
                },
                on: {
                  "click": _vm.openStatusDialog
                }
              }, [_vm._v(" " + _vm._s(_vm.value.order.status) + " ")]) : _vm._e()];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('m-action-list', {
          attrs: {
            "divider": true,
            "actions": _vm.actions
          },
          on: {
            "actionClicked": _vm.processAction
          }
        })];
      },
      proxy: true
    }])
  }, [_c('booking-customer-information-time-line-element', {
    attrs: {
      "firstName": _vm.customer.firstName,
      "copyClipBoard": false,
      "lastName": _vm.customer.lastName,
      "numberPlate": _vm.customer.vehicleOwnerType,
      "email": _vm.customer.email
    }
  }), _c('efahrer-image', {
    attrs: {
      "images": _vm.value.images,
      "loading": _vm.loading
    },
    on: {
      "exportSwipeImages": _vm.onExportSwipeImages,
      "exportImages": _vm.onExportImages
    }
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUpdateStatusDialogActive,
      "rightLoading": _vm.loadingStatusUpdate
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUpdateStatusDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUpdateStatusDialogActive = $event;
      },
      "rightClick": _vm.changeOrderStatus,
      "leftClick": _vm.cancelChangeOrderStatus,
      "close": _vm.cancelChangeOrderStatus
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.statusItems,
      "solo": "",
      "flat": "",
      "outlined": ""
    },
    model: {
      value: _vm.tempStatus,
      callback: function callback($$v) {
        _vm.tempStatus = $$v;
      },
      expression: "tempStatus"
    }
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('components.thg.EfahrerDetailCard.createThgTitle'),
      "subtitle": _vm.$t('components.thg.EfahrerDetailCard.createThgSubTitle'),
      "isDialogActive": _vm.isCreateThgDialogActive,
      "rightLoading": _vm.loadingCreateThg
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isCreateThgDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isCreateThgDialogActive = $event;
      },
      "rightClick": _vm.createThgFromOrder,
      "leftClick": _vm.cancelCreateThgFromOrder,
      "close": _vm.cancelCreateThgFromOrder
    }
  }, [_c('efahrer-create-thg-form', {
    attrs: {
      "partnerId": _vm.partnerId
    },
    on: {
      "partnerChange": _vm.partnerChange,
      "yearChange": _vm.thgYearChange,
      "codeChange": _vm.thgCodeChange,
      "campaignChange": _vm.thgCampaignChange
    }
  })], 1), _c('debug', [_vm._v(_vm._s(_vm.value.order))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }