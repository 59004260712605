var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("report.thgChargingStation.meterReading.title")))])]), _c('v-card', {
    staticClass: "flex-container",
    attrs: {
      "flat": ""
    }
  }, [_c('filter-card', {
    attrs: {
      "search": _vm.search,
      "filterList": _vm.filterList,
      "filterItems": _vm.meterReadings,
      "filteredItems": _vm.filteredItems
    },
    on: {
      "update:search": function updateSearch($event) {
        _vm.search = $event;
      }
    }
  }), _c('div', {
    staticClass: "scrollable"
  }, [_vm.filteredItems.length !== 0 ? _c('v-virtual-scroll', {
    attrs: {
      "item-height": "66",
      "items": _vm.filteredItems
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-list-item', {
          key: item.id,
          class: _vm.isActive(item),
          attrs: {
            "two-line": ""
          },
          on: {
            "click": function click($event) {
              return _vm.set(item);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(item.meterReading.amountInKwh) + " KW/h")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.simpleDate(item.meterReading.startDate)) + " - " + _vm._s(_vm.simpleDate(item.meterReading.endDate)))])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.date(item)))]), _c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v(" " + _vm._s(_vm.thgStatus(item.status).icon))])], 1)], 1)];
      }
    }], null, false, 457949092)
  }) : _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.emptyDataSrc,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }], null, false, 974937243)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportList.noData")))])])], 1)], 1)], 1)], 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }