

































































































import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import ThgQuoteWorkitemCard from "@/components/thg/ThgQuoteWorkitemCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { UserModule } from "@/store/modules/me-user.store";
import { Component } from "vue-property-decorator";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { handleError } from "@/lib/utility/handleError";
import ThgQuoteMeterReadingWorkItemCard from "@/components/thg/ThgQuoteMeterReadingWorkItemCard.vue";
import { IThg } from "@/models/thg.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { IAdminUser } from "@/models/user.entity";
import { IPageFilterElement } from "@/models/page-filter-element.entity";

const VueTyper = require("vue-typer").VueTyper;

@Component({
  components: {
    TheLayoutPortal,
    ThgQuoteWorkitemCard,
    ThgQuoteMeterReadingWorkItemCard,
    VueTyper,
    FilterCardPagination
  }
})
export default class ThgWorkitemProcessingView extends PartnerFallbackMixin {
  selectedUser: IAdminUser | null = null;
  window = 0;
  foundPartner = false;
  loading = false;
  noMoreWorkText = [
    "Lean Back! 😎",
    "Cheers 🍻",
    "Currently no more work. 🫶 ",
    "Look at where you came from 😤",
    "Look at you now 🤩",
    "Thank's 🙏"
  ];
  workText = [
    `Come on, ${UserModule.user.firstName} 🚀`,
    "You can do it  🫵",
    "Pave the way 🏋️",
    "Put your back into it 💪"
  ];

  items = [
    {
      id: 1,
      icon: "mdi-information",
      text:
        "In dieser Ansicht kannst du die eingegangen Fahrzeugscheine prüfen. Bitte überprüfe jeden Fahrzeugschein gewissenhaft."
    },
    {
      id: 2,
      icon: "mdi-alert",
      text: "Fülle alle Felder aus. Checke, ob es sich um ein Elektrofahrzeug handelt 🔎 Genauigkeit geht hier vor."
    },
    {
      id: 3,
      icon: "mdi-check-circle",
      text: "Have Fun 🚀"
    }
  ];

  get isVehicleSelected() {
    return this.window === 0;
  }

  get isMeterReadingSelected() {
    return this.window === 1;
  }

  get hasNextWorkItem() {
    return ThgPortalModule.hasNextWorkItem;
  }

  get isWorkStarted() {
    return ThgPortalModule.isWorkStarted;
  }

  get thgWorkitem(): IThg | ThgThgMeterReadingViewModelGen {
    return ThgPortalModule.thgWorkitem;
  }

  get workitemFilter(): IPageFilterElement[] {
    return ThgPortalModule.workItemFilter;
  }

  set workitemFilter(filter: IPageFilterElement[]) {
    ThgPortalModule.setWorkItemFilter(filter);
  }

  get workitemFilterList() {
    return ThgPortalModule.workItemFilterList;
  }

  async askForWork() {
    this.loading = true;
    try {
      const promises: Promise<any>[] = [];
      if (this.isVehicleSelected) {
        promises.push(ThgPortalModule.getThgAsWorkItem());
      } else {
        promises.push(ThgPortalModule.getMeterReadingAsWorkItem());
      }
      promises.push(ThgAnalyticsModule.getStatusDistributionData());
      await Promise.all(promises);

      this.selectedUser = await AdminUserPaginationModule.getSelectedUser(this.thgWorkitem.userId);
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
      ThgPortalModule.updateIsWorkStarted(true);
    }
  }

  async mounted() {
    ThgPortalModule.updateIsWorkStarted(false);
    ThgPortalModule.updateHasNextWorkItem(true);
  }
}
