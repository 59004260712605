var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.billing.type !== _vm.BillingTypeEnum.CANCELLATION ? _c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.cancelBillingDialog = true;
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-cancel")])], 1) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingCancellationDialog.cancelTooltip")) + " ")])]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.cancelBillingDialog,
      "title": _vm.$t('components.thg.ThgBillingCancellationDialog.cancelTitle'),
      "subtitle": '#' + _vm.billing.billingNumber,
      "margin": 0,
      "rightLoading": _vm.loading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.cancelBillingDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.cancelBillingDialog = $event;
      },
      "leftClick": _vm.cancelBillingCancellation,
      "rightClick": _vm.confirmBillingCancellation
    }
  }, [_vm.billing.type === _vm.BillingTypeEnum.CREDIT_PARTNER ? _c('div', [_vm._l(_vm.partnerCommissions, function (commission, index) {
    return _c('v-row', {
      key: 'commission' + index,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "4"
      }
    }, [_c('v-text-field', {
      attrs: {
        "type": "number",
        "label": 'year'
      },
      model: {
        value: commission.year,
        callback: function callback($$v) {
          _vm.$set(commission, "year", $$v);
        },
        expression: "commission.year"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('v-text-field', {
      attrs: {
        "type": "number",
        "label": 'commission'
      },
      model: {
        value: commission.partnerCommission,
        callback: function callback($$v) {
          _vm.$set(commission, "partnerCommission", $$v);
        },
        expression: "commission.partnerCommission"
      }
    })], 1), _c('v-col', {
      attrs: {
        "cols": "2"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.partnerCommissions.splice(index, 1);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1)], 1);
  }), _c('v-btn', {
    attrs: {
      "elevation": 0
    },
    on: {
      "click": function click($event) {
        _vm.partnerCommissions.push({
          year: new Date().getFullYear(),
          partnerCommission: 0
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add commission")) + " ")])], 2) : _vm._e(), _vm.billing.type === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION ? _c('div', [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": '€/kWh',
      "hint": '(Bei neueren Rechnungen (nach Mai 13 2024) sollte der Preis der Ladesäule automatisch aus der Rechnung übernommen werden)',
      "persistent-hint": ""
    },
    model: {
      value: _vm.pricePerKwh,
      callback: function callback($$v) {
        _vm.pricePerKwh = $$v;
      },
      expression: "pricePerKwh"
    }
  })], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }