







































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop } from "vue-property-decorator";
import FilterCardPagination from "../filter/FilterCardPagination.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import PartnerSelectionForm from "./PartnerSelectionForm.vue";
import ThgDialogYearSelection from "./ThgDialogYearSelection.vue";

@Component({
  components: {
    AnalyticsDateRangeSelectorDialog,
    FilterCardPagination,
    ConfirmActionDialog,
    PartnerSelectionForm,
    ThgDialogYearSelection
  }
})
export default class ThgMultiQuickCheckoutDialog extends DarkModeHighlightMixin {
  @Prop({ default: [] })
  thgs!: IThg[];

  /**
   * Form valid
   */
  isValid = false;
  dialog = false;
  loading = false;

  isPartnerSet = false;
  selectedYears: number[] = [];
  userNotification = false;
  partnerId?: string = "";
  counter = 0;

  get disbledNew() {
    if (!this.thgs.length) {
      return true;
    }
    return false;
  }

  get disabled() {
    if (!this.selectedYears.length) {
      return true;
    }
    if (this.partnerId) {
      return false;
    }

    return true;
  }

  get prices() {
    return PartnerActiveOfferModule.prices;
  }

  get years(): number[] {
    return this.prices.map(p => p.year) ?? [];
  }

  async openDialog() {
    this.counter = 0;
    this.dialog = true;

    if (!this.partnerId) {
      this.loading = true;
      this.partnerId = this.thgs[0].partnerId;
      await this.savePartner(this.partnerId).finally(() => (this.loading = false));
    }
  }

  changePartner() {
    this.isPartnerSet = false;
  }

  async quickCheckoutThg() {
    try {
      this.loading = true;
      if (!this.partnerId) {
        this.$toast("Please select Partner with save.");

        return;
      }
      for (const thg of this.thgs) {
        this.counter = this.counter + 1;
        await ThgPortalModule.quickcheckoutThgAsAdmin({
          thgPartendId: thg.id,
          partnerId: this.partnerId,
          userNotification: this.userNotification,
          years: this.selectedYears
        });
      }
      this.$emit("created");
    } catch (error) {
      handleError(error);
    }

    this.loading = false;
    this.dialog = false;
  }

  async savePartner(partnerId: string) {
    this.$log.debug(this.partnerId);

    this.loading = true;
    try {
      await PartnerActiveOfferModule.loadDocuments(partnerId);
      this.partnerId = partnerId;
      this.isPartnerSet = true;
      this.userNotification = false;
      this.selectedYears = [];
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
