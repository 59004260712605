































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { ThgBatchViewmodelItemGen } from "@/services/thg/v1/data-contracts";
import { BatchModule } from "@/store/modules/batch.store";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, LatestEntriesCardEmpty, Card } })
export default class ThgBillingSelectionItemBatchData extends DarkModeHighlightMixin {
  @Prop()
  thgId!: string;

  @Prop()
  batch!: ThgBatchViewmodelItemGen;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  loading = true;

  locked = true;

  toggleLock = () => {
    this.locked = !this.locked;
  };

  batchData: ThgBatchViewmodelItemGen | undefined = this.batch;

  beforeMount() {
    this.loading = true;
  }

  async mounted() {
    this.loading = true;

    if (!this.batch) {
      this.loading = true;

      await BatchModule.findAll();
      const allBatches = BatchModule.allBatches;

      const batchData = allBatches.find(b => {
        if (b.itemIds.includes(this.thgId)) {
          return true;
        }
      });

      this.batchData = batchData;
    }

    this.loading = false;
  }
}
