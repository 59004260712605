import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { IPromotion, Promotion } from "@/models/promotion.entity";
import {
  ThgAccountViewmodelGen,
  ThgAdminPromotionControllerFindAllAdminParamsGen
} from "@/services/thg/v1/data-contracts";
import { Module, getModule } from "vuex-module-decorators";
import store from "../VuexPlugin";
import { PaginationFilterListElement } from "./base-pagination.store";
import { PaginatedBaseStore } from "../paginated-base.store";
import { IPageDataProvider } from "@/lib/utility/data/page-data-provider.interface";
import { AbstractPageDataProvider } from "@/lib/utility/data/page-data-provider.abstract";
import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import { PromotionDataAccessLayer } from "./access-layers/promotion.access-layer";
import promotionAdminService from "@/services/thg/services/promotionAdminService";

export class AccountPageDataProvider extends AbstractPageDataProvider<
  IPromotion,
  ThgAdminPromotionControllerFindAllAdminParamsGen
> {
  async getPage(query: ThgAdminPromotionControllerFindAllAdminParamsGen): Promise<IPageViewModel<IPromotion>> {
    const res = await promotionAdminService.findAllAdmin(query);

    if (!res.data) {
      res.data = [];
    }

    return res as IPageViewModel<IPromotion>;
  }

  getIdentifier(entity: ThgAccountViewmodelGen): string {
    return entity.id;
  }
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "promotion",
  store
})
export class PromotionStore extends PaginatedBaseStore<IPromotion, ThgAdminPromotionControllerFindAllAdminParamsGen> {
  protected _isLoadAll = false;

  filterOptions: PaginationFilterListElement[] = Promotion.filterables;

  _data = PromotionDataAccessLayer;
  _pageProvider: IPageDataProvider<
    IPromotion,
    ThgAdminPromotionControllerFindAllAdminParamsGen
  > = new AccountPageDataProvider();
  _pager = new PageDataHandler(this._data, this._pageProvider);
}

export const PromotionModule = getModule(PromotionStore);
