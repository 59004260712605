





























import ThgBillingSelectionItemPdfResult from "@/components/thg/ThgBillingSelectionItemPdfResult.vue";
import { ThgBillingViewmodelGen, ThgPartnerCommissionPerYearDtoGen } from "@/services/thg/v1/data-contracts";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import ThgBillingSelectionItemAccountingResult from "./ThgBillingSelectionItemAccountingResult.vue";
import ThgBillingSelectionItemBillingResult from "./ThgBillingSelectionItemBillingResult.vue";

@Component({
  components: {
    Card,
    ThgBillingSelectionItemAccountingResult,
    ThgBillingSelectionItemPdfResult,
    ThgBillingSelectionItemBillingResult,
    LatestEntriesCardEmpty
  }
})
/**
 * A view where a user can review the data of a billed user and billed documents and generate the billing while validating its correctness
 */
export default class ThgBillingDetailResultsCard extends mixins(Vue) {
  /**
   * a billing
   */
  @Prop()
  createdBilling!: ThgBillingViewmodelGen;

  /**
   * a billing
   */
  @Prop()
  commissions!: ThgPartnerCommissionPerYearDtoGen[];
  get partnerCommissions(): ThgPartnerCommissionPerYearDtoGen[] {
    return this.commissions;
  }
  set partnerCommissions(commission: ThgPartnerCommissionPerYearDtoGen[]) {
    this.$emit("update:commissions", commission);
  }
  /**
   * a billing
   */
  @Prop()
  pricePerKwh!: number;
  get _pricePerKwh() {
    return this.pricePerKwh;
  }
  set _pricePerKwh(pricePerKwh: number) {
    this.$emit("update:pricePerKwh", pricePerKwh);
  }
}
