import { PartnerEntity } from "@/models/partnerEntity";
import {
  ThgAffiliateViewmodelGen,
  ThgBankingDtoGen,
  ThgBatchViewmodelItemGen,
  ThgBillingInformationViewmodelGen,
  ThgChargingStationViewModelGen,
  ThgThgMeterReadingViewModelGen
} from "@/services/thg/v1/data-contracts";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { BankingModule } from "@/store/modules/banking.store";
import { BatchModule } from "@/store/modules/batch.store";
import { BillingInformationModule } from "@/store/modules/billing-information.store";
import { ChargingStationAdminModule } from "@/store/modules/charging-station-admin.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import Vue from "vue";
import { BillingTypeEnum } from "../enum/billingType.enum";
import { handleError } from "./handleError";
import { Billing, IBilling } from "@/models/billing.entity";
import { IThg } from "@/models/thg.entity";
import { IAdminUser } from "@/models/user.entity";

export interface IBillingDocuments {
  loadingBilling: boolean;
  loadingData: boolean;
  billing: IBilling | null;
  cancelledBilling: IBilling | null;
  billedBeing: PartnerEntity | IAdminUser | null;
  billedDocuments: (IThg | ThgThgMeterReadingViewModelGen)[];
  banking: ThgBankingDtoGen | null;
  partnerBillingInformation: ThgBillingInformationViewmodelGen | null;
  chargingStations: ThgChargingStationViewModelGen[];
  affiliateCodes: ThgAffiliateViewmodelGen[];
  batches: ThgBatchViewmodelItemGen[];
  selectedPartners: PartnerEntity[];
}

export class BillingDataLoader {
  documents: IBillingDocuments = {
    loadingBilling: true,
    loadingData: true,
    billing: null,
    cancelledBilling: null,
    billedBeing: null,
    billedDocuments: [],
    banking: null,
    partnerBillingInformation: null,
    chargingStations: [],
    affiliateCodes: [],
    batches: [],
    selectedPartners: []
  };

  constructor(private billingNumber: number) {
    this.stageOne().then(() => {
      this.setDocumentsLoading();
      this.stageTwo().then(() => {
        this.stageThree().then(() => {
          this.stageFour().then(() => {
            this.setDocumentsLoaded();
          });
        });
      });
    });
  }

  setDocumentsLoading() {
    this.documents.loadingData = true;
  }

  setDocumentsLoaded() {
    this.documents.loadingData = false;
  }

  /**
   * Load Billing
   */
  private async stageOne() {
    await Promise.all([this.setBilling()]);
    Vue.$log.debug("Finished Stage 1", this.documents);
  }

  /**
   * Load cancelled Billing in case of cancellation
   */
  private async stageTwo() {
    if (this.documents.billing?.type === BillingTypeEnum.CANCELLATION) {
      await Promise.all([this.setCancellationBilling()]);
    }
    Vue.$log.debug("Finished Stage 2", this.documents);
  }

  /**
   * Load Documents that can be directly derived from billing
   *
   * this includes the billed being which is either a user or a partner
   * banking of user if user is billed
   * billing info of partner if partner is billed
   * for credit charging station this is meter readings
   * for all others this is vehicle thgs
   */
  private async stageThree() {
    const promises: Promise<any>[] = [];

    let referenceIds: string[] = this.documents.billing?.referenceIds || [];
    if (!referenceIds.length && this.documents.billing?.thgId) {
      referenceIds = [this.documents.billing?.thgId];
    }

    const billingType = this.documents.billing?.type as BillingTypeEnum;
    const cancelledBillingType = this.documents.cancelledBilling?.type as BillingTypeEnum;

    if (
      (billingType === BillingTypeEnum.CANCELLATION &&
        cancelledBillingType !== BillingTypeEnum.CREDIT_CHARGING_STATION) ||
      (referenceIds.length &&
        [BillingTypeEnum.AFFILIATE, BillingTypeEnum.CREDIT, BillingTypeEnum.CREDIT_PARTNER].includes(billingType))
    ) {
      promises.push(this.setSelectedThgs(referenceIds).catch(handleError));
      promises.push(this.setBatches(referenceIds).catch(handleError));
    }
    if (
      [BillingTypeEnum.CREDIT_CHARGING_STATION].includes(this.documents.billing?.type as BillingTypeEnum) ||
      (billingType === BillingTypeEnum.CANCELLATION && cancelledBillingType === BillingTypeEnum.CREDIT_CHARGING_STATION)
    ) {
      promises.push(this.setSelectedMeterReadings(referenceIds).catch(handleError));
    }
    const userId = this.documents.billing?.userId;
    if (userId) {
      promises.push(this.setUser(userId).catch(handleError));
      promises.push(this.setBanking(userId).catch(handleError));
    }
    const partnerId = this.documents.billing?.partnerId;
    if (partnerId) {
      promises.push(this.setPartner(partnerId).catch(handleError));
      promises.push(this.setPartnerBillingInfo(partnerId).catch(handleError));
    }

    await Promise.all(promises);
    Vue.$log.debug("Finished Stage 3", this.documents);
  }
  /**
   * Loads additional documents
   * those are:
   * chargingStations: ThgChargingStationViewModelGen[];
   * affiliateCodes: ThgAffiliateViewmodelGen[];
   * Partners related to selected documents
   */
  private async stageFour() {
    const setAffiliatesAsync = this.setAffiliates().catch(handleError);
    const setChargingStationsAsync = this.setChargingStations().catch(handleError);
    const setPartnersAsync = this.setPartners().catch(handleError);
    await Promise.all([setAffiliatesAsync, setChargingStationsAsync, setPartnersAsync]);
    Vue.$log.debug("Finished Stage 4", this.documents);
  }

  private async setBilling() {
    try {
      this.documents.loadingBilling = true;
      this.documents.billing = await new Billing({
        billingNumber: this.billingNumber
      }).fetch();
    } catch (e) {
      handleError(e);
    } finally {
      this.documents.loadingBilling = false;
    }
  }

  private async setCancellationBilling() {
    try {
      const cancelledBillingNumber = this.documents.billing?.pdfBilling[0]?.input.cancellationNumber;

      if (cancelledBillingNumber) {
        this.documents.cancelledBilling = await new Billing({
          billingNumber: cancelledBillingNumber
        }).fetch();
      }
    } catch (e) {
      handleError(e);
    }
  }

  /**
   * TODO: Make this one get many call
   * @param referenceIds
   */
  private async setSelectedThgs(referenceIds: string[]) {
    const found = await ThgPortalModule.getMany(referenceIds);
    this.documents.billedDocuments.splice(0, this.documents.billedDocuments.length, ...found);
  }
  /**
   * TODO: Make this one get many call
   * @param referenceIds
   */
  private async setBatches(referenceIds: string[]) {
    if (!BatchModule.allBatches.length) {
      await BatchModule.findAll();
    }
    const batches = BatchModule.allBatches;

    const uniqueBatches = new Set<ThgBatchViewmodelItemGen>();
    for (const referenceId of referenceIds) {
      const found = batches.find(d => {
        d.itemIds.includes(referenceId);
      });
      if (found) {
        uniqueBatches.add(found);
      }
    }
    this.documents.batches.push(...uniqueBatches);
  }
  /**
   * TODO: Make this one get many call
   * @param referenceIds
   */
  private async setSelectedMeterReadings(referenceIds: string[]) {
    const billedDocumentsCallsAsync: Promise<void>[] = [];
    referenceIds.forEach(referenceId => {
      if (referenceId) {
        const selectedThgPromise = ThgPortalModule.getSelectedMeterReading(referenceId).then(thg => {
          this.documents.billedDocuments.push(thg);
        });
        billedDocumentsCallsAsync.push(selectedThgPromise);
      }
    });
    await Promise.all(billedDocumentsCallsAsync);
  }
  private async setUser(userId: string) {
    const user = await AdminUserPaginationModule.getSelectedUser(userId);
    if (user?._id === userId) {
      this.documents.billedBeing = user;
    }
  }
  private async setBanking(userId: string) {
    const banking = await BankingModule.getBanking({ userId: userId || "" });
    if (banking) {
      this.documents.banking = banking;
    }
  }
  private async setPartner(partnerId: string) {
    if (partnerId) {
      const partner = await PartnerModule.getPartnerById(partnerId);
      if (partner?.id === partnerId) {
        this.documents.billedBeing = partner;
      }
    }
  }
  private async setPartnerBillingInfo(partnerId: string) {
    if (partnerId) {
      const partnerBillingInfo = await BillingInformationModule.getPartnerBillingInformation(partnerId);
      if (partnerBillingInfo?.id === partnerId) {
        this.documents.partnerBillingInformation = partnerBillingInfo;
      }
    }
  }
  private async setAffiliates() {
    const affiliateCodes: Map<string, Promise<ThgAffiliateViewmodelGen>> = new Map();
    for (const billedDocuments of this.documents.billedDocuments) {
      const partnerId = billedDocuments.partnerId;
      const code = billedDocuments.code;

      if (partnerId && code) {
        const identifier = JSON.stringify({ partnerId, code });
        if (!affiliateCodes.get(identifier)) {
          affiliateCodes.set(identifier, AffiliatePortalModule.getSelectedAffiliateByCode({ partnerId, code }));
        }
      }
    }
    await Promise.all([...affiliateCodes.values()]);
  }
  /**
   * Loads the partners for the billed documents
   */
  private async setPartners() {
    const partnerIds = this.documents.billedDocuments.map(bd => bd.id);
    if (!PartnerModule.partners.length) {
      await PartnerModule.getPartners();
    }

    this.documents.selectedPartners = PartnerModule.partners.filter(p => partnerIds.includes(p._id));
  }
  /**
   * TODO Fix type
   * @returns
   */
  private async setChargingStations() {
    if (this.documents.billing?.type !== BillingTypeEnum.CREDIT_CHARGING_STATION) {
      return;
    }

    const chargingStationIds: Set<string> = new Set();
    for (const billedDocument of this.documents.billedDocuments) {
      const thgMeterReading = billedDocument as ThgThgMeterReadingViewModelGen;

      if (thgMeterReading.meterReading.chargingStationId) {
        chargingStationIds.add(thgMeterReading.meterReading.chargingStationId);
      }
    }

    const chargingStations = await ChargingStationAdminModule.getManyChargingStations([...chargingStationIds.values()]);
    this.documents.chargingStations = (chargingStations as any) as ThgChargingStationViewModelGen[];
  }
}
