/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreatePromotionDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgPartnerPromotionControllerFindAllParamsGen,
  ThgPromotionViewModelGen,
  ThgUpdatePromotionDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerPromotionService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Partner Promotion Service
   * @name PartnerPromotionControllerCreate
   * @summary Create a Promotion for a partner
   * @request POST:/partner/{partnerId}/promotion
   * @secure
   * @response `200` `ThgPromotionViewModelGen` The created Promotion
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPromotionControllerCreate = (partnerId: string, data: ThgCreatePromotionDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgPromotionViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/promotion`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Promotion Service
   * @name PartnerPromotionControllerFindAll
   * @summary Get all promotions
   * @request GET:/partner/{partnerId}/promotion
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgPromotionViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPromotionControllerFindAll = (
    { partnerId, ...query }: ThgPartnerPromotionControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgPromotionViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/promotion`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Promotion Service
   * @name PartnerPromotionControllerFindOne
   * @summary Get a promotion
   * @request GET:/partner/{partnerId}/promotion/{promotionId}
   * @secure
   * @response `200` `ThgPromotionViewModelGen` The promotion
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPromotionControllerFindOne = (partnerId: string, promotionId: string, params: RequestParams = {}) =>
    this.http.request<ThgPromotionViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/promotion/${promotionId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Promotion Service
   * @name PartnerPromotionControllerUpdate
   * @summary Update a promotion for a partner
   * @request PATCH:/partner/{partnerId}/promotion/{id}
   * @secure
   * @response `200` `ThgPromotionViewModelGen` The promotion
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPromotionControllerUpdate = (
    partnerId: string,
    id: string,
    data: ThgUpdatePromotionDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPromotionViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/promotion/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Promotion Service
   * @name PartnerPromotionControllerRemove
   * @summary Delete a promotion from a partner
   * @request DELETE:/partner/{partnerId}/promotion/{id}
   * @secure
   * @response `200` `ThgPromotionViewModelGen` The deleted Promotion
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPromotionControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<ThgPromotionViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/promotion/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
