var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": "Bearbeitungsstatus",
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('thg-admin-update-status-dialog', {
          attrs: {
            "loading": _vm.loading,
            "thg": _vm.thg
          },
          on: {
            "save": _vm.save
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v(" " + _vm._s(_vm.thgStatus(_vm.thg.status).icon))])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.thg.status) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }