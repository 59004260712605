var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('card', {
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": "Nachweise"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "loading": _vm.isLoading
          },
          on: {
            "click": _vm.openUpdateDialog
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.noProofs ? _c('v-card-text', {
    staticClass: "my-4"
  }, [_vm._v("Keine Nacheweise.")]) : _c('thg-charging-station-information-proofs', {
    attrs: {
      "proofs": _vm.thg.proofs
    }
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.updateDialog,
      "title": "Nachweise hinzufügen",
      "subtitle": "Hier kannst du Nachweise hinzufügen",
      "leftDisabled": _vm.isLoading,
      "rightDisabled": true
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.updateDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.updateDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.updateDialog = false;
      },
      "rightClick": function rightClick($event) {
        _vm.updateDialog = false;
      }
    }
  }, [_c('file-input', {
    attrs: {
      "show-size": "",
      "accept": "application/pdf, .pdf, image/jpeg, image/png",
      "label": "Dateien einzeln auswählen oder hier ablegen",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading,
      "maxFileSizeInMb": 10,
      "onlyPdf": false,
      "multiple": true,
      "outlined": true
    },
    on: {
      "change": _vm.onChange
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }