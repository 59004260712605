var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "elevation": "false",
      "color": "info",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v(" Download ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "title": _vm.$t('components.thg.ThgQuoteExportDialog.title'),
      "leftText": _vm.$t('abort'),
      "rightText": _vm.$t('components.thg.ThgQuoteExportDialog.btnText')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.exportExcel
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }