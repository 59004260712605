











import ThgListAdmin from "@/components/thg/ThgListAdmin.vue";
import ThgQuoteDetailCard from "@/components/thg/ThgQuoteDetailCard.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { handleError } from "@/lib/utility/handleError";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import { IThg } from "@/models/thg.entity";
import { IUser } from "@/models/user.entity";
import { ThgThgControllerFindAllPaginatedParamsGen } from "@/services/thg/v1/data-contracts";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { PaginatedBaseStore } from "@/store/paginated-base.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    ThgListAdmin,
    ThgQuoteDetailCard
  }
})
export default class ThgListAdminView extends mixins(PaginationListVueMixin) {
  isLoadingDetail = false;
  selectedUser: IUser | null = null;

  getModule(): PaginatedBaseStore<IThg, ThgThgControllerFindAllPaginatedParamsGen> {
    return ThgPaginationModule;
  }

  async mounted() {
    await this.refresh();

    const item = this.getModule().entities[0];
    if (item) {
      await this.loadDetail(item);
    }
  }

  async set(item: IThg) {
    if (this.$vuetify.breakpoint.smAndDown) {
      await this.$router.push({ name: "ThgQuoteDetailView", params: { thgId: item.id, partnerId: item.partnerId } });
    } else {
      await this.loadDetail(item);
    }
  }

  async loadDetail(thg: IThg) {
    try {
      this.isLoadingDetail = true;
      await ThgPortalModule.getSelectedThgByPartnerID({ thgId: thg.id, partnerId: thg.partnerId });

      this.selectedUser = await AdminUserPaginationModule.getSelectedUser(thg.userId);
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoadingDetail = false;
    }
  }
}
