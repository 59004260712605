















import { Vue, Component, Prop } from "vue-property-decorator";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IThg } from "@/models/thg.entity";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";

export interface AnalyticsQueryFilterEmitChangeData {
  filter: IPageFilterElement[];
  field?: keyof IThg;
}

@Component({})
export default class AnalyticsQueryFilter extends Vue {
  @Prop()
  items!: string[];
  @Prop()
  field!: keyof IThg;
  @Prop()
  labelText!: string;

  readonly defaultFilterValue = "All";

  selectedItem = "";
  queryFilter: IPageFilterElement[] = [];

  get requiredRule() {
    return [requiredRule()];
  }

  get displayItems() {
    return [...this.items, this.defaultFilterValue];
  }

  mounted() {
    this.selectedItem = this.defaultFilterValue;
  }

  onChange(filter: string) {
    if (filter === this.defaultFilterValue) {
      this.$emit("change", { filter: [], field: this.field } as AnalyticsQueryFilterEmitChangeData);
      return;
    }

    const fieldFilter = this.queryFilter.find(filter => filter.key === this.field);

    // Initialize filter
    if (!fieldFilter) {
      this.queryFilter.push(
        new PageFilterElement({
          key: this.field,
          operation: "$eq",
          value: filter
        })
      );
      this.$emit("change", { filter: this.queryFilter, field: this.field } as AnalyticsQueryFilterEmitChangeData);
      return;
    } else {
      // Replace filter value
      fieldFilter.value = filter;
      this.queryFilter[0] = fieldFilter;

      this.$emit("change", { filter: this.queryFilter, field: this.field } as AnalyticsQueryFilterEmitChangeData);
      return;
    }
  }
}
