








import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import ThgQuoteDetailCard from "@/components/thg/ThgQuoteDetailCard.vue";
import { Component, Vue } from "vue-property-decorator";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    LayoutSimple,
    ThgQuoteDetailCard
  }
})
export default class ThgQuoteDetailView extends Vue {
  loading = false;

  selectedUser: IAdminUser | null = null;

  async mounted() {
    this.loading = true;
    try {
      await PartnerModule.getPartnerById(this.$route.params.partnerId);
      const thg = await ThgPortalModule.getSelectedThg(this.$route.params.thgId);

      this.selectedUser = await AdminUserPaginationModule.getSelectedUser(thg.userId);
    } catch {
      this.$toast.error("Fehler beim Laden der Thg-Quote");
    } finally {
      this.loading = false;
    }
  }
}
