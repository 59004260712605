var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-list', {
    attrs: {
      "title": _vm.$t('navigation.ThePartnerList.setting.billingList'),
      "filterList": _vm.filterList,
      "loading": _vm.loading,
      "isLoadingMore": _vm.isLoadingMore,
      "getModule": _vm.getModule
    },
    on: {
      "loadMore": _vm.loadMore,
      "refresh": _vm.refresh
    },
    scopedSlots: _vm._u([{
      key: "options",
      fn: function fn() {
        return [_c('thg-billing-plausibility-check-dialog', {
          attrs: {
            "checkType": _vm.CheckEnum.BILLING,
            "marginRight": 4,
            "showTextButton": false
          }
        }), _c('thg-billing-create-new-dialog', {
          on: {
            "refresh": _vm.refresh
          }
        })];
      },
      proxy: true
    }, {
      key: "listItem",
      fn: function fn(_ref) {
        var listItem = _ref.listItem;
        return [listItem ? _c('v-list-item', {
          key: listItem.id,
          class: _vm.isActive(listItem),
          attrs: {
            "disabled": _vm.isLoadingDetail,
            "two-line": ""
          },
          on: {
            "click": function click($event) {
              return _vm.set(listItem);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(listItem.billingNumber))]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.getBillingTypeText(listItem.type)) + " ")])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.date(listItem)))])], 1)], 1) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }