/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg/thg-http-client.provider";
import { Efahrer } from "../thg/v1/Efahrer";
import { ThgEfahrerControllerFindAllParamsGen, ThgUpdateEfahrerThgOrderStatusDtoGen } from "../thg/v1/data-contracts";
import { HttpClient } from "../thg/v1/http-client";

export class EfahrerService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Efahrer;

  /**
   * Construct service
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Efahrer(this.client);
  }

  async getAll(query: ThgEfahrerControllerFindAllParamsGen) {
    return (await this.proxy.efahrerControllerFindAll(query)).data;
  }

  async get(id: number) {
    return (await this.proxy.efahrerControllerFindOne(id)).data;
  }

  async setStatus(orderId: number, status: ThgUpdateEfahrerThgOrderStatusDtoGen) {
    return (await this.proxy.efahrerControllerStatus(orderId, status)).data;
  }
}

export default new EfahrerService(new ThgHttpClientProvider());
