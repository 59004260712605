













































































































































import { calculateChargedCarsPerDayByChargingStation } from "@/lib/utility/calculate-charged-cars-per-day";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import Debug from "../utility/Debug.vue";
import Tooltip from "../utility/tooltip.vue";
import ThgChargingStationInformationProofs from "./ThgChargingStationInformationProofs.vue";

@Component({
  components: { Debug, Card, Tooltip, ThgChargingStationInformationProofs }
})
export default class ThgChargingStationInformationCard extends DarkModeHighlightMixin {
  @Prop({ default: {} })
  chargingStation!: ThgChargingStationViewModelGen;

  @Prop({ default: false })
  nav!: boolean;

  @Prop({ default: false })
  hideEdit?: boolean;

  @Prop({ default: false })
  showCarsPerDay?: boolean;

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };

    return options;
  }

  get partner() {
    return PartnerModule.partner;
  }

  getEvseId(powerOutletId: string) {
    if (!this.chargingStation.chargingPointOperator) {
      return "";
    }

    return `${this.chargingStation.address.countryCode}*${this.chargingStation.chargingPointOperator.evseOperatorId}*E${powerOutletId}`;
  }

  toDate(date: string) {
    return new Date(date).toLocaleString("de-DE", this.dateOptions as any);
  }

  get hasGeo() {
    return (
      this.chargingStation.address?.geo &&
      this.chargingStation.address.geo.lat > 0 &&
      this.chargingStation.address.geo.lng > 0
    );
  }

  get amountOfCarsPerDay() {
    const batteryCapacityInkWh = 50; // Like a standard Tesla Model 3

    return calculateChargedCarsPerDayByChargingStation(this.chargingStation, batteryCapacityInkWh);
  }
}
