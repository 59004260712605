var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "title": _vm.$t('components.thg.thgCreditorCsvSelection.creditors'),
      "controlElements": _vm.controlElements,
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {},
      "selectedItems": _vm.selected,
      "showSelect": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "headerActions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.openCreateCreditorDialog
                }
              }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgCreditorCsvSelection.createCreditor")))])])];
      },
      proxy: true
    }, {
      key: "bodyActions",
      fn: function fn() {
        return [_vm.selected.length ? _c('v-btn', {
          attrs: {
            "color": "success"
          },
          on: {
            "click": _vm.startCreditorCsvGeneration
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelection.startCreditorCsvGeneration")) + " ")]) : _vm._e(), _c('thg-billing-plausibility-check-dialog', {
          attrs: {
            "checkType": _vm.CheckEnum.ACCOUNT,
            "marginRight": 4
          }
        })];
      },
      proxy: true
    }, {
      key: "selectActions",
      fn: function fn(_ref2) {
        var selected = _ref2.selected;
        return [_c('v-btn', {
          attrs: {
            "color": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.startCreditorCsvGeneration(selected);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelection.startCreditorCsvGeneration")) + " ")])];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToUserDetail(item.userId);
            }
          }
        }, [_vm._v(_vm._s(item.userId))])];
      }
    }, {
      key: "item.partnerId",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToPartnerDetail(item.partnerId);
            }
          }
        }, [_vm._v(_vm._s(item.partnerId))])];
      }
    }])
  }), _c('debug', {
    attrs: {
      "debug": _vm.allAccounts
    }
  }), _vm.createCreditorDialog ? _c('thg-billing-creditor-create-dialog', {
    attrs: {
      "isDialogActive": _vm.createCreditorDialog
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.createCreditorDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.createCreditorDialog = $event;
      }
    }
  }) : _vm._e(), _c('thg-billing-creditor-update-dialog', {
    attrs: {
      "account": _vm.updateCreditorItem,
      "isDialogActive": _vm.updateCreditorDialog
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.updateCreditorDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.updateCreditorDialog = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }