var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "wrapper"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.isSelectedAffiliate ? _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "2000"
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.selectedAffiliate.code))])]), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.selectedAffiliate.id) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.displayToDetailView ? _c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.toDetailView
          }
        }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v("Zur Detail Ansicht")])])], 1), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('affiliate-detail-information-card', {
    attrs: {
      "selectedAffiliate": _vm.selectedAffiliate
    }
  })], 1)], 1) : _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.emptyDataSrc,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.else.noData")))])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }