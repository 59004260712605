












import Card from "@/components/utility/Card.vue";
import Component, { mixins } from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";

@Component({
  components: { Card }
})
export default class ThgBillingBatchNumberData extends mixins(Vue) {
  @Prop()
  value!: number;
  @Prop()
  title!: string;
  @Prop()
  label!: string;

  /**
   * titles, texts and translations. Whatever the modern connoisseur of fine billings may desire
   */
  get i18n() {
    return this.$t("views.ThgBillingBatchSelectionItemView") || {};
  }
  get _value() {
    return this.value;
  }
  set _value(value: number) {
    this.$emit("update:value", value);
  }
}
