var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [_vm.thg ? _c('div', {
    staticClass: "dataContainer flexContainer"
  }, [_c('thg-billing-selection-item-contact-data', {
    staticClass: "ma-1",
    attrs: {
      "userId": _vm.thg.userId
    }
  }), _c('thg-billing-selection-item-registration-data', {
    staticClass: "ma-1",
    attrs: {
      "registration": _vm.thg.registration
    }
  }), _c('thg-billing-selection-item-registration-image', {
    staticClass: "ma-1",
    attrs: {
      "thg": _vm.thg,
      "reload": true
    }
  }), _c('div', {
    style: "display: flex; flex-direction: column; width: ".concat(_vm.width)
  }, [_c('thg-billing-selection-item-payout-data', {
    staticClass: "ma-1",
    attrs: {
      "thg": _vm.thg
    }
  }), _c('thg-billing-selection-item-partner-data', {
    staticClass: "ma-1",
    attrs: {
      "partnerId": _vm.thg.partnerId
    }
  })], 1), _c('thg-billing-selection-item-batch-data', {
    staticClass: "ma-1",
    attrs: {
      "thgId": _vm.thg.id
    }
  }), _vm.thg ? _c('thg-billing-selection-item-banking-data', {
    staticClass: "ma-1",
    attrs: {
      "userId": _vm.thg.userId
    }
  }) : _vm._e()], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }