import { render, staticRenderFns } from "./DataCard.vue?vue&type=template&id=18a30aad&"
import script from "./DataCard.vue?vue&type=script&lang=ts&"
export * from "./DataCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCardActions,VIcon,VSkeletonLoader,VTooltip})
