
















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import Debug from "../utility/Debug.vue";

@Component({
  components: { Debug, Card }
})
export default class ThgMeterReadingDetailTable extends DarkModeHighlightMixin {
  @Prop({ default: [] })
  meterReadings!: ThgThgMeterReadingViewModelGen[];

  get meterReadingHeaders() {
    return [
      { text: "", value: null },
      { text: this.$t("components.thg.ThgVehicleTable.table.status"), value: "status" },
      { text: this.$t("components.thg.ThgChargingStationOverviewCard.year"), value: "year" },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.startDate"),
        value: "meterReading.startDate"
      },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.endDate"),
        value: "meterReading.endDate"
      },
      {
        text: this.$t("components.thg.ThgChargingStationMeterReadingTable.amountInKwh"),
        value: "meterReading.amountInKwh"
      }
    ];
  }

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  handleClick(value: any) {
    this.$router.push({
      name: "ThgMeterReadingDetailView",
      params: {
        meterReadingId: value.id
      }
    });
  }
}
