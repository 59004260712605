






















































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { BillingModule } from "@/store/modules/billing.store";
import {
  ThgAccountingRecordItemViewmodelGen,
  ThgPartnerCommissionPerYearDtoGen
} from "@/services/thg/v1/data-contracts";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import Card from "../utility/Card.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { OperationModule } from "@/store/modules/operation.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { Billing } from "@/models/billing.entity";

@Component({ components: { Debug, Card, LatestEntriesCardEmpty, ConfirmActionDialog } })
export default class ThgBillingSelectionItemAccountingResult extends DarkModeHighlightMixin {
  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  @Prop()
  billing: any;

  @Prop()
  commissions!: ThgPartnerCommissionPerYearDtoGen[];

  @Prop()
  pricePerKwH!: number;
  get _pricePerKwH() {
    return this.pricePerKwH;
  }
  set _pricePerKwH(pricePerKwH: number) {
    this.$emit("update:pricePerKwH", pricePerKwH);
  }

  accountRecordIndex = 0;

  get partnerCommissions(): ThgPartnerCommissionPerYearDtoGen[] {
    return this.commissions;
  }

  set partnerCommissions(commissions: ThgPartnerCommissionPerYearDtoGen[]) {
    this.$emit("update:commissions", commissions);
  }

  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  onRightClick() {
    this.accountRecordIndex = (this.accountRecordIndex + 1) % (this.accountRecords.length + 1);
  }

  onLeftClick() {
    this.accountRecordIndex = (this.accountRecordIndex - 1) % (this.accountRecords.length + 1);
  }

  locked = true;

  accountRecordsLoading = false;
  accountRecords: any[] = [];
  isRegenerateDialogActive = false;

  addAccountingStatement() {
    this.accountRecords.push({
      amount: 0,
      debitOrCredit: "",
      account: 0,
      contraAccount: 0,
      bookingText: ""
    });
  }

  @Watch("billing", { deep: true })
  async mounted() {
    this.accountRecords = this.billing.accountRecord;
  }

  async handleRegenerate() {
    if (
      this.billing &&
      [BillingTypeEnum.CREDIT_PARTNER, BillingTypeEnum.CREDIT_CHARGING_STATION].includes(this.billing.type)
    ) {
      this.isRegenerateDialogActive = true;
    } else {
      await this.regenerate();
    }
  }

  async refresh() {
    await BillingModule.getBilling(this.billing.billingNumber);
    if (this.$route.params.i) {
      BillingModule.addToCreatedBillings({
        index: parseInt(this.$route.params.i),
        createdBilling: BillingModule.createdBilling
      });
    }
    this.accountRecords = BillingModule.createdBilling.accountRecord;
  }

  async confirmEdit() {
    this.accountRecordsLoading = true;
    const accountingItems: ThgAccountingRecordItemViewmodelGen[] = [];
    this.accountRecords.forEach((a: any) => {
      if (a) {
        accountingItems.push({
          amount: parseFloat(a.amount),
          account: a.account,
          contraAccount: parseFloat(a.contraAccount),
          debitOrCredit: a.debitOrCredit,
          bookingText: a.bookingText
        });
      }
    });
    try {
      await new Billing(this.billing).updateAccountingItem(accountingItems);
      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
      this.refresh();
    } catch (e) {
      this.$toast.error((e as any).message);
    }
    this.locked = true;
    this.accountRecordsLoading = false;
  }

  async regenerate() {
    try {
      this.accountRecordsLoading = true;
      if (this.billing.referenceIds && this.billing.referenceIds.length > 50) {
        await this.regenerateAsync();
      } else {
        await this.regenerateSync();
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.accountRecordsLoading = false;
    }
  }

  async regenerateSync() {
    const isBatchBilling = this.billing.referenceIds?.length > 0;

    await BillingModule.regenerateAccountingItem({
      billingNumber: this.billing.billingNumber,
      isBatchBilling: isBatchBilling,
      partnerCommissions: this.partnerCommissions.map(c => {
        return { partnerCommission: Number(c.partnerCommission), year: Number(c.year) };
      }),
      pricePerKwh: Number(this._pricePerKwH || 0)
    });
    await this.refresh();
    this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
  }

  /**
   * Regenerates the pdf using a background job
   */
  async regenerateAsync() {
    const operationId = await BillingModule.regenerateAccountingRecordsAsync({
      billingNumber: this.billing.billingNumber,
      data: {
        partnerCommissions: this.partnerCommissions.map(c => {
          return { partnerCommission: Number(c.partnerCommission), year: Number(c.year) };
        }),
        pricePerKwh: Number(this._pricePerKwH || 0)
      }
    });

    OperationModule.dispatchToast({
      operationId,
      onClick: () => {
        new GoToHelper(this.$router).goToOperationDetail(operationId);
      }
    });
  }

  removeAccountRecord(index: number) {
    this.accountRecords.splice(index, 1);
  }
}
