var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('thg-list-admin', {
          attrs: {
            "loading": _vm.loadingAll
          },
          on: {
            "set": _vm.set,
            "refresh": _vm.refresh,
            "loadMore": _vm.loadMore
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('thg-quote-detail-card', {
          attrs: {
            "loading": _vm.isLoadingDetail,
            "selectedUser": _vm.selectedUser
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }