























import ThgBillingList from "@/components/thg/ThgBillingList.vue";
import ThgBillingNumberDetail from "@/components/thg/ThgBillingNumberDetail.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { BillingDataLoader } from "@/lib/utility/billing-data-loader";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import { BillingModule } from "@/store/modules/billing.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    ThgBillingList,
    ThgBillingNumberDetail
  }
})
export default class ThgBillingListView extends PaginationListVueMixin {
  isLoadingDetail = false;

  getModule() {
    return BillingModule;
  }

  billingDataLoader: BillingDataLoader | null = null;

  get billingNumber() {
    return this.billingDataLoader?.documents.billing?.billingNumber;
  }

  async mounted() {
    BillingModule.setIsLoadAll(false);

    await this.refresh();

    const billing = this.getModule().paginationList[0];
    if (billing) {
      this.loadBilling(billing.billingNumber);
    }
  }

  set(item: any) {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push({ name: "ThgBillingNumberDetailView", params: { billingNumber: item.billingNumber } });
    } else {
      this.loadBilling(item.billingNumber);
    }
  }

  loadBilling(billingNumber: number) {
    this.billingDataLoader = null;
    this.$nextTick(() => {
      this.billingDataLoader = new BillingDataLoader(billingNumber);
    });
  }
}
