











































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { IThg } from "@/models/thg.entity";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemPayoutData extends DarkModeHighlightMixin {
  @Prop()
  thg!: IThg;

  locked = true;

  updatingRegistrationResults = false;

  get impactTypeEnumValues() {
    return Object.values(ImpactTypeEnum);
  }

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  async editPayoutConfiguration() {
    const thgUpdateThgDtoGen = {
      numberplate: this.thg.numberplate,
      impactType: this.thg.impactType,
      impactFactor: parseInt(this.thg.impactFactor.toString()),
      payoutConfiguration: {
        isFixed: this.thg.payoutConfiguration.isFixed,
        revenue: parseInt(this.thg.payoutConfiguration.revenue?.toString())
      }
    };
    this.updatingRegistrationResults = true;
    try {
      await ThgPortalModule.updateThg(thgUpdateThgDtoGen);
      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
    } catch (e) {
      this.$log.error(e);

      this.$toast.error((e as any).message);
    }
    this.locked = true;
    this.updatingRegistrationResults = false;
  }
}
