












































import { Component, Prop, Vue } from "vue-property-decorator";
import { BillingModule } from "@/store/modules/billing.store";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { ThgOperationIdViewModelGen } from "@/services/thg/v1/data-contracts";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";

/**
 * Starts operation in backend to credit-bill thgs automatically
 */
@Component({ components: { ConfirmActionDialog } })
export default class ThgBillingCreditAutoBillDialog extends Vue {
  @Prop({ default: false })
  isBatch?: boolean;

  isDialogActive = false;

  loading = false;

  /**
   * Suppress creation of billings and only check for complteness. (applies to batch billing only)
   */
  checkCompletenessOnly = false;

  /**
   * The type to autobill. (applies to batch billing only)
   */
  billingTypes: BillingTypeEnum[] = [];

  get i18n() {
    return this.$t("components.thg.ThgBillingCreditAutoBillDialog");
  }

  /**
   * Gets all labels for the available billing types
   */
  get typeLabels() {
    return [BillingTypeEnum.CREDIT, BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT];
  }

  goToOperationDetail(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, undefined, true);
  }

  async autoBill() {
    try {
      this.loading = true;

      let results: ThgOperationIdViewModelGen;
      if (!this.isBatch) {
        results = await BillingModule.creditAutoBilling();
      } else {
        results = await BillingBatchModule.dispatchAutoBill({
          checkCompletenessOnly: this.checkCompletenessOnly,
          types: this.billingTypes
        });
      }

      this.$toast.success(this.$t("components.thg.thgBillingAutoBillDialog.startedAutoBilling"), {
        onClick: () => this.goToOperationDetail(results.operationId)
      });
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.isDialogActive = false;
      this.loading = false;
    }
  }
}
