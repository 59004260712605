/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import {
  ThgPageViewModelGen,
  ThgAdminPromotionControllerFindAllAdminParamsGen,
  ThgPromotionViewModelGen
} from "../v1/data-contracts";
import { AdminPromotionService } from "../v1/AdminPromotionService";

/**
 * Service to talk to the promotion endpoint.
 */
export class PromotionAdminService {
  private readonly promotion: AdminPromotionService;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.promotion = new AdminPromotionService(clientProvider.client());
  }

  /**
   * Gets all promotions across all partner
   * @returns
   */
  async findAllAdmin(
    query: ThgAdminPromotionControllerFindAllAdminParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgPromotionViewModelGen[] | undefined }> {
    return (await this.promotion.adminPromotionControllerFindAllAdmin(query)).data;
  }
}

export default new PromotionAdminService(new ThgHttpClientProvider());
