






























import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBatchDetailViewmodelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { Card }
})
export default class ThgBatchDetailInformationChangeHistoryCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  batch!: ThgBatchDetailViewmodelGen;

  date(timestamp: any) {
    return simpleDate(timestamp.created);
  }
}
