
































import AnalyticsDataTableDialog from "@/components/analytics/AnalyticsDataTableDialog.vue";
import AnalyticsKpiColumn from "@/components/analytics/AnalyticsKpiColumn.vue";
import { IKpi } from "@/lib/interfaces/kpi-interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBaseChartItemViewmodelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { AnalyticsKpiColumn, AnalyticsDataTableDialog, Card }
})
export default class TableDataCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: [] })
  data!: ThgBaseChartItemViewmodelGen[];

  @Prop({ default: 9 })
  showMaxItems!: number;

  @Prop({
    default() {
      return [];
    }
  })
  kpiGroup!: IKpi[];

  search = "";

  get shopwKpiGroup(): boolean {
    return this.kpiGroup.length != 0;
  }

  get previewData(): ThgBaseChartItemViewmodelGen[] {
    return this.data.slice(0, this.showMaxItems);
  }

  get headers() {
    return [
      { text: "Vin", value: "name" },
      { text: "Jahr", value: "value" }
    ];
  }
}
