

























































































































































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ThgBillingControllerGetAllBillingsParamsGen, ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { Component, Ref } from "vue-property-decorator";
import { BillingModule } from "@/store/modules/billing.store";
import { ConfigModule } from "@/store/modules/config";
import ContextMenu from "@/components/utility/ContextMenu.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { handleError } from "@/lib/utility/handleError";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { CheckEnum } from "@/lib/enum/check.enum";
import ThgBillingSideCard from "./ThgBillingSideCard.vue";
import { IBilling, Billing } from "@/models/billing.entity";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";

@Component({
  components: {
    PaginatedTable,
    TheLayoutPortal,
    TableWrapper,
    LatestEntriesCardEmpty,
    FilterCardPagination,
    Debug,
    ContextMenu,
    ThgBillingSideCard
  }
})
export default class ThgBillingAccountingSelectionView extends DarkModeHighlightMixin {
  readonly BillingTypeEnum = BillingTypeEnum;

  @Ref("paginationTable")
  paginationTable!: PaginatedTable<ThgBillingViewmodelGen, ThgBillingControllerGetAllBillingsParamsGen>;

  predefinedFilter = [];

  expanded = [];

  publishDialog = false;

  publishConfirmationDialog = false;

  isPublish = false;

  isNotifyUsers = false;

  selected: ThgBillingViewmodelGen[] = [];

  readonly CheckEnum = CheckEnum;

  map = new Map<number, string>();

  isExpandDialogActive = false;

  selectedForExpansion: IBilling | null = null;

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get pagination() {
    return BillingModule;
  }

  get headers() {
    const headers = [
      { text: this.$t("components.thg.thgBillingCsvSelection.created"), value: "timestamp.created" },
      { text: this.$t("components.thg.thgBillingCsvSelection.billingNumber"), value: "billingNumber" },
      { text: this.$t("components.thg.thgBillingCsvSelection.type"), value: "type" },
      { text: this.$t("components.thg.thgBillingCsvSelection.thgId"), value: "thgId" },
      {
        text: this.$t("components.thg.thgBillingCsvSelection.relatedEntities"),
        value: "relatedParties",
        sortable: false
      },
      { text: this.$t("components.thg.thgBillingCsvSelection.isPublished"), value: "isPublished", align: "center" },
      {
        text: this.$t("components.thg.thgBillingCsvSelection.accountRecord"),
        value: "accountRecord.length",
        align: "end",
        width: "120px"
      },
      {
        text: this.$t("components.thg.thgBillingCsvSelection.accountRecordTotal"),
        value: "accountRecordTotal",
        align: "end",
        width: "120px"
      }
    ];

    if (ConfigModule.debug) {
      headers.unshift({ text: "index", value: "index" }, { text: this.$t("id"), value: "id" });
    }

    return headers;
  }

  get confirmationDialogText() {
    const pluralString = this.selected.length > 1 ? "Plural" : "";

    if (!this.isPublish) {
      return String(
        this.$t(`components.thg.thgBillingSelectionItemBillingResult.confirmText.private${pluralString}`, {
          amount: this.selected.length
        })
      );
    }

    if (!this.isNotifyUsers) {
      return String(
        this.$t(`components.thg.thgBillingSelectionItemBillingResult.confirmText.publicNoMail${pluralString}`, {
          amount: this.selected.length
        })
      );
    }

    return String(
      this.$t(`components.thg.thgBillingSelectionItemBillingResult.confirmText.publicMail${pluralString}`, {
        amount: this.selected.length
      })
    );
  }

  goToPartnerDetail(partnerId: string) {
    this.$router.push({ name: "PartnerDetailView", params: { partnerId: partnerId } });
  }

  goToUserDetail(userId: string) {
    this.$router.push({ name: "UserAdministrationDetail", params: { userId: userId } });
  }

  goToBilling(billingNumber: number) {
    this.$router.push({ name: "ThgBillingNumberDetailView", params: { billingNumber: billingNumber.toString() } });
  }

  goToThgDetail(partnerId: string, thgId: string) {
    this.$router.push({
      name: "ThgQuoteDetailView",
      params: { partnerId: partnerId, thgId: thgId }
    });
  }

  goToMeterReadingDetail(partnerId: string, thgId: string) {
    new GoToHelper(this.$router).goToMeterReadingDetail(partnerId, thgId, true);
  }

  goToOperationDetail(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, undefined, true);
  }

  openPublishConfirmationDialog() {
    this.publishDialog = false;

    this.publishConfirmationDialog = true;
  }

  closePublishConfirmationDialog() {
    this.publishDialog = true;

    this.publishConfirmationDialog = false;
  }

  async startCsvGeneration() {
    const billingNumbers = this.selected.map((s: ThgBillingViewmodelGen) => s.billingNumber);

    try {
      const operationId = await BillingBatchModule.dispatchBillingCsv({ billingNumbers });

      this.$toast.success(this.$t("components.thg.thgBillingCsvSelection.startedCsvGenerations"), {
        onClick: () => this.goToOperationDetail(operationId)
      });
    } catch (e) {
      handleError(e);
    }
  }

  async onExpand(p: ThgBillingViewmodelGen) {
    this.selectedForExpansion = null;
    this.isExpandDialogActive = false;

    this.$nextTick(() => {
      this.selectedForExpansion = new Billing(p);
      this.isExpandDialogActive = true;
    });
  }

  async publishSelected() {
    const billingNumbers = this.selected.map(billing => billing.billingNumber);

    try {
      const operationId = (await BillingModule.publishBatch({
        billingNumbers: billingNumbers,
        publish: this.isPublish,
        notifyUsers: this.isNotifyUsers
      })) as any;

      this.$log.info(operationId.operationId);

      this.$toast.success(this.$t("components.thg.thgBillingCsvSelection.operationDetail"), {
        onClick: () => this.goToOperationDetail(operationId.operationId)
      });
    } catch (e) {
      handleError(e);
    } finally {
      this.publishConfirmationDialog = false;

      await BillingModule.fetchAllFromBeginning({});
    }
  }
}
