import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import insuranceService from "@/services/thg/services/insuranceService";
import {
  ThgCreateInsuranceDtoGen,
  ThgInsuranceControllerGetAllParamsGen,
  ThgInsuranceViewModelGen,
  ThgPageViewModelGen
} from "@/services/thg/v1/data-contracts";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import store from "@/store/VuexPlugin";

@Module({
  dynamic: true,
  namespaced: true,
  name: "adminInsurance",
  store
})
export class AdminInsuranceStore extends BasePagination<
  ThgInsuranceViewModelGen,
  ThgInsuranceControllerGetAllParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: ThgInsuranceViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [
    { key: "name", type: PageFilterTypes.STRING },
    { key: "number", type: PageFilterTypes.STRING },
    { key: "policyStartDate", type: PageFilterTypes.STRING },
    { key: "policyEndDate", type: PageFilterTypes.STRING },
    { key: "isActive", type: PageFilterTypes.BOOLEAN }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  protected async loadDocuments(
    query: ThgInsuranceControllerGetAllParamsGen
  ): Promise<
    ThgPageViewModelGen & {
      data?: ThgInsuranceViewModelGen[] | undefined;
    }
  > {
    return insuranceService.getAll(query);
  }

  /**
   * A single selected insurance
   */
  private _insurance: ThgInsuranceViewModelGen | undefined;

  /**
   * get insurance
   */
  get insurance() {
    return this._insurance;
  }

  @Mutation
  mutateInsurance(insurance: ThgInsuranceViewModelGen) {
    this._insurance = insurance;
  }

  /**
   * Deletes an insurance
   */
  @Action
  async delete(data: { userId: string; insuranceId: string }) {
    const deleted = await insuranceService.delete(data.userId, data.insuranceId);
    this.context.commit("mutateInsurance", deleted);
  }
  /**
   * Deletes an insurance
   */
  @Action
  async create(data: { userId: string; data: ThgCreateInsuranceDtoGen }) {
    const created = await insuranceService.create(data.userId, data.data);
    this.context.commit("mutateInsurance", created);
  }
}
export const AdminInsuranceModule = getModule(AdminInsuranceStore);
