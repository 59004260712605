
















































import FilterCard from "@/components/filter/FilterCard.vue";
import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({ components: { ThgQuoteExportDialog, FilterCard } })
export default class ThgMeterReadingListCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  search = "";
  selected: ThgThgMeterReadingViewModelGen = ThgPortalModule.selectedMeterReading;
  thgStatusMap = thgStatusMap;

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      this.thgStatusMap.get(thgStatusEnum) || {
        text: "Alles im grünen Bereich.",
        icon: "mdi-help",
        portalText: thgStatusEnum,
        color: "error"
      }
    );
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get meterReadings() {
    return ThgPortalModule.allMeterReadings;
  }

  isActive(item: ThgThgMeterReadingViewModelGen) {
    return item.id === ThgPortalModule.selectedMeterReading.id ? `grey ${this.color}` : "";
  }

  date(item: ThgThgMeterReadingViewModelGen): string {
    return simpleDate(item.timestamp?.created);
  }

  simpleDate(date: string): string {
    return simpleDate(date);
  }

  isLoadingDetail(status: boolean) {
    this.$emit("isLoadingDetail", status);
  }

  async set(item: ThgThgMeterReadingViewModelGen) {
    this.isLoadingDetail(true);
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "ThgMeterReadingDetailView", params: { meterReadingId: item.id } });
    } else {
      const user = await AdminUserPaginationModule.getSelectedUser(item.userId);
      if (!user) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noUserFound")));
      }

      const meterReading = await ThgPortalModule.getSelectedMeterReading(item.id);
      if (!meterReading || !item.meterReading.chargingStationId) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      } else {
        const chargingStation = await ThgPortalModule.getSelectedChargingStation(item.meterReading.chargingStationId);
        const meterReadings = await ThgPortalModule.getMeterReadingForChargingStation(
          item.meterReading.chargingStationId
        );

        if (!chargingStation) {
          Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
        }
        if (!meterReadings) {
          Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
        }
      }
    }
    this.isLoadingDetail(false);
  }

  //Filter:

  /**
   * The available categories visible in the filter menu
   */
  filterList = getDateFilterList("timestamp");

  items = this.meterReadings;

  get filteredItems() {
    return this.items;
  }
}
