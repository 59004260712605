






import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import ThgBillingAccountingSelection from "@/components/thg/ThgBillingAccountingSelection.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    ThgBillingAccountingSelection
  }
})
export default class ThgBillingAccountingSelectionView extends Vue {}
