var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('batch-list-card', {
          attrs: {
            "loading": _vm.loadingAll
          },
          on: {
            "isLoadingDetail": _vm.setloadingSelectedBatch
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('batch-detail-card', {
          attrs: {
            "loading": _vm.getloadingSelectedBatch
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }