





































import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { CheckEnum } from "@/lib/enum/check.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import { ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { BillingModule } from "@/store/modules/billing.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import ThgBillingCreateNewDialog from "./ThgBillingCreateNewDialog.vue";
import ThgBillingPlausibilityCheckDialog from "./ThgBillingPlausibilityCheckDialog.vue";

@Component({
  components: {
    ThgQuoteExportDialog,
    ThgBillingPlausibilityCheckDialog,
    ThgBillingCreateNewDialog,
    TheLayoutPortalList
  }
})
export default class ThgBillingList extends mixins(DarkModeHighlightMixin, PaginationListMixin) {
  get CheckEnum() {
    return CheckEnum;
  }

  getBillingTypeText(billingType: BillingTypeEnum) {
    return this.$t("components.thg.thgBillingCreateNewBillingManual")[billingType] || billingType;
  }

  getModule() {
    return BillingModule;
  }

  isActive(item: ThgBillingViewmodelGen) {
    return item.billingNumber === BillingModule.createdBilling.billingNumber ? this.color : "";
  }
}
