var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('data-card', {
    attrs: {
      "title": _vm.$t('components.thg.thgBillingSelectionContactData.companyData'),
      "locked": _vm.locked,
      "confirmEdit": _vm.confirmEdit
    },
    on: {
      "update:locked": function updateLocked($event) {
        _vm.locked = $event;
      }
    }
  }, [_vm.data ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.company'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.data.isTaxDeductible && !_vm.data.taxnumber ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3455975242),
    model: {
      value: _vm.data.company,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "company", $$v);
      },
      expression: "data.company"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.taxnumber'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.data.isTaxDeductible && !_vm.data.taxnumber ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3455975242),
    model: {
      value: _vm.data.taxnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "taxnumber", $$v);
      },
      expression: "data.taxnumber"
    }
  }), _c('v-select', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.isTaxDeductible'),
      "items": [true, false],
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.data.isTaxDeductible,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "isTaxDeductible", $$v);
      },
      expression: "data.isTaxDeductible"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.data
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }