/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import counterService from "@/services/thg/services/counterService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "counter",
  store
})
export class CounterStore extends VuexModule {
  private _counterSequence = -1;

  get counterSequence(): number {
    return this._counterSequence;
  }

  @Mutation
  mutateCounterSequence(counterSequence: number) {
    this._counterSequence = counterSequence;
  }

  @Action
  async getCounterController(counterName: string): Promise<void> {
    const counter = await counterService.getCounterController(counterName);

    this.context.commit("mutateCounterSequence", counter.sequence);
  }
}

export const CounterModule = getModule(CounterStore);
