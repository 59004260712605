/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreateInsuranceDtoGen,
  ThgExceptionViewmodelGen,
  ThgInsuranceControllerGetAllParamsGen,
  ThgInsuranceViewModelGen,
  ThgPageViewModelGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Insurance<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerGetAll
   * @summary (ADMIN) Get all insurances
   * @request GET:/insurance/all
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgInsuranceViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerGetAll = (query: ThgInsuranceControllerGetAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgInsuranceViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/insurance/all`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerCreateForUser
   * @summary (ADMIN) Create a insurance for a user
   * @request POST:/user/{userId}/insurance
   * @secure
   * @response `201` `ThgInsuranceViewModelGen` Created Insurance
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerCreateForUser = (userId: string, data: ThgCreateInsuranceDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/user/${userId}/insurance`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerFindOneByUser
   * @summary (ADMIN) Gets an insurance of a user
   * @request GET:/user/{userId}/insurance/{insuranceId}
   * @secure
   * @response `200` `ThgInsuranceViewModelGen` Found Insurance
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerFindOneByUser = (userId: string, insuranceId: string, params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/user/${userId}/insurance/${insuranceId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerDeleteForUser
   * @summary (ADMIN) Delete an insurance for a user
   * @request DELETE:/user/{userId}/insurance/{insuranceId}
   * @secure
   * @response `200` `ThgInsuranceViewModelGen` the deleted insurance
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerDeleteForUser = (userId: string, insuranceId: string, params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/user/${userId}/insurance/${insuranceId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerCreateForRequestingUser
   * @summary (USER) Create a insurance for requesting user
   * @request POST:/insurance/me
   * @secure
   * @response `201` `ThgInsuranceViewModelGen` Created Insurance
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerCreateForRequestingUser = (data: ThgCreateInsuranceDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/insurance/me`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerFindByRequestingUser
   * @summary (USER) Gets the insurances of the requesting user
   * @request GET:/insurance/me
   * @secure
   * @response `200` `ThgInsuranceViewModelGen` Found Insurances
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerFindByRequestingUser = (params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/insurance/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerFindOneByRequestingUser
   * @summary (USER) Gets the insurances of the requesting user
   * @request GET:/insurance/{insuranceId}/me
   * @secure
   * @response `200` `(ThgInsuranceViewModelGen)[]` Found Insurance
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerFindOneByRequestingUser = (insuranceId: string, params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/insurance/${insuranceId}/me`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags insurance
   * @name InsuranceControllerDeleteForRequestingUser
   * @summary (USER) Delete an insurance for a requesting user
   * @request DELETE:/insurance/{insuranceId}/me
   * @secure
   * @response `200` `ThgInsuranceViewModelGen` the deleted insurance
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  insuranceControllerDeleteForRequestingUser = (insuranceId: string, params: RequestParams = {}) =>
    this.http.request<ThgInsuranceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/insurance/${insuranceId}/me`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
