var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('components.thg.thgBillingCsvSelection.createCreditorDialog'),
      "isDialogActive": _vm.dialogActive,
      "max-width": "600",
      "leftDisabled": _vm.creatingCreditor,
      "rightDisabled": _vm.creatingCreditor || !_vm.accountDto.userId && !_vm.accountDto.partnerId,
      "rightLoading": _vm.creatingCreditor
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.dialogActive = false;
      },
      "rightClick": _vm.createCreditor
    }
  }, [_c('v-row', {
    staticClass: "ml-1 mr-1 mt-2"
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": _vm.$t('components.thg.thgBillingCsvSelection.creditorNumber'),
      "outlined": "",
      "rounded": "",
      "value": _vm.accountNumber + 1,
      "loading": _vm.counterLoading,
      "disabled": ""
    }
  })], 1), _c('v-row', {
    staticClass: "ml-1 mr-1 mt-2"
  }, [_c('v-btn-toggle', {
    staticClass: "mb-1",
    attrs: {
      "rounded": "",
      "dense": ""
    },
    model: {
      value: _vm.userOrPartner,
      callback: function callback($$v) {
        _vm.userOrPartner = $$v;
      },
      expression: "userOrPartner"
    }
  }, [_c('v-btn', {
    on: {
      "click": function click($event) {
        _vm.accountDto.partnerId = undefined;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-account")])], 1), _c('v-btn', {
    on: {
      "click": function click($event) {
        _vm.accountDto.userId = undefined;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-human-male-female")])], 1)], 1), _vm.userOrPartner === 0 ? _c('thg-billing-creditor-selection-user-list', {
    attrs: {
      "userId": _vm.accountDto.userId
    },
    on: {
      "update:userId": function updateUserId($event) {
        return _vm.$set(_vm.accountDto, "userId", $event);
      },
      "update:user-id": function updateUserId($event) {
        return _vm.$set(_vm.accountDto, "userId", $event);
      }
    }
  }) : _vm._e(), _vm.userOrPartner === 1 ? _c('thg-billing-creditor-selection-partner-list', {
    attrs: {
      "partnerId": _vm.accountDto.partnerId
    }
  }) : _vm._e()], 1), _c('v-switch', {
    staticClass: "ml-4 mr-4",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemBillingResult.verifyData')
    },
    model: {
      value: _vm.verifyData,
      callback: function callback($$v) {
        _vm.verifyData = $$v;
      },
      expression: "verifyData"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }