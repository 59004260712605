

































































































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { AffiliateTypeEnum } from "@/lib/enum/AffiliateType.enum";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgUpdateAffiliateDtoGen } from "@/services/thg/v1/data-contracts";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class AffiliateUpdateDialog extends DarkModeHighlightMixin {
  isLoading = false;
  dialog = false;
  valid = false;
  isLoadingUsers = false;
  isUser = false;
  showUserSwitch = false;

  title = "components.thg.AffiliateUpdateDialog.title";
  btnText = "components.thg.AffiliateUpdateDialog.btnText";

  affiliateTypeEnum = Object.keys(AffiliateTypeEnum).map(k => AffiliateTypeEnum[k as any]);

  updateAffiliate: ThgUpdateAffiliateDtoGen = {
    code: "",
    type: AffiliateTypeEnum.FREINDS as any,
    note: "",
    provision: 0,
    discountForCustomer: 0,
    isValid: true,
    userId: undefined
  };

  get isActivatedText(): string {
    if (this.updateAffiliate.isValid) {
      return this.$t("components.thg.AffiliateUpdateDialog.isValid").toString();
    } else {
      return this.$t("components.thg.AffiliateUpdateDialog.isNotValid").toString();
    }
  }

  get loading() {
    return this.isLoading;
  }

  get users() {
    return AdminUserPaginationModule.paginationList;
  }

  async loadUsers() {
    this.isLoadingUsers = true;
    try {
      if (this.users.length === 0) {
        await AdminUserPaginationModule.getUsers();
      }
    } catch (error) {
      ErrorLogModule.addErrorLog({
        name: "Fetching User Error",
        message: "Could not load current User"
      });

      this.$toast("Faild to load users.");
    } finally {
      this.isLoadingUsers = false;
    }
  }

  async update() {
    this.isLoading = true;
    try {
      const updateDto: ThgUpdateAffiliateDtoGen = {
        code: this.updateAffiliate.code,
        type: this.updateAffiliate.type,
        note: this.updateAffiliate.note,
        provision: this.updateAffiliate.provision,
        discountForCustomer: this.updateAffiliate.discountForCustomer,
        isValid: this.updateAffiliate.isValid
      };

      if (this.updateAffiliate.userId) {
        updateDto["userId"] = this.updateAffiliate.userId;
      }

      await AffiliatePortalModule.updateSelectedAffiliate({
        affiliateId: AffiliatePortalModule.selectedAffiliate.id,
        partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
        dto: updateDto
      });
    } catch (error) {
      this.$log.error("Affiliate store not working as aspected.");
    } finally {
      this.isLoading = false;
      this.dialog = false;
    }
  }

  async initialize() {
    this.isLoading = true;
    this.isUser = false;
    this.showUserSwitch = true;
    this.updateAffiliate = deepCopy(AffiliatePortalModule.selectedAffiliate);
    if (this.updateAffiliate.userId) {
      this.showUserSwitch = false;
      await this.loadUsers();
      this.isUser = true;
    }

    this.isLoading = false;
  }

  close() {
    this.dialog = false;
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get impactFactorRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberLowerRule(100));
    rules.push(numberHigherRule(0));

    return rules;
  }
}
