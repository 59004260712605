



















import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { IThg } from "@/models/thg.entity";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemRegistrationImage extends DarkModeHighlightMixin {
  registrationImagesLoading = false;

  @Prop()
  thg!: IThg;

  @Prop()
  reload?: boolean;

  registrationImages: string[] = this.thg.registrationImages.map(i => i.url);

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "507px";
    }
  }

  async mounted() {
    if (this.reload) {
      this.registrationImagesLoading = true;
      await ThgPortalModule.getSelectedThgByPartnerID({ thgId: this.thg.id, partnerId: this.thg.partnerId });
      this.registrationImages = ThgPortalModule.selectedThg.registrationImages.map(i => i.url);
      this.registrationImagesLoading = false;
    }
  }
}
