var render = function () {
  var _vm$selectedUser, _vm$selectedUser$cont, _vm$selectedUser2, _vm$selectedUser2$con;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "report.thgChargingStation.title"
    }
  }, [_c('v-container', {
    staticClass: "wrapper"
  }, [!_vm.loading && _vm.chargingStation ? _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "1200px"
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.chargingStation.name) + " ")]), _c('v-spacer')], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.createdDate) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'ThgChargingStationUpdateView',
                params: {
                  chargingStationId: _vm.chargingStation.id
                }
              });
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, false, 2624090253)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toEdit")))])])], 1), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [!_vm.loading ? _c('thg-charging-station-information-card', {
    attrs: {
      "chargingStation": _vm.chargingStation
    }
  }) : _c('v-card', [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "card-heading, list-item-two-line,"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('customer-contact-card', {
    attrs: {
      "userId": _vm.selectedUser._id,
      "companyName": _vm.selectedUser.company,
      "customerName": _vm.fullname,
      "address": _vm.selectedUser.address,
      "email": (_vm$selectedUser = _vm.selectedUser) === null || _vm$selectedUser === void 0 ? void 0 : (_vm$selectedUser$cont = _vm$selectedUser.contact) === null || _vm$selectedUser$cont === void 0 ? void 0 : _vm$selectedUser$cont.email,
      "phone": (_vm$selectedUser2 = _vm.selectedUser) === null || _vm$selectedUser2 === void 0 ? void 0 : (_vm$selectedUser2$con = _vm$selectedUser2.contact) === null || _vm$selectedUser2$con === void 0 ? void 0 : _vm$selectedUser2$con.phone
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [!_vm.loading ? _c('thg-meter-reading-detail-table', {
    attrs: {
      "meterReadings": _vm.meterReadings
    }
  }) : _c('v-card', [_c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "table-heading, table-tbody, table-tfoot"
    }
  })], 1)], 1)], 1), _c('thg-meter-reading-create-dialog', {
    attrs: {
      "chargingStation": _vm.chargingStation,
      "loading": _vm.loading,
      "fab": true
    },
    on: {
      "save": _vm.createMeterReading
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }