var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "title": _vm.title,
      "margin": 0
    }
  }, [_c('v-card-actions', [_c('v-text-field', {
    attrs: {
      "label": _vm.label,
      "type": "number",
      "outlined": "",
      "dense": "",
      "flat": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.value ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm._value,
      callback: function callback($$v) {
        _vm._value = $$v;
      },
      expression: "_value"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }