












import ThgChargingStationDetailInformationCard from "@/components/thg/ThgChargingStationDetailInformationCard.vue";
import ThgChargingStationListCard from "@/components/thg/ThgChargingStationListCard.vue";
import ThgChargingStationPaginatedListCard from "@/components/thg/ThgChargingStationPaginatedListCard.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { FeatureModule } from "@/store/modules/feature.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import { ChargingStationPaginatedModule } from "@/store/modules/charging-station-paginated.store";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    TheLayoutPortalSplit,
    ThgChargingStationListCard,
    ThgChargingStationPaginatedListCard,
    ThgChargingStationDetailInformationCard
  }
})
export default class ThgChargingStation extends PartnerFallbackMixin {
  loading = false;

  selectedUser: IAdminUser | null = null;

  get isPaginationEnabled() {
    return FeatureModule.isPaginationEnabled;
  }

  async mounted() {
    this.loading = true;

    try {
      await this.trySetByRouteOrDefault();
      await ThgPortalModule.getChargingStations();
      await ChargingStationPaginatedModule.fetchAllFromBeginning({});
    } catch (error) {
      this.$log.error(error);
    }

    try {
      if (ThgPortalModule.allChargingStations.length !== 0) {
        await ThgPortalModule.getSelectedChargingStation(ThgPortalModule.allChargingStations[0].id);
        await ThgPortalModule.getMeterReadingForChargingStation(ThgPortalModule.allChargingStations[0].id);
        this.selectedUser = await AdminUserPaginationModule.getSelectedUser(
          ThgPortalModule.allChargingStations[0].userId
        );
      }
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }
}
