





































import { UserModule } from "@/store/modules/me-user.store";
import { Component, Vue } from "vue-property-decorator";
import ThgBillingCreateNewBillingManual from "./ThgBillingCreateNewBillingManual.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ThgBillingCreateNewBillingManualPdf from "./ThgBillingCreateNewBillingManualPdf.vue";
import { ThgBillingPdfInputGen } from "@/services/thg/v1/data-contracts";
import { IBilling } from "@/models/billing.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { CounterModule } from "@/store/modules/counter.store";
import { BillingModule } from "@/store/modules/billing.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { CreateBillingDto } from "@/models/createBilling.dto";
@Component({
  components: { ThgBillingCreateNewBillingManual, ThgBillingCreateNewBillingManualPdf, ConfirmActionDialog }
})
export default class ThgBillingCreateNewDialog extends Vue {
  newBillingDialog = false;

  counterLoading = false;

  isLoading = false;

  loadingAllUsers = false;

  createBilling: CreateBillingDto | null = null;

  createdBilling: IBilling | null = null;

  pdfInput: ThgBillingPdfInputGen | null = null;

  initDialogLoading = false;

  isValid = true;

  get isAdmin() {
    return UserModule.isAdmin;
  }

  async initDialog() {
    this.initDialogLoading = true;
    this.createBilling = new CreateBillingDto();
    this.pdfInput = {
      billingItems: [],
      name: "",
      address: {
        street: "",
        zip: "",
        city: "",
        state: ""
      },
      companyData: {
        name: "",
        address: {
          street: "",
          zip: "",
          city: "",
          state: "",
          countryCode: ""
        },
        contact: {
          email: "",
          phone: ""
        }
      },
      nameLine2: "",
      cancellationNumber: undefined,
      userName: "",
      taxnumber: "",
      serviceDateFrom: "",
      serviceDateTo: "",
      companyName: "",
      appUrl: "",
      billingType: "invoice",
      billingNumber: 0,
      accountNumber: 0,
      iban: "",
      bankName: "",
      bottomText: ""
    };

    this.createdBilling = null;

    try {
      await this.loadCounterValue();
    } catch (e) {
      handleError(e);
    }
    this.loadingAllUsers = true;

    try {
      await AdminUserPaginationModule.getUsers();
    } catch (e) {
      handleError(e);
    } finally {
      this.loadingAllUsers = false;
    }

    this.newBillingDialog = true;
    this.initDialogLoading = false;
  }

  async loadCounterValue() {
    this.counterLoading = true;
    try {
      await CounterModule.getCounterController("billing");
    } catch (e) {
      handleError(e);
    } finally {
      this.counterLoading = false;
    }
  }

  async createNewBilling() {
    this.isLoading = true;
    try {
      if (!this.createBilling) return;

      if (this.createBilling.accountNumber !== undefined) {
        if (!this.createBilling.accountNumber) {
          this.createBilling.accountNumber = (undefined as any) as number;
        } else {
          this.createBilling.accountNumber = Number(this.createBilling.accountNumber);
        }
      }

      const created = await this.createBilling.create();

      this.createdBilling = created;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async addBillingPdf() {
    this.isLoading = true;
    let created: IBilling | null = null;
    try {
      if (!this.createdBilling) return;

      if (!this.pdfInput) return;

      created = await BillingModule.addBillingPdfManually({
        billingNumber: this.createdBilling.billingNumber,
        pdfInput: {
          billingItems: this.pdfInput.billingItems.map(item => ({
            amount: Number(item.amount),
            isVat: item.isVat,
            product: {
              name: item.product.name,
              pricePerProduct: Number(item.product.pricePerProduct),
              isDeductible: item.product.isDeductible,
              description: item.product.description
            },
            totalPriceWithoutTax: Math.round(Number(item.amount) * Number(item.product.pricePerProduct) * 100) / 100,
            vat:
              (Number(item.isVat) *
                Math.round(Number(item.amount) * Number(item.product.pricePerProduct) * 0.19 * 100)) /
              100,
            totalPriceWithVat:
              Math.round(
                Number(item.amount) * Number(item.product.pricePerProduct) * (1 + 0.19 * Number(item.isVat)) * 100
              ) / 100
          })),
          name: this.pdfInput.name,
          address: this.pdfInput.address,
          companyData: this.pdfInput.companyData,
          nameLine2: this.pdfInput.nameLine2,
          cancellationNumber: Number(this.pdfInput.cancellationNumber),
          userName: this.pdfInput.userName,
          taxnumber: this.pdfInput.taxnumber,
          serviceDateFrom: this.pdfInput.serviceDateFrom
            ? new Date(this.pdfInput.serviceDateFrom).toISOString()
            : undefined,
          serviceDateTo: this.pdfInput.serviceDateTo ? new Date(this.pdfInput.serviceDateTo).toISOString() : undefined,
          companyName: this.pdfInput.companyName,
          appUrl: this.pdfInput.appUrl,
          billingType: this.pdfInput.billingType,
          accountNumber: Number(this.pdfInput.accountNumber),
          iban: this.pdfInput.iban,
          bankName: this.pdfInput.bankName,
          billingNumber: this.pdfInput.billingNumber,
          bottomText: this.pdfInput.bottomText
        }
      });

      this.createdBilling = created;
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }

    return created;
  }

  async confirmBillingCreation() {
    await this.createNewBilling();

    if (!this.pdfInput) return;
    if (!this.createdBilling) return;

    this.pdfInput.accountNumber = this.createdBilling.accountNumber;
    this.pdfInput.billingNumber = this.createdBilling.billingNumber;
    this.pdfInput.billingType = this.createdBilling.type;

    if (this.createdBilling.userId) {
      let user = AdminUserPaginationModule.maps.id.get(this.createdBilling?.userId)[0];
      if (!user) {
        user = await AdminUserPaginationModule.getSelectedUser(this.createdBilling.userId);
      }
      if (user) {
        this.pdfInput.name = `${user.firstName} ${user.lastName}`;
        this.pdfInput.userName = user.userName;
        this.pdfInput.address = {
          street: user.address.street,
          zip: user.address.zip,
          city: user.address.city,
          state: user.address.state
        };
      }
    }
    this.pdfInput.companyData = {
      name: "mint future GmbH",
      address: {
        street: "Marie-Curie-Str. 3",
        zip: "49076",
        city: "Osnabrück",
        state: ""
      },
      contact: {
        email: "info@mintfuture.de",
        phone: "+49 541 96328788"
      }
    };
  }

  async confirmPdfCreation() {
    const created = await this.addBillingPdf();
    if (created) this.newBillingDialog = false;
  }

  async onConfirm() {
    if (!this.createdBilling) {
      await this.confirmBillingCreation();
    } else {
      await this.confirmPdfCreation();

      const created = this.createdBilling;
      if (!created) return;

      this.$toast.success(this.$t("components.thg.ThgBillingCancellationDialog.successCreation"), {
        onClick: () => new GoToHelper(this.$router).goToBillingDetail(created.billingNumber, created.partnerId)
      });
    }
  }

  onCreated(billing: IBilling) {
    this.createdBilling = billing;
  }

  onAdded() {
    this.newBillingDialog = false;
    this.$emit("refresh");
  }
}
