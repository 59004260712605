var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": _vm.$t('components.thg.ThgBatchDetailInformationDistributionCard.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('thg-batch-update-price-dialog')];
      },
      proxy: true
    }])
  }, [_vm.showKpiGroup ? _c('v-row', {
    staticClass: "ma-2"
  }, _vm._l(_vm.kpiGroup, function (kpi, i2) {
    return _c('analytics-kpi-column', {
      key: i2,
      attrs: {
        "icon": kpi.icon,
        "color": "primary",
        "title": kpi.name,
        "data": kpi.value
      }
    });
  }), 1) : _vm._e(), _vm.showPriceKpiGroup && _vm.showKpiGroup ? _c('v-divider') : _vm._e(), _vm.showPriceKpiGroup ? _c('v-row', {
    staticClass: "ma-2"
  }, _vm._l(_vm.priceKpiGroup, function (kpi, i2) {
    return _c('analytics-kpi-column', {
      key: i2,
      attrs: {
        "icon": kpi.icon,
        "color": "primary",
        "title": kpi.name,
        "data": kpi.value
      }
    });
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }