var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading && _vm.selection.id ? _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "1200px"
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.selection.id) + " " + _vm._s(_vm.selection.isActive ? "✅" : "⛔️"))]), _c('v-spacer'), !_vm.isActive ? _c('v-btn', {
    attrs: {
      "loading": _vm.isActivateLoading,
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.activate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgAdvertismentDetailInformationCard.activate")) + " ")]) : _c('v-btn', {
    attrs: {
      "loading": _vm.isActivateLoading,
      "text": "",
      "small": ""
    },
    on: {
      "click": _vm.deactivate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgAdvertismentDetailInformationCard.deactivate")) + " ")])], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.createdDate) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.toDetailView
          }
        }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
      }
    }], null, false, 2345870730)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toDetail")))])]), _c('simple-delete-dialog', {
    on: {
      "remove": _vm.remove
    }
  })], 1), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('label-component', {
    attrs: {
      "labels": _vm.selection.tags,
      "loading": _vm.labelsLoading
    },
    on: {
      "update": _vm.updateTags
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-advertisment-target-group-information-card', {
    attrs: {
      "ad": _vm.selection
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('thg-advertisment-information-card', {
    attrs: {
      "ad": _vm.selection
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('thg-advertisment-admin-information-card', {
    attrs: {
      "ad": _vm.selection
    }
  })], 1)], 1), _c('v-row')], 1)], 1) : !_vm.loading ? _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-img', {
    attrs: {
      "src": _vm.emptyDataSrc,
      "height": "100",
      "width": "200",
      "contain": ""
    }
  })], 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }