





































































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import UserAdministrationDetailPermissions from "@/components/partner/UserAdministrationDetailPermissions.vue";
import UserAdministrationDetailRoles from "@/components/partner/UserAdministrationDetailRoles.vue";
import ThgQuoteWorkitemImageCard from "@/components/thg/ThgQuoteWorkitemImageCard.vue";
import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import ImageDialog from "@/components/utility/ImageDialog.vue";
import { calculateVinChecksum, VinChecksum } from "@/lib/utility/calculateVinChecksum";
import { simpleDate } from "@/lib/utility/date-helper";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgRegistrationDtoGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ClipperModule } from "@/store/modules/clipper.store";
import { ConfigModule } from "@/store/modules/config";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import ThgCropImageDialog from "./ThgCropImageDialog.vue";
import ThgCropImageLoader from "./ThgCropImageLoader.vue";
import ThgWorkItemRejectionCard from "./ThgWorkItemRejectionCard.vue";
import ThgQuoteQuickCheckoutListDialog from "./ThgQuoteQuickCheckoutListDialog.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { PartnerModule } from "@/store/modules/partner";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    CustomerContactCard,
    UserAdministrationDetailPermissions,
    UserAdministrationDetailRoles,
    ThgQuoteWorkitemRegistrationCard,
    ImageDialog,
    ThgQuoteWorkitemImageCard,
    TemplateDialog,
    ThgCropImageDialog,
    ThgCropImageLoader,
    ThgWorkItemRejectionCard,
    ThgQuoteQuickCheckoutListDialog
  },
  filters: {
    getFlagEmoji
  }
})
export default class ThgQuoteWorkitemCard extends DarkModeHighlightMixin {
  loading = false;

  @Prop()
  selectedUser!: IAdminUser;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedThg!: IThg;

  newWorkitem = true;
  userNotification = true;
  fin = "";
  checkSum: VinChecksum = 0;

  imageIndex = 0;
  currentRotation = 0;
  currentScale = 1;
  currentTranslationX = 0;
  currentTranslationY = 0;

  isActionEnabled = false;

  isQuickCheckoutDialogActive = false;

  get updatedThg() {
    return this.selectedThg;
  }

  get imageStyling() {
    return `transform: rotate(${this.currentRotation}deg) scale(${this.currentScale}) translate(${this.currentTranslationX}%, ${this.currentTranslationY}%);`;
  }

  get imageUrl() {
    return this.registrationImages[this.imageIndex].url;
  }

  get partner(): PartnerEntity {
    return PartnerModule.partner;
  }

  get hasImages(): boolean {
    return this.registrationImages.length != 0;
  }

  get getChecksum() {
    return "Check:" + calculateVinChecksum(this.fin);
  }

  get date(): string {
    const timestamp = deepCopy<any>(this.selectedThg.timestamp);
    return simpleDate(timestamp.created);
  }

  uppercase() {
    this.fin = this.fin.toUpperCase();
  }

  checkfin() {
    this.checkSum = calculateVinChecksum(this.fin);
  }

  get workItemCard(): ThgQuoteWorkitemRegistrationCard {
    return this.$refs.registrationCard as ThgQuoteWorkitemRegistrationCard;
  }

  updateName() {
    (this.$refs.registrationCard as ThgQuoteWorkitemRegistrationCard).setUsernameFromIUser();
  }

  updateCompany() {
    (this.$refs.registrationCard as ThgQuoteWorkitemRegistrationCard).setCompanyFromIUser();
  }

  toThgDetailView() {
    const roueData = this.$router.resolve({
      name: "ThgQuoteDetailView",
      params: { partnerId: this.selectedThg.partnerId, thgId: this.selectedThg.id }
    });

    window.open(roueData.href, "_blank");
  }

  toPartnerHomeView() {
    const roueData = this.$router.resolve({
      name: ConfigModule.partnerSelectorDestinationVue,
      params: { partnerId: this.selectedThg.partnerId }
    });

    window.open(roueData.href, "_blank");
  }

  copyEmailToClipboard() {
    navigator.clipboard.writeText(this.selectedUser?.userName ?? "");
    this.$toast.info("In Zwischenablage kopiert.");
  }

  async askForWork() {
    this.loading = true;
    this.isActionEnabled = false;
    try {
      if (this.newWorkitem) {
        await ThgPortalModule.getThgAsWorkItem();
        this.selectedUser = await AdminUserPaginationModule.getSelectedUser(ThgPortalModule.thgWorkitem.userId);
        ThgPortalModule.updateIsWorkStarted(true);
      } else {
        ThgPortalModule.updateIsWorkStarted(false);
        ThgPortalModule.updateHasNextWorkItem(true);
      }

      await ThgAnalyticsModule.getStatusDistributionData();
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim Laden.");
    } finally {
      this.loading = false;
    }
  }

  async submit(registration: ThgRegistrationDtoGen) {
    this.loading = true;
    await ThgPortalModule.updateWorkitemRegistration(registration);
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.CONFIRMED },
      noUserNotification: !this.userNotification
    });
    ClipperModule.initializeForImages([]);
    await this.askForWork();
  }

  get registrationImages() {
    return this.selectedThg.registrationImages;
  }
}
