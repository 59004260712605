










































































import { requiredRule } from "@/lib/rules/requiredRule";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { Component, Prop, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";

@Component({
  components: { Debug }
})
export default class ThgAdminUpdateStatusDialog extends Vue {
  @Prop()
  thg!: IThg;

  /**
   * Displays loading animation
   */
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  hideActivator!: boolean;

  /**
   * The internal status
   */
  status = this.thg.status;

  /**
   * Notify the user about this change?
   */
  userNotification = true;

  /**
   * Form valid
   */
  isValid = false;

  /**
   * Show Dialog
   */
  isDialogActive = false;

  /**
   * All available status
   */
  items = Object.keys(ThgStatusEnum).map(k => ThgStatusEnum[k as any]);

  helpItems = [
    {
      title: "Status Allgemein",
      content: "components.thg.ThgQuoteDetailInformationStatusCard.statusSuccess",
      icon: "mdi-check",
      color: "primary"
    },
    {
      title: "Error Status Allgemein",
      content: "components.thg.ThgQuoteDetailInformationStatusCard.statusError",
      icon: "mdi-close-outline",
      color: "error"
    },
    {
      title: "Error BEV",
      content: "components.thg.ThgQuoteDetailInformationStatusCard.statusErroBev",
      icon: "mdi-close-outline",
      color: "error"
    },
    {
      title: "Error Meter Reading",
      content: "components.thg.ThgQuoteDetailInformationStatusCard.statusErrorMeterReading",
      icon: "mdi-close-outline",
      color: "error"
    }
  ];

  openDialog() {
    this.isDialogActive = true;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  async save(): Promise<IThg> {
    this.isDialogActive = false;

    this.$emit("save", this.status, this.userNotification);
    this.close();

    this.thg.status = this.status;

    return this.thg;
  }

  async close() {
    this.isDialogActive = false;
    this.status = this.thg.status;
    this.$emit("refresh");
  }
}
