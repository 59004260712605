









































































import ThgBatchDetailInformationChangeHistoryCard from "@/components/thg/ThgBatchDetailInformationChangeHistoryCard.vue";
import ThgBatchDetailInformationDistributionCard from "@/components/thg/ThgBatchDetailInformationDistributionCard.vue";
import ThgBatchDetailInformationPartnerDistributionCard from "@/components/thg/ThgBatchDetailInformationPartnerDistributionCard.vue";
import ThgBatchDetailInformationStatusCard from "@/components/thg/ThgBatchDetailInformationStatusCard.vue";
import ThgBatchDetailInformationThgTableCard from "@/components/thg/ThgBatchDetailInformationThgTableCard.vue";
import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { BatchModule } from "@/store/modules/batch.store";
import { Component, Prop } from "vue-property-decorator";
import ThgBatchDetailInformationPayoutCard from "./ThgBatchDetailInformationPayoutCard.vue";
import ThgBatchDetailInformationInstructionCard from "./ThgBatchDetailInformationInsturctionCard.vue";
import { thgTotalKwhHelper } from "@/lib/utility/thg-total-kwh.helper";
import Debug from "@/components/utility/Debug.vue";

@Component({
  components: {
    ThgBatchDetailInformationThgTableCard,
    ThgBatchDetailInformationStatusCard,
    ThgBatchDetailInformationChangeHistoryCard,
    ThgBatchDetailInformationDistributionCard,
    ThgBatchDetailInformationPartnerDistributionCard,
    ThgBatchDetailInformationPayoutCard,
    ThgBatchDetailInformationInstructionCard,
    Debug
  }
})
export default class BatchDetailCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  tab = 0;

  get selectedBatch() {
    return BatchModule.selectedBatch;
  }

  get partnerDistribution() {
    return BatchModule.partnerDistribution;
  }

  get isSelectedBatch() {
    return !!this.selectedBatch?.batch.id && !(this.selectedBatch?.batch.id === "");
  }

  get createdDate() {
    return this.date(this.selectedBatch.batch);
  }

  get batchTypeIcon(): string {
    if (this.selectedBatch.batch.type === BatchTypeEnum.meterReading) {
      return "mdi-ev-station";
    }
    return "mdi-car-multiple";
  }

  date(item: any) {
    if (item.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(item.timestamp?.created, locale);
    }
    return "";
  }

  get amountInMWh(): string {
    return thgTotalKwhHelper(this.selectedBatch.batch.type as BatchTypeEnum, this.selectedBatch.thgList);
  }
}
