




















































































































































import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { ThgCroppingIsCompleteCheck } from "@/lib/thg/ThgCroppingIsCompleteCheck";
import { ThgDuplicateFilter } from "@/lib/thg/ThgDuplicateFilter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  ThgBatchDetailViewmodelGen,
  ThgChargingStationViewModelGen,
  ThgThgMeterReadingViewModelGen
} from "@/services/thg/v1/data-contracts";
import { BatchModule } from "@/store/modules/batch.store";
import { ChargingStationAdminModule } from "@/store/modules/charging-station-admin.store";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ThgBatchDuplicateCheckDialog from "./ThgBatchDuplicateCheckDialog.vue";
import ThgEditImagesDialog from "./ThgEditImagesDialog.vue";
import ThgQuoteExportMeterReadingDialog from "./ThgQuoteExportMeterReadingDialog.vue";
import ThgQuoteExportThgVehicleDialog from "./ThgQuoteExportThgVehicleDialog.vue";
import ThgBatchDownloadDialog from "./ThgBatchDownloadDialog.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IThg } from "@/models/thg.entity";

@Component({
  components: {
    Card,
    ThgEditImagesDialog,
    ThgBatchDuplicateCheckDialog,
    ThgQuoteExportMeterReadingDialog,
    ThgQuoteExportThgVehicleDialog,
    ThgBatchDownloadDialog
  }
})
export default class ThgBatchDetailInformationInstructionCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  batch!: ThgBatchDetailViewmodelGen;

  isDups = false;
  emptyVin = 0;
  emptyVehicleClass = 0;
  emptyName = 0;

  isCalculatingDups = false;
  isErrorOnCrop = false;
  isGeoMissingOnChargingStation = false;

  isLoadingChargingStation = false;
  chargingStationsWithMissingGeo: ThgChargingStationViewModelGen[] = [];

  get isMissingData() {
    if (this.emptyName || this.emptyVehicleClass || this.emptyVin) {
      return true;
    }
    return false;
  }

  /**
   * Redirects to the detail view of the chargingstation
   * Opens a new broswer tab
   * @param c
   */
  openChargingStation(c: ThgChargingStationViewModelGen) {
    if (!c?.id) {
      return;
    }

    const goToHelper = new GoToHelper(this.$router);
    goToHelper.goToChargingStationDetail(c.id, true);
  }

  async mounted() {
    if (this.isVehicle) {
      this.isCalculatingDups = true;
      try {
        const duplicateFilter = new ThgDuplicateFilter();
        duplicateFilter.filter(this.batch.thgList as IThg[]);
        this.isCalculatingDups = false;
        if (duplicateFilter.dups.length) {
          this.isDups = true;
        }
        this.emptyName = duplicateFilter.emptyName;
        this.emptyVin = duplicateFilter.emptyVin;
        this.emptyVehicleClass = duplicateFilter.emptyVehicleClass;
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("error checking duplicates");
        this.isCalculatingDups = false;
      }

      try {
        const thgCroppingIsCompleteCheck = new ThgCroppingIsCompleteCheck(this.batch.thgList as IThg[]);
        const sumErrorCrops =
          thgCroppingIsCompleteCheck.count.noCrop +
          thgCroppingIsCompleteCheck.count.noFront +
          thgCroppingIsCompleteCheck.count.noImage;

        if (sumErrorCrops) {
          this.isErrorOnCrop = true;
        }
      } catch (error) {
        this.$log.error(error);
        this.$toast.error("error checking cropping data");
      }
    }
    if (this.isMeterreading) {
      await this.checkGeoCoordinatesOnChargingStation();
    }
  }

  get isVehicle() {
    return BatchModule.selectedBatch.batch.type === BatchTypeEnum.vehicle;
  }

  get isMeterreading() {
    return BatchModule.selectedBatch.batch.type === BatchTypeEnum.meterReading;
  }

  async checkGeoCoordinatesOnChargingStation() {
    try {
      this.isLoadingChargingStation = true;
      const chargingStationIds: string[] = [];
      ((this.batch.thgList as any) as ThgThgMeterReadingViewModelGen[]).forEach(c => {
        if (c?.meterReading?.chargingStationId) {
          chargingStationIds.push(c.meterReading.chargingStationId);
        }
      });

      const charingStations = await ChargingStationAdminModule.getManyChargingStations([
        ...new Set(chargingStationIds)
      ]);

      charingStations.forEach(c => {
        if (!c.address.geo.lat || !c.address.geo.lng) {
          this.isGeoMissingOnChargingStation = true;

          this.chargingStationsWithMissingGeo.push(c);
        }
      });

      this.isLoadingChargingStation = false;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Error checking geo coordinates");
      this.isLoadingChargingStation = false;
    }
  }
}
