

















import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { thgVehicleClassMap } from "@/lib/utility/thgVehicleClassMap";
import CsvExportMixin from "@/mixins/CsvExportMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { Component, Prop } from "vue-property-decorator";
import * as XLSX from "xlsx";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { calculateVinChecksum } from "@/lib/utility/calculateVinChecksum";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgQuoteExportThgVehicleDialog extends CsvExportMixin {
  @Prop()
  thgs!: IThg[];

  isDialogActive = false;

  exportExcel() {
    const rows = [];
    for (const thg of this.thgs) {
      const row = {
        name: thg.registration.name,
        datum_der_zulassung: this.registrationDate(thg),
        fahrzeugidentifikationsnummer: thg.registration.identificationnumber,
        pruefZiffer: calculateVinChecksum(thg.registration?.identificationnumber || ""),
        antriebsart: "ja",
        fahrzeugklasse: this.vehicleClass(thg),
        datum_der_vereinbarung: this.dateOfConfirmation(thg),
        anmerkungen: this.getImageName(thg)
      };

      rows.push(row);
    }

    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
    XLSX.writeFile(workbook, "vehicles" + new Date().toISOString() + ".xlsx", { compression: true });
    this.isDialogActive = false;
  }

  registrationDate(thg: IThg) {
    let formatedDate = "";

    if (
      thg.registration.firstregistrationDay &&
      thg.registration.firstregistrationMonth &&
      thg.registration.firstregistrationYear
    ) {
      try {
        const isoDate = new Date(
          new Date().setFullYear(
            +thg.registration.firstregistrationYear,
            +thg.registration.firstregistrationMonth - 1,
            +thg.registration.firstregistrationDay
          )
        ).toISOString();
        formatedDate = simpleDoubleDigitDate(isoDate);
      } catch (error) {
        this.$log.error(error);
      }
    }

    return formatedDate;
  }

  dateOfConfirmation(thg: IThg): string {
    return simpleDoubleDigitDate(thg.timestamp.created);
  }

  getImageName(thg: IThg): string {
    return thg.registration.identificationnumber + ".pdf";
  }
  vehicleClass(thg: IThg): string {
    return (
      thgVehicleClassMap.get((thg.registration.vehicleClass as ThgVehicleTypes) || "")?.ubaIdentifier || "UNDEFINED"
    );
  }
}
