


























































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { simpleDate } from "@/lib/utility/date-helper";
import CsvExportMixin from "@/mixins/CsvExportMixin.vue";
import {
  ThgThgCustomerDataViewModelGen,
  ThgUserBaseViewModelGen,
  ThgInsuranceViewModelGen,
  ThgThgMeViewModelGen
} from "@/services/thg/v1/data-contracts";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { IFullOptions } from "json-2-csv";
import { Component, Prop } from "vue-property-decorator";

enum ExportOptions {
  USER = "user",
  THG = "thg",
  INSURANCE = "insurance",
  ALL = "all"
}

/**
 *
 * Defines which fields are to be exported to the csv. The order is the order of the columns in the csv.
 *
 */
class UserExportData {
  id = "";

  username = "";

  firstname = "";

  lastname = "";

  email = "";

  phone = "";

  street = "";

  city = "";

  zip = "";

  state = "";

  isMarketingOptIn = false;

  company = "";

  created = "";

  constructor(user?: ThgUserBaseViewModelGen) {
    if (user) {
      this.id = user.id;
      this.username = user.userName;
      this.firstname = user.firstName;
      this.lastname = user.lastName;
      this.company = user.company;

      this.email = user.contact.email;
      this.phone = user.contact.phone || "";

      this.street = user.address.street || "";
      this.city = user.address.city || "";
      this.zip = user.address.zip || "";
      this.state = user.address.state || "";

      this.isMarketingOptIn = user.isMarketingOptIn;

      this.created = user?.timestamps?.created || "";
    }
  }
}

/**
 *
 * Defines which fields are to be exported to the csv. The order is the order of the columns in the csv.
 *
 */
class InsuranceExportData {
  id = "";

  userId = "";

  name = "";

  number = "";

  created = "";

  constructor(insurance?: ThgInsuranceViewModelGen) {
    if (insurance) {
      this.id = insurance.id;
      this.userId = insurance.userId;
      this.name = insurance.name;
      this.number = insurance.number;
      this.created = insurance.timestamp.created;
    }
  }
}

/**
 *
 * Defines which fields are to be exported to the csv. The order is the order of the columns in the csv.
 *
 */
class ThgExportData {
  id = "";

  userId = "";

  partnerId = "";

  vin = "";

  numberplate = "";

  vehicleClass = "";

  driveType = "";

  status = "";

  impactFactor = 0;

  impactType = "";

  campaign = "";

  code = "";

  year = "";

  created = "";

  constructor(thg?: ThgThgMeViewModelGen) {
    if (thg) {
      this.id = thg.id;
      this.userId = thg.userId;
      this.partnerId = thg.partnerId;

      this.numberplate = thg.numberplate;

      this.vin = thg.registration.identificationnumber || "";
      this.vehicleClass = thg.registration.vehicleClass || "M1";
      this.driveType = thg.registration.driveTyp || "";

      this.status = thg.status;
      this.impactFactor = thg.impactFactor;
      this.impactType = thg.impactType;
      this.campaign = thg.campaign;
      this.code = thg.code || "";
      this.year = thg.year.toString();
      this.created = thg.timestamp.created;
    }
  }
}

/**
 *
 * Defines which fields are to be exported to the csv. The order is the order of the columns in the csv.
 *
 */
class AllExportData extends ThgExportData {
  user: UserExportData;
  insurance: InsuranceExportData;
  constructor(thg: ThgThgMeViewModelGen, user: ThgUserBaseViewModelGen, insurance?: ThgInsuranceViewModelGen) {
    super(thg);
    this.user = new UserExportData(user);
    this.insurance = new InsuranceExportData(insurance);
  }
}

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class ThgCustomerDataAnalyticsExportDialog extends CsvExportMixin {
  @Prop()
  customerAnalyticsData!: ThgThgCustomerDataViewModelGen[];

  exportEnum = ExportOptions;

  dialog = false;

  exportSetting = ExportOptions.USER;

  options: IFullOptions = {
    delimiter: {
      wrap: '"', // Double Quote (") character
      field: ";", // Comma field delimiter
      eol: "\n" // Newline delimiter
    },
    unwindArrays: false,
    prependHeader: true,
    sortHeader: false,
    excelBOM: true,
    useLocaleFormat: true,
    trimFieldValues: true
  };

  get from() {
    return simpleDate(ThgAnalyticsModule.from);
  }

  get to() {
    return simpleDate(ThgAnalyticsModule.to);
  }

  close() {
    this.dialog = false;
  }

  async exportData() {
    await this.download(this.dataToExport, this.options);
    this.dialog = false;
  }

  get dataToExport() {
    const exportData = [];

    switch (this.exportSetting) {
      case ExportOptions.USER:
        for (const data of this.customerAnalyticsData) {
          exportData.push(new UserExportData(data.user));
        }
        return exportData;
      case ExportOptions.THG:
        for (const data of this.customerAnalyticsData) {
          for (const thg of data.thg) {
            exportData.push(new ThgExportData(thg));
          }
        }
        return exportData;
      case ExportOptions.INSURANCE:
        for (const data of this.customerAnalyticsData) {
          if (data.insurance) {
            for (const insurance of data.insurance) {
              exportData.push(new InsuranceExportData(insurance));
            }
          }
        }
        return exportData;
      case ExportOptions.ALL:
        for (const data of this.customerAnalyticsData) {
          let insurance: ThgInsuranceViewModelGen = undefined as any;
          if (data.insurance && data.insurance.length) {
            insurance = data.insurance[0];
          }
          for (const thg of data.thg) {
            exportData.push(new AllExportData(thg, data.user, insurance));
          }
        }
        return exportData;
      default:
        return {};
    }
  }
}
