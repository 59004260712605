










import Card from "@/components/utility/Card.vue";
import CsvImportDialog from "@/components/utility/CsvImportDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ThgCreatePartnerZipDtoGen, ThgZipDtoGen } from "@/services/thg/v1/data-contracts";
import { ZipModule } from "@/store/modules/zip.store";
import { Component, Vue } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";

@Component({
  components: { Card, CsvImportDialog }
})
export default class ZipImportDialog extends Vue {
  items: ThgZipDtoGen[] = [];

  headers: ITableWrapperHeader[] = [
    {
      text: "zip",
      align: "center",
      value: "zip",
      forceEditField: true,
      rules: [requiredRule("")]
    },
    {
      text: "city",
      align: "center",
      value: "city",
      forceEditField: true,
      rules: [requiredRule("")]
    }
  ];

  get partnerId() {
    return this.$route.params.partnerId;
  }

  changeItems(items: any[]) {
    this.items = [];
    for (const row of items) {
      const createdZipEntry: ThgZipDtoGen = {
        city: row.city,
        zip: row.zip
      };

      this.$log.debug("changeItems", createdZipEntry);
      this.items.push(createdZipEntry);
    }
  }

  async create(items: ThgZipDtoGen) {
    this.$log.debug("createVehicle", items);

    const existingItems = ZipModule.zip?.zipList || [];
    this.$log.debug("createVehicle", [...new Set([...existingItems, ...this.items])]);

    const dto: ThgCreatePartnerZipDtoGen = {
      zipList: this.items.concat(existingItems)
    };

    await ZipModule.create({ partnerId: this.partnerId, data: dto });
  }

  refresh() {
    this.$emit("refresh");
  }
}
