var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('side-card', {
    attrs: {
      "width": "1200px",
      "loading": _vm.isLoading
    },
    on: {
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [!_vm.isLoading ? _c('thg-billing-card', {
    attrs: {
      "isUserLoading": _vm.isUserLoading,
      "isPartnerLoading": _vm.isPartnerLoading,
      "partner": _vm.partner,
      "user": _vm.user
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "text"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }