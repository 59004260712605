var render = function () {
  var _vm$chargingStation$c, _vm$chargingStation$c2, _vm$chargingStation$a, _vm$chargingStation$a2, _vm$chargingStation$a3, _vm$chargingStation$a4, _vm$chargingStation$a5, _vm$chargingStation$a6, _vm$chargingStation$a7, _vm$chargingStation$a8;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "min-width": "250px",
      "title": _vm.$t('report.thgChargingStation.title')
    },
    scopedSlots: _vm._u([_vm.nav ? {
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.$router.push({
                      name: 'ThgChargingStationDetailView',
                      params: {
                        chargingStationId: _vm.chargingStation.id
                      }
                    });
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, false, 356366453)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toDetail")))])]), !_vm.hideEdit ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    return _vm.$router.push({
                      name: 'ThgChargingStationUpdateView',
                      params: {
                        chargingStationId: _vm.chargingStation.id
                      }
                    });
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }], null, false, 2943197479)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toEdit")))])]) : _vm._e()];
      },
      proxy: true
    } : null], null, true)
  }, [_c('debug', {
    attrs: {
      "debug": _vm.chargingStation
    }
  }), _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-earth")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.chargingStation.isRegistered ? "BnetzA Registriert" : "Nicht Registriert") + " ")]), (_vm$chargingStation$c = _vm.chargingStation.chargingPointOperator) !== null && _vm$chargingStation$c !== void 0 && _vm$chargingStation$c.bnetzaOperatorId ? _c('v-list-item-subtitle', [_vm._v(" Operator Id: " + _vm._s((_vm$chargingStation$c2 = _vm.chargingStation.chargingPointOperator) === null || _vm$chargingStation$c2 === void 0 ? void 0 : _vm$chargingStation$c2.bnetzaOperatorId) + " ")]) : _vm._e(), _vm.chargingStation.registrationDate ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.toDate(_vm.chargingStation.registrationDate)) + " ")]) : _vm._e()], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-ev-plug-tesla")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.chargingStation.chargingPointCount) + " x " + _vm._s(_vm.chargingStation.connectionPowerInKwh) + " KW/h ")])], 1), _vm.showCarsPerDay ? _c('tooltip', {
    attrs: {
      "text": _vm.$t('components.thg.ThgQuoteMeterReadingWorkItemCard.amountOfCarsPerDayExplaination', {
        amountOfCarsPerDay: _vm.amountOfCarsPerDay
      })
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-car-battery")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.amountOfCarsPerDay) + " Cars/Day ")])], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-calendar")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.toDate(_vm.chargingStation.commissioningDate)) + " " + _vm._s(_vm.chargingStation.decommissioningDate ? "- " + _vm.toDate(_vm.chargingStation.decommissioningDate) : "") + " ")])], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-identifier")])], 1), _c('v-list-item-subtitle', [_c('ol', _vm._l(_vm.chargingStation.chargingPoints, function (point, i) {
    return _c('li', {
      key: i
    }, [_vm._v(_vm._s(_vm.getEvseId(point.evseId)))]);
  }), 0)])], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-map-marker")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s((_vm$chargingStation$a = _vm.chargingStation.address) === null || _vm$chargingStation$a === void 0 ? void 0 : _vm$chargingStation$a.street) + ", " + _vm._s((_vm$chargingStation$a2 = _vm.chargingStation.address) === null || _vm$chargingStation$a2 === void 0 ? void 0 : _vm$chargingStation$a2.zip) + " " + _vm._s((_vm$chargingStation$a3 = _vm.chargingStation.address) === null || _vm$chargingStation$a3 === void 0 ? void 0 : _vm$chargingStation$a3.city) + ", " + _vm._s((_vm$chargingStation$a4 = _vm.chargingStation.address) === null || _vm$chargingStation$a4 === void 0 ? void 0 : _vm$chargingStation$a4.state) + " "), _c('br'), _vm.hasGeo ? _c('a', {
    attrs: {
      "href": 'https://www.google.com/maps/search/?api=1&query=' + _vm.chargingStation.address.geo.lat + '%2C' + _vm.chargingStation.address.geo.lng,
      "target": "_blank"
    }
  }, [_vm._v(" @" + _vm._s((_vm$chargingStation$a5 = _vm.chargingStation.address) === null || _vm$chargingStation$a5 === void 0 ? void 0 : (_vm$chargingStation$a6 = _vm$chargingStation$a5.geo) === null || _vm$chargingStation$a6 === void 0 ? void 0 : _vm$chargingStation$a6.lat) + "," + _vm._s((_vm$chargingStation$a7 = _vm.chargingStation.address) === null || _vm$chargingStation$a7 === void 0 ? void 0 : (_vm$chargingStation$a8 = _vm$chargingStation$a7.geo) === null || _vm$chargingStation$a8 === void 0 ? void 0 : _vm$chargingStation$a8.lng) + " ")]) : _vm._e()])], 1), _vm.chargingStation.isFromRenewableEnergy || false ? _c('v-list-item', [_c('tooltip', {
    attrs: {
      "text": _vm.$t('components.thg.ThgChargingStationCard.chargingStationRenewableEnergy')
    }
  }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-solar-power")])], 1)], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.chargingStation.renewableEnergySourceDescription) + " ")])], 1) : _vm._e(), _vm.chargingStation.refs && _vm.chargingStation.refs.length > 0 ? _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-paperclip")])], 1), _c('v-list-item-content', [_c('thg-charging-station-information-proofs', {
    attrs: {
      "proofs": _vm.chargingStation.proofs
    }
  })], 1)], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }