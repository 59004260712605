













import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import ThgAnalyticsMeterReadingTable from "@/components/thg/ThgAnalyticsMeterReadingTable.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import ThgPartnerDashboardMixin from "@/mixins/ThgPartnerDashboardMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    AnalyticsDateRangeSelectorDialog,
    ThgAnalyticsMeterReadingTable,
    MHeader
  }
})
export default class ThgPartnerChargingDetails extends ThgPartnerDashboardMixin {
  async mounted() {
    await this.loadData();
  }

  async loadDataForDateRange(dateRange: string[]) {
    await this.setDateRange(dateRange);
  }

  get breadCrumbs() {
    return [
      {
        text: "Übersicht",
        exact: true,
        disabled: false,
        to: {
          name: "ThgPartnerChargingDetails",
          params: {
            partnerId: PartnerModule.partner._id
          }
        }
      }
    ];
  }
}
