var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    class: {
      'ma-0': _vm.$vuetify.breakpoint.smAndDown,
      'ma-2': _vm.$vuetify.breakpoint.mdAndUp
    },
    attrs: {
      "loading": _vm.loading,
      "outlined": "",
      "flat": "",
      "height": "420",
      "title": _vm.$t(_vm.title)
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('analytics-data-table-dialog', {
          attrs: {
            "data": _vm.data
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.shopwKpiGroup ? _c('v-card-text', _vm._l(_vm.kpiGroup, function (kpi, i2) {
    return _c('analytics-kpi-column', {
      key: i2,
      attrs: {
        "icon": kpi.icon,
        "color": "primary",
        "title": kpi.name,
        "data": kpi.value
      }
    });
  }), 1) : _vm._e(), _c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.previewData,
      "search": _vm.search,
      "dense": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    }
  }), _c('v-divider')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }