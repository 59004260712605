import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { Advertisement } from "../v1/Advertisement";
import {
  ThgAdvertisementControllerGetAllParamsGen,
  ThgCreateAdvertisementDtoGen,
  ThgUpdateAdvertisementAdminDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Service to communicate with backend.
 */
class AdvertisementAdvminService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for communicating with backend.
   */
  advertisementProxy: Advertisement;

  /**
   * @class Initialize Service
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.advertisementProxy = new Advertisement(this.client);
  }

  /**
   * (ADMIN) Gets all ads.
   *
   * @returns the ads
   */
  async getAll(query?: ThgAdvertisementControllerGetAllParamsGen) {
    const q = query || {};
    return this.advertisementProxy.advertisementControllerGetAll(q).then(value => value.data);
  }

  /**
   *
   * @param userId
   * @param dto
   * @returns
   */
  async create(partnerId: string, dto: ThgCreateAdvertisementDtoGen) {
    return this.advertisementProxy.advertisementControllerCreate(partnerId, dto).then(value => value.data);
  }

  /**
   *
   * @param partnerId
   * @param id
   * @returns
   */
  async get(partnerId: string, id: string) {
    return this.advertisementProxy.advertisementControllerFind(partnerId, id).then(value => value.data);
  }

  /**
   *
   * @param partnerId
   * @param id
   * @returns
   */
  async remove(partnerId: string, id: string) {
    return this.advertisementProxy.advertisementControllerDelete(partnerId, id).then(value => value.data);
  }

  /**
   *
   * @param partnerId
   * @param id
   * @param dto
   * @returns
   */
  async update(partnerId: string, id: string, dto: ThgUpdateAdvertisementAdminDtoGen) {
    return this.advertisementProxy.advertisementControllerUpdate(partnerId, id, dto).then(value => value.data);
  }
}

/**
 * Service to communicate with backend for admins.
 */
export default new AdvertisementAdvminService(new ThgHttpClientProvider());
