

































































import { Component } from "vue-property-decorator";
import DataCard from "../utility/DataCard.vue";
import Debug from "../utility/Debug.vue";
import ThgBillingSelectionItemPartnerDataMixin from "@/mixins/ThgBillingSelectionItemPartnerDataMixin.vue";
import { mixins } from "vue-class-component";

@Component({ components: { Debug, DataCard } })
export default class ThgBillingSelectionItemPartnerAddressData extends mixins(
  ThgBillingSelectionItemPartnerDataMixin
) {}
