




import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component } from "vue-property-decorator";
import { ClipperModule } from "@/store/modules/clipper.store";

@Component({
  components: {}
})
export default class ThgCropImageLoader extends DarkModeHighlightMixin {
  get isLoading() {
    return ClipperModule.isLoading;
  }
}
