








import AffiliateDetailCard from "@/components/thg/AffiliateDetailCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { IAdminUser } from "@/models/user.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    AffiliateDetailCard
  }
})
export default class AffiliateDetailView extends Vue {
  loading = false;

  selectedUser: IAdminUser | null = null;

  async mounted() {
    this.loading = true;
    try {
      await PartnerModule.getPartnerById(this.$route.params.partnerId);
      await AffiliatePortalModule.getSelectedAffiliate({
        partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
        affiliateId: this.$route.params.affiliateId
      });
      if (AffiliatePortalModule.selectedAffiliate.userId) {
        this.selectedUser = await AdminUserPaginationModule.getSelectedUser(
          AffiliatePortalModule.selectedAffiliate.userId
        );
      }
    } catch {
      this.$toast.error("Fehler beim Laden des Affiliate");
    } finally {
      this.loading = false;
    }
  }
}
