/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { PartnerPromotionService } from "../v1/PartnerPromotionService";
import {
  ThgCreatePromotionDtoGen,
  ThgPageViewModelGen,
  ThgPartnerPromotionControllerFindAllParamsGen,
  ThgPromotionViewModelGen,
  ThgUpdatePromotionDtoGen
} from "../v1/data-contracts";

/**
 * Service to talk to the promotionendpoint.
 */
export class PromotionService {
  private readonly promotion: PartnerPromotionService;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.promotion = new PartnerPromotionService(clientProvider.client());
  }

  /**
   * Creates a promotionfor a given partner
   *
   * @param partnerId
   * @param data
   * @returns
   */
  async create(partnerId: string, data: ThgCreatePromotionDtoGen): Promise<ThgPromotionViewModelGen> {
    return (await this.promotion.partnerPromotionControllerCreate(partnerId, data)).data;
  }

  /**
   * Gets all promotionconfigs by partner
   *
   * @param partnerId
   * @param promotionId
   * @returns
   */
  async findAllByPartnerId(
    query: ThgPartnerPromotionControllerFindAllParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgPromotionViewModelGen[] | undefined }> {
    return (await this.promotion.partnerPromotionControllerFindAll(query)).data;
  }

  /**
   * Gets a promotionconfig by partner
   *
   * @param partnerId
   * @param promotionId
   * @returns
   */
  async findOneByPartnerId(partnerId: string, promotionId: string): Promise<ThgPromotionViewModelGen> {
    return (await this.promotion.partnerPromotionControllerFindOne(partnerId, promotionId)).data;
  }

  /**
   * Updates a promotionconfig by partner
   *
   * @param partnerId
   * @param promotionId
   * @returns
   */
  async updateByPartnerId(
    partnerId: string,
    promotionId: string,
    data: ThgUpdatePromotionDtoGen
  ): Promise<ThgPromotionViewModelGen> {
    return (await this.promotion.partnerPromotionControllerUpdate(partnerId, promotionId, data)).data;
  }

  /**
   * Removes a promotionconfig by partner
   *
   * @param partnerId
   * @param promotionId
   * @returns
   */
  async removeByPartnerId(partnerId: string, promotionId: string): Promise<ThgPromotionViewModelGen> {
    return (await this.promotion.partnerPromotionControllerRemove(partnerId, promotionId)).data;
  }
}

export default new PromotionService(new ThgHttpClientProvider());
