





































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ClipperModule } from "@/store/modules/clipper.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import { IAdminUser } from "@/models/user.entity";
import ThgQuoteQuickCheckoutListDialog from "./ThgQuoteQuickCheckoutListDialog.vue";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";

@Component({ components: { TemplateDialog, ThgQuoteQuickCheckoutListDialog } })
export default class ThgWorkItemRejectionCard extends DarkModeHighlightMixin {
  @Prop()
  selectedThg!: IThg;
  @Prop()
  selectedUser!: IAdminUser;
  @Prop()
  userNotification!: boolean;
  @Prop()
  newWorkitem!: boolean;
  @Prop({ default: false })
  reduced!: boolean;

  isActionEnabled = this.reduced;
  disabled = this.reduced;

  isQuickCheckoutDialogActive = false;

  get isAt() {
    return this.selectedThg.countryCode === CountryCodeEnum.austria;
  }

  get _userNotification() {
    return this.userNotification;
  }
  set _userNotification(userNotification: boolean) {
    this.$emit("update:userNotification", userNotification);
  }
  get _newWorkitem() {
    return this.newWorkitem;
  }
  set _newWorkitem(newWorkitem: boolean) {
    this.$emit("update:newWorkitem", newWorkitem);
  }

  get isClippyAutoOpen() {
    return ClipperModule.isCroppingInitiallyActive;
  }
  set isClippyAutoOpen(isClippyAutoOpen: boolean) {
    ClipperModule.setIsCroppingInitiallyActive(isClippyAutoOpen);
  }

  askForWork() {
    this.$emit("askForWork");
  }

  async requestRegistrationImage() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.REQUEST_REGISTRATION_IMAGES },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }

  async cancelOrder() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.CANCELED },
      noUserNotification: true
    });
    this.askForWork();
  }

  async requestData() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.REQUEST_DATA },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }

  async noBev() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.NO_BEV },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }

  async isSpam() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.SPAM },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }
  async isNoGermanRegistationDocument() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.NO_GERMAN_REGISTRATION_DOCUMENT },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }

  async setInvalidVehicleClass() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.INVALID_VEHICLE_CLASS },
      noUserNotification: !this._userNotification
    });
    if ((ThgPortalModule.thgWorkitem as IThg).parentId) {
      this.isQuickCheckoutDialogActive = true;
    } else {
      this.askForWork();
    }
  }

  async setHybridAt() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.HYBRID_AT },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }

  async setPayedAndInvalidVehicleClass() {
    await ThgPortalModule.updateThgWorkitemStatus({
      dto: { status: ThgStatusEnum.PAYED_AND_INVALID_VEHICLE_CLASS },
      noUserNotification: !this._userNotification
    });
    this.askForWork();
  }

  closeIsQuickCheckoutDialogActive() {
    this.isQuickCheckoutDialogActive = false;
    this.askForWork();
  }

  get partner() {
    return PartnerModule.partner;
  }
  get from() {
    return [this.partner];
  }
  get to() {
    return [this.selectedUser?.contact?.email];
  }
  get context() {
    return {
      thg: this.selectedThg,
      partner: this.partner,
      user: this.selectedUser
    };
  }
}
