













































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";
import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({ components: { Debug, LatestEntriesCardEmpty, Card } })
export default class ThgBillingSelectionChargingStationData extends DarkModeHighlightMixin {
  @Prop()
  chargingStation!: ThgChargingStationViewModelGen;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  loading = true;

  locked = true;

  toggleLock = () => {
    this.locked = !this.locked;
  };
}
