





















































import ThgMeterReadingForm from "@/components/thg/ThgMeterReadingForm.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";
import { CreateMeterReading } from "@/store/models/thg/meter-reading";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { ThgMeterReadingForm }
})
export default class ThgMeterReadingCreateDialog extends DarkModeHighlightMixin {
  @Prop()
  chargingStation!: ThgChargingStationViewModelGen;

  @Prop({ default: false })
  fab!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  dialog = false;

  async save(data: CreateMeterReading) {
    this.$emit("save", data);
    this.close();
  }

  openDialog() {
    this.dialog = true;
  }

  close() {
    (this.$refs.form as ThgMeterReadingForm).resetForm();
    this.dialog = false;
  }
}
