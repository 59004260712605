







































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component } from "vue-property-decorator";
import PartnerBillingInformationBankingCard from "./PartnerBillingInformationBankingCard.vue";
import PartnerBillingInformationDataCard from "./PartnerBillingInformationDataCard.vue";
import { PartnerModule } from "@/store/modules/partner";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";

@Component({
  components: { PartnerBillingInformationBankingCard, PartnerBillingInformationDataCard }
})
export default class PartnerBillingInformationDialog extends DarkModeHighlightMixin {
  dialog = false;
  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  get partner() {
    return PartnerModule.partner;
  }

  get companyName() {
    return this.partner?.companyName;
  }

  get partnerId() {
    if (PartnerModule.partner) {
      return PartnerModule.partner?._id || PartnerModule.partner.id;
    }
    return "";
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }
}
