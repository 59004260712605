var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionPartnerData.title'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [!_vm.partnerLoading && _vm.partnerData ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionPartnerData.partnerId'),
      "outlined": "",
      "dense": "",
      "disabled": "",
      "flat": ""
    },
    model: {
      value: _vm.partnerData._id,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerData, "_id", $$v);
      },
      expression: "partnerData._id"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": "Name",
      "outlined": "",
      "dense": "",
      "disabled": "",
      "flat": ""
    },
    model: {
      value: _vm.partnerData.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerData, "companyName", $$v);
      },
      expression: "partnerData.companyName"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionPartnerData.cardTitle'),
      "outlined": "",
      "dense": "",
      "disabled": "",
      "flat": ""
    },
    model: {
      value: _vm.partnerData.settings.cardTitle,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerData.settings, "cardTitle", $$v);
      },
      expression: "partnerData.settings.cardTitle"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionPartnerData.cardSubtitle'),
      "outlined": "",
      "dense": "",
      "disabled": "",
      "flat": ""
    },
    model: {
      value: _vm.partnerData.settings.cardSubtitle,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerData.settings, "cardSubtitle", $$v);
      },
      expression: "partnerData.settings.cardSubtitle"
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }