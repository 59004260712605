var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [!_vm.isPaginationEnabled ? _c('thg-charging-station-list-card', {
          attrs: {
            "loading": _vm.loading
          }
        }) : _c('thg-charging-station-paginated-list-card', {
          attrs: {
            "loading": _vm.loading
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('thg-charging-station-detail-information-card', {
          attrs: {
            "loading": _vm.loading,
            "selectedUser": _vm.selectedUser
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }