import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import priceAdminService from "@/services/thg/services/priceAdminService";
import priceService from "@/services/thg/services/priceService";
import {
  ThgCreatePriceDtoGen,
  ThgAdminPurchasingPriceControllerFindAllAdminParamsGen,
  ThgPriceViewModelGen,
  ThgUpdatePriceDtoGen
} from "@/services/thg/v1/data-contracts";
import { Action, Module, Mutation, getModule } from "vuex-module-decorators";
import store from "../VuexPlugin";
import { PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";
import { BackwardsCompatiblePaginatedStore } from "../backwards-compatible-paginated.store";
import { PageDataHandler } from "@/lib/utility/data/page-data-handler";
import { PriceDataAccessLayer } from "./access-layers/price.access-layer";

@Module({
  dynamic: true,
  namespaced: true,
  name: "price",
  store
})
export class PriceStore extends BackwardsCompatiblePaginatedStore<
  ThgPriceViewModelGen,
  ThgAdminPurchasingPriceControllerFindAllAdminParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 250;
  protected _totalPages = 0;
  protected _paginationList: ThgPriceViewModelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [
    { key: "isActive", type: PageFilterTypes.BOOLEAN },
    { key: "value", type: PageFilterTypes.NUMBER },
    { key: "year", type: PageFilterTypes.NUMBER },
    { key: "commission", type: PageFilterTypes.NUMBER },
    { key: "isArchived", type: PageFilterTypes.BOOLEAN },
    { key: "partnerId", type: PageFilterTypes.OBJECT_ID },
    { key: "id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  private _price: ThgPriceViewModelGen | null = null;

  get price(): ThgPriceViewModelGen | null {
    return this._price;
  }

  @Mutation
  mutatePrice(price: ThgPriceViewModelGen) {
    this._price = price;
  }

  @Action
  protected async loadDocuments(query: ThgAdminPurchasingPriceControllerFindAllAdminParamsGen): Promise<any> {
    const resources = await priceAdminService.findAllAdmin(query);

    return resources;
  }

  _data = PriceDataAccessLayer;
  _pager = new PageDataHandler(this._data, this._pageProvider);

  @Action
  public async findAllActiveAdmin(): Promise<ThgPriceViewModelGen[]> {
    const resources = await priceAdminService.findAllActiveAdmin();

    this.context.commit("mutatePriceLookup", resources);
    this.context.dispatch("setPaginationList", resources);

    return resources;
  }

  @Action
  async create(data: { partnerId: string; data: ThgCreatePriceDtoGen }): Promise<ThgPriceViewModelGen> {
    const price = await priceService.create(data.partnerId, data.data);
    this.context.commit("mutatePrice", price || null);
    return price;
  }

  @Action
  async findOneByPartnerId(data: { partnerId: string; id: string }): Promise<ThgPriceViewModelGen> {
    const resource = await priceService.findOneByPartnerId(data.partnerId, data.id);
    this.context.commit("mutatePrice", resource || null);
    return resource;
  }

  @Action
  async update(data: { partnerId: string; id: string; data: ThgUpdatePriceDtoGen }): Promise<ThgPriceViewModelGen> {
    const resource = await priceService.updateByPartnerId(data.partnerId, data.id, data.data);
    this.context.commit("mutatePrice", resource || null);
    this.replaceInList(resource);
    return resource;
  }

  @Action
  async remove(data: { partnerId: string; id: string }): Promise<ThgPriceViewModelGen> {
    const resource = await priceService.removeByPartnerId(data.partnerId, data.id);
    this.context.commit("mutatePrice", resource || null);
    return resource;
  }
}

export const PriceModule = getModule(PriceStore);
