





























































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { ThgRegistrationDtoGen } from "@/services/thg/v1/data-contracts";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemRegistrationData extends DarkModeHighlightMixin {
  @Prop()
  registration!: ThgRegistrationDtoGen;

  updatingRegistrationResults = false;

  locked = true;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  async confirmEdit() {
    if (!this.registration) {
      return;
    }
    this.updatingRegistrationResults = true;

    const registrationDto = {
      ...this.registration,
      manufacturerName: this.registration.manufacturerNameCode as any,
      manufacturerType: this.registration.manufacturerTypeCode as any
    };

    try {
      await ThgPortalModule.updateRegistration(registrationDto);
      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    }
    this.locked = true;
    this.updatingRegistrationResults = false;
  }
}
