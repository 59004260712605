import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { Counter } from "../v1/Counter";
import { ThgCounterViewmodelGen } from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Communicates with the counter module
 */
class CounterService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy for the thg billing.
   */
  counterProxy: Counter;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.counterProxy = new Counter(this.client);
  }

  /**
   * (ADMIN) Gets the current value of the counter
   *
   * @param counterName billing, account
   */
  async getCounterController(counterName: string): Promise<ThgCounterViewmodelGen> {
    return (await this.counterProxy.counterControllerFindOne(counterName)).data;
  }
}

export default new CounterService(new ThgHttpClientProvider());
