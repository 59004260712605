














import ThgMeterReadingDetailCard from "@/components/thg/ThgMeterReadingDetailCard.vue";
import ThgMeterReadingListCard from "@/components/thg/ThgMeterReadingListCard.vue";
import ThgMeterReadingListCardPaginated from "@/components/thg/ThgMeterReadingListCardPaginated.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { IAdminUser } from "@/models/user.entity";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    ThgMeterReadingListCard,
    ThgMeterReadingListCardPaginated,
    ThgMeterReadingDetailCard
  }
})
export default class ThgMeterReading extends PartnerFallbackMixin {
  loadingSelectedMeterReading = false;

  selectedUser: IAdminUser | null = null;

  get loadingAllMeterReadings() {
    return ThgPortalModule.allMeterReadingsLoading;
  }

  set loadingAllMeterReadings(loading: boolean) {
    ThgPortalModule.setAllMeterReadingsLoading(loading);
  }

  get getLoadingSelectedMeterReading() {
    return this.loadingSelectedMeterReading;
  }

  setLoadingSelectedMeterReading(isLoading: any) {
    this.loadingSelectedMeterReading = isLoading;
  }
}
