





















import { dumThgChargingStation, dumpThgChargingStationsAsCsv } from "@/lib/utility/dumpThgChargingStations";
import { handleError } from "@/lib/utility/handleError";
import CsvExportMixin from "@/mixins/CsvExportMixin.vue";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { ChargingStationAdminModule } from "@/store/modules/charging-station-admin.store";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import * as XLSX from "xlsx";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgQuoteExportMeterReadingDialog extends CsvExportMixin {
  @Prop()
  thgs!: ThgThgMeterReadingViewModelGen[];

  isDialogActive = false;

  exportType: "csv" | "xlsx" = "xlsx";

  async exportCsv() {
    try {
      this.isLoading = true;
      const chargingStationIds: string[] = [];
      this.thgs.forEach(c => {
        if (c.meterReading.chargingStationId) {
          chargingStationIds.push(c.meterReading.chargingStationId);
        }
      });

      const charingStations = await ChargingStationAdminModule.getManyChargingStations([
        ...new Set(chargingStationIds)
      ]);

      if (this.exportType == "csv") {
        const csv = dumpThgChargingStationsAsCsv(this.thgs, charingStations);
        this.create_csv_file(csv);
      }

      if (this.exportType === "xlsx") {
        const thgs = dumThgChargingStation(this.thgs, charingStations);
        const worksheet = XLSX.utils.json_to_sheet(thgs);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");
        XLSX.writeFile(workbook, "chargingstations" + new Date().toISOString() + ".xlsx", { compression: true });
      }
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
      this.isDialogActive = false;
    }
  }
}
