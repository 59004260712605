




































































import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { ThgCreateUserDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgCustomerDataForm extends Vue {
  @Prop({ default: false })
  loading!: boolean;

  createUserDto: ThgCreateUserDtoGen = {
    firstName: "",
    lastName: "",
    userName: ""
  };

  isValid = false;
  get requiredRule() {
    return [requiredRule()];
  }

  get emailRule() {
    return [emailRule(), requiredRule()];
  }
  save() {
    this.$emit("save", this.createUserDto);
  }
}
