/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgChargingStationViewModelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";

import Vue from "vue";
import { simpleDoubleDigitDate } from "./date-helper";

/**
 * View model for the "uba fomrular mitteilung oeffentlicher ladepunkte 38. bimschv"
 */
export interface IThgChargingStationExport {
  /**
   * Betreiber der Ladeeinrichtung*
   * Name des Beteibers gemäß der Anzeige gegenüber der Bundesnetzagentur
   */
  name: string;

  /**
   * Betreiber der Ladeeinrichtung*
   * Bertreibernummer (zugewiesen durch Bundesnetzagentur nach erster Anzeige eines Ladepunkts)
   */
  bnetzaOperatorId?: string;

  /**
   * Datum der Inbetriebnahme*
   * Datum der Inebtriebnahme oder des öffentlich Werdens der Ladeeinrichtung
   */
  commissioningDate: string;

  /**
   * Datum der Anzeige gegenüber der Bundesnetzagentur*
   * Datum der Anzeige der Ladeeinrichtung gegenüber der Bundesnetzagentur
   */
  registrationDate?: string;

  /**
   * Nennleistung (kW) der Ladeeinrichtung*
   * Gemäß Angabe gegenüber der Bundesnetzagentur
   */
  connectionPowerInKwh: string | number;

  /**
   * Public Key der Ladeeinrichtung
   * Angeben falls vorhanden
   */
  publicKey?: string;

  /**
   * Standort der Ladeeinrichtung*
   * Straße
   */
  street: string;

  /**
   * Standort der Ladeeinrichtung*
   * Hausnummer
   */
  streetNumber?: string;

  /**
   * Standort der Ladeeinrichtung*
   * Adresszusatz (optional)
   */
  addressOption?: string;

  /**
   * Standort der Ladeeinrichtung*
   * Postleitzahl
   */
  zip: string;

  /**
   * Standort der Ladeeinrichtung*
   * Ort
   */
  city: string;

  /**
   * Standort der Ladeeinrichtung*
   * Breitengrad
   */
  lat: string | number;

  /**
   * Standort der Ladeeinrichtung*
   * Längengrad
   */
  lng: string | number;

  /**
   * Zeitraum der Entnahme der mit diesem Antrag mitgeteilten Strommenge (Spalte Q)*
   * Beginn der Entnahme [tt.mm.jjjj]
   */
  startDate: string;

  /**
   * Zeitraum der Entnahme der mit diesem Antrag mitgeteilten Strommenge (Spalte Q)*
   * Ende der Entnahme [tt.mm.jjjj]
   */
  endDate: string;

  /**
   * Entnommene Menge in MWh*
   * Energetischen Menge des zur Verwendung in Straßenfahrzeugen mit Elektroantrieb entnommenen Stroms in Megawattstunden nach § 6 Abs. 1 38. BImSchV
   */
  amountInMwh: string | number;

  /**
   * "Datum der Vereinbarung in Textform mit dem Betreiber der Ladepunkte [TT.MM.JJJJ]"
   */
  date: string;
}

export function dumThgChargingStation(
  thgMeterReadings: ThgThgMeterReadingViewModelGen[],
  thgChargingStations: ThgChargingStationViewModelGen[],
  transformNumbers = false
): IThgChargingStationExport[] {
  const chargingStationMap: Map<string, ThgChargingStationViewModelGen> = new Map();

  thgChargingStations.map((c: ThgChargingStationViewModelGen) => chargingStationMap.set(c.id, c));

  const thgs: IThgChargingStationExport[] = [];

  for (const meterReading of thgMeterReadings) {
    const chargingStation = chargingStationMap.get(meterReading.meterReading.chargingStationId ?? "");

    const commissioningDate = chargingStation?.commissioningDate
      ? simpleDoubleDigitDate(chargingStation.commissioningDate)
      : "";
    const registrationDate = chargingStation?.registrationDate
      ? simpleDoubleDigitDate(chargingStation.registrationDate)
      : "";
    const startDate = simpleDoubleDigitDate(meterReading.meterReading.startDate);
    const endDate = simpleDoubleDigitDate(meterReading.meterReading.endDate);
    const date = simpleDoubleDigitDate(meterReading.timestamp.created);

    /**
     * Parse Street in Streetnumber and street
     */
    const rx = /^(.*?)\s+(\d+(?:[-./]\d+)?)(?:\s*(\S.*))?$/;
    let street = chargingStation?.address?.street;
    let streetNumber = "";

    try {
      if (chargingStation?.address) {
        const result = chargingStation.address.street.match(rx);
        if (result && result?.length >= 1) {
          street = result[1];
        }

        if (result && result?.length >= 2) {
          streetNumber = result[2];
        }

        if (result && result?.length >= 3 && result[3]) {
          streetNumber = streetNumber + result[3];
        }
      }
    } catch (error) {
      Vue.$log.error(error);
    }

    let connectionPowerInKwh: string | number = chargingStation?.connectionPowerInKwh || 0;
    let lat: string | number = chargingStation?.address?.geo?.lat || 0;
    let lng: string | number = chargingStation?.address?.geo?.lng || 0;
    let amountInMwh: string | number = meterReading.meterReading.amountInKwh / 1000 || 0;

    if (transformNumbers) {
      connectionPowerInKwh = chargingStation?.connectionPowerInKwh
        ? `${(+chargingStation.connectionPowerInKwh.toFixed(2)).toLocaleString("de-De")}`
        : "";
      lat = `${chargingStation?.address?.geo?.lat.toFixed(7).replace(".", ",")}` || "";
      lng = `${chargingStation?.address?.geo?.lng.toFixed(7).replace(".", ",")}` || "";
      amountInMwh = `${(+(meterReading.meterReading.amountInKwh / 1000).toFixed(3)).toLocaleString("de-De")}`;
    }

    const model: IThgChargingStationExport = {
      name: chargingStation?.chargingPointOperator?.name ?? "",
      bnetzaOperatorId: chargingStation?.chargingPointOperator?.bnetzaOperatorId ?? "",
      commissioningDate: commissioningDate,
      registrationDate: registrationDate,
      connectionPowerInKwh: connectionPowerInKwh,
      publicKey: "",
      street: street || "",
      streetNumber: streetNumber || "",
      addressOption: "",
      zip: chargingStation?.address?.zip || "",
      city: chargingStation?.address?.city || "",
      lat: lat,
      lng: lng,
      startDate: startDate,
      endDate: endDate,
      amountInMwh: amountInMwh,
      date: date
    };

    thgs.push(model);
  }
  return thgs;
}

/**
 *
 */
export function dumpThgChargingStationsAsCsv(
  thgMeterReadings: ThgThgMeterReadingViewModelGen[],
  thgChargingStations: ThgChargingStationViewModelGen[]
) {
  const thgs = dumThgChargingStation(thgMeterReadings, thgChargingStations, true);
  return thgs.map(t => Object.values(t).join(";")).join("\r\n");
}
