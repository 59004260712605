
















































import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import FilterCard from "@/components/filter/FilterCard.vue";
import { CounterModule } from "@/store/modules/counter.store";
import { ThgCreateBillingThgDtoGen } from "@/services/thg/v1/data-contracts";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import ThgBillingCreditorSelectionUserList from "./ThgBillingCreditorSelectionUserList.vue";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";

@Component({ components: { ThgQuoteExportDialog, FilterCard, ThgBillingCreditorSelectionUserList } })
export default class ThgBillingCreateBillingManual extends DarkModeHighlightMixin {
  @Prop()
  value!: ThgCreateBillingThgDtoGen;

  counterLoading = false;

  loadingCreateBilling = false;

  loadingAllUsers = false;

  /**
   * Gets all labels for the available billing types
   */
  get typeLabels() {
    return Object.values(BillingTypeEnum);
  }

  /**
   * BillingTypeEnum
   */
  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  /**
   * Internationalization
   */
  get i18n() {
    return this.$t("components.thg.thgBillingCreateBillingManual");
  }

  get allUsers() {
    return AdminUserPaginationModule.paginationList;
  }

  get userId(): any {
    return this.value.userId;
  }

  set userId(value: any) {
    this.value.userId = value._id;
  }

  get billingNumber() {
    return CounterModule.counterSequence;
  }

  get counter() {
    return CounterModule.counterSequence;
  }

  userIdSelection(userId: string) {
    this.value.userId = userId;
  }
}
