

















































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { handleError } from "@/lib/utility/handleError";
import { $t } from "@/lib/utility/t";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IPageFilterElement, PageFilterElement } from "@/models/page-filter-element.entity";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { MeterReadingPaginatedModule } from "@/store/modules/meter-reading-paginated.store";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { Component, Prop } from "vue-property-decorator";
import { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import { thgTotalKwhHelper } from "@/lib/utility/thg-total-kwh.helper";

@Component({
  components: { AnalyticsDateRangeSelectorDialog, PaginatedTable }
})
export default class ThgBatchSelectDialog extends DarkModeHighlightMixin {
  @Prop()
  type!: BatchTypeEnum;

  @Prop({ default: false })
  typeLock?: boolean;

  @Prop({ default: true })
  autoSetFiler?: boolean;

  get types() {
    return Object.values(BatchTypeEnum);
  }

  get currentType() {
    return this.type;
  }
  set currentType(type: BatchTypeEnum) {
    this.selectedThg = [];
    this.$emit("update:type", type);
  }

  get isChargingStation() {
    return this.currentType === BatchTypeEnum.meterReading;
  }

  get isVehicle() {
    return this.currentType === BatchTypeEnum.vehicle;
  }

  selectedThg: IThg[] = [];
  dialog = false;

  isLoading = false;

  get headers(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [
      {
        text: $t("components.thg.ThgChargingStationMeterReadingTable.created"),
        value: "timestamp.created",
        type: "date"
      },
      { text: "ID", value: "id" },
      { text: "UserID", value: "userId" },
      { text: "PartnerID", value: "partnerId" }
    ];

    if (this.isVehicle) {
      headers.push(
        { text: $t("views.fleet.FleetVehicleListView.vehicleClass"), value: "registration.vehicleClass" },
        { text: $t("components.thg.ThgBatchSelectDialog.numberplate"), value: "numberplate" },
        {
          text: $t("components.thg.ThgBatchSelectDialog.identificationnumber"),
          value: "registration.identificationnumber"
        },
        { text: $t("components.thg.ThgBatchSelectDialog.name"), value: "registration.name" }
      );
    } else {
      headers.push(
        { text: $t("views.ThgBillingBatchSelectionItemView.startDate"), value: "meterReading.startDate", type: "date" },
        { text: $t("views.ThgBillingBatchSelectionItemView.endDate"), value: "meterReading.endDate", type: "date" },
        { text: $t("views.ThgBillingBatchSelectionItemView.amountInKwh"), value: "meterReading.amountInKwh" }
      );
    }

    headers.push(
      {
        text: $t("components.thg.ThgBatchSelectDialog.countryCode"),
        value: "countryCode"
      },
      {
        text: $t("components.thg.ThgBatchSelectDialog.year"),
        value: "year"
      }
    );

    headers.push({ text: $t("components.thg.ThgBatchSelectDialog.status"), value: "status" });

    return headers;
  }

  get disabled() {
    return !this.selectedThg.length;
  }

  get loading() {
    return this.isLoading;
  }

  get store() {
    if (this.isChargingStation) {
      return MeterReadingPaginatedModule;
    } else {
      return ThgPaginationModule;
    }
  }

  get thgTotalKwhHelper() {
    return thgTotalKwhHelper(this.currentType, this.selectedThg);
  }

  async getItems() {
    if (this.isChargingStation) {
      await this.filterMeterReadings();
    } else {
      await this.filterVehicles();
    }
  }

  async filterVehicles() {
    try {
      this.isLoading = true;
      await ThgPaginationModule.fetchAll({});
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  async filterMeterReadings() {
    try {
      this.isLoading = true;
      await MeterReadingPaginatedModule.fetchAllFromBeginning({});
    } catch (e) {
      handleError(e);
    } finally {
      this.isLoading = false;
    }
  }

  emitSelected() {
    this.$emit("select", this.selectedThg);
    this.dialog = false;
  }

  async initialize() {
    this.selectedThg = [];

    if (this.autoSetFiler) {
      const filter: IPageFilterElement[] = [
        new PageFilterElement({
          key: "status",
          operation: "$eq",
          value: ThgStatusEnum.CONFIRMED
        }),
        new PageFilterElement({
          key: "countryCode",
          operation: "$eq",
          value: CountryCodeEnum.germany
        }),
        new PageFilterElement({
          key: "year",
          operation: "$eq",
          value: "2023"
        })
      ];
      ThgPaginationModule.setFilter(filter);
      MeterReadingPaginatedModule.setFilter(filter);
    }

    await this.getItems();
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }
}
