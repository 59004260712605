var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('div', [_c('v-card', {
    staticClass: "ma-8",
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelection.title")) + " "), _c('v-spacer'), _c('v-select', {
    staticClass: "mb-n9",
    attrs: {
      "items": _vm.billableTypes,
      "rules": _vm.requiredRule,
      "rounded": "",
      "flat": "",
      "outlined": "",
      "dense": "",
      "label": "Abrechnungstyp",
      "loading": _vm.isLoading,
      "disabled": _vm.isLoading
    },
    on: {
      "change": _vm.refilter
    },
    model: {
      value: _vm.billingType,
      callback: function callback($$v) {
        _vm.billingType = $$v;
      },
      expression: "billingType"
    }
  })], 1), _c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "append-icon": "mdi-magnify",
      "label": _vm.$t('components.thg.thgBillingSelection.search'),
      "single-line": "",
      "hide-details": "",
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          staticClass: "mr-4",
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDialog = true;
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-filter-outline")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingCsvSelection.filter")) + " ")])]), _c('thg-billing-plausibility-check-dialog', {
    attrs: {
      "checkType": _vm.CheckEnum.BILLING,
      "marginRight": 4
    }
  }), _vm.billingType === _vm.BillingTypeEnum.CREDIT ? _c('thg-billing-credit-auto-bill-dialog') : _vm._e(), _c('v-btn', {
    attrs: {
      "disabled": _vm.selected.length === 0,
      "text": "",
      "outlined": "",
      "color": "success"
    },
    on: {
      "click": _vm.startThgPayoutBillingProcess
    }
  }, [_vm._v(" " + _vm._s(_vm.startPayoutTitle) + " ")])], 1), _c('v-divider'), _c('v-data-table', {
    staticClass: "mt-4 word-break",
    attrs: {
      "show-select": "",
      "loading": _vm.isLoading,
      "loading-text": _vm.$t('components.thg.thgBillingSelection.loading'),
      "search": _vm.search,
      "headers": _vm.headers,
      "items": _vm.readyForBilling,
      "items-per-page": 15,
      "item-key": "id",
      "sortBy": "timestamp.created",
      "sortDesc": true,
      "custom-filter": _vm.customFilter
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToThgDetail(item.partnerId, item.id);
            }
          }
        }, [_vm._v(_vm._s(item.id))])];
      }
    }, {
      key: "item.numberplate",
      fn: function fn(_ref3) {
        var _item$registration;

        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.numberplate || ((_item$registration = item.registration) === null || _item$registration === void 0 ? void 0 : _item$registration.numberplate)) + " ")];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.timestamp.created).toLocaleString()))])];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.selected
    }
  })], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.filterDialog,
      "width": 600,
      "fullscreen": _vm.isMobile,
      "title": "Filter",
      "rightLoading": _vm.isLoading,
      "rightText": _vm.$t('components.thg.thgBillingCsvSelection.filter')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.filterDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.filterDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.filterDialog = false;
      },
      "rightClick": _vm.loadThgs
    }
  }, [_c('v-row', {
    staticClass: "ma-2"
  }, [_c('v-switch', {
    staticClass: "mt-n3",
    attrs: {
      "label": "Bereit für Rechnungslauf"
    },
    model: {
      value: _vm.filter.notBilled,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "notBilled", $$v);
      },
      expression: "filter.notBilled"
    }
  })], 1), _c('v-row', {
    staticClass: "ma-2"
  }, [_vm.filter.notBilled ? _c('v-select', {
    attrs: {
      "items": _vm.billableTypes,
      "rules": _vm.requiredRule,
      "rounded": "",
      "flat": "",
      "outlined": "",
      "dense": "",
      "label": "Abrechnungstyp"
    },
    model: {
      value: _vm.billingType,
      callback: function callback($$v) {
        _vm.billingType = $$v;
      },
      expression: "billingType"
    }
  }) : _c('v-combobox', {
    staticClass: "mb-n2",
    attrs: {
      "items": _vm.thgStatusList,
      "label": _vm.$t('components.thg.thgBillingSelection.filterDialog'),
      "rounded": "",
      "flat": "",
      "outlined": "",
      "multiple": "",
      "dense": "",
      "small-chips": "",
      "clearable": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-icon', [_vm._v("mdi-filter-outline")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filter.status,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "status", $$v);
      },
      expression: "filter.status"
    }
  })], 1), _c('v-row', {
    staticClass: "ml-2 mb-5 mr-2"
  }, [_c('label', {
    attrs: {
      "for": "publishedLow"
    }
  }, [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingCsvSelection.dateLow")))]), _c('v-spacer'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.dateRange[0],
      expression: "filter.dateRange[0]"
    }],
    staticClass: "bordered",
    attrs: {
      "type": "date",
      "name": "publishedLow"
    },
    domProps: {
      "value": _vm.filter.dateRange[0]
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filter.dateRange, 0, $event.target.value);
      }
    }
  })], 1), _c('v-row', {
    staticClass: "ml-2 mb-2 mr-2"
  }, [_c('label', {
    attrs: {
      "for": "publishedLow"
    }
  }, [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingCsvSelection.dateUp")))]), _c('v-spacer'), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.dateRange[1],
      expression: "filter.dateRange[1]"
    }],
    staticClass: "bordered ml-9",
    attrs: {
      "type": "date",
      "name": "publishedUp"
    },
    domProps: {
      "value": _vm.filter.dateRange[1]
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.filter.dateRange, 1, $event.target.value);
      }
    }
  })], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }