var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition",
      "scrollable": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attr = _ref.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "small": "",
            "elevation": "0",
            "color": "error"
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attr, false), on), [_vm._v(" Duplikate prüfen ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgBatchDuplicateCheckDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _vm.isHavingDuplicates ? _c('v-card-text', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDuplicateCheckDialog.duplicates", {
    duplicates: _vm.duplicates.length
  })) + " ")]) : _c('v-card-text', {
    staticClass: "mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDuplicateCheckDialog.noDuplicates")))]), _vm.isHavingDuplicates ? _c('div', {
    staticClass: "pa-4"
  }, [_c('v-chip-group', {
    attrs: {
      "mandatory": "",
      "active-class": "primary--text"
    },
    model: {
      value: _vm.selectedDuplicateValue,
      callback: function callback($$v) {
        _vm.selectedDuplicateValue = $$v;
      },
      expression: "selectedDuplicateValue"
    }
  }, _vm._l(_vm.duplicates, function (tag) {
    return _c('v-chip', {
      key: tag,
      attrs: {
        "value": tag
      }
    }, [_vm._v(" " + _vm._s(tag) + " ")]);
  }), 1)], 1) : _vm._e(), _vm.isHavingDuplicates ? _c('v-data-table', {
    staticClass: "mt-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.thgs,
      "item-key": "id",
      "sortBy": "timestamp.created",
      "sortDesc": true,
      "items-per-page": 10
    },
    scopedSlots: _vm._u([{
      key: "item.id",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToThg(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.id) + " ")])];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToUser(item);
            }
          }
        }, [_vm._v(_vm._s(item.userId))])];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.timestamp.created).toLocaleString()))])];
      }
    }], null, false, 2416266334)
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }