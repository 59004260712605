







































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import RefsThg from "@/components/utility/RefsThg.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import { PageFilterElement } from "@/models/page-filter-element.entity";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    ConfirmActionDialog,
    PaginatedTable,
    RefsThg
  },
  filters: {
    simpleDoubleDigitDate
  }
})
export default class ThgQuoteQuickCheckoutListDialog extends Vue {
  readonly store = ThgPaginationModule;

  readonly ThgStatusEnum = ThgStatusEnum;

  @Prop()
  isDialogActive!: boolean;

  @Prop()
  fullscreen!: boolean;

  @Prop()
  parentId!: string;

  @Prop()
  partnerId!: string;

  @Prop({ default: false })
  handleInvalidClass!: boolean;

  isLoadingSiblings = false;

  isLoadingParent = false;

  parents: IThg[] = [];

  siblings: IThg[] = [];

  isUpdateStatusLoading = false;

  changeStatusToPayedAndInvalidVehicleClassId = "";

  notifyUser = false;

  get isDialogActiveLocal() {
    return this.isDialogActive;
  }

  set isDialogActiveLocal(isDialogActive: boolean) {
    this.$emit("update:isDialogActive", isDialogActive);
  }

  async mounted() {
    // fetch parent
    this.isLoadingSiblings = true;
    this.isLoadingParent = true;
    this.store.setFilters([
      new PageFilterElement({
        key: "_id",
        operation: "$eq",
        value: this.parentId
      })
    ]);
    await this.store.fetchAll({}).catch(handleError);
    this.parents.push(...this.store.filteredAndSorted);
    this.isLoadingParent = false;

    // fetch all siblings
    this.store.setFilters([
      new PageFilterElement({
        key: "parentId",
        operation: "$eq",
        value: this.parentId
      })
    ]);
    this.store.fetchAll({}).catch(handleError);
    this.siblings.push(...this.store.filteredAndSorted);
    this.isLoadingSiblings = false;
  }

  async goToDetail(item: { partnerId: string; id: string }) {
    const partnerId = item.partnerId;
    const id = item.id;

    await new GoToHelper(this.$router).goToThgDetail(partnerId, id, true);
  }

  changeStatusToPayedAndInvalidVehicleClass(id: string) {
    this.changeStatusToPayedAndInvalidVehicleClassId = id;
    this.notifyUser = false;
  }

  async updateStatusToPayedAndInvalidVehicleClass() {
    this.isUpdateStatusLoading = true;

    const parent = this.parents.find(e => e.id === this.changeStatusToPayedAndInvalidVehicleClassId);
    parent?.updateStatus(ThgStatusEnum.PAYED_AND_INVALID_VEHICLE_CLASS, this.notifyUser).catch(handleError);

    this.isUpdateStatusLoading = false;
    this.changeStatusToPayedAndInvalidVehicleClassId = "";
  }

  close() {
    this.$emit("close");
  }
}
