






import OperationTable from "@/components/operations/OperationTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    OperationTable
  }
})
export default class ThgOperations extends Vue {}
