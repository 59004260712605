var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.item ? _c('v-list-item', {
    attrs: {
      "three-line": ""
    }
  }, [_vm._t("icon"), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-xs",
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.hasOpenDetailListener() ? _c('a', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.emitOpenDetail.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.item.numberplate) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.item.numberplate) + " ")])]), _c('v-list-item-subtitle', {
    on: {
      "click": _vm.openUpdateStatusDialog
    }
  }, [_vm.status ? _c('tooltip', {
    attrs: {
      "text": _vm.$t(_vm.status.portalText)
    }
  }, [_c('v-avatar', {
    staticClass: "mt-n1 mb-n1 mt-1",
    attrs: {
      "color": _vm.status.color,
      "elevation": 0,
      "size": "8"
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.status.icon) + " ")])], 1), _c('small', [_vm._v(" " + _vm._s(_vm.$t(_vm.status.portalText)) + " (" + _vm._s(_vm.item.status) + ") ")])], 1) : _vm._e(), _c('thg-admin-update-status-dialog', {
    ref: "thgAdminUpdateStatusDialog",
    attrs: {
      "loading": _vm.isUpdateStatusLoading,
      "hideActivator": true,
      "thg": _vm.item
    },
    on: {
      "save": _vm.updateStatus
    }
  })], 1), _c('v-list-item-subtitle', {
    staticClass: "text-xs",
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.item && _vm.item.timestamp && _vm.item.timestamp.created ? _c('span', {
    staticClass: "mr-1"
  }, [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(_vm.item.timestamp.created)) + " ")]) : _vm._e(), _vm.item.year ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.item.year) + " ")]) : _vm._e(), _vm.item.impactFactor ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.item.impactType) + " " + _vm._s(_vm.item.impactFactor) + " ")]) : _vm._e(), _vm.item.countryCode ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.item.countryCode) + " ")]) : _vm._e(), _vm.item.code ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.item.code) + " ")]) : _vm._e(), _vm.item.promotionId ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.promotion")) + " ")]) : _vm._e(), _vm.item.parentId ? _c('v-chip', {
    staticClass: "mr-1",
    attrs: {
      "x-small": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteQuickCheckoutListDialog.quickCheckout")) + " ")]) : _vm._e()], 1)], 1)], 2) : _c('div');
}
var staticRenderFns = []

export { render, staticRenderFns }