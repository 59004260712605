






























































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgTransferThgDtoGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop } from "vue-property-decorator";
import { IThg } from "@/models/thg.entity";

@Component({
  components: {}
})
export default class ThgQuoteTransferDialog extends DarkModeHighlightMixin {
  @Prop({ default: [] })
  value!: IThg[];

  @Prop({ default: true })
  icon = true;

  isLoading = false;
  dialog = false;
  valid = false;

  newPartner: PartnerEntity | null = null;

  transferToUser = false;
  userId = "";

  get thgIds() {
    return this.value.map(i => i.id).join(", ");
  }

  get loading() {
    return this.isLoading;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get currentPartner() {
    return PartnerModule.partnersMap.get(this.value[0].partnerId);
  }

  async transfer() {
    this.isLoading = true;

    try {
      let dto: ThgTransferThgDtoGen | undefined = undefined;

      if (!this.transferToUser) {
        if (!this.newPartner) {
          throw Error("no partner selected");
        }

        dto = { partnerId: this.newPartner.id || this.newPartner._id };
      } else {
        dto = { userId: this.userId };
      }

      const promises = [];
      for (const thg of this.value) {
        if (dto) {
          promises.push(ThgPortalModule.transfer({ partnerId: thg.partnerId, thgId: thg.id, dto }));
        }
      }

      await Promise.all(promises);

      this.$toast.success("👍");

      this.dialog = false;

      if (this.value.length === 1) {
        this.$router.push({
          name: "ThgQuoteDetailView",
          params: { thgId: this.value[0].id, partnerId: dto?.partnerId ?? this.value[0].partnerId }
        });
      } else {
        this.$router.push({
          name: "ThgQuote",
          params: { partnerId: dto?.partnerId ?? this.value[0].partnerId }
        });
      }
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Fehler beim transferieren.");
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.dialog = false;
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }
}
