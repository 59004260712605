


















































import ExcelImport from "@/components/utility/ExcelImport.vue";
import {
  booleanValidation,
  countrycodeValidation,
  excelDateToJSDate,
  numberValidation
} from "@/lib/excelImportTransformation/transformers";
import {
  ThgChargingStationViewModelGen,
  ThgCreateAnonymousChargingStationDtoGen
} from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Vue } from "vue-property-decorator";
import * as XLSX from "xlsx";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";

@Component({
  components: {
    ExcelImport
  }
})
export default class ThgChargingStationCreateBatchCard extends Vue {
  loading = false;
  config: { [key: string]: IExcelImportConfig } = {
    chargingStationName: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.chargingStationName.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.chargingStationName.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "ladestation_name",
      transform: value => value.toString()
    },
    chargingPointOperatorName: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.chargingPointOperatorName.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.chargingPointOperatorName.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "firmenname_betreiber",
      transform: value => value.toString()
    },
    zip: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.zip.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.zip.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "postleitzahl",
      transform: value => value.toString()
    },
    city: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.city.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.city.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "stadt",
      transform: value => value.toString()
    },
    street: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.street.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.street.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "strasse",
      transform: value => value.toString()
    },
    state: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.state.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.state.hint").toString(),
      required: false,
      import: false,
      originColumnNameInExcelFile: "bundesland",
      transform: value => value.toString()
    },
    countryCode: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.countryCode.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.countryCode.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "laendercode",
      transform: value => countrycodeValidation(value)
    },
    lat: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.lat.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.lat.hint").toString(),
      required: false,
      import: false,
      originColumnNameInExcelFile: "breitengrad",
      transform: value => numberValidation(value)
    },
    lng: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.lng.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.lng.hint").toString(),
      required: false,
      import: false,
      originColumnNameInExcelFile: "laengengrad",
      transform: value => numberValidation(value)
    },
    connectionPowerInKwh: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.connectionPowerInKwh.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.connectionPowerInKwh.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "anschlussleistung",
      transform: value => numberValidation(value)
    },
    chargingPointCount: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.chargingPointCount.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.chargingPointCount.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "anzahl_ladepunkte",
      transform: value => numberValidation(value)
    },
    commissioningDate: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.commissioningDate.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.commissioningDate.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "inbetriebnahmedatum",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    isRegistered: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.isRegistered.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.isRegistered.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "bnetza_registriert",
      transform: value => booleanValidation(value)
    },
    registrationDate: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.registrationDate.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.registrationDate.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "bnetza_registrierungs_datum",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    chargingPointOperatorBnetzaOperatorId: {
      label: this.$t(
        "components.thg.ThgChargingStationCreateBatchView.chargingPointOperatorBnetzaOperatorId.title"
      ).toString(),
      hint: this.$t(
        "components.thg.ThgChargingStationCreateBatchView.chargingPointOperatorBnetzaOperatorId.hint"
      ).toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "benetza_betreiber_id",
      transform: value => value.toString()
    },
    isFromRenewableEnergy: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.isFromRenewableEnergy.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.isFromRenewableEnergy.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "erneuerbare_energie",
      transform: value => booleanValidation(value)
    },
    renewableEnergySourceDescription: {
      label: this.$t(
        "components.thg.ThgChargingStationCreateBatchView.renewableEnergySourceDescription.title"
      ).toString(),
      hint: this.$t(
        "components.thg.ThgChargingStationCreateBatchView.renewableEnergySourceDescription.hint"
      ).toString(),
      required: false,
      import: true,
      originColumnNameInExcelFile: "erneuerbare_energie_beschreibung",
      transform: value => value.toString()
    },
    firstName: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.firstName.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.firstName.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "vorname",
      transform: value => value.toString()
    },
    lastName: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.lastName.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.lastName.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "nachname",
      transform: value => value.toString()
    },
    userName: {
      label: this.$t("components.thg.ThgChargingStationCreateBatchView.userName.title").toString(),
      hint: this.$t("components.thg.ThgChargingStationCreateBatchView.userName.hint").toString(),
      required: true,
      import: true,
      originColumnNameInExcelFile: "email",
      transform: value => value.toString()
    }
  };

  dataImports: ThgChargingStationViewModelGen[] = [];
  failedImports: { reason: string; dto?: ThgCreateAnonymousChargingStationDtoGen }[] = [];

  generateChargingTemplate() {
    const keys = Object.keys(this.config);
    const headers = {};
    for (const key of keys) {
      const prop = this.config[key].originColumnNameInExcelFile;
      headers[prop] = "";
    }
    const worksheet = XLSX.utils.json_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "chargingstationTemplate");
    XLSX.writeFile(workbook, "chargingstationTemplate" + new Date().toISOString() + ".xlsx", { compression: true });
  }

  createMeterReadingTemplate() {
    const flatMeterReadingDto = [];
    for (const item of this.dataImports) {
      flatMeterReadingDto.push({
        chargingStationName: item.name,
        chargingStationId: item.id,
        userId: item.userId,
        partnerId: "",
        startDate: "",
        endDate: "",
        amountInKwh: ""
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(flatMeterReadingDto);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Meterreadings");
    XLSX.writeFile(workbook, "meterreadgin_template" + new Date().toISOString() + ".xlsx", { compression: true });
  }

  createErrorReport() {
    const errorReport = [];
    for (const item of this.failedImports) {
      errorReport.push({
        reason: item.reason,
        dto: item.dto?.name
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(errorReport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Error Report");
    XLSX.writeFile(workbook, "error_report_import_charging" + new Date().toISOString() + ".xlsx", {
      compression: true
    });
  }

  /**
   *
   * @param items
   */
  async importData(items: { data: any; headers: any }) {
    this.loading = true;
    this.dataImports = [];
    this.failedImports = [];

    for (const item of items.data) {
      let createAnonymousChargingStation!: ThgCreateAnonymousChargingStationDtoGen;
      try {
        createAnonymousChargingStation = {
          name: item.chargingStationName,
          chargingPointOperator: {
            name: item.chargingPointOperatorName,
            bnetzaOperatorId: item.chargingPointOperatorBnetzaOperatorId
          },
          isFromRenewableEnergy: item.isFromRenewableEnergy,
          renewableEnergySourceDescription: item.renewableEnergySourceDescription || "",
          refs: [],
          address: {
            city: item.city,
            street: item.street,
            zip: item.zip,
            state: item.state || "",
            countryCode: item.countryCode || undefined,
            geo: {
              lat: item.lat || 0,
              lng: item.lng || 0
            }
          },
          isRegistered: item.isRegistered,
          commissioningDate: item.commissioningDate,
          registrationDate: item.registrationDate,
          connectionPowerInKwh: item.connectionPowerInKwh,
          chargingPointCount: item.chargingPointCount,
          createUserDto: {
            firstName: item.firstName,
            lastName: item.lastName,
            userName: item.userName
          },
          thgMeterReadings: []
        };
      } catch (error) {
        const errorMessage = (error as Error).message;
        this.failedImports.push({ reason: errorMessage });
        continue;
      }

      try {
        this.dataImports.push(await this.save(createAnonymousChargingStation));
      } catch (error) {
        const errorMessage = (error as Error).message;
        this.failedImports.push({ reason: errorMessage, dto: createAnonymousChargingStation });
      }
    }

    this.loading = false;
  }

  async save(dto: ThgCreateAnonymousChargingStationDtoGen): Promise<ThgChargingStationViewModelGen> {
    if (!PartnerModule.partner._id) {
      throw new Error("PartnerId not set");
    }
    this.$log.debug(JSON.stringify(dto));
    return await ThgPortalModule.createAnonymous({ partnerId: PartnerModule.partner._id, dto: dto });
  }

  goToChargingStation() {
    this.$router.push({
      name: "ThgChargingStation"
    });
  }
}
