
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ThgBillingInformationViewmodelGen, ThgCreateBillingInformationDtoGen } from "@/services/thg/v1/data-contracts";
import { BillingInformationModule } from "@/store/modules/billing-information.store";

@Component({})
export default class ThgBillingSelectionItemPartnerDataMixin extends Vue {
  @Prop()
  partner!: ThgBillingInformationViewmodelGen;

  data: ThgBillingInformationViewmodelGen = { ...this.partner };

  locked = true;

  @Watch("partner", { deep: true })
  updateData() {
    this.data = { ...this.partner };
  }

  async confirmEdit() {
    if (!this.data) {
      return;
    }

    const partnerId = this.data.partnerId;

    const createOrUpdateDto: ThgCreateBillingInformationDtoGen = {
      isCompany: this.data.isCompany,
      isTaxDeductible: this.data.isTaxDeductible,
      firstName: this.data.firstName,
      lastName: this.data.lastName,
      company: this.data.company,
      taxnumber: this.data.taxnumber,
      address: {
        state: this.data.address.state,
        street: this.data.address.street,
        zip: this.data.address.zip,
        city: this.data.address.city,
        countryCode: this.data.address.countryCode
      },
      contact: {
        email: this.data.contact.email,
        phone: this.data.contact.phone
      }
    };

    await BillingInformationModule.createOrUpdateBillingInformation({ partnerId, createOrUpdateDto });
  }
}
