















import { Component, Vue, Watch } from "vue-property-decorator";
import ThgPriceTable from "@/components/thg/ThgPriceTable.vue";
import ThgPromotionTable from "@/components/thg/ThgPromotionTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { BaseGoToHelper } from "@/lib/utility/goToHelper";

export enum ThgPriceTabs {
  PRICE = "price",
  PROMOTION = "promotion"
}

@Component({
  components: {
    TheLayoutPortal,
    ThgPriceTable,
    ThgPromotionTable
  },
  filters: {}
})
export default class ThgPriceTableView extends Vue {
  tab = 0;

  get tabs() {
    return Object.values(ThgPriceTabs);
  }

  setTab(tab?: ThgPriceTabs) {
    this.tab = 0;
    if (tab) {
      const index = this.tabs.indexOf(tab as ThgPriceTabs);

      if (index > -1) {
        this.tab = index;
      }
    }
  }

  mounted() {
    const tab = this.$route.params.tab;
    this.setTab(tab as ThgPriceTabs);
  }

  @Watch("tab")
  syncRoute() {
    const location = this.$router.resolve({
      name: "ThgPriceTab",
      params: {
        tab: this.tabs[this.tab] ?? ""
      }
    });
    if (location) {
      new BaseGoToHelper(this.$router).setUrl(location.location);
    }
  }
}
