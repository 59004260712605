export const targetGroupAttributes = [
  "userId",
  "userCompanyName",
  "zip",
  "thgVehicleId",
  "thgVehicleParnterId",
  "thgVehicleYear",
  "thgVehicleClass",
  "thgVehicleVin",
  "thgVehicleIsMultiyear",
  "thgVehicleNumberplate",
  "thgVehicleStatus",
  "thgVehicleCountryCode",
  "partnerId",
  "meterReadingId"
] as const;
export type TargetGroupAttributes = typeof targetGroupAttributes[number];
