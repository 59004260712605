






import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import ThgBillingCreditorSelection from "@/components/thg/ThgBillingCreditorSelection.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    ThgBillingCreditorSelection
  }
})
export default class ThgBillingCreditorSelectionView extends Vue {}
