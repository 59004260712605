





























import AnalyticsKpiColumn from "@/components/analytics/AnalyticsKpiColumn.vue";
import ThgBatchUpdatePriceDialog from "@/components/thg/ThgBatchUpdatePriceDialog.vue";
import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBatchDetailViewmodelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { IThg } from "@/models/thg.entity";

@Component({
  components: { AnalyticsKpiColumn, ThgBatchUpdatePriceDialog, Card }
})
export default class ThgBatchDetailInformationDistributionCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  batch!: ThgBatchDetailViewmodelGen;

  date(timestamp: any) {
    return simpleDate(timestamp.created);
  }

  get thgs() {
    return this.batch.thgList as IThg[];
  }

  get numberOfQuotes() {
    return this.batch.batch.itemIds.length;
  }

  get vehicleClassDistribution(): Map<string, number> {
    const distributionMap = new Map<string, number>([
      ["M1", 0],
      ["M3", 0],
      ["N1", 0],
      ["N2", 0],
      ["N3", 0],
      ["Other", 0]
    ]);

    for (const thg of this.thgs) {
      const vehicleClass = thg.registration.vehicleClass as any;
      if (vehicleClass) {
        distributionMap.set(vehicleClass, (distributionMap.get(vehicleClass) || 0) + 1);
      }
    }
    return distributionMap;
  }

  get kpiGroup() {
    const kpiGroup = [
      {
        icon: "mdi-certificate-outline",
        value: this.vehicleClassDistribution.get("M1") || 0,
        name: "M1"
      },
      {
        icon: "mdi-certificate-outline",
        value: this.vehicleClassDistribution.get("N1") || 0,
        name: "N1"
      },
      {
        icon: "mdi-certificate-outline",
        value: this.vehicleClassDistribution.get("N2") || 0,
        name: "N2"
      },
      {
        icon: "mdi-certificate-outline",
        value: this.vehicleClassDistribution.get("N3") || 0,
        name: "N3"
      },
      {
        icon: "mdi-certificate-outline",
        value: this.vehicleClassDistribution.get("other") || 0,
        name: "other"
      },
      {
        icon: "mdi-certificate-outline",
        value: this.vehicleClassDistribution.get("M3") || 0,
        name: "M3"
      }
    ];

    return kpiGroup;
  }

  get showKpiGroup() {
    return this.batch.batch.type === BatchTypeEnum.vehicle;
  }

  get showPriceKpiGroup() {
    return this.batch.batch.pricePerKwh;
  }

  get priceKpiGroup() {
    const priceKpiGroup = [];
    if (this.batch.batch.pricePerKwh) {
      priceKpiGroup.push({
        icon: "mdi-currency-eur",
        value: this.batch.batch.pricePerKwh.value || 0,
        name: this.batch.batch.pricePerKwh.unit.toString() + " /kwh"
      });
      priceKpiGroup.push({
        icon: "mdi-percent",
        value: this.batch.batch.pricePerKwh.commission || 0,
        name: "components.thg.ThgBatchDetailInformationDistributionCard.comission"
      });
    }

    return priceKpiGroup;
  }
}
