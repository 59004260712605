




































































import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import { IAdminUser } from "@/models/user.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import DataCard from "../utility/DataCard.vue";
import Debug from "../utility/Debug.vue";

@Component({ components: { Debug, DataCard } })
export default class ThgBillingSelectionItemUserCompanyData extends Vue {
  @Prop()
  user!: IAdminUser;

  data = this.user;

  locked = true;

  async confirmEdit() {
    if (!this.data) {
      return;
    }

    const updateUserDto = new UpdateUserDtoFactory().createFromIUser(this.data);
    await this.user.updateFromDto(updateUserDto);
  }
}
