
























































import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { ThgBillingViewmodelGen, ThgPartnerCommissionPerYearDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({ components: { ConfirmActionDialog } })
export default class ThgBillingCancellationDialog extends Vue {
  @Prop()
  billing!: ThgBillingViewmodelGen;

  cancelBillingDialog = false;

  loading = false;

  partnerCommissions: ThgPartnerCommissionPerYearDtoGen[] = [];

  pricePerKwh = "";

  isValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  async confirmBillingCancellation() {
    this.loading = true;
    try {
      const billing = await BillingBatchModule.createBilling({
        query: {},
        data: {
          partnerCommissions: this.partnerCommissions.map(commission => ({
            year: Number(commission.year),
            partnerCommission: Number(commission.partnerCommission)
          })),
          billingType: BillingTypeEnum.CANCELLATION,
          createAccountingRecord: true,
          createPdf: true,
          force: true,
          pricePerKwh: Number(this.pricePerKwh),
          ids: [this.billing.billingNumber.toString()]
        }
      });
      this.$toast.success(this.$t("components.thg.ThgBillingCancellationDialog.successCancelation"), {
        onClick: () => this.goToBillingDetail(billing.billingNumber)
      });
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.cancelBillingDialog = false;
      this.loading = false;
    }
  }

  goToBillingDetail(billingNumber: number) {
    this.$router.push({
      name: "ThgBillingNumberDetailView",
      params: { billingNumber: billingNumber.toString() }
    });
  }
  cancelBillingCancellation() {
    this.cancelBillingDialog = false;
  }
}
