/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAdvertisementControllerGetAllForPartnerParamsGen,
  ThgAdvertisementControllerGetAllParamsGen,
  ThgAdvertisementViewmodelGen,
  ThgCreateAdvertisementDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgUpdateAdvertisementAdminDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Advertisement<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Advertisement
   * @name AdvertisementControllerGetAll
   * @summary (ADMIN) Get all advertisments
   * @request GET:/advertisement
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgAdvertisementViewmodelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  advertisementControllerGetAll = (query: ThgAdvertisementControllerGetAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgAdvertisementViewmodelGen[] }, ThgExceptionViewmodelGen>({
      path: `/advertisement`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Advertisement
   * @name AdvertisementControllerGetAllForPartner
   * @summary (ADMIN) Get all advertisments
   * @request GET:/partner/{partnerId}/advertisement
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgAdvertisementViewmodelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  advertisementControllerGetAllForPartner = (
    { partnerId, ...query }: ThgAdvertisementControllerGetAllForPartnerParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgAdvertisementViewmodelGen[] }, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/advertisement`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Advertisement
   * @name AdvertisementControllerCreate
   * @summary (ADMIN) Create a advertisement
   * @request POST:/partner/{partnerId}/advertisment
   * @secure
   * @response `200` `ThgAdvertisementViewmodelGen` Created Advertisement
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  advertisementControllerCreate = (partnerId: string, data: ThgCreateAdvertisementDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgAdvertisementViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/advertisment`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Advertisement
   * @name AdvertisementControllerUpdate
   * @summary (ADMIN) Update a advertisement for a partner
   * @request PATCH:/partner/{partnerId}/advertisment/{advertisementId}
   * @secure
   * @response `200` `ThgAdvertisementViewmodelGen` Updated Advertisement
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  advertisementControllerUpdate = (
    partnerId: string,
    advertisementId: string,
    data: ThgUpdateAdvertisementAdminDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgAdvertisementViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/advertisment/${advertisementId}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Advertisement
   * @name AdvertisementControllerFind
   * @summary (ADMIN) Gets an advertisement
   * @request GET:/partner/{partnerId}/advertisement/{advertisementId}
   * @secure
   * @response `200` `ThgAdvertisementViewmodelGen` Found advertisement
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  advertisementControllerFind = (partnerId: string, advertisementId: string, params: RequestParams = {}) =>
    this.http.request<ThgAdvertisementViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/advertisement/${advertisementId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Advertisement
   * @name AdvertisementControllerDelete
   * @summary (ADMIN) Delete an advertisement
   * @request DELETE:/partner/{partnerId}/advertisement/{advertisementId}
   * @secure
   * @response `200` `ThgAdvertisementViewmodelGen` the deleted advertisement
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  advertisementControllerDelete = (partnerId: string, advertisementId: string, params: RequestParams = {}) =>
    this.http.request<ThgAdvertisementViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/advertisement/${advertisementId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
