var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "ml-3",
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionChargingStationData.title'),
      "margin": 0
    }
  }, [_c('v-card-actions', [_vm.chargingStation ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionChargingStationData.id'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.chargingStation.id,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "id", $$v);
      },
      expression: "chargingStation.id"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionChargingStationData.name'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.chargingStation.name,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "name", $$v);
      },
      expression: "chargingStation.name"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionChargingStationData.isRegistered'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.chargingStation.isRegistered,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation, "isRegistered", $$v);
      },
      expression: "chargingStation.isRegistered"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionChargingStationData.state'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.chargingStation.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "state", $$v);
      },
      expression: "chargingStation.address.state"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionChargingStationData.city'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.chargingStation.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "city", $$v);
      },
      expression: "chargingStation.address.city"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionChargingStationData.street'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.chargingStation.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address, "street", $$v);
      },
      expression: "chargingStation.address.street"
    }
  })], 1) : !_vm.loading ? _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('latest-entries-card-empty')], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }