var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.createdBilling ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [_c('thg-billing-selection-item-pdf-result', {
    key: JSON.stringify(_vm.createdBilling) + 'pdf',
    staticClass: "ma-1",
    attrs: {
      "commissions": _vm.partnerCommissions,
      "pricePerKwh": _vm._pricePerKwh,
      "billing": _vm.createdBilling
    },
    on: {
      "update:commissions": function updateCommissions($event) {
        _vm.partnerCommissions = $event;
      },
      "update:pricePerKwh": function updatePricePerKwh($event) {
        _vm._pricePerKwh = $event;
      },
      "update:price-per-kwh": function updatePricePerKwh($event) {
        _vm._pricePerKwh = $event;
      }
    }
  }), _c('thg-billing-selection-item-billing-result', {
    key: JSON.stringify(_vm.createdBilling) + 'result',
    staticClass: "ma-1",
    attrs: {
      "billing": _vm.createdBilling
    }
  }), _c('thg-billing-selection-item-accounting-result', {
    key: _vm.createdBilling.id + 'accounting',
    staticClass: "ma-1",
    attrs: {
      "commissions": _vm.partnerCommissions,
      "pricePerKwh": _vm._pricePerKwh,
      "billing": _vm.createdBilling
    },
    on: {
      "update:commissions": function updateCommissions($event) {
        _vm.partnerCommissions = $event;
      },
      "update:pricePerKwh": function updatePricePerKwh($event) {
        _vm._pricePerKwh = $event;
      },
      "update:price-per-kwh": function updatePricePerKwh($event) {
        _vm._pricePerKwh = $event;
      }
    }
  })], 1) : _c('div', [_c('latest-entries-card-empty', {
    staticClass: "mt-15"
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }