var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionContactData.title'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "loading": _vm.updatingUser,
                  "disabled": _vm.locked
                },
                on: {
                  "click": _vm.confirmEdit
                }
              }, on), [_vm.locked ? _c('v-icon', [_vm._v("mdi-check")]) : _c('v-icon', {
                staticClass: "unsavedData",
                attrs: {
                  "color": "red",
                  "x-large": ""
                }
              }, [_vm._v("mdi-check")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionContactData.confirmEdit")) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.locked = !_vm.locked;
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.locked ? "mdi-lock-outline" : "mdi-lock-open"))])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionBatchData.unlock")) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [!_vm.loadingUser && _vm.userData ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.id'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": ""
    },
    model: {
      value: _vm.userData._id,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "_id", $$v);
      },
      expression: "userData._id"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.firstName'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.userData.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "firstName", $$v);
      },
      expression: "userData.firstName"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.lastName'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.userData.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "lastName", $$v);
      },
      expression: "userData.lastName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.street'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.userData.address.street ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 3372356096),
    model: {
      value: _vm.userData.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.userData.address, "street", $$v);
      },
      expression: "userData.address.street"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.zip'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.userData.address.zip ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2697150242),
    model: {
      value: _vm.userData.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.userData.address, "zip", $$v);
      },
      expression: "userData.address.zip"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.city'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.userData.address.city ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 4271925606),
    model: {
      value: _vm.userData.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.userData.address, "city", $$v);
      },
      expression: "userData.address.city"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.state'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.userData.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.userData.address, "state", $$v);
      },
      expression: "userData.address.state"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.company'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.userData.company,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "company", $$v);
      },
      expression: "userData.company"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.phone'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.userData.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.userData.contact, "phone", $$v);
      },
      expression: "userData.contact.phone"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.email'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.userData.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.userData.contact, "email", $$v);
      },
      expression: "userData.contact.email"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.taxnumber'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm.userData.company && !_vm.userData.taxnumber ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 496424255),
    model: {
      value: _vm.userData.taxnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.userData, "taxnumber", $$v);
      },
      expression: "userData.taxnumber"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.userData
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }