

















import { convertPdfToImagePromises } from "@/lib/utility/convertPdfToImage";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgChargingStationProofDocumentDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import saveAs from "file-saver";
import { ImageSwipeImage } from "@/lib/interfaces/vue-image-swipe.interface";

@Component({
  components: {}
})
export default class ThgChargingStationInformationProofs extends DarkModeHighlightMixin {
  @Prop({ default: () => [] })
  proofs!: ThgChargingStationProofDocumentDtoGen[];

  images: ImageSwipeImage[] = [];

  isLoading = false;

  async mounted() {
    this.isLoading = true;

    for (const proof of this.proofs) {
      if (proof.extensionType === "pdf") {
        const pages = await convertPdfToImagePromises(proof.url);

        let index = 0;
        for (const page of pages) {
          index++;

          const url = await page;

          this.images.push({
            src: url,
            thumbnail: url,
            w: 0,
            h: 0,
            title: `${proof.originalName} ${index}/${this.images.length}`
          });
        }
      } else if (proof.extensionType === "image") {
        this.images.push({
          src: proof.url,
          thumbnail: proof.url,
          w: 0,
          h: 0,
          title: `${proof.originalName}`
        });
      }
    }

    this.isLoading = false;
  }

  downloadFile(proof: ThgChargingStationProofDocumentDtoGen) {
    if (proof) {
      saveAs(proof.url, proof.originalName);
    } else {
      this.$toast.error(this.$t("report.ThgChargingStationDetailCard.fileNotFound"));
    }
  }
}
