

























import { Component, Vue, Prop } from "vue-property-decorator";
import PartnerSelectionForm from "@/components/thg/PartnerSelectionForm.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
@Component({
  components: {
    PartnerSelectionForm
  }
})
export default class EfahrerCreateThgForm extends Vue {
  @Prop({ default: false })
  loading!: boolean;
  @Prop()
  partnerId!: string;

  year = "";
  code = "";
  campaign = "";

  get yearRules() {
    return [requiredRule(), isNumberRule()];
  }

  savePartnerId(partnerId: string) {
    this.$emit("partnerChange", partnerId);
  }
}
