/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgCreatePriceDtoGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgPartnerPurchasingPriceControllerFindAllParamsGen,
  ThgPriceViewModelGen,
  ThgUpdatePriceDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PartnerPurchasingPriceService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Partner Purchasing Price Service
   * @name PartnerPurchasingPriceControllerCreate
   * @summary Create a Price for a partner
   * @request POST:/partner/{partnerId}/price
   * @secure
   * @response `200` `ThgPriceViewModelGen` The created Price
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPurchasingPriceControllerCreate = (
    partnerId: string,
    data: ThgCreatePriceDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPriceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/price`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Purchasing Price Service
   * @name PartnerPurchasingPriceControllerFindAll
   * @summary Get all prices
   * @request GET:/partner/{partnerId}/price
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgPriceViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPurchasingPriceControllerFindAll = (
    { partnerId, ...query }: ThgPartnerPurchasingPriceControllerFindAllParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgPriceViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/price`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Purchasing Price Service
   * @name PartnerPurchasingPriceControllerFindOne
   * @summary Get a price
   * @request GET:/partner/{partnerId}/price/{priceId}
   * @secure
   * @response `200` `ThgPriceViewModelGen` The price
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPurchasingPriceControllerFindOne = (partnerId: string, priceId: string, params: RequestParams = {}) =>
    this.http.request<ThgPriceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/price/${priceId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Purchasing Price Service
   * @name PartnerPurchasingPriceControllerUpdate
   * @summary Update a price for a partner
   * @request PATCH:/partner/{partnerId}/price/{id}
   * @secure
   * @response `200` `ThgPriceViewModelGen` The price
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPurchasingPriceControllerUpdate = (
    partnerId: string,
    id: string,
    data: ThgUpdatePriceDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPriceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/price/${id}`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Partner Purchasing Price Service
   * @name PartnerPurchasingPriceControllerRemove
   * @summary Delete a price from a partner
   * @request DELETE:/partner/{partnerId}/price/{id}
   * @secure
   * @response `200` `ThgPriceViewModelGen` The deleted Price
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  partnerPurchasingPriceControllerRemove = (partnerId: string, id: string, params: RequestParams = {}) =>
    this.http.request<ThgPriceViewModelGen, ThgExceptionViewmodelGen>({
      path: `/partner/${partnerId}/price/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params
    });
}
