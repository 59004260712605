var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table-wrapper', {
    attrs: {
      "hideTableHeader": true,
      "allItems": _vm.meterReadings,
      "loading": _vm.loading,
      "headers": _vm.meterReadingTableHeaders
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-chip', {
                attrs: {
                  "dark": "",
                  "color": _vm.thgStatus(item.status).color
                }
              }, [_c('v-icon', _vm._g(_vm._b({}, 'v-icon', attrs, false), on), [_vm._v(" " + _vm._s(_vm.thgStatus(item.status).icon) + " ")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(_vm.thgStatus(item.status).text)))])])];
      }
    }, {
      key: "item.userId",
      fn: function fn(_ref3) {
        var _vm$user, _vm$user2, _vm$user3, _vm$user4;

        var item = _ref3.item;
        return [_vm.user(item.userId) ? _c('span', [_c('div', [_c('b', [_vm._v(" " + _vm._s(((_vm$user = _vm.user(item.userId)) === null || _vm$user === void 0 ? void 0 : _vm$user.firstName) + " " + ((_vm$user2 = _vm.user(item.userId)) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.lastName)) + " ")])]), _c('span', [_vm._v(" " + _vm._s((_vm$user3 = _vm.user(item.userId)) === null || _vm$user3 === void 0 ? void 0 : _vm$user3.company) + " ")]), _c('span', [_vm._v(" " + _vm._s((_vm$user4 = _vm.user(item.userId)) === null || _vm$user4 === void 0 ? void 0 : _vm$user4.userName) + " ")])]) : _vm._e()];
      }
    }, {
      key: "item.meterReading.chargingStationId",
      fn: function fn(_ref4) {
        var _vm$chargingStation, _vm$chargingStation2, _vm$chargingStation3;

        var item = _ref4.item;
        return [_vm.chargingStation(item.meterReading.chargingStationId) ? _c('span', [_c('div', [_vm._v(" " + _vm._s((_vm$chargingStation = _vm.chargingStation(item.meterReading.chargingStationId)) === null || _vm$chargingStation === void 0 ? void 0 : _vm$chargingStation.address.street) + " ")]), _c('span', [_vm._v(" " + _vm._s((_vm$chargingStation2 = _vm.chargingStation(item.meterReading.chargingStationId)) === null || _vm$chargingStation2 === void 0 ? void 0 : _vm$chargingStation2.address.zip) + " ")]), _c('span', [_vm._v(" " + _vm._s((_vm$chargingStation3 = _vm.chargingStation(item.meterReading.chargingStationId)) === null || _vm$chargingStation3 === void 0 ? void 0 : _vm$chargingStation3.address.city) + " ")])]) : _vm._e()];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }