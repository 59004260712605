var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    style: "background-color: ".concat(_vm.backgroundColor, " ").concat(_vm.colorVariantTop),
    attrs: {
      "elevation": 0
    }
  }, [!_vm.isLoading ? _c('vue-picture-swipe', {
    ref: "pictureSwipe",
    staticClass: "mx-2 mt-4 mb-2",
    attrs: {
      "items": _vm.images,
      "options": {
        shareEl: false,
        rotationOn: true,
        showAnimationDuration: 0,
        hideAnimationDuration: 0
      }
    }
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "image"
    }
  }), _vm._l(_vm.proofs, function (proof) {
    return _c('v-chip', {
      key: proof.id,
      staticClass: "ma-1",
      attrs: {
        "color": "primary",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(proof);
        }
      }
    }, [_vm._v(" " + _vm._s(proof.originalName) + " "), _c('v-icon', {
      staticClass: "ml-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-download")])], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }