var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("report.thgChargingStation.title")))]), _c('v-spacer'), _c('v-tooltip', {
    attrs: {
      "bottom": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push({
                name: 'ThgChargingStationCreateOverviewView'
              });
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }], null, false, 1871134190)
  }, [_c('span', [_vm._v("Neue THG-Quote anlegen")])])], 1), _c('v-card', {
    staticClass: "flex-container",
    attrs: {
      "flat": ""
    }
  }, [_c('filter-card', {
    attrs: {
      "search": _vm.search,
      "filterList": _vm.filterList,
      "filterItems": _vm.chargingStations,
      "filteredItems": _vm.filteredItems
    },
    on: {
      "update:search": function updateSearch($event) {
        _vm.search = $event;
      }
    }
  }), _c('div', {
    staticClass: "scrollable"
  }, [_vm.filteredItems.length !== 0 ? _c('v-virtual-scroll', {
    attrs: {
      "item-height": "66",
      "items": _vm.filteredItems
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _item$chargingPointOp, _item$address, _item$address2;

        var item = _ref2.item;
        return [_c('v-list-item', {
          key: item.id,
          class: _vm.isActive(item),
          attrs: {
            "two-line": ""
          },
          on: {
            "click": function click($event) {
              return _vm.set(item);
            }
          }
        }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(((_item$chargingPointOp = item.chargingPointOperator) === null || _item$chargingPointOp === void 0 ? void 0 : _item$chargingPointOp.name) || "") + " (" + _vm._s(item.name) + ")")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.connectionPowerInKwh) + "kW | " + _vm._s(((_item$address = item.address) === null || _item$address === void 0 ? void 0 : _item$address.street) || "") + ", " + _vm._s(((_item$address2 = item.address) === null || _item$address2 === void 0 ? void 0 : _item$address2.city) || ""))])], 1), _c('v-list-item-action', [_c('v-list-item-action-text', [_vm._v(_vm._s(_vm.date(item)))]), _c('v-list-item-action-text')], 1)], 1)];
      }
    }], null, false, 3323006126)
  }) : _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.emptyDataSrc,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }], null, false, 974937243)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportList.noData")))])])], 1)], 1)], 1)], 1) : _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }