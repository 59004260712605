








import ThgAdvertisemtDetailInformationCard from "@/components/thg/ThgAdvertisemtDetailInformationCard.vue";
import ThgMeterReadingDetailCard from "@/components/thg/ThgMeterReadingDetailCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    ThgMeterReadingDetailCard,
    ThgAdvertisemtDetailInformationCard
  }
})
export default class ThgAdvertisementDetail extends Vue {
  loading = false;

  async mounted() {
    this.loading = true;
    let ad = undefined;
    try {
      ad = await AdvertisementAdminModule.getAdById({
        partnerId: this.$route.params.partnerId,
        id: this.$route.params.advertisementId
      });
    } catch {
      this.$toast.error("Fehler beim Laden der Anzeige.");
    }

    try {
      if (ad) {
        await PartnerModule.getPartnerById(ad.partnerId);
      }
    } catch {
      this.$toast.error("Fehler beim Laden des Partners.");
    }
    this.loading = false;
  }
}
