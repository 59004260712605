var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": _vm.$t('components.thg.ThgQuoteDetailInformationImpactFactorCard.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('thg-quote-update-dialog')];
      },
      proxy: true
    }])
  }, [_c('v-card-text', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteDetailInformationImpactFactorCard.type")) + ": " + _vm._s(_vm.thg.impactType) + " "), _c('br')]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteDetailInformationImpactFactorCard.factor")) + ": " + _vm._s(_vm.thg.impactFactor) + " "), _c('br')]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteDetailInformationImpactFactorCard.payout")) + ": " + _vm._s(100 - _vm.thg.impactFactor) + " "), _c('br')]), _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteDetailInformationImpactFactorCard.code")) + ": " + _vm._s(_vm.thg.code) + " "), _c('br')])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }