










































import { Component, Prop, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/me-user.store";
import { CheckEnum } from "@/lib/enum/check.enum";
import Card from "../utility/Card.vue";
import { AccountModule } from "@/store/modules/account.store";
import { BillingModule } from "@/store/modules/billing.store";
import { ThgOperationIdViewModelGen } from "@/services/thg/v1/data-contracts";

/**
 * Used to send a mail
 *
 * first a selection of templates is shown that can be used in the current context
 * define context by passing it in the context prop or passing a loadContext callback function that loads the context
 */
@Component({ components: { Card } })
export default class ThgBillingPlausibilityCheckDialog extends Vue {
  @Prop({ default: true })
  showTextButton?: boolean;

  @Prop({ default: 0 })
  marginRight?: number;

  @Prop({ default: 0 })
  marginLeft?: number;

  @Prop()
  checkType!: CheckEnum;

  checkDialog = false;

  loading = false;

  get isAdmin() {
    return UserModule.isAdmin;
  }

  toOperationDetail(operationId: string, partnerId?: string) {
    if (partnerId) {
      this.$router.push({ name: "OperationPartnerView", params: { operationId: operationId, partnerId: partnerId } });
    } else {
      this.$router.push({ name: "OperationView", params: { operationId: operationId } });
    }
  }

  async executeCheck() {
    try {
      this.loading = true;
      let results: ThgOperationIdViewModelGen;
      if (this.checkType === CheckEnum.ACCOUNT) {
        results = await AccountModule.check();
      }
      if (this.checkType === CheckEnum.BILLING) {
        results = await BillingModule.check();
      }

      this.$toast(this.$t("components.thg.ThgBillingPlausibilityCheckDialog.goToCheck"), {
        onClick: () => this.toOperationDetail(results.operationId, this.$route.params.partnerId)
      });
    } catch (e) {
      this.$toast((e as any).message);
      this.$log.error(e);
    } finally {
      this.checkDialog = false;
      this.loading = false;
    }
  }
}
