











































































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { ThgAffiliateViewmodelGen } from "@/services/thg/v1/data-contracts";
import { UpdateAffiliateDto } from "@/store/interface/thg/affiliate.get.one.dto.interface";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { AffiliateTypeEnum } from "@/lib/enum/AffiliateType.enum";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemAffiliateData extends DarkModeHighlightMixin {
  @Prop()
  affiliate!: ThgAffiliateViewmodelGen;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  get types() {
    return Object.values(AffiliateTypeEnum);
  }

  get affiliateTypes() {
    return Object.values(AffiliateTypeEnum);
  }

  locked = true;

  updatingAffiliate = false;

  async editAffiliate() {
    try {
      this.updatingAffiliate = true;
      const updateAffiliateDtoGen: UpdateAffiliateDto = {
        dto: {
          code: this.affiliate.code,
          type: this.affiliate.type,
          provision: this.affiliate.provision,
          userId: this.affiliate.userId,
          note: this.affiliate.note,
          discountForCustomer: this.affiliate.discountForCustomer,
          isValid: this.affiliate.isValid
        },
        partnerId: this.affiliate.partnerId,
        affiliateId: this.affiliate.id
      };

      await AffiliatePortalModule.updateSelectedAffiliate(updateAffiliateDtoGen);

      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    }
    this.locked = true;
    this.updatingAffiliate = false;
  }
}
