

































































































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { AffiliateTypeEnum } from "@/lib/enum/AffiliateType.enum";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgCreateAffiliateDtoGen } from "@/services/thg/v1/data-contracts";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { PartnerModule } from "@/store/modules/partner";
import { ErrorLogModule } from "@/store/modules/error-log";
import { ConflictException } from "@/lib/exceptions/http";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class AffiliateCreateDialog extends DarkModeHighlightMixin {
  isLoading = false;
  isLoadingUsers = false;
  dialog = false;
  valid = false;

  title = "components.thg.AffiliateCreateDialog.title";
  btnText = "components.thg.AffiliateCreateDialog.btnText";

  affiliateType = AffiliateTypeEnum.FREINDS;

  affiliateTypeEnum = Object.keys(AffiliateTypeEnum).map(k => AffiliateTypeEnum[k as any]);

  createAffiliate: ThgCreateAffiliateDtoGen = {
    code: "",
    type: AffiliateTypeEnum.FREINDS as any,
    note: "",
    provision: 0,
    discountForCustomer: 0,
    isValid: true
  };

  isSubmittingUser = false;

  isUser = false;
  userId = "";

  initialize() {
    this.createAffiliate = {
      code: "",
      type: AffiliateTypeEnum.FREINDS as any,
      note: "",
      provision: 0,
      discountForCustomer: 0,
      isValid: true
    };
  }

  async loadUsers() {
    this.isLoadingUsers = true;
    try {
      if (this.users.length === 0) {
        await AdminUserPaginationModule.getUsers();
      }
    } catch (error) {
      ErrorLogModule.addErrorLog({
        name: "Fetching User Error",
        message: "Could not load current User"
      });

      this.$toast("Faild to load users.");
    } finally {
      this.isLoadingUsers = false;
    }
  }

  get users() {
    return AdminUserPaginationModule.paginationList;
  }

  get isActivatedText(): string {
    if (this.createAffiliate.isValid) {
      return this.$t("components.thg.AffiliateCreateDialog.isValid").toString();
    }
    return this.$t("components.thg.AffiliateCreateDialog.isNotValid").toString();
  }

  get loading() {
    return this.isLoading;
  }

  async update() {
    this.isLoading = true;
    try {
      const createDto: ThgCreateAffiliateDtoGen = {
        code: this.createAffiliate.code,
        type: this.affiliateType,
        note: this.createAffiliate.note,
        provision: this.createAffiliate.provision,
        discountForCustomer: this.createAffiliate.discountForCustomer,
        isValid: this.createAffiliate.isValid
      };

      if (this.isUser) {
        createDto.userId = this.userId;
      }
      await AffiliatePortalModule.createAffiliate({
        partnerId: PartnerModule.partner.id || PartnerModule.partner._id,
        createAffiliateDto: createDto
      });
      this.dialog = false;
    } catch (error) {
      if (error instanceof ConflictException) {
        this.$toast.error(this.$t("components.thg.AffiliateCreateDialog.createErrorConflict").toString());
      } else {
        this.$toast.error(this.$t("components.thg.AffiliateCreateDialog.createError").toString());
        ErrorLogModule.addErrorLog({
          name: "Affiliate Error",
          message: "Could not create AffiliateDocument"
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  close() {
    this.dialog = false;
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get impactFactorRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberLowerRule(100));
    rules.push(numberHigherRule(0));

    return rules;
  }
}
