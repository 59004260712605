var render = function () {
  var _vm$selection$chargin, _vm$selection$chargin2, _vm$selection$chargin3, _vm$selectedUser, _vm$selectedUser$cont, _vm$selectedUser2, _vm$selectedUser2$con;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.loading && _vm.selection ? _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "1200px"
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.selection.name) + " (" + _vm._s(_vm.selection.id) + ")")]), _c('v-spacer')], 1), _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.createdDate) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.toDetailView
          }
        }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
      }
    }], null, false, 2345870730)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toDetail")))])]), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('thg-meter-reading-create-dialog', _vm._g({
          attrs: {
            "chargingStation": _vm.selection,
            "loading": _vm.isLoading
          },
          on: {
            "save": _vm.createMeterReading
          }
        }, on))];
      }
    }], null, false, 2058526890)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.createMeterReading")))])]), _c('thg-charging-station-transfer-dialog'), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.toEditView
          }
        }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, false, 2980456661)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toEdit")))])])], 1), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('thg-meter-reading-create-dialog', {
    attrs: {
      "chargingStation": _vm.selection,
      "loading": _vm.isLoading,
      "fab": true
    },
    on: {
      "save": _vm.createMeterReading
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('thg-charging-station-information-card', {
    attrs: {
      "chargingStation": _vm.selection,
      "showCarsPerDay": true
    }
  })], 1), _vm.selection.chargingPointOperator ? _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('customer-contact-card', {
    attrs: {
      "companyName": (_vm$selection$chargin = _vm.selection.chargingPointOperator) === null || _vm$selection$chargin === void 0 ? void 0 : _vm$selection$chargin.name,
      "customerName": (_vm$selection$chargin2 = _vm.selection.chargingPointOperator) === null || _vm$selection$chargin2 === void 0 ? void 0 : _vm$selection$chargin2.managingDirectorName,
      "address": (_vm$selection$chargin3 = _vm.selection.chargingPointOperator) === null || _vm$selection$chargin3 === void 0 ? void 0 : _vm$selection$chargin3.address
    }
  })], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('customer-contact-card', {
    attrs: {
      "userId": _vm.selectedUser._id,
      "companyName": _vm.selectedUser.company,
      "customerName": _vm.fullname,
      "address": _vm.selectedUser.address,
      "email": (_vm$selectedUser = _vm.selectedUser) === null || _vm$selectedUser === void 0 ? void 0 : (_vm$selectedUser$cont = _vm$selectedUser.contact) === null || _vm$selectedUser$cont === void 0 ? void 0 : _vm$selectedUser$cont.email,
      "phone": (_vm$selectedUser2 = _vm.selectedUser) === null || _vm$selectedUser2 === void 0 ? void 0 : (_vm$selectedUser2$con = _vm$selectedUser2.contact) === null || _vm$selectedUser2$con === void 0 ? void 0 : _vm$selectedUser2$con.phone
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-meter-reading-detail-table', {
    attrs: {
      "meterReadings": _vm.meterReadings
    }
  })], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }