



































import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import { ContactEntity } from "@/models/contactEntity";
import { IAdminUser } from "@/models/user.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import DataCard from "../utility/DataCard.vue";
import Debug from "../utility/Debug.vue";

@Component({ components: { Debug, DataCard } })
export default class ThgBillingSelectionItemUserContactData extends Vue {
  @Prop()
  user!: IAdminUser;

  data = this.user;

  contact: ContactEntity = this.user.contact ?? {
    email: "",
    phone: ""
  };

  locked = true;

  async confirmEdit() {
    if (!this.data) {
      return;
    }

    const updateUserDto = new UpdateUserDtoFactory().createFromIUser({ ...this.data, contact: this.contact });
    const updatedUser = await this.user.updateFromDto(updateUserDto);
    if (!updatedUser) return;
    this.user.contact = updatedUser.contact;
  }
}
