
import ThgCropImage from "@/components/thg/ThgCropImage.vue";
import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class ImageCropMixin extends Vue {
  getCropImage() {
    return this.$refs.thgCropImage as ThgCropImage;
  }

  unclip() {
    this.getCropImage().unclip();
  }
  reclip() {
    this.getCropImage().reclip();
  }
  clip() {
    this.getCropImage().clip();
  }
}
