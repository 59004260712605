/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAdminPromotionControllerFindAllAdminParamsGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgPromotionViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class AdminPromotionService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Admin Promotion Service
   * @name AdminPromotionControllerFindAllAdmin
   * @summary Get all promotions
   * @request GET:/promotion
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgPromotionViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminPromotionControllerFindAllAdmin = (
    query: ThgAdminPromotionControllerFindAllAdminParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgPromotionViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/promotion`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
}
