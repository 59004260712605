var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": "Ghgs",
      "margin": 0
    }
  }, [_c('v-card-actions', [_c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, _vm._l(_vm.thgs, function (thg) {
    return _c('div', {
      key: thg.id
    }, [_c('a', {
      on: {
        "click": function click($event) {
          return _vm.goToGhgDetail(thg);
        }
      }
    }, [_vm._v(" " + _vm._s(thg.id) + " ")])]);
  }), 0)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }