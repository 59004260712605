



























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import Card from "../utility/Card.vue";
import ThgBillingSelectionItemPartnerData from "./ThgBillingSelectionItemPartnerData.vue";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";

@Component({ components: { Debug, Card, ThgBillingSelectionItemPartnerData } })
export default class ThgBillingSelectionItemSelectPartnerData extends DarkModeHighlightMixin {
  @Prop()
  partners!: PartnerEntity;

  @Prop()
  partnerIds!: string[];

  @Watch("partnerIds")
  setSelectedPartnerId() {
    if (this.partnerIds?.length) {
      BillingBatchModule.setSelectedPartnerId(this.partnerIds[0]);
    }
  }

  mounted() {
    this.setSelectedPartnerId();
  }

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }
}
