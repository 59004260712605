var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('paginated-table', {
    ref: "paginationTable",
    attrs: {
      "store": _vm.pagination,
      "title": _vm.$t('components.thg.thgBillingCsvSelection.title'),
      "headers": _vm.headers,
      "baseQuery": {}
    },
    scopedSlots: _vm._u([{
      key: "item.relatedParties",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.partnerId ? _c('small', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingCsvSelection.partnerId")) + ": "), _c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToPartnerDetail(item.partnerId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.partnerId) + " ")]), _c('br')]) : _vm._e(), item.userId ? _c('small', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingCsvSelection.userId")) + ": "), _c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToUserDetail(item.userId);
            }
          }
        }, [_vm._v(" " + _vm._s(item.userId) + " ")]), _c('br')]) : _vm._e()];
      }
    }, {
      key: "item.type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingCreateNewBillingManual." + item.type)) + " ")];
      }
    }, {
      key: "item.timestamp.created",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.timestamp.created).toLocaleString()))])];
      }
    }, {
      key: "item.billingNumber",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToBilling(item.billingNumber);
            }
          }
        }, [_vm._v(_vm._s(item.billingNumber))])];
      }
    }, {
      key: "item.thgId",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('a', {
          on: {
            "click": function click($event) {
              return _vm.goToThgDetail(item.partnerId, item.thgId);
            }
          }
        }, [_c('small', [_vm._v(" " + _vm._s(item.thgId) + " ")])]), _vm._l(item.referenceIds || [], function (referenceId, index) {
          return _c('div', {
            key: referenceId + item.number
          }, [index < 4 ? _c('div', [index < 3 ? _c('a', {
            on: {
              "click": function click($event) {
                return _vm.goToThgDetail(item.partnerId, referenceId);
              }
            }
          }, [_c('small', [_vm._v(" " + _vm._s(referenceId) + " ")])]) : index === 3 ? _c('small', [_vm._v(" +" + _vm._s(item.referenceIds.length - 3) + " ")]) : _vm._e()]) : _vm._e()]);
        })];
      }
    }, {
      key: "item.isPublished",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.isPublished ? _c('v-icon', {
          attrs: {
            "color": "success"
          }
        }, [_vm._v(" mdi-check ")]) : _c('v-icon', {
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-close ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }