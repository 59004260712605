















































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgTransferThgMeterReadingDtoGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgMeterReadingTransferDialog extends DarkModeHighlightMixin {
  isLoading = false;
  isDialogActive = false;
  isFormValid = false;

  radioGroup: "partnerId" | "userId" | "chargingStationId" = "userId";

  /** Partner to transfer the meter reading to */
  partner: PartnerEntity | null = null;
  userId = "";
  chargingStationId = "";

  title = "components.thg.ThgMeterReadingTransferDialog.title";
  content = "components.thg.ThgMeterReadingTransferDialog.content";
  btnText = "components.thg.ThgMeterReadingTransferDialog.btnText";

  get transferToUser() {
    return this.radioGroup === "userId";
  }

  get transferToChargingStation() {
    return this.radioGroup === "chargingStationId";
  }

  get transferToPartner() {
    return this.radioGroup === "partnerId";
  }

  get loading() {
    return this.isLoading;
  }

  get partners() {
    return PartnerModule.partners;
  }

  /**
   * Meter reading partner id
   */
  get partnerId() {
    return ThgPortalModule.selectedMeterReading.partnerId;
  }

  get meterReadingId() {
    return ThgPortalModule.selectedMeterReading.id;
  }

  get subTitle() {
    return `${this.$t(this.content)} ${this.meterReadingId}`;
  }

  get requiredRules() {
    return [requiredRule()];
  }

  /**
   * Clear all inputs when switching between fields.
   * Even if more than one field is populated, only the data from the selected one will
   * be sent to the backend.
   */
  clearFormInputs() {
    (this.$refs.form as any).reset();
  }

  async transfer() {
    this.isLoading = true;
    let dto: ThgTransferThgMeterReadingDtoGen = {};

    try {
      if (this.transferToUser) {
        dto = { userId: this.userId };
      } else if (this.transferToChargingStation) {
        dto = { chargingStationId: this.chargingStationId };
      } else if (this.transferToPartner && this.partner && this.partner.id) {
        dto = { partnerId: this.partner.id };
      } else {
        throw new Error("No transfer option selected");
      }

      const updatedMeterReading = await ThgPortalModule.transferMeterReading({
        partnerId: this.partnerId,
        meterReadingId: this.meterReadingId,
        data: dto
      });

      this.$toast.success("👍");

      this.$router.push({
        name: "ThgMeterReadingDetailView",
        params: { meterReadingId: updatedMeterReading.id }
      });

      this.isDialogActive = false;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgMeterReadingTransferDialog.errorText"));
    } finally {
      this.isLoading = false;
    }
  }
}
