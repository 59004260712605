












import ThgQuoteForm from "@/components/thg/ThgQuoteForm.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { handleError } from "@/lib/utility/handleError";
import { ThgCreateAnonymousThgDtoGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ReportModule } from "@/store/modules/report.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Vue } from "vue-property-decorator";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";

@Component({
  components: {
    LayoutSimple,
    ThgQuoteForm
  }
})
export default class ThgQuoteCreateView extends Vue {
  loading = false;

  async mounted() {
    this.loading = true;
    try {
      await PartnerModule.getPartnerById(this.$route.params.partnerId);
      // Hack because we rely on the report module to create THGs. As we are in the portal we don't set it by default.
      await ReportModule.setPartner(PartnerModule.partner);

      await PartnerActiveOfferModule.loadDocuments(this.$route.params.partnerId);
    } catch {
      this.$toast.error(this.$t("components.thg.ThgQuoteCreateView.error"));
    } finally {
      this.loading = false;
    }
  }

  async save(item: ThgCreateAnonymousThgDtoGen) {
    this.$log.debug(item);
    try {
      this.loading = true;

      await ThgCreateModule.createAnonymous({ partnerId: this.$route.params.partnerId, thgQuote: item });

      this.$toast.success(this.$t("components.thg.ThgQuoteCreateView.success"));

      this.$router.push({
        name: "ThgDashboardPartnerSelected",
        params: {
          partnerId: this.$route.params.partnerId
        }
      });
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
