








import { calculateDayDifference, simpleDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: { Card },
  filters: {}
})
export default class ThgQuoteMeterReadingWorkItemCardChart extends DarkModeHighlightMixin {
  @Prop()
  meterReadings!: IThg[];
  @Prop()
  meterReading!: IThg;

  get sortMeterReadings() {
    return this.meterReadings.sort(
      (a, b) =>
        new Date(a.meterReading?.startDate || "").getTime() - new Date(b.meterReading?.startDate || "").getTime()
    );
  }

  get chartOption() {
    const source: (string | number)[][] = [];
    const colors: (string | undefined)[] = [];

    for (const meterReading of this.sortMeterReadings) {
      if (meterReading.meterReading) {
        const name = `${simpleDate(meterReading.meterReading.startDate)}-${simpleDate(
          meterReading.meterReading.endDate
        )} (${meterReading.id})`;
        const dayDifference = Math.abs(
          calculateDayDifference(
            new Date(meterReading.meterReading.endDate),
            new Date(meterReading.meterReading.startDate)
          )
        );

        const value = dayDifference
          ? Math.round(meterReading.meterReading.amountInKwh / dayDifference)
          : meterReading.meterReading.amountInKwh;

        source.push([name, value]);
        if (meterReading.id === this.meterReading.id) {
          colors.push("blue");
        } else {
          colors.push("red");
        }
      }
    }

    return {
      dataset: [
        {
          dimensions: ["name", "value"],
          source
        }
      ],
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow"
        }
      },
      grid: {
        left: "3%",
        right: "3%",
        top: "5%",
        bottom: "5%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        axisLabel: {
          formatter: (param: any) => {
            const time = param.split(" ")[0];

            return time;
          }
        }
      },
      yAxis: {},
      series: {
        type: "bar",
        encode: { x: "name", y: "value" },
        tooltip: {
          valueFormatter: (value: string) => value + this.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.kwh")
        },
        itemStyle: {
          color: (param: any) => {
            const split = param.name.split("(")[1];
            const id = split.split(")")[0];

            if (id === this.meterReading.id) {
              return "green";
            }

            return "blue";
          }
        }
      }
    };
  }
}
