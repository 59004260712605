












import { Component, Prop, Vue } from "vue-property-decorator";
import { IRefDetail } from "./RefsList.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPartnerViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {},
  filters: {}
})
export default class RefsPartner extends Vue implements IRefDetail<ThgPartnerViewModelGen> {
  @Prop()
  item!: ThgPartnerViewModelGen;

  @Prop({ default: false })
  small!: boolean;

  @Prop({ default: false })
  confirmable!: boolean;

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  goToDetail() {
    new GoToHelper(this.$router).goToPartnerDetail(PartnerModule.partner.id, true);
  }
}
