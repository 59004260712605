








































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { BillingModule } from "@/store/modules/billing.store";
import { NotFoundException } from "@/lib/exceptions/http";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { ThgPartnerCommissionPerYearDtoGen } from "@/services/thg/v1/data-contracts";
import { OperationModule } from "@/store/modules/operation.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";

@Component({ components: { Debug, LatestEntriesCardEmpty, Card, ConfirmActionDialog } })
export default class ThgBillingSelectionItemPdfResult extends DarkModeHighlightMixin {
  @Prop()
  billing: any;

  @Prop()
  commissions!: ThgPartnerCommissionPerYearDtoGen[];

  get partnerCommissions(): ThgPartnerCommissionPerYearDtoGen[] {
    return this.commissions;
  }

  set partnerCommissions(commissions: ThgPartnerCommissionPerYearDtoGen[]) {
    this.$emit("update:commissions", commissions);
  }

  /**
   * a billing
   */
  @Prop()
  pricePerKwh!: number;

  get _pricePerKwh() {
    return this.pricePerKwh;
  }
  set _pricePerKwh(pricePerKwh: number) {
    this.$emit("update:pricePerKwh", pricePerKwh);
  }

  pdfLoading = false;
  pdf = "";
  locked = true;
  uploadDialog = false;
  dateString = "";
  isRegenerateDialogActive = false;

  file: File | undefined = undefined;

  @Ref("uploader") readonly uploader!: HTMLInputElement;

  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "508px";
    }
  }

  get innerWidth() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "90%";
    } else {
      return "500px";
    }
  }

  openPdf() {
    window.open(this.pdf, "_blank");
  }

  @Watch("billing", { deep: true })
  async mounted() {
    if (!this.billing) {
      this.pdfLoading = false;

      return;
    }
    try {
      this.pdfLoading = true;
      this.pdf = await BillingModule.getPdf(this.billing.billingNumber);
    } catch (e) {
      if (!(e instanceof NotFoundException)) {
        this.$log.error(e);
        this.$toast.error((e as any).message);
      }
    } finally {
      this.pdfLoading = false;
    }
  }

  /**
   * regenerates the pdf
   */
  async regenerate() {
    this.pdfLoading = true;

    if (this.billing.referenceIds && this.billing.referenceIds.length > 50) {
      await this.regenerateAsync();
    } else {
      await this.regenerateSync();
    }

    this.pdfLoading = false;
    this.isRegenerateDialogActive = false;
  }

  /**
   * Regenerates the pdf not via async job and looks up the pdf link directly
   */
  async regenerateSync() {
    try {
      const isBatchBilling = this.billing.referenceIds?.length > 0;
      await BillingModule.regeneratePdf({
        billingNumber: this.billing.billingNumber,
        dateString: this.dateString,
        isBatchBilling: isBatchBilling,
        partnerCommissions: this.partnerCommissions.map(c => {
          return { partnerCommission: Number(c.partnerCommission), year: Number(c.year) };
        }),
        pricePerKwh: Number(this._pricePerKwh)
      });

      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
    } catch (e) {
      handleError(e);
    } finally {
      this.pdf = BillingModule.billingPdf;
    }
  }

  /**
   * Regenerates the pdf using a background job
   */
  async regenerateAsync() {
    try {
      const operationId = await BillingModule.regeneratePdfAsync({
        billingNumber: this.billing.billingNumber,
        data: {
          billingDate: this.dateString,
          partnerCommissions: this.partnerCommissions.map(c => {
            return { partnerCommission: Number(c.partnerCommission), year: Number(c.year) };
          }),
          pricePerKwh: Number(this._pricePerKwh)
        }
      });

      OperationModule.dispatchToast({
        operationId,
        onClick: () => {
          new GoToHelper(this.$router).goToOperationDetail(operationId);
        }
      });
    } catch (e) {
      handleError(e);
    }
  }

  async onFileInput(event: any) {
    this.file = event.target.files[0];
  }

  uploading = false;

  async submit() {
    if (!this.file) {
      this.$toast.error(this.$t("components.thg.thgBillingSelectionItemPdfResult.noFileProvided"));
      return;
    }

    try {
      this.uploading = true;
      await BillingModule.uploadPdf({ billingNumber: this.billing.billingNumber, file: this.file });
      this.uploading = false;
      this.$toast.success(this.$t("components.thg.thgBillingSelectionItemPdfResult.uploaded"));

      this.pdfLoading = true;
      await BillingModule.getPdf(this.billing.billingNumber);
      this.pdf = BillingModule.billingPdf;
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
      this.pdf = "";
    } finally {
      this.uploading = false;
      this.pdfLoading = false;
      this.uploadDialog = false;
    }
  }
}
