








































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import Debug from "../utility/Debug.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { Debug, Card, ConfirmActionDialog }
})
export default class ThgMeterReadingInformationCard extends DarkModeHighlightMixin {
  @Prop({ default: {} })
  meterReading!: ThgThgMeterReadingViewModelGen;

  @Prop({ default: false })
  loading!: boolean;

  /**
   * Form valid
   */
  isValid = false;

  /**
   * Show Dialog
   */
  isDialogActive = false;

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }

  toDate(date: string) {
    return new Date(date).toLocaleString("de-DE", this.dateOptions as any);
  }

  get requiredRule() {
    return requiredRule();
  }

  save() {
    this.$emit("save", this.meterReading);

    this.isDialogActive = false;
  }
}
