

















































































































import TemplateDialogPartnerSelection from "@/components/template/TemplateDialogPartnerSelection.vue";
import ThgBillingDetailDataCard from "@/components/thg/ThgBillingDetailDataCard.vue";
import ThgBillingDetailResultsCard from "@/components/thg/ThgBillingDetailResultsCard.vue";
import ThgBillingRejectButton from "@/components/thg/ThgBillingRejectButton.vue";
import Card from "@/components/utility/Card.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { ConflictException } from "@/lib/exceptions/http";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { handleError } from "@/lib/utility/handleError";
import ThgBillingBatchMixin from "@/mixins/ThgBillingBatchMixin.vue";
import {
  ThgAffiliateViewmodelGen,
  ThgBankingDtoGen,
  ThgBatchViewmodelItemGen,
  ThgBillingBatchCreateDtoGen,
  ThgBillingInformationViewmodelGen,
  ThgChargingStationViewModelGen,
  ThgPartnerCommissionPerYearDtoGen,
  ThgThgMeterReadingViewModelGen
} from "@/services/thg/v1/data-contracts";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { BankingModule } from "@/store/modules/banking.store";
import { BatchModule } from "@/store/modules/batch.store";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { BillingInformationModule } from "@/store/modules/billing-information.store";
import { ChargingStationAdminModule } from "@/store/modules/charging-station-admin.store";
import { OperationModule } from "@/store/modules/operation.store";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Watch } from "vue-property-decorator";
import { IThg } from "@/models/thg.entity";

@Component({
  components: {
    LayoutSimple,
    Card,
    TableWrapper,
    ConfirmActionDialog,
    TemplateDialogPartnerSelection,
    ThgBillingDetailDataCard,
    ThgBillingDetailResultsCard,
    ThgBillingRejectButton
  }
})
/**
 * A view where a user can review the data of a billed user and billed documents and generate the billing while validating its correctness
 */
export default class ThgBillingBatchSelectionItemView extends ThgBillingBatchMixin {
  billingType = (this.$route?.params?.billingType as BillingTypeEnum) || BillingTypeEnum.CREDIT;

  /**
   * Items in thg list that are selected
   */
  selectedSelectedBilledDocumentsLocal: (IThg | ThgThgMeterReadingViewModelGen)[] = [];
  get selectedSelectedBilledDocuments() {
    return this.selectedSelectedBilledDocumentsLocal;
  }
  /**
   * Limit amount of documents that can be billed
   */
  set selectedSelectedBilledDocuments(selected: (IThg | ThgThgMeterReadingViewModelGen)[]) {
    this.selectedSelectedBilledDocumentsLocal.splice(0, this.selectedSelectedBilledDocumentsLocal.length, ...selected);
  }

  /**
   * is data (like banking) loading for a billed user or partner
   */
  loadingDataForBilledBeing = true;

  /**
   * Is the force dialog active where the user is warned that a billing already exists
   */
  isForcedDialogActive = false;

  /**
   * Is a billing being generated rn
   */
  loadingCreation = false;

  /**
   * Is any initial data loading or not
   */
  isLoading = false;

  /**
   * The index of the currently selected user or partner in the list of "billed beings"
   */
  selectedWindow = 0;

  /**
   * The banking of a user or partner
   */
  banking: ThgBankingDtoGen | null = null;

  /**
   * The information of billed partners
   */
  partnerBillingInformation: ThgBillingInformationViewmodelGen | { partnerId: string } | null = null;

  /**
   * The affiliate codes that are related to the billed documents in case of a affiliate billing
   */
  affiliateCodes: ThgAffiliateViewmodelGen[] = [];

  /**
   * The commission of a partner
   */
  commissions: ThgPartnerCommissionPerYearDtoGen[] = [];

  /**
   * The price per kwh
   */
  pricePerKwHStore = 0;

  get pricePerKwH() {
    return this.pricePerKwHStore;
  }

  set pricePerKwH(value: number) {
    this.pricePerKwHStore = value;
  }

  /**
   * the documents that the billing is referring to
   */
  selectedBilledDocuments: (IThg | ThgThgMeterReadingViewModelGen)[] = [];

  partnerBankings = new Map<string, ThgBankingDtoGen | null>();

  chargingStation: ThgChargingStationViewModelGen | undefined;

  chargingStations: ThgChargingStationViewModelGen[] = [];

  get partners() {
    return PartnerModule.partners;
  }

  get partner() {
    return this.partners.find(p => p._id === this.partnerIdsFromDocuments[0]);
  }

  get selectedPartners() {
    return this.partners.filter(p => this.partnerIdsFromDocuments.includes(p._id));
  }

  batches: ThgBatchViewmodelItemGen[] = [];

  /**
   * titles, texts and translations. Whatever the modern connoisseur of fine billings may desire
   */
  get i18n() {
    return this.$t("views.ThgBillingBatchSelectionItemView") || {};
  }

  /**
   * The user/company that is billed
   */
  get billedBeings() {
    return BillingBatchModule.billedBeings;
  }

  /**
   * The related documents that are billed
   */
  get billedDocuments() {
    return BillingBatchModule.billedDocuments;
  }

  /**
   * The user/partner that is present in the view
   */
  get selectedBilledBeing() {
    if (!this.billedBeings) {
      return undefined;
    }
    return this.billedBeings[this.selectedWindow];
  }

  /**
   * The documents that are billed for the current billedBeing
   */
  async getSelectedBilledDocuments() {
    const selectedBilledDocuments: (IThg | ThgThgMeterReadingViewModelGen)[] = [];

    if (!this.selectedBilledBeing || !this.billedDocuments) {
      this.$toast.error(this.i18n["noSelectionRedirect"]);
      this.goToBillingSelection();
      return;
    }

    if (this.getIsAffiliateBillingType(this.billingType)) {
      // we need to get the thg documents that have an affiliate code that belongs to the selected user
      const userAffiliates: ThgAffiliateViewmodelGen[] = await AffiliatePortalModule.getAffiliatesByUserId(
        this.selectedBilledBeing._id
      );

      this.billedDocuments.forEach(bd => {
        const foundIndex = userAffiliates.findIndex(
          userAffilaite => bd.code === userAffilaite.code && bd.partnerId === userAffilaite.partnerId
        );
        if (foundIndex > -1) {
          selectedBilledDocuments.push(bd);
        }
      });
    }
    if (this.getIsUserFocusedBillingType(this.billingType)) {
      selectedBilledDocuments.push(...this.billedDocuments.filter(d => d.userId === this.selectedBilledBeing?.id));
    }
    if (this.getIsPartnerFocusedBillingType(this.billingType)) {
      selectedBilledDocuments.push(...this.billedDocuments.filter(d => d.partnerId === this.selectedBilledBeing?.id));
    }
    if (this.billingType === BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT) {
      for (const bd of selectedBilledDocuments) {
        if (!this.partnerBankings.get(bd.partnerId)) {
          const banking = (await this.loadPartnerBanking(bd.partnerId)) || null;
          this.partnerBankings.set(bd.partnerId, banking);
        }
      }
    }

    this.selectedBilledDocuments = selectedBilledDocuments;
    this.selectedSelectedBilledDocuments = selectedBilledDocuments;
  }

  /**
   * Looks up the created billing. this approach of adding all the created ghgs to the store as an array is used so that when we use the arrows to go back and forth between users, we can still see all previously craeted billings without having to looke them up.
   */
  get createdBilling() {
    if (!this.selectedBilledBeing) {
      return null;
    }
    return (
      BillingBatchModule.createdBillings.find(
        billing => billing.billedBeingId === (this.selectedBilledBeing?.id || this.selectedBilledBeing?._id)
      ) || null
    );
  }

  get partnerIdsFromDocuments() {
    const map: Set<string> = new Set();
    this.selectedSelectedBilledDocuments.forEach(bd => map.add(bd.partnerId));
    return [...map.keys()];
  }

  get billingSelectionAlert() {
    // more than 0 documents must be selected
    if (this.selectedSelectedBilledDocuments.length === 0) {
      return this.i18n["noSelection"];
    }

    if ([BillingTypeEnum.CREDIT_CHARGING_STATION, BillingTypeEnum.CREDIT].includes(this.billingType)) {
      // check charging station
      const partnerIds = this.partnerIdsFromDocuments;
      if (partnerIds.length > 1) {
        return this.i18n["onlyOnePartner"];
      }
    }

    return "";
  }

  /**
   * Gets partner to send mail from
   */
  get fromPartner() {
    return this.partners.find(p => p._id === this.partnerIdsFromDocuments[0]);
  }

  /**
   * Remove document from local billing list when it is rejected
   * @param thgId
   */
  reject(thgId: string) {
    const index = this.selectedBilledDocuments.findIndex(d => d.id === thgId);
    if (index > -1) {
      this.selectedBilledDocuments.splice(index, 1);
    }
  }

  @Watch("selectedSelectedBilledDocuments")
  setCommissions() {
    if (this.billingType === BillingTypeEnum.CREDIT_PARTNER) {
      const commissions: ThgPartnerCommissionPerYearDtoGen[] = [];
      const years = [...new Set(this.selectedSelectedBilledDocuments.map(d => d.year))];
      for (const year of years.sort((a, b) => a - b)) {
        let commission = this.commissions.find(c => c.year === year);
        if (!commission) {
          commission = { year, partnerCommission: 0 };
        }
        commissions.push(commission);
      }
      this.commissions.splice(0, this.commissions.length, ...commissions);
    }
  }

  /**
   * Loads userdata or comapnydata and data on billed documents and whatever data has to be loaded additionally. Just call this whenever you open a new window and need some fresh air or data
   */
  async loadEverything() {
    this.isLoading = true;
    await this.loadDataForBilledBeing();
    await this.getSelectedBilledDocuments();
    await this.loadAdditionalData();
    this.isLoading = false;
  }

  calculatePrice() {
    if (this.billingType !== BillingTypeEnum.CREDIT_CHARGING_STATION) {
      return;
    }
    if (!this.billedBeings) {
      return;
    }

    for (const billdedBeing of this.selectedBilledDocuments) {
      if (billdedBeing.payoutConfiguration.revenue) {
        this.pricePerKwHStore = +billdedBeing.payoutConfiguration.revenue / 2000;
        return;
      }
    }
  }

  /**
   * When component mounts data for the first selected user or partner that should be billed are loaded
   *
   * In case we bill a credit also all batches are loaded, so that it is easier to check if a ghg is in a batch or not (@see getBatchForGhg)
   */
  async mounted() {
    await this.loadEverything();
    this.calculatePrice();
    this.setCommissions();
  }

  /**
   * Goes to billing data for the next user/ partner that should be billed
   */
  async goToNextWindow() {
    this.selectedWindow++;
    this.banking = null;
    await this.loadEverything();
  }

  /**
   * Goes to billing data for a previous user/ partner that should be billed
   */
  async goToPreviousWindow() {
    this.selectedWindow--;
    this.banking = null;
    await this.loadEverything();
  }

  /**
   * Loads up banking for a user
   */
  async loadUserBanking(userId: string) {
    return BankingModule.getBanking({ userId });
  }

  /**
   * Loads up banking for a partner
   */
  async loadPartnerBanking(partnerId: string) {
    return BankingModule.getBanking({ partnerId });
  }

  /**
   * Load billing information for a partner. Billing information contains stuff like address and contact details and company info (taxnr,...)
   */
  async loadPartnerBillingInformation(partnerId: string) {
    return BillingInformationModule.getPartnerBillingInformation(partnerId);
  }

  /**
   * Loads all the data like address and banking for a user or a partner depending on the type of billing created
   */
  async loadDataForBilledBeing() {
    try {
      this.banking = null;
      this.loadingDataForBilledBeing = true;
      const id = this.selectedBilledBeing?._id || this.selectedBilledBeing?.id;

      if (this.getIsUserFocusedBillingType(this.billingType) && id) {
        this.banking = (await this.loadUserBanking(id)) || null;
      }

      if (this.getIsPartnerFocusedBillingType(this.billingType) && id) {
        this.banking = (await this.loadPartnerBanking(id)) || null;
        const partnerBillingInformation = await this.loadPartnerBillingInformation(id);
        const partnerId = partnerBillingInformation?.partnerId || id;
        this.partnerBillingInformation = { ...(partnerBillingInformation || {}), partnerId };
      }
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loadingDataForBilledBeing = false;
    }
  }

  /**
   * Loads additional data like for example affilliate codes in case of a affiliate billing
   */
  async loadAdditionalData() {
    if (this.getIsAffiliateBillingType(this.billingType)) {
      const codes: string[] = [];
      this.selectedBilledDocuments.forEach(bd => {
        if (bd.code) {
          codes.push(bd.code);
        }
      });

      const affiliates = await AffiliatePortalModule.getManyByCode(codes);
      this.affiliateCodes.splice(0, this.affiliateCodes.length, ...affiliates);
    }

    if (this.billingType === BillingTypeEnum.CREDIT_CHARGING_STATION) {
      const chargingStationIdentifiers: Set<string> = new Set();
      (this.selectedBilledDocuments as ThgThgMeterReadingViewModelGen[]).forEach(bd => {
        if (bd.meterReading.chargingStationId) {
          chargingStationIdentifiers.add(bd.meterReading.chargingStationId);
        } else {
          this.$log.warn("No charging station id found");
        }
      });
      const chasta = await ChargingStationAdminModule.getManyChargingStations([...chargingStationIdentifiers.keys()]);
      this.chargingStations.splice(0, this.chargingStations.length, ...chasta);
    }

    if ([BillingTypeEnum.CREDIT, BillingTypeEnum.CREDIT_PARTNER].includes(this.billingType)) {
      const batches = await BatchModule.findAll();
      this.batches.splice(0, this.batches.length, ...batches.data);
    }
  }

  /**
   * Creates a billing without skipping the check if a document was already billed
   */
  async nonForceCreateBilling() {
    await this.createBilling(false);
  }

  /**
   * Creates a billing even if one of the documents was already billed
   */
  async forceCreateBilling() {
    await this.createBilling(true);
    this.isForcedDialogActive = false;
  }

  /**
   * Creates a new billing
   *
   * @param isForced determines if the billing is created even when one of the documents was already billed
   */
  async createBilling(isForced = false) {
    if (!this.assertBillingCanBeSent() || !this.billingType) {
      return;
    }
    try {
      this.loadingCreation = true;
      const createBillingDto = {
        billingType: this.billingType,
        partnerCommissions: this.commissions.map(c => {
          return { partnerCommission: Number(c.partnerCommission), year: Number(c.year) };
        }),
        createPdf: true,
        force: isForced,
        createAccountingRecord: true,
        ids: this.selectedSelectedBilledDocuments.map(id => id.id),
        pricePerKwh: Number(this.pricePerKwH)
      };
      if (this.selectedSelectedBilledDocuments.length > 50) {
        await this.createBillingAsync(createBillingDto);
      } else {
        await this.createBillingSync(createBillingDto, isForced);
      }
    } catch (e) {
      handleError(e);
      if (e instanceof ConflictException) {
        this.isForcedDialogActive = true;
      }
    } finally {
      this.loadingCreation = false;
    }
  }

  /**
   * creates a billing document using a background task
   * @param dto
   */
  async createBillingAsync(dto: ThgBillingBatchCreateDtoGen) {
    const operationId = await BillingBatchModule.createBillingAsync(dto);

    OperationModule.dispatchToast({
      operationId,
      onClick: () => {
        new GoToHelper(this.$router).goToOperationDetail(operationId);
      }
    });
  }

  /**
   * creates a billing document "synchronously" without background task
   * @param dto
   * @param isForced
   */
  async createBillingSync(dto: ThgBillingBatchCreateDtoGen, isForced: boolean) {
    const billing = await BillingBatchModule.createBilling({
      query: { force: isForced },
      data: dto
    });

    if (billing && (this.selectedBilledBeing?._id || this.selectedBilledBeing?.id)) {
      BillingBatchModule.addBillingToCreatedBillings({
        id: this.selectedBilledBeing._id || this.selectedBilledBeing.id || "",
        billing: billing
      });
    }
  }

  /**
   * Go to the page where the user picks what to bill
   */
  goToBillingSelection() {
    this.$router.push({ name: "ThgBillingBatchSelectionType", params: { billingType: this.billingType } });
  }

  /**
   * Makes sure that all data is saved and no data is missing
   */
  assertBillingCanBeSent(): boolean {
    if (this.selectedSelectedBilledDocuments.length === 0) {
      this.$toast.error(this.$t("views.thgPortal.thgBillingSelectionItem.noDocumentsSelected"));
      return false;
    }

    const alerts = document.getElementsByClassName("billingAlert");
    if (alerts.length !== 0) {
      this.$toast.error(this.$t("views.thgPortal.thgBillingSelectionItem.billingAlert"));
      return false;
    }

    const unsavedData = document.getElementsByClassName("unsavedData");
    if (unsavedData.length !== 0) {
      this.$toast.error(this.$t("views.thgPortal.thgBillingSelectionItem.unsavedData"));
      return false;
    }
    return true;
  }
}
