






















































































































































































































































































































































import TemplateDialog from "@/components/template/TemplateDialog.vue";
import ThgBillingSelectionChargingStationData from "@/components/thg/ThgBillingSelectionChargingStationData.vue";
import ThgBillingSelectionItemAffiliateData from "@/components/thg/ThgBillingSelectionItemAffiliateData.vue";
import ThgBillingSelectionItemBankingData from "@/components/thg/ThgBillingSelectionItemBankingData.vue";
import ThgBillingSelectionItemBatchData from "@/components/thg/ThgBillingSelectionItemBatchData.vue";
import ThgBillingSelectionItemMeterReadingData from "@/components/thg/ThgBillingSelectionItemMeterReadingData.vue";
import ThgBillingSelectionItemPartnerAddressData from "@/components/thg/ThgBillingSelectionItemPartnerAddressData.vue";
import ThgBillingSelectionItemPartnerCompanyData from "@/components/thg/ThgBillingSelectionItemPartnerCompanyData.vue";
import ThgBillingSelectionItemPartnerContactData from "@/components/thg/ThgBillingSelectionItemPartnerContactData.vue";
import ThgBillingSelectionItemPartnerData from "@/components/thg/ThgBillingSelectionItemPartnerData.vue";
import ThgBillingSelectionItemPartnerIdentityData from "@/components/thg/ThgBillingSelectionItemPartnerIdentityData.vue";
import ThgBillingSelectionItemPayoutData from "@/components/thg/ThgBillingSelectionItemPayoutData.vue";
import ThgBillingSelectionItemRegistrationData from "@/components/thg/ThgBillingSelectionItemRegistrationData.vue";
import ThgBillingSelectionItemSelectPartnerData from "@/components/thg/ThgBillingSelectionItemSelectPartnerData.vue";
import ThgBillingSelectionItemThgListData from "@/components/thg/ThgBillingSelectionItemThgListData.vue";
import ThgBillingSelectionItemUserAddressData from "@/components/thg/ThgBillingSelectionItemUserAddressData.vue";
import ThgBillingSelectionItemUserCompanyData from "@/components/thg/ThgBillingSelectionItemUserCompanyData.vue";
import ThgBillingSelectionItemUserContactData from "@/components/thg/ThgBillingSelectionItemUserContactData.vue";
import ThgBillingSelectionItemUserIdentityData from "@/components/thg/ThgBillingSelectionItemUserIdentityData.vue";
import ThgBillingSelectionThgBaseData from "@/components/thg/ThgBillingSelectionThgBaseData.vue";
import Card from "@/components/utility/Card.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { GoToHelper } from "@/lib/utility/goToHelper";
import ThgBillingBatchMixin from "@/mixins/ThgBillingBatchMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import {
  ThgAffiliateViewmodelGen,
  ThgBankingDtoGen,
  ThgBatchViewmodelItemGen,
  ThgBillingInformationViewmodelGen,
  ThgChargingStationViewModelGen,
  ThgPartnerCommissionPerYearDtoGen,
  ThgThgMeterReadingViewModelGen,
  ThgBaseChartItemViewmodelGen
} from "@/services/thg/v1/data-contracts";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop } from "vue-property-decorator";
import Tooltip from "../utility/tooltip.vue";
import ThgBillingBatchNumberData from "./ThgBillingBatchNumberData.vue";
import BarChartCard from "../analytics/BarChartCard.vue";
import { IThg } from "@/models/thg.entity";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    LayoutSimple,
    Card,
    TableWrapper,
    ConfirmActionDialog,
    TemplateDialog,
    Tooltip,
    ThgBillingSelectionItemPartnerData,
    ThgBillingSelectionItemSelectPartnerData,
    ThgBillingSelectionItemBankingData,
    ThgBillingSelectionItemUserContactData,
    ThgBillingSelectionItemUserAddressData,
    ThgBillingSelectionItemUserIdentityData,
    ThgBillingSelectionItemUserCompanyData,
    ThgBillingSelectionItemPartnerIdentityData,
    ThgBillingSelectionItemPartnerContactData,
    ThgBillingSelectionItemPartnerAddressData,
    ThgBillingSelectionItemPartnerCompanyData,
    ThgBillingSelectionItemBatchData,
    ThgBillingSelectionItemPayoutData,
    ThgBillingSelectionItemRegistrationData,
    ThgBillingSelectionItemAffiliateData,
    ThgBillingSelectionItemThgListData,
    ThgBillingSelectionItemMeterReadingData,
    ThgBillingSelectionThgBaseData,
    ThgBillingSelectionChargingStationData,
    ThgBillingBatchNumberData,
    BarChartCard
  }
})
/**
 * A view where a user can review the data of a billed user and billed documents and generate the billing while validating its correctness
 */
export default class ThgBillingDetailDataCard extends ThgBillingBatchMixin {
  @Prop()
  billingType!: BillingTypeEnum;

  @Prop()
  noSelect?: boolean;

  /**
   * the user or partner or anyone who is billed
   */
  @Prop()
  selectedBilledBeing!: PartnerEntity | IAdminUser;

  /**
   * all documents that are billed
   */
  @Prop()
  billedDocuments!: (PartnerEntity | IAdminUser)[];

  /**
   * the documents that belong to the billed entity
   */
  @Prop()
  selectedBilledDocuments!: (IThg | ThgThgMeterReadingViewModelGen)[];

  /**
   * the documents that are selected for billing
   */
  @Prop()
  selectedSelectedBilledDocuments!: (IThg | ThgThgMeterReadingViewModelGen)[];

  /**
   * Banking data of user that is billed
   */
  @Prop()
  banking!: ThgBankingDtoGen;

  /**
   * Banking data of partner that is billed in case of CREDIT_CUSTOMER_ACCOUNT
   */
  @Prop()
  partnerBankings!: Map<string, ThgBankingDtoGen | null>;

  @Prop()
  partnerBillingInformation!:
    | ThgBillingInformationViewmodelGen
    | {
        partnerId: string;
      };

  @Prop()
  chargingStations!: ThgChargingStationViewModelGen[];

  /**
   * The affiliate codes that are related to the billed documents in case of a affiliate billing
   */
  @Prop()
  affiliateCodes!: ThgAffiliateViewmodelGen[];

  @Prop()
  batches!: ThgBatchViewmodelItemGen[];

  @Prop()
  selectedPartners!: PartnerEntity[];

  /**
   * The _commission of a partner
   */
  @Prop()
  commissions: ThgPartnerCommissionPerYearDtoGen[] = [];
  get partnerCommissions(): ThgPartnerCommissionPerYearDtoGen[] {
    return this.commissions;
  }
  set partnerCommissions(commissions: ThgPartnerCommissionPerYearDtoGen[]) {
    this.$emit("update:commissions", commissions);
  }

  /**
   * The price per kwh
   */
  @Prop()
  pricePerKwH = 0;

  get _pricePerKwH() {
    return this.pricePerKwH;
  }
  set _pricePerKwH(pricePerKwH: number) {
    this.$emit("update:pricePerKwH", pricePerKwH);
  }

  /**
   * Items in thg list that are selected
   */
  get _selectedSelectedBilledDocuments() {
    return this.selectedSelectedBilledDocuments;
  }
  /**
   * Limit amount of documents that can be billed
   */
  set _selectedSelectedBilledDocuments(selected: (IThg | ThgThgMeterReadingViewModelGen)[]) {
    this.$emit("update:selectedSelectedBilledDocuments", selected);
  }

  /**
   * returns the set of partner ids that are in the billed documents
   */
  get partnerIdsFromSelectedBilledDocuments() {
    const partnerIds = new Set<string>();
    this._selectedSelectedBilledDocuments.forEach(bd => partnerIds.add(bd.partnerId));
    return partnerIds;
  }

  get selectedBilledDocumentsPayoutDistribution() {
    const map: Map<number, { count: number; revenue: number }> = new Map();

    for (const document of this.selectedBilledDocuments) {
      if (!map.has(document.payoutConfiguration.revenue)) {
        const perYear = map.get(document.year);

        const newCount = (perYear?.count ?? 0) + 1;
        const newRevenue = (perYear?.revenue ?? 0) + document.payoutConfiguration.revenue;

        map.set(document.year, {
          count: newCount,
          revenue: newRevenue
        });
      }
    }

    const results: ThgBaseChartItemViewmodelGen[] = [];
    for (const [year, data] of map.entries()) {
      results.push({
        name: year.toString(),
        value: Math.round((data.revenue / data.count) * 100) / 100
      });
    }

    return results;
  }

  /**
   * Items in affiliate list that are selected. Not used.
   */
  selectedAffiliateCodes: ThgAffiliateViewmodelGen[] = [];

  /**
   * A flag determining if the ghg edit dialog is active
   */
  isEditGhgDialogActive = false;

  /**
   * A flag determining if the dialog to edit affiliate information is active
   */
  isAffiliateDialogActive = false;

  /**
   * The ghg that is edited in the edit ghg dialog
   */
  ghgToEdit: IThg | ThgThgMeterReadingViewModelGen | null = null;

  /**
   * The affilate that is edited in the edit affiliate dialog
   */
  affiliateToEdit: ThgAffiliateViewmodelGen | null = null;

  /**
   * The batch data of a billing that is used in context of a ghg billing
   */
  batchData: ThgBatchViewmodelItemGen | null = null;

  chargingStation: ThgChargingStationViewModelGen | undefined;

  /**
   * titles, texts and translations. Whatever the modern connoisseur of fine billings may desire
   */
  get i18n() {
    return this.$t("views.ThgBillingBatchSelectionItemView") || {};
  }

  /**
   * Unselect the selected billed documents
   */
  unselect() {
    this._selectedSelectedBilledDocuments.splice(0, this._selectedSelectedBilledDocuments.length);
  }

  get billingSelectionAlert() {
    // more than 0 documents must be selected
    if (this._selectedSelectedBilledDocuments.length === 0) {
      return this.i18n["noSelection"];
    }

    if ([BillingTypeEnum.CREDIT_CHARGING_STATION, BillingTypeEnum.CREDIT].includes(this.billingType)) {
      // check charging station
      const partnerIds = this.partnerIdsFromDocuments;
      if (partnerIds.length > 1) {
        return this.i18n["onlyOnePartner"];
      }
    }

    return "";
  }

  // TABLE STUFF
  /**
   * The table headers in thg ghg table
   */
  get ghgTableHeaders(): ITableWrapperHeader[] {
    const headers: ITableWrapperHeader[] = [{ text: "id", value: "id", sortable: false }];
    if (
      [
        BillingTypeEnum.CREDIT,
        BillingTypeEnum.CREDIT_PARTNER,
        BillingTypeEnum.AFFILIATE,
        BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT
      ].includes(this.billingType)
    ) {
      headers.push({ text: "numberplate", value: "numberplate", sortable: false });

      if ([BillingTypeEnum.CREDIT, BillingTypeEnum.CREDIT_PARTNER].includes(this.billingType)) {
        headers.push(
          { text: this.i18n["payout"], value: "payoutConfiguration", sortable: false },
          { text: this.i18n["vin"], value: "vin", sortable: false },
          { text: this.i18n["vehicleClass"], value: "vehicleClass", sortable: false },
          { text: this.i18n["hasBatch"], value: "hasBatch", sortable: false },
          { text: this.i18n["batchPrice"], value: "batchPrice", sortable: false }
        );
      }

      if (this.billingType === BillingTypeEnum.AFFILIATE) {
        headers.push({ text: this.i18n["code"], value: "code", sortable: true });
      }

      if (this.billingType === BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT) {
        headers.push({ text: this.i18n["impactFactor"], value: "impactFactor", sortable: true });
      }

      headers.push({ text: this.i18n["year"], value: "year", sortable: false });
    }

    if (this.billingType === BillingTypeEnum.CREDIT_CHARGING_STATION) {
      headers.push(
        { text: this.i18n["countryCode"], value: "countryCode", sortable: true, type: "string" },
        { text: this.i18n["startDate"], value: "meterReading.startDate", sortable: true, type: "date" },
        { text: this.i18n["endDate"], value: "meterReading.endDate", sortable: true, type: "date" },
        { text: this.i18n["amountInKwh"], value: "meterReading.amountInKwh", sortable: true, type: "number" },
        {
          text: this.i18n["isFixed"],
          value: "payoutConfiguration.isFixed",
          sortable: true,
          type: "boolean"
        },
        {
          text: this.i18n["revenue"],
          value: "payoutConfiguration.revenue",
          sortable: true,
          type: "number"
        },
        {
          text: this.i18n["chargingStationId"],
          value: "meterReading.chargingStationId",
          sortable: true,
          type: "number"
        }
      );
    }
    if (
      [
        BillingTypeEnum.CREDIT_CHARGING_STATION,
        BillingTypeEnum.CREDIT,
        BillingTypeEnum.AFFILIATE,
        BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT
      ].includes(this.billingType)
    ) {
      headers.push({
        text: this.i18n["partner"],
        value: "partnerId",
        sortable: true,
        type: "string"
      });
    }
    headers.push({ text: this.i18n["created"], value: "timestamp.created", type: "date" });
    if (!this.noSelect) {
      headers.push({ text: "", value: "controls", align: "end", sortable: false });
    }

    return headers;
  }

  /**
   * Affiliate code table headers
   */
  get affiliateCodeTableHeaders() {
    const headers: ITableWrapperHeader[] = [
      { text: this.i18n["code"], value: "code", sortable: true },
      { text: this.i18n["type"], value: "type", sortable: true },
      { text: this.i18n["note"], value: "note", sortable: true },
      { text: this.i18n["provision"], value: "provision", sortable: true },
      { text: this.i18n["amountOfThgs"], value: "amountOfThgs", sortable: true },
      { text: "", value: "controls", align: "end", sortable: false }
    ];

    return headers;
  }

  /**
   * Affiliate code control elements
   */
  get affiliateCodeControlElements() {
    return [
      {
        icon: "mdi-pen",
        action: this.openAffiliateDetailsDialog,
        text: this.i18n["edit"]
      },
      {
        icon: "mdi-company",
        action: this.goToPartnerDetail,
        text: this.i18n["goPartner"]
      }
    ];
  }

  /**
   * Control elements in thg ghg table
   */
  get ghgControlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [
      {
        icon: "mdi-pen",
        action: this.openEditGhgDialog,
        text: this.i18n["edit"]
      },
      {
        icon: "mdi-face-recognition",
        action: this.goToUserDetail,
        text: this.i18n["goUser"]
      }
    ];

    if (this.billingType === BillingTypeEnum.CREDIT_CHARGING_STATION) {
      controlElements.push({
        icon: "mdi-ev-station",
        action: this.goToGhgDetail,
        text: this.i18n["goGhg"]
      });
    } else {
      controlElements.push({
        icon: "mdi-car",
        action: this.goToGhgDetail,
        text: this.i18n["goGhg"]
      });
    }

    return controlElements;
  }

  get partnerIdsFromDocuments() {
    const map: Set<string> = new Set();
    this._selectedSelectedBilledDocuments.forEach(bd => map.add(bd.partnerId));
    return [...map.keys()];
  }

  // Filter functions
  /**
   * returns the batch that is related to the currently selected ghg. the information is used in the table to indicate if a ghg has a batch or if data is missing as well as in the edit ghg dialog
   */
  getBatchForGhg(ghgId: string) {
    return this.batches.find(b => {
      if (b.itemIds.includes(ghgId)) {
        return true;
      }
    });
  }

  getNumberPlate(item: IThg) {
    return item.numberplate || item.registration?.numberplate;
  }

  getBatchName(ghgId: string) {
    return this.getBatchForGhg(ghgId)?.name;
  }

  /**
   * Looks up those thgs that have the affiliate code that is billed
   *
   * @param affiliateCode
   */
  getThgsForAffiliate(affiliateCode: string) {
    return this.selectedBilledDocuments.filter(bd => bd.code === affiliateCode);
  }

  getIsGhgSelected(id: string) {
    return this._selectedSelectedBilledDocuments.findIndex(d => d.id === id) >= 0;
  }

  // DIALOG
  /**
   * Prepares state to edit data of a ghg. e.g. loads batch, sets selected ghg
   */
  openEditGhgDialog(ghg: IThg | ThgThgMeterReadingViewModelGen) {
    ThgPortalModule.setSelectedThg(ghg as IThg);

    const chargingStationId = (ghg as ThgThgMeterReadingViewModelGen).meterReading?.chargingStationId;
    if (chargingStationId) {
      this.chargingStation = this.chargingStations.find(cs => cs.id === chargingStationId);
    }

    this.ghgToEdit = ghg;
    this.batchData = this.getBatchForGhg(ghg.id) || null;
    this.isEditGhgDialogActive = true;
  }

  /**
   * Opens the dialog to edit an affiliate
   */
  openAffiliateDetailsDialog(affiliate: ThgAffiliateViewmodelGen) {
    this.isAffiliateDialogActive = true;
    this.affiliateToEdit = affiliate;
  }

  // GOTOS
  /**
   * Go to user detail in a new tab
   */
  goToUserDetail(ghg: IThg) {
    new GoToHelper(this.$router).goToUserDetail(ghg.userId, true);
  }

  /**
   * Opens the Detail view of a GHG document in a new Tab
   */
  goToGhgDetail(ghg: IThg) {
    new GoToHelper(this.$router).goToThgDetail(ghg.partnerId, ghg.id, true);
  }

  /**
   * Opens the partner detail page in an new tab
   */
  goToPartnerDetail(affiliate: ThgAffiliateViewmodelGen) {
    new GoToHelper(this.$router).goToPartnerDetail(affiliate.partnerId, true);
  }
  // ------
}
