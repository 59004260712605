

















































import FilterCard from "@/components/filter/FilterCard.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { CancelToken } from "@/lib/utility/cancelToken";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ChargingStationPaginatedModule } from "@/store/modules/charging-station-paginated.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({ components: { TheLayoutPortalList, FilterCard } })
export default class ThgChargingStationPaginatedListCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  isLoadingMore = false;

  isLoadingDetail = false;

  dateFilter = getDateFilterList("timestamp");

  /**
   * The available categories visible in the filter menu
   */
  get filterList(): IFilterListElement[] {
    return [...this.dateFilter];
  }

  get items() {
    return this.getModule().paginationList;
  }

  getModule() {
    return ChargingStationPaginatedModule;
  }

  async set(item: ThgChargingStationViewModelGen) {
    this.setIsLoadingDetail(true);
    if (["xs", "sm"].includes(this.$vuetify.breakpoint?.name)) {
      this.$router.push({ name: "ThgChargingStationDetailView", params: { chargingStationId: item.id } });
    } else {
      this.$log.debug(item);

      const thg = await ThgPortalModule.getSelectedChargingStation(item.id);
      if (!thg) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      const meterReadings = await ThgPortalModule.getMeterReadingForChargingStation(item.id);
      if (!meterReadings) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      const user = await AdminUserPaginationModule.getSelectedUser(item.userId);
      if (!user) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noUserFound")));
      }
    }
    this.setIsLoadingDetail(false);
  }

  async loadAllFromLastId() {
    if (this.items.length === 0) {
      await ChargingStationPaginatedModule.fetchAllFromBeginning({});
    } else {
      ChargingStationPaginatedModule.cancelToken?.requestCancellation();
      const cancelToken = new CancelToken();
      ChargingStationPaginatedModule.setCancelToken(cancelToken);
      await ChargingStationPaginatedModule.fetchRest({
        query: {},
        cancelToken: cancelToken
      });
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.loadAllFromLastId();
    } catch (e) {
      this.$toast.error((e as any).message);
    } finally {
      this.isLoadingMore = false;
    }
  }

  async refresh() {
    this.isLoadingMore = true;
    ChargingStationPaginatedModule.emptyList();
    await ChargingStationPaginatedModule.fetchAllFromBeginning({});
    this.isLoadingMore = false;
  }

  setIsLoadingDetail(status: boolean) {
    this.isLoadingDetail = status;
    this.$emit("isLoadingDetail", status);
  }

  isActive(item: ThgChargingStationViewModelGen) {
    return item?.id === ThgPortalModule.selectedChargingStation?.id;
  }

  date(item: any): string {
    return simpleDate(item?.timestamp?.created);
  }
}
