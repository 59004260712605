/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ReportImageType } from "@/models/Report/ReportImageType";
import { IThg } from "@/models/thg.entity";

/**
 * Checks if Cropping is Complete
 */
export class ThgCroppingIsCompleteCheck {
  thgs: IThg[] = [];
  constructor(thgs: IThg[]) {
    this.thgs = thgs;
  }

  get count() {
    let noFront = 0;
    let noImage = 0;
    let noCrop = 0;
    this.thgs.forEach(t => {
      if (!this.hasRegistrationFront(t)) {
        noFront++;
      }
      if (this.getHasNoImages(t)) {
        noImage++;
      }
      if (!this.getHasImageEdits(t)) {
        noCrop++;
      }
    });

    return { noFront, noImage, noCrop };
  }
  getHasAllImageEdits(thg: IThg) {
    let hasAllEdits = true;
    thg.registrationImages.forEach(e => {
      if (hasAllEdits) {
        const hasEdits =
          e.editData?.crop?.height !== undefined &&
          e.editData?.crop?.width !== undefined &&
          e.editData?.crop?.x !== undefined &&
          e.editData?.crop?.y !== undefined;

        hasAllEdits = hasEdits;
      }
    });
    return hasAllEdits;
  }
  getHasImageEdits(thg: IThg) {
    let hasAllEdits = false;
    thg.registrationImages.forEach(e => {
      if (!hasAllEdits) {
        const hasEdits =
          e.editData?.crop?.height !== undefined &&
          e.editData?.crop?.width !== undefined &&
          e.editData?.crop?.x !== undefined &&
          e.editData?.crop?.y !== undefined;

        hasAllEdits = hasEdits;
      }
    });
    return hasAllEdits;
  }
  getHasNoImages(thg: IThg) {
    return thg.registrationImages.length === 0;
  }
  hasRegistrationFront(thg: IThg) {
    for (const registrationImage of thg.registrationImages) {
      if ((registrationImage.type as any) === ReportImageType.registration) {
        return true;
      }
    }
    return false;
  }
}
