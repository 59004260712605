

















































































































































































































































import { NetworkError } from "@/lib/exceptions/http/NetworkError";
import { emailRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { CreateAnonymousThgQuote } from "@/store/models/thg/create-thg-quote";
import { PartnerModule } from "@/store/modules/partner";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component } from "vue-property-decorator";
import PartnerSelectionForm from "./PartnerSelectionForm.vue";
import ThgFleetAssistantDialog from "./ThgFleetAssistantDialog.vue";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    PartnerSelectionForm
  }
})
export default class ThgFleetAdminAssistantDialog extends ThgFleetAssistantDialog {
  isValidAdmin = false;

  /**
   * The partner id that should be selected
   */
  partnerId?: string = "";

  /**
   * Check if partner is selected
   */
  isPartnerSet = false;

  /**
   *
   */
  userName = "";

  /**
   *
   */
  firstName = "";

  /**
   *
   */
  lastName = "";

  /**
   * Notify user
   */
  userNotification = true;

  /**
   * New create THG Quote object
   */
  thgQuote = new CreateAnonymousThgQuote();

  get emailRule() {
    return [emailRule(), requiredRule()];
  }

  get rightDisabled() {
    if (this.page === 0) {
      return !(this.partnerId && this.userName && this.firstName && this.lastName);
    }

    if (this.page === 1) {
      return this.availableProducts.length < 1;
    }

    return false;
  }

  get partnerName() {
    if (!this.partnerId) {
      return "";
    }

    return PartnerModule.partnersMap.get(this.partnerId)?.companyUsername ?? this.partnerId;
  }

  async mounted() {
    await PartnerModule.getPartners();
  }

  async savePartner(partnerId: string) {
    this.$log.debug(this.partnerId);

    this.isLoadingPrices = true;

    const partner = PartnerModule.partnersMap.get(partnerId);

    if (!partner) {
      this.$toast.error("Partner not set");
      throw new Error("Partner not set");
    }

    ReportModule.setPartnerByName(partner);

    try {
      await PartnerActiveOfferModule.loadDocuments(partnerId);
      this.partnerId = partnerId;
      this.isPartnerSet = true;
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoadingPrices = false;
    }
  }

  changePartner() {
    this.isPartnerSet = false;
  }

  async create() {
    this.isLoading = true;

    if (!this.partnerId) {
      this.$toast.error("Partner not set");
      throw new Error("Partner not set");
    }

    try {
      const tempNumberplate = this.numberplate;

      this.thgQuote.createUserDto.userName = this.userName;
      this.thgQuote.createUserDto.firstName = this.firstName;
      this.thgQuote.createUserDto.lastName = this.lastName;
      this.thgQuote.isConfirmed = true;
      this.thgQuote.years = this.years;
      this.thgQuote.numberplate = this.numberplate;
      this.thgQuote.registrationImages = [this.frontImageId, this.backImageId];

      await ThgCreateModule.createAnonymous({
        partnerId: this.partnerId,
        userNotification: this.userNotification,
        thgQuote: this.thgQuote
      });

      this.$toast.success(
        `THG ${tempNumberplate} (${this.years.join(" & ")}) für ${this.userName} bei ${this.partnerName} beantragt 👍`
      );

      this.numberplate = "";
      this.focusNumberplate();
      this.numberplates.push(tempNumberplate);

      this.thgQuote = new CreateAnonymousThgQuote();
    } catch (error) {
      this.$log.error(error);

      if (error instanceof NetworkError) {
        this.$toast.error("Fehler mit der Verbindung. Bitte zu einem späteren Zeitpunkt erneut versuchen.");
      } else {
        this.$toast.error("Fehler beim Senden. Bitte Eingaben noch einmal prüfen.");
      }
    } finally {
      this.isLoading = false;
    }
  }
}
