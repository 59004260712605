











import ThgAdvertisemtDetailInformationCard from "@/components/thg/ThgAdvertisemtDetailInformationCard.vue";
import ThgAdvertismentListCardPaginated from "@/components/thg/ThgAdvertismentListCardPaginated.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import { AdvertisementPaginatedAdminModule } from "@/store/modules/advertisment-admin-paginated-store";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalSplit,
    ThgAdvertismentListCardPaginated,
    ThgAdvertisemtDetailInformationCard
  }
})
export default class ThgAdvertisment extends Vue {
  loadingSelected = false;
  loadingAll = false;

  get getLoadingSelected() {
    return this.loadingSelected;
  }

  setLoadingSelected(isLoading: any) {
    this.loadingSelected = isLoading;
  }

  async refresh() {
    this.loadingSelected = true;
    await this.loadAll();
    await this.loadSelected();
  }

  async loadAll() {
    this.loadingAll = true;

    try {
      await AdvertisementPaginatedAdminModule.fetchAllFromBeginning({});
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingAll = false;
    }

    try {
      if (AdvertisementPaginatedAdminModule.paginationList.length !== 0) {
        await AdvertisementAdminModule.getAdById({
          partnerId: AdvertisementPaginatedAdminModule.paginationList[0].partnerId,
          id: AdvertisementPaginatedAdminModule.paginationList[0].id
        });
      }
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingSelected = false;
    }
  }

  async loadSelected() {
    this.loadingSelected = true;
    try {
      if (AdvertisementPaginatedAdminModule.paginationList.length !== 0) {
        AdvertisementAdminModule.getAdById({
          partnerId: AdvertisementPaginatedAdminModule.paginationList[0].partnerId,
          id: AdvertisementPaginatedAdminModule.paginationList[0].id
        });
      }
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingSelected = false;
    }
  }
}
