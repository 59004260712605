var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_vm.showTextButton && _vm.isAdmin ? _c('v-btn', {
    class: "ml-".concat(_vm.marginLeft, " mr-").concat(_vm.marginRight),
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "text": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.checkDialog = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingPlausibilityCheckDialog.plausibilityCheck")) + " ")]) : _vm.isAdmin ? _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.checkDialog = true;
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-marker-check")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingPlausibilityCheckDialog.plausibilityCheck")) + " ")])]) : _vm._e(), _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.checkDialog,
      callback: function callback($$v) {
        _vm.checkDialog = $$v;
      },
      expression: "checkDialog"
    }
  }, [_c('card', {
    attrs: {
      "title": _vm.$t('components.thg.ThgBillingPlausibilityCheckDialog.plausibilityCheck'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c('v-card-actions', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingPlausibilityCheckDialog.text")) + " " + _vm._s(_vm.$t("components.thg.ThgBillingPlausibilityCheckDialog." + _vm.checkType)) + "? ")]), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "text": "",
            "outlined": ""
          },
          on: {
            "click": function click($event) {
              _vm.checkDialog = false;
            }
          }
        }, [_vm._v(" Abort ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": "",
            "outlined": "",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.executeCheck
          }
        }, [_vm._v(" Check ")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }