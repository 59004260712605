var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.partnerIds.length !== 1 ? _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionPartnerData.title'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-icon', {
          staticClass: "billingAlert icon",
          attrs: {
            "color": "red"
          }
        }, [_vm._v(" mdi-alert-box ")])];
      },
      proxy: true
    }], null, false, 4220774336)
  }, [_c('v-card-actions', [_vm.partnerIds.length === 0 ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("views.ThgBillingBatchSelectionItemView.noSelection")) + " ")]) : _c('p', [_vm._v(" " + _vm._s(_vm.$t("views.ThgBillingBatchSelectionItemView.onlyOnePartner")) + " ")])])], 1) : _c('thg-billing-selection-item-partner-data', {
    attrs: {
      "partnerId": _vm.partnerIds[0],
      "partner": _vm.partner
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }