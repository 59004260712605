
































import { Component, Vue } from "vue-property-decorator";

import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import EfahrerSideCard from "@/components/thg/efahrer/EfahrerSideCard.vue";
import Debug from "@/components/utility/Debug.vue";
import { EfahrerModule } from "@/store/modules/efahrer.store";
import { ThgEfahrerOrderPopulatedViewModelGen, ThgEfahrerOrderViewModelGen } from "@/services/thg/v1/data-contracts";
import { handleError } from "@/lib/utility/handleError";
import { EfahrerOrderStatusEnum } from "@/store/enum/thg/efahrer-order-status.enum";

@Component({
  components: {
    TheLayoutPortal,
    EfahrerSideCard,
    Debug
  }
})
export default class ThgEfahrer extends Vue {
  isLoading = false;

  status: EfahrerOrderStatusEnum = EfahrerOrderStatusEnum.CREATED;
  statusItems: string[] = Object.values(EfahrerOrderStatusEnum);

  selectedOrder: ThgEfahrerOrderPopulatedViewModelGen | null = null;

  get items() {
    return EfahrerModule.orders;
  }

  get headers() {
    return [
      { text: "Id", value: "id" },
      { text: "Status", value: "status" },
      { text: "Datum", value: "created_at", type: "date" },
      { text: "Email", value: "customer.email" },
      { text: "Vorname", value: "customer.firstName" },
      { text: "Nachname", value: "customer.lastName" }
    ];
  }

  async fetch() {
    this.isLoading = true;
    EfahrerModule.getOrders({ status: this.status }).finally(() => (this.isLoading = false));
  }

  async mounted() {
    await this.fetch();
  }

  async onOrderClick(item: ThgEfahrerOrderViewModelGen) {
    this.isLoading = true;

    try {
      await EfahrerModule.getOrder(item.id);

      this.selectedOrder = EfahrerModule.order;
    } catch (error) {
      handleError(error);
      this.selectedOrder = null;
    } finally {
      this.isLoading = false;
    }
  }

  closeSideCard() {
    this.selectedOrder = null;
  }
}
