var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('thg-advertisment-list-card-paginated', {
          on: {
            "isLoadingDetail": _vm.setLoadingSelected,
            "refresh": _vm.refresh
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('thg-advertisemt-detail-information-card', {
          attrs: {
            "loading": _vm.loadingSelected
          },
          on: {
            "reloadAll": _vm.refresh
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }