













import AffiliateDetailCard from "@/components/thg/AffiliateDetailCard.vue";
import AffiliateListCard from "@/components/thg/AffiliateListCard.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { PartnerModule } from "@/store/modules/partner";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    TheLayoutPortalSplit,
    AffiliateListCard,
    AffiliateDetailCard
  }
})
export default class Affiliate extends PartnerFallbackMixin {
  loadingSelected = false;

  selectedUser: IAdminUser | null = null;

  get loadingAll() {
    return AffiliatePortalModule.isLoading;
  }

  set loadingAll(loading: boolean) {
    AffiliatePortalModule.setIsLoading(loading);
  }

  get getloadingSelected() {
    return this.loadingSelected;
  }

  setloadingSelected(isLoading: any) {
    this.loadingSelected = isLoading;
  }

  async mounted() {
    this.loadingAll = true;
    this.loadingSelected = true;

    try {
      await this.trySetByRouteOrDefault();
      const affiliates = await AffiliatePortalModule.getAffiliatesByPartnerId(
        PartnerModule.partner.id || PartnerModule.partner._id
      );
      this.$log.debug(affiliates);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingAll = false;
    }

    try {
      if (AffiliatePortalModule.affiliates.length !== 0) {
        await AffiliatePortalModule.getSelectedAffiliate({
          affiliateId: AffiliatePortalModule.affiliates[0].id,
          partnerId: PartnerModule.partner.id || PartnerModule.partner._id
        });
        if (AffiliatePortalModule.affiliates[0].userId) {
          this.selectedUser = await AdminUserPaginationModule.getSelectedUser(
            AffiliatePortalModule.affiliates[0].userId
          );
        }
      }
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loadingSelected = false;
    }
  }
}
