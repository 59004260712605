





















































import FilterCard from "@/components/filter/FilterCard.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { simpleDate } from "@/lib/utility/date-helper";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import TheLayoutPortalList from "../../layouts/TheLayoutPortalList.vue";
import ThgFleetAdminAssistantDialog from "./ThgFleetAdminAssistantDialog.vue";

@Component({
  components: {
    FilterCard,
    TheLayoutPortalList,
    ThgFleetAdminAssistantDialog
  },
  filters: {
    getFlagEmoji,
    simpleDate
  }
})
export default class ThgListAdmin extends mixins(PaginationListMixin, DarkModeHighlightMixin, PermissionMixin) {
  getModule() {
    return ThgPaginationModule;
  }

  async refresh() {
    this.$emit("refresh");
  }

  isSelected(item: IThg) {
    return item.id === ThgPortalModule.selectedThg.id ? `grey ${this.color}` : "";
  }

  thgStatus(thgStatusEnum: any) {
    return (
      thgStatusMap.get(thgStatusEnum) || {
        text: "Alles im grünen Bereich.",
        icon: "mdi-help",
        portalText: thgStatusEnum
      }
    );
  }
}
