
















import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { IUser } from "@/models/user.entity";
import { HttpException } from "@/lib/exceptions/http";
import { requiredRule } from "@/lib/rules/requiredRule";

@Component({ components: { Debug } })
export default class ThgBillingCreditorSelectionUserList extends DarkModeHighlightMixin {
  @Prop()
  userId!: string;

  loadingAllUsers = true;

  inputValue = "";

  showList = false;

  get allUsers() {
    return AdminUserPaginationModule.paginationList;
  }
  get rules() {
    return [requiredRule()];
  }

  filter(user: IUser, searchString: string) {
    return (JSON.stringify(user) + `${user.firstName} ${user.lastName}`)
      .toLowerCase()
      .includes(searchString.toLowerCase());
  }

  async mounted() {
    this.loadingAllUsers = true;
    try {
      if (this.allUsers?.length === 0) {
        await AdminUserPaginationModule.getUsers();
      }
      // if (this.userId) {
      //   this._selected = this.allUsers.find(u => u._id.toString() === this.userId) || null;
      // }
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loadingAllUsers = false;
    }
  }

  get selected(): string {
    return this.userId;
  }
  set selected(selected: string) {
    this.$emit("update:userId", selected);
  }
}
