
































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: {}
})
export default class SimpleDeleteDialog extends DarkModeHighlightMixin {
  /**
   * The event emitted by the dialog on delete Action
   */
  private readonly EMIT_EVENT_DELETE = "remove";

  /**
   * The event emitted by the dialog on cancel Action
   */
  private readonly EMIT_EVENT_CACNEL = "cancel";

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: "components.dialog.DeleteDialog.title" })
  title!: string;

  @Prop({ default: "components.dialog.DeleteDialog.reason" })
  text!: string;

  @Prop({ required: false })
  itemToDelete?: any;

  dialog = false;

  /**
   * Emit that delete can be continued
   */
  emitDelete() {
    if (this.itemToDelete) {
      this.$emit(this.EMIT_EVENT_DELETE, this.itemToDelete);
    } else {
      this.$emit(this.EMIT_EVENT_DELETE, true);
    }
    this.close();
  }

  /**
   * Emit that delete is aborted
   */
  emitCancel() {
    this.$emit(this.EMIT_EVENT_CACNEL, true);
    this.close();
  }

  close() {
    this.dialog = false;
  }
}
