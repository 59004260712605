var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "title": _vm.$t('report.thgChargingStation.meterReading.title'),
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.isDialogActive = true;
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("report.ThgChargingStationDetailCard.toDetail")))])]), _c('confirm-action-dialog', {
          attrs: {
            "isDialogActive": _vm.isDialogActive
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event;
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event;
            },
            "leftClick": function leftClick($event) {
              _vm.isDialogActive = false;
            },
            "rightClick": _vm.save
          }
        }, [_c('v-form', {
          ref: "form2",
          model: {
            value: _vm.isValid,
            callback: function callback($$v) {
              _vm.isValid = $$v;
            },
            expression: "isValid"
          }
        }, [_c('v-text-field', {
          staticClass: "px-0",
          attrs: {
            "rules": [_vm.requiredRule],
            "label": _vm.$t('components.thg.ThgChargingStationMeterReadingTable.startDate'),
            "required": "",
            "outlined": "",
            "data-test-start-date": ""
          },
          model: {
            value: _vm.meterReading.meterReading.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.meterReading.meterReading, "startDate", $$v);
            },
            expression: "meterReading.meterReading.startDate"
          }
        }), _c('v-text-field', {
          staticClass: "px-0",
          attrs: {
            "rules": [_vm.requiredRule],
            "label": _vm.$t('components.thg.ThgChargingStationMeterReadingTable.endDate'),
            "required": "",
            "outlined": "",
            "data-test-end-date": ""
          },
          model: {
            value: _vm.meterReading.meterReading.endDate,
            callback: function callback($$v) {
              _vm.$set(_vm.meterReading.meterReading, "endDate", $$v);
            },
            expression: "meterReading.meterReading.endDate"
          }
        }), _c('v-text-field', {
          attrs: {
            "rules": [_vm.requiredRule],
            "label": _vm.$t('components.thg.ThgChargingStationMeterReadingTable.amountInKwh'),
            "outlined": "",
            "dense": "",
            "flat": "",
            "data-test-amount-in-kwh": ""
          },
          model: {
            value: _vm.meterReading.meterReading.amountInKwh,
            callback: function callback($$v) {
              _vm.$set(_vm.meterReading.meterReading, "amountInKwh", _vm._n($$v));
            },
            expression: "meterReading.meterReading.amountInKwh"
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('debug', {
    attrs: {
      "debug": _vm.meterReading
    }
  }), _c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-calendar")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.toDate(_vm.meterReading.meterReading.startDate)) + " - " + _vm._s(_vm.toDate(_vm.meterReading.meterReading.endDate)) + " ")])], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-battery-charging")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.meterReading.meterReading.amountInKwh) + " KW/h ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }