/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import {
  ThgPageViewModelGen,
  ThgAdminPurchasingPriceControllerFindAllAdminParamsGen,
  ThgPriceViewModelGen
} from "../v1/data-contracts";
import { AdminPriceService } from "../v1/AdminPriceService";

/**
 * Service to talk to the price endpoint.
 */
export class PriceAdminService {
  private readonly price: AdminPriceService;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.price = new AdminPriceService(clientProvider.client());
  }

  /**
   * Gets all prices across all partner
   * @returns
   */
  async findAllAdmin(
    query: ThgAdminPurchasingPriceControllerFindAllAdminParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgPriceViewModelGen[] | undefined }> {
    return (await this.price.adminPurchasingPriceControllerFindAllAdmin(query)).data;
  }

  /**
   * Finds all active prices for all partner
   *
   * @returns
   */
  async findAllActiveAdmin(): Promise<ThgPriceViewModelGen[]> {
    return (await this.price.adminPurchasingPriceControllerFindAllActiveAdmin()).data;
  }
}

export default new PriceAdminService(new ThgHttpClientProvider());
