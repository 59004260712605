
















































import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { detailedDate } from "@/lib/utility/date-helper";
import { UpdateAdvertisementAdminDto } from "@/models/Advertisment/update-advertisment";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { Component, Prop, Vue } from "vue-property-decorator";
import SimpleDeleteDialog from "../dialog/SimpleDeleteDialog.vue";
import CustomerContactCard from "../partner/CustomerContactCard.vue";
import ThgAdvertismentAdminInformationCard from "../thg/ThgAdvertismentAdminInformationCard.vue";
import ThgAdvertismentInformationCard from "../thg/ThgAdvertismentInformationCard.vue";
import ThgAdvertismentTargetGroupInformationCard from "../thg/ThgAdvertismentTargetGroupInformationCard.vue";
import Debug from "../utility/Debug.vue";
import LabelComponent from "../utility/LabelComponent.vue";

@Component({
  components: {
    Debug,
    CustomerContactCard,
    ThgAdvertismentInformationCard,
    LabelComponent,
    SimpleDeleteDialog,
    ThgAdvertismentAdminInformationCard,
    ThgAdvertismentTargetGroupInformationCard
  }
})
export default class ThgAdvertisemtDetailInformationCard extends Vue {
  @Prop()
  loading!: boolean;

  private readonly RELOAD_ALL = "reloadAll";
  labelsLoading = false;

  /**
   * Internal loading state
   */
  isActivateLoading = false;

  get selection() {
    return AdvertisementAdminModule.selected;
  }

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get isActive() {
    return this.selection.isActive;
  }

  get createdDate() {
    return this.date(this.selection);
  }

  date(item: any) {
    if (item.timestamp?.created) {
      const locale = this.$t("utility.toLocalDateString").toString() || "de-de";
      return detailedDate(item.timestamp.created, locale);
    }
    return "";
  }

  toDetailView() {
    this.$router.push({
      name: "ThgAdvertisementDetail",
      params: { advertisementId: this.selection.id }
    });
  }

  async activate() {
    this.isActivateLoading = true;
    try {
      const updatetAdDto = new UpdateAdvertisementAdminDto(this.selection);
      updatetAdDto.activate();
      await AdvertisementAdminModule.updateSelected(updatetAdDto);
      this.$toast.success("👍🏻");
    } catch (error) {
      this.$toast.error("👎🏻");
      this.$log.error(error);
    } finally {
      this.isActivateLoading = false;
    }
  }

  async deactivate() {
    this.isActivateLoading = true;
    try {
      const updatetAdDto = new UpdateAdvertisementAdminDto(this.selection);
      updatetAdDto.deactivate();
      await AdvertisementAdminModule.updateSelected(updatetAdDto);
      this.$toast.success("👍🏻");
    } catch (error) {
      this.$toast.error("👎🏻");
      this.$log.error(error);
    } finally {
      this.isActivateLoading = false;
    }
  }

  async updateTags(labels: string[]): Promise<void> {
    this.labelsLoading = true;
    try {
      const updatetAdDto = new UpdateAdvertisementAdminDto(this.selection);
      updatetAdDto.tags = labels;
      await AdvertisementAdminModule.updateSelected(updatetAdDto);
      this.$toast.success("Updated tag.");
    } catch (error) {
      this.$toast.error("Failed to update tag");
      this.$log.error(error);
    } finally {
      this.labelsLoading = false;
    }
  }

  async remove() {
    this.loading = true;
    try {
      await AdvertisementAdminModule.delete();
      this.emitReload();
      this.$toast.success("Ad Removed successfully.");
    } catch (error) {
      this.$toast.error("Failed to remove ad.");
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Emit that all list should be reloaded
   */
  emitReload() {
    this.$emit(this.RELOAD_ALL, true);
  }

  get dateOptions() {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric"
    };
    return options;
  }
}
