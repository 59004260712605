


















































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBatchDetailViewmodelGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { saveAs } from "file-saver";
import JsZip, { JSZipGeneratorOptions } from "jszip";
import { Component, Prop } from "vue-property-decorator";
import { ReportImageType } from "@/models/Report/ReportImageType";

@Component({
  components: {}
})
export default class ThgBatchDownloadDialog extends DarkModeHighlightMixin {
  @Prop()
  thgs!: IThg[];

  @Prop()
  batch!: ThgBatchDetailViewmodelGen;

  dialog = false;

  /**
   * If a download failes it is stored in failedDownloads to give the user feedback of it.
   */
  failedDownloads: { item: IThg; type: string; reason: any }[] = [];

  /**
   * Downloadoptions: Which file type should be downloaded?
   */
  fileType: "image" | "pdf" = "image";

  /**
   * Downloadoptions: How many files should be stored to a zip at maximum? If number of downloads is taller, multiple zips are generated. This otpion helps to reduce the risk of ram errors
   */
  batchSize = 1000;

  /**
   * Downloadoptions: Specifies wether the zip should be compressed or not
   */
  compression = false;

  /**
   * Flag to abort the download
   */
  stopDownload = false;

  /**
   * Flag to say that the download is started
   */
  isDownloadStarted = false;

  /**
   * Progress of the Download (progress 10 means that 10 images are downloaded)
   */
  progress = 0;

  /**
   * Specifies how many images have to be downloaded in total
   */
  numberOfDownloads = 0;

  /**
   * Is calculatet by progress / numberofDownloads * 100
   */
  relativeProgress = 0;

  /**
   * Flag to specify if the download is finished
   */
  isFinished = true;

  /**
   * Flag to specify if the download is finished
   */
  isCreatingZip = false;

  /**
   * Message to give the user more details about the download
   */
  message = "";

  /**
   * Headers for the table (error store)
   */
  get headers() {
    return [
      { text: "ID", value: "item.id" },
      { text: "Type", value: "type" },
      { text: "Ursache", value: "reason" }
    ];
  }

  /**
   * Set Default values
   */
  initialize() {
    this.progress = 0;
    this.numberOfDownloads = 0;
    this.relativeProgress = 0;
    this.message = "";
    this.isDownloadStarted = false;
    this.isFinished = false;
    this.failedDownloads = [];
  }

  /**
   * Trigger the download due to choosed filetype
   */
  async download() {
    this.stopDownload = false;
    if (this.fileType == "image") {
      return this.downloadImages();
    }

    if (this.fileType == "pdf") {
      this.$toast.error(`Filetype ${this.fileType} not implemented.`);
    }
  }

  /**
   * Stops the download on trigger
   */
  abortDownload() {
    this.stopDownload = true;
    this.initialize();
  }

  /**
   *
   * Returns the front registration image url
   *
   * @param thg
   */
  registrationImageFront(thg: IThg): string {
    const item = thg.registrationImages.filter(item => item.type === (ReportImageType.registration as any));
    if (item.length !== 0) {
      return item[0].url || "";
    }
    return "";
  }

  /**
   * Downloads registration Documents as images and zip them using this.saveZip
   */
  async downloadImages() {
    this.message = "Initialize Download";
    this.isDownloadStarted = true;
    this.isFinished = false;
    let images: { name: string; data: Blob }[] = [];
    let batchSize = 0;

    this.numberOfDownloads = this.thgs.length;
    for (const thg of this.thgs) {
      if (this.stopDownload == true) {
        break;
      }
      try {
        this.message = "Download image with thg id: " + thg.id;
        const foundGhg = await ThgPortalModule.getSelectedThgByPartnerID({ thgId: thg.id, partnerId: thg.partnerId });

        if (!foundGhg?.registrationImages.length) {
          this.failedDownloads.unshift({ item: thg, type: "warning", reason: "No registration image found" });
          this.updateRelativeProgress();
          continue;
        }

        const url = this.registrationImageFront(foundGhg);
        if (!url) {
          this.failedDownloads.unshift({
            item: foundGhg,
            type: "warning",
            reason: "No front registration image found"
          });
          this.updateRelativeProgress();
          continue;
        }

        let fileName = foundGhg.id + "." + foundGhg.registrationImages[0].name.split(".").pop();
        if (thg.registration.identificationnumber) {
          fileName = thg.registration.identificationnumber + "." + foundGhg.registrationImages[0].name.split(".").pop();
        }

        const foundFiles = images.filter(item => item.name === fileName);
        if (foundFiles.length) {
          const newFileName = "_" + foundGhg.id + "." + foundGhg.registrationImages[0].name.split(".").pop();
          this.failedDownloads.unshift({
            item: foundGhg,
            type: "error",
            reason: `Duplicate Filename ${fileName}. Renamed to ${newFileName}.`
          });
          fileName = newFileName;
        }

        const resp = await fetch(url).then(resp => resp);
        if (resp.status >= 300) {
          this.failedDownloads.unshift({
            item: foundGhg,
            type: "warning",
            reason: resp.status + ": " + resp.statusText
          });
          this.updateRelativeProgress();
          continue;
        }
        images.push({ name: fileName, data: await resp.blob() });
      } catch (error) {
        this.failedDownloads.unshift({ item: thg, type: "error", reason: error });
        this.updateRelativeProgress();

        continue;
      }

      batchSize = batchSize + 1;
      this.updateRelativeProgress();

      if (this.progress >= this.numberOfDownloads || batchSize >= this.batchSize) {
        await this.saveZip(images);
        images = [];
        batchSize = 0;
        this.$log.info("Saved zip.");
        this.isCreatingZip = false;
      }
    }
    this.isFinished = true;
    this.message = "Download finished.";
  }

  /**
   *
   * Creates a zip file
   *
   * @param files
   */
  async saveZip(files: { name: string; data: Blob }[]) {
    this.isCreatingZip = true;
    this.message = "Create Zip and save to device.";
    const zip = JsZip();

    for (const file of files) {
      zip.file(file.name, file.data);
    }
    const options: JSZipGeneratorOptions<"blob"> = {
      type: "blob"
    };

    if (this.compression) {
      options["compression"] = "DEFLATE";
      options["compressionOptions"] = { level: 9 };
    }

    const zipBlob = await zip.generateAsync(options);
    const currentDate = new Date().toISOString();
    const fileName = `${this.batch.batch.name}-${currentDate}.zip`;

    return saveAs(zipBlob, fileName);
  }

  /**
   * updates the relative progress
   */
  updateRelativeProgress() {
    this.progress = this.progress + 1;
    this.relativeProgress = (this.progress / this.numberOfDownloads) * 100;
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
    this.initialize();
  }
}
