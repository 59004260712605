











































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import {
  ThgBankingControllerGetBankingParamsGen,
  ThgBankingDtoGen,
  ThgBankingControllerNewBankingParamsGen
} from "@/services/thg/v1/data-contracts";
import { BankingModule } from "@/store/modules/banking.store";
import { UserModule } from "@/store/modules/me-user.store";
import { electronicFormatIBAN } from "ibantools";
import { ibanRule } from "@/lib/rules/ibanRule";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemBankingData extends DarkModeHighlightMixin {
  @Prop()
  userId!: string;

  @Prop()
  partnerId!: string;

  @Prop()
  banking?: ThgBankingDtoGen;

  @Prop()
  title?: string;

  /**
   * should the billing be loaded from the backend?
   */
  @Prop({ default: true })
  load?: boolean;

  get isAdmin() {
    return UserModule.isAdmin;
  }

  get ibanRules() {
    return [ibanRule()];
  }

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  get _title() {
    return this.title || "Banking";
  }

  locked = true;

  bankingData: ThgBankingDtoGen = {
    name: this.banking?.name || "",
    iban: this.banking?.iban || "",
    bank: this.banking?.bank || ""
  };

  bankingLoading = false;

  async mounted() {
    if (!this.banking && this.load) {
      this.bankingLoading = true;

      const bankingDto: ThgBankingControllerGetBankingParamsGen = {
        userId: this.userId,
        partnerId: this.partnerId
      };
      try {
        this.bankingData = (await BankingModule.getBanking(bankingDto)) || BankingModule.emptyBanking;
      } catch (e) {
        this.$toast((e as any).message);
        this.$log.error(e);
      } finally {
        this.bankingLoading = false;
      }
    }
  }

  async createNew() {
    this.bankingLoading = true;
    const bankingDto: ThgBankingControllerNewBankingParamsGen = {
      userId: this.userId,
      partnerId: this.partnerId
    };
    const dto: ThgBankingDtoGen = {
      name: this.bankingData?.name || "",
      iban: electronicFormatIBAN(this.bankingData?.iban) || "",
      bank: this.bankingData?.bank || ""
    };
    if (!dto.name || !dto.iban || !dto.bank) {
      this.$log.info(this.$t("components.thg.thgBillingSelectionBankingData.missingData"));
      this.$toast.error(this.$t("components.thg.thgBillingSelectionBankingData.missingData"));
      return;
    }
    try {
      await BankingModule.createBanking({ query: bankingDto, dto });
      this.$toast.success(this.$t("components.thg.thgBillingSelectionBankingData.success"));
    } catch (e) {
      this.$log.info(e);
      this.$toast.error((e as any).message);
    } finally {
      this.locked = true;
      this.bankingData = BankingModule.banking || {
        name: this.banking?.name || "",
        iban: this.banking?.iban || "",
        bank: this.banking?.bank || ""
      };
      this.bankingLoading = false;
    }
  }
}
