






















































































































































import { UpdateUserDtoFactory } from "@/lib/utility/updateUserDtoFactory";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IAdminUser } from "@/models/user.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import Debug from "../utility/Debug.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemContactData extends DarkModeHighlightMixin {
  @Prop()
  userId!: string;

  @Prop()
  user!: IAdminUser;

  loadingUser = true;
  userData: IAdminUser | null = this.user;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  updatingUser = false;

  locked = true;

  async mounted() {
    if (!this.user) {
      this.userData = await AdminUserPaginationModule.getSelectedUser(this.userId);
    }
    this.loadingUser = false;
  }

  async confirmEdit() {
    if (!this.userData) {
      return;
    }
    this.updatingUser = true;

    try {
      const updateUserDto = new UpdateUserDtoFactory().createFromIUser(this.userData);
      await this.user.updateFromDto(updateUserDto);
      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
      this.locked = true;
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    }

    this.updatingUser = false;
  }
}
