



























import PublicImageUploadDialog from "@/components/public-image/PublicImageUploadDialog.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CreateOrUpdateAdvertisement } from "@/models/Advertisment/create-advertisment";
import { PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import { ThgCreateAdvertisementDtoGen } from "@/services/thg/v1/data-contracts";
import { AdvertisementPaginatedAdminModule } from "@/store/modules/advertisment-admin-paginated-store";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { ErrorLogModule } from "@/store/modules/error-log";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    PublicImageUploadDialog
  }
})
export default class ThgAdvertisementCreate extends Vue {
  loading = false;
  valid = false;
  stepper = 1;
  createOrUpdateAdvertisement: ThgCreateAdvertisementDtoGen = new CreateOrUpdateAdvertisement();
  partnerId = "";

  isLoading = false;
  isLoadingPartners = false;

  async mounted() {
    await this.loadPartners();
  }

  async loadPartners() {
    this.isLoadingPartners = true;
    try {
      if (this.partners.length === 0) {
        await PartnerModule.getPartners();
      }
    } catch (error) {
      ErrorLogModule.addErrorLog({
        name: "Fetching Partner Error",
        message: "Could not load Partners"
      });

      this.$toast("Faild to load Partnersd.");
    } finally {
      this.isLoadingPartners = false;
    }
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get isText() {
    if (["text", "bannerAndText"].includes(this.createOrUpdateAdvertisement.content.type)) {
      return true;
    }
    return false;
  }

  get isBanner() {
    if (["banner", "bannerAndText"].includes(this.createOrUpdateAdvertisement.content.type)) {
      return true;
    }
    return false;
  }

  handleImage(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.createOrUpdateAdvertisement.content.bannerUrl = image.cdnUrl;
    }
  }

  async create() {
    try {
      const ad = await AdvertisementAdminModule.create({
        partnerId: this.partnerId,
        dto: this.createOrUpdateAdvertisement
      });
      AdvertisementPaginatedAdminModule.replaceInList(ad);
      this.$router.push({ name: "ThgAdvertisement" });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error("Error during creation of ad.");
    }
  }
}
