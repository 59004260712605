





































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import PortalBankingDialog from "@/components/partner/PortalBankingDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBankingDtoGen } from "@/services/thg/v1/data-contracts";
import { BankingModule } from "@/store/modules/banking.store";
import { Component, Prop } from "vue-property-decorator";
import LatestEntriesCardEmpty from "../cards/LatestEntriesCardEmpty.vue";
import Card from "../utility/Card.vue";

@Component({
  components: {
    CustomerContactCard,
    PortalBankingDialog,
    LatestEntriesCardEmpty,
    Card
  }
})
export default class PartnerBillingInformationBankingCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  partnerId!: string;

  @Prop({ default: false })
  hasPermissions!: string;

  bankingLoading = true;

  get banking() {
    return BankingModule.banking;
  }

  async mounted() {
    if (this.hasPermissions) {
      await this.loadBanking();
    } else {
      this.bankingLoading = false;
    }
  }

  async updateBanking(bankingDto: ThgBankingDtoGen) {
    if (!this.partnerId) {
      this.$toast.error(this.$t("components.partner.UserAdministrationDetailBanking.error"));
      return;
    }

    try {
      this.bankingLoading = true;
      await BankingModule.createBankingAsPartner({ partnerId: this.partnerId, dto: bankingDto });
      this.$toast.success(this.$t("components.partner.UserAdministrationDetailBanking.success"));
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    }
    await this.loadBanking();
  }

  async loadBanking() {
    if (!this.partnerId) {
      return;
    }
    try {
      this.bankingLoading = true;
      await BankingModule.getBankingAsPartner(this.partnerId);
    } catch (e) {
      this.$log.error(e);
    } finally {
      this.bankingLoading = false;
    }
  }
}
