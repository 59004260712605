import affiliateService from "@/services/thg/services/affiliateService";
import { ThgAffiliateViewmodelGen } from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AffiliateCreateStoreDto } from "../interface/thg/affiliate.create.dto.interface";
import { AffiliateGetOneDto, UpdateAffiliateDto } from "../interface/thg/affiliate.get.one.dto.interface";

@Module({
  dynamic: true,
  namespaced: true,
  name: "affiliate",
  store
})
export class AffiliatePortalStore extends VuexModule {
  /**
   * All affiliates per partner
   */
  private _affiliates: ThgAffiliateViewmodelGen[] = [];

  get affiliates(): ThgAffiliateViewmodelGen[] {
    return this._affiliates;
  }

  /**
   * The selected Affiliate
   */
  private _selectedAffiliate: ThgAffiliateViewmodelGen = {} as any;

  get selectedAffiliate(): ThgAffiliateViewmodelGen {
    return this._selectedAffiliate;
  }

  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Mutation
  _mutateAffiliates(myAffiliates: []) {
    this._affiliates = myAffiliates;
  }

  @Action
  async getAffiliatesByPartnerId(partnerId: string): Promise<ThgAffiliateViewmodelGen[]> {
    this.context.commit("_mutateIsLoading", true);
    this.context.commit("_mutateAffiliates", []);
    this.context.commit("_mutateSelectedAffiliate", {});
    const affiliates: any[] = await affiliateService.getAllAffiliate(partnerId);
    affiliates.sort(function(a, b) {
      return a.timestamp.created > b.timestamp.created ? -1 : a.timestamp.created > b.timestamp.created ? 1 : 0;
    });
    this.context.commit("_mutateAffiliates", affiliates);
    this.context.commit("_mutateIsLoading", false);
    return affiliates;
  }

  @Action
  async getAffiliatesByUserId(userId: string): Promise<ThgAffiliateViewmodelGen[]> {
    this.context.commit("_mutateIsLoading", true);
    this.context.commit("_mutateAffiliates", []);
    this.context.commit("_mutateSelectedAffiliate", {});
    const affiliates: any[] = await affiliateService.getAffiliatesByUserId(userId);
    this.context.commit("_mutateAffiliates", affiliates);
    this.context.commit("_mutateIsLoading", false);
    return affiliates;
  }

  @Mutation
  _mutateSelectedAffiliate(affiliate: ThgAffiliateViewmodelGen) {
    this._selectedAffiliate = affiliate;
  }

  @Action
  async getSelectedAffiliate(dto: AffiliateGetOneDto): Promise<ThgAffiliateViewmodelGen> {
    const selectedAffiliate: ThgAffiliateViewmodelGen = await affiliateService.getAffiliate(
      dto.partnerId,
      dto.affiliateId
    );
    this.context.commit("_mutateSelectedAffiliate", selectedAffiliate);
    return selectedAffiliate;
  }

  @Action
  async getSelectedAffiliateByCode(dto: { code: string; partnerId: string }): Promise<ThgAffiliateViewmodelGen> {
    const selectedAffiliate: ThgAffiliateViewmodelGen = await affiliateService.getAffiliateByCode(
      dto.partnerId,
      dto.code
    );
    this.context.commit("_mutateSelectedAffiliate", selectedAffiliate);
    return selectedAffiliate;
  }

  @Action
  async updateSelectedAffiliate(dto: UpdateAffiliateDto): Promise<ThgAffiliateViewmodelGen> {
    let selectedAffiliate = {} as ThgAffiliateViewmodelGen;
    try {
      selectedAffiliate = await affiliateService.updateAffiliate(dto.partnerId, dto.affiliateId, dto.dto);
      this.context.commit("_mutateSelectedAffiliate", selectedAffiliate);
    } catch (error) {
      if (error?.response) {
        if (error.response.status === 409) {
          Vue.$toast.warning("Update nicht möglich. Code bereits verwendet.");
        } else {
          Vue.$toast.error("Fehler beim updaten.");
        }
      } else {
        Vue.$log.error(error);
      }
    }
    return selectedAffiliate;
  }

  @Action
  async createAffiliate(dto: AffiliateCreateStoreDto): Promise<ThgAffiliateViewmodelGen> {
    const createdAffiliate: ThgAffiliateViewmodelGen = await affiliateService.createAffiliate(
      dto.partnerId,
      dto.createAffiliateDto
    );
    this.context.commit("_mutateAddCreatedToList", createdAffiliate);
    this.context.commit("_mutateSelectedAffiliate", createdAffiliate);
    return createdAffiliate;
  }

  @Mutation
  _mutateAddCreatedToList(createdAffiliate: ThgAffiliateViewmodelGen) {
    this._affiliates.unshift(createdAffiliate);
  }

  @Mutation
  _mutateIsLoading(isLaoding: boolean) {
    this._isLoading = isLaoding;
  }

  @Action
  async setIsLoading(isLoading: boolean) {
    this.context.commit("_mutateIsLoading", isLoading);
  }

  @Action
  async getManyByCode(codes: string[]) {
    const affiliates = await affiliateService.getManyAffiliatesByCode(codes);

    this.context.commit("_mutateAffiliates", affiliates);
    return affiliates;
  }
}

export const AffiliatePortalModule = getModule(AffiliatePortalStore);
