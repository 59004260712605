










import ThgBillingNumberDetail from "@/components/thg/ThgBillingNumberDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { BillingDataLoader } from "@/lib/utility/billing-data-loader";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    ThgBillingNumberDetail
  }
})
export default class ThgBillingNumberDetailView extends Vue {
  get routeBillingNumber() {
    return this.$route.params.billingNumber;
  }
  billingDataLoader: BillingDataLoader | null = null;

  loadBilling(billingNumber: number) {
    this.billingDataLoader = new BillingDataLoader(billingNumber);
  }

  async mounted() {
    this.loadBilling(parseInt(this.routeBillingNumber));
  }
}
