




















import ReportImageGallery from "@/components/report/ReportImageGallery.vue";
import FileDownload from "@/components/utility/FileDownload.vue";
import { mapBaseImageToDownloadFile } from "@/lib/utility/map-base-image-to-download-file";
import ThgQuoteUpdateRegistrationImageDialog from "@/components/thg/ThgQuoteUpdateRegistrationImageDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IBaseImage } from "@/models/caseEntity";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";
import { IDownloadFile } from "../utility/interface/download-file.interface";
import Card from "../utility/Card.vue";
import ThgEditImagesDialog from "./ThgEditImagesDialog.vue";

@Component({
  components: {
    ReportImageGallery,
    FileDownload,
    ThgQuoteUpdateRegistrationImageDialog,
    Card,
    ThgEditImagesDialog
  }
})
export default class ThgQuoteDetailImageCard extends DarkModeHighlightMixin {
  @Prop({})
  thg!: IThg;

  get downloadableFiles(): IDownloadFile[] {
    const imagesToDownload: IDownloadFile[] = [];

    mapBaseImageToDownloadFile(imagesToDownload, this.thg.registrationImages, "fahrzeugschein");
    return imagesToDownload;
  }

  get mapToImage() {
    const images: any[] = [];
    this.imageMapper(images, this.thg.registrationImages, "Fahrzeugschein.");
    return images;
  }

  private imageMapper(imageItemList: any[], thgImages: IBaseImage[], title: string) {
    for (const image of thgImages) {
      const item = {
        src: image.url,
        thumbnail: image.url,
        w: 0,
        h: 0,
        title: title
      };
      imageItemList.push(item);
    }

    return imageItemList;
  }
}
