






import LoginCard from "@/components/login/LoginCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { LoginCard, TheLayoutPortal }
})
export default class Login extends Vue {
  logo = "https://static.mmmint.ai/wirkaufendeinethg/wirkaufendeinethg_logo.png";

  get title() {
    return this.$t("views.thgPortal.ThgLogin.title");
  }
  get text() {
    return this.$t("views.thgPortal.ThgLogin.text");
  }

  onLoginFunction = async () => {
    this.$router.push({ path: "/" });
  };
}
