/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgEfahrerControllerFindAllParamsGen,
  ThgEfahrerOrderPopulatedViewModelGen,
  ThgEfahrerOrderViewModelGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgUpdateEfahrerThgOrderStatusDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Efahrer<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags efahrer
   * @name EfahrerControllerFindAll
   * @request GET:/efahrer
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgEfahrerOrderViewModelGen)[] })`
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  efahrerControllerFindAll = (query: ThgEfahrerControllerFindAllParamsGen, params: RequestParams = {}) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgEfahrerOrderViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/efahrer`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags efahrer
   * @name EfahrerControllerFindOne
   * @request GET:/efahrer/{id}
   * @secure
   * @response `200` `ThgEfahrerOrderPopulatedViewModelGen`
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  efahrerControllerFindOne = (id: number, params: RequestParams = {}) =>
    this.http.request<ThgEfahrerOrderPopulatedViewModelGen, ThgExceptionViewmodelGen>({
      path: `/efahrer/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags efahrer
   * @name EfahrerControllerStatus
   * @request POST:/efahrer/{id}
   * @secure
   * @response `201` `void`
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  efahrerControllerStatus = (id: number, data: ThgUpdateEfahrerThgOrderStatusDtoGen, params: RequestParams = {}) =>
    this.http.request<void, ThgExceptionViewmodelGen>({
      path: `/efahrer/${id}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params
    });
}
