import advertisementAdminService from "@/services/thg/services/advertisementAdminService";
import {
  ThgAdvertisementViewmodelGen,
  ThgCreateAdvertisementDtoGen,
  ThgUpdateAdvertisementAdminDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AdvertisementPaginatedAdminModule } from "./advertisment-admin-paginated-store";

@Module({
  dynamic: true,
  namespaced: true,
  name: "advertisment-admin-store",
  store
})
export class AdvertisementAdminStore extends VuexModule {
  private _selected: ThgAdvertisementViewmodelGen = {} as any;

  get selected(): ThgAdvertisementViewmodelGen {
    return this._selected;
  }

  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Mutation
  _mutateSelected(ad: ThgAdvertisementViewmodelGen) {
    this._selected = ad;
  }

  @Mutation
  _mutateIsLoading(isLaoding: boolean) {
    this._isLoading = isLaoding;
  }

  @Action
  async getAdById(data: { partnerId: string; id: string }) {
    const ad = await advertisementAdminService.get(data.partnerId, data.id);
    this.context.commit("_mutateSelected", ad);
    return ad;
  }

  @Action
  async create(data: { partnerId: string; dto: ThgCreateAdvertisementDtoGen }) {
    const ad = await advertisementAdminService.create(data.partnerId, data.dto);
    this.context.commit("_mutateSelected", ad);
    return ad;
  }

  @Action
  async updateSelected(dto: ThgUpdateAdvertisementAdminDtoGen) {
    const ad = await advertisementAdminService.update(this.selected.partnerId, this.selected.id, dto);
    this.context.commit("_mutateSelected", ad);
    AdvertisementPaginatedAdminModule.replaceInList(ad);
    return ad;
  }

  @Action
  async delete() {
    const ad = await advertisementAdminService.remove(this.selected.partnerId, this.selected.id);
    this.context.commit("_mutateSelected", {});
    return ad;
  }
}

export const AdvertisementAdminModule = getModule(AdvertisementAdminStore);
