var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-4",
    attrs: {
      "flat": ""
    }
  }, [!_vm.loading ? [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "md": "9",
      "cols": "12"
    }
  }, [_c('div', {
    style: !_vm.$vuetify.breakpoint.smAndDown ? 'overflow-y: scroll; height:calc(100vh - 100px)' : ''
  }, [_c('v-row', [_c('v-col', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "sm": "4",
      "md": "3",
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v("Datum: " + _vm._s(_vm.date))]), _c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.selectedThg.year) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.partner.companyName) + " " + _vm._s(_vm._f("getFlagEmoji")(_vm.partner.countryCode)) + " ")])]), _c('v-col', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "sm": "4",
      "md": "3",
      "cols": "12"
    }
  }, [_c('div', [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.copyName
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-content-copy")]), _vm._v(_vm._s(_vm.selectedUser.firstName) + " " + _vm._s(_vm.selectedUser.lastName) + " ")], 1)], 1), _c('div', [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.copyEmailToClipboard
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-content-copy")]), _vm._v(" " + _vm._s(_vm.selectedUser.userName) + " ")], 1)], 1)]), _vm.selectedUser.company ? _c('v-col', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "sm": "4",
      "md": "3",
      "cols": "12"
    }
  }, [_c('div', [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.copyCompany
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-content-copy")]), _vm._v(" " + _vm._s(_vm.selectedUser.company) + " ")], 1)], 1)]) : _vm._e(), _c('v-col', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "sm": "4",
      "md": "3",
      "cols": "12"
    }
  }, [_c('div', [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.toPartnerHomeView
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-open-in-new")]), _vm._v("partner ")], 1)], 1), _c('div', [_c('v-btn', {
    attrs: {
      "x-small": "",
      "text": ""
    },
    on: {
      "click": _vm.toThgDetailView
    }
  }, [_c('v-icon', {
    attrs: {
      "x-small": ""
    }
  }, [_vm._v("mdi-open-in-new")]), _vm._v("thg ")], 1)], 1)])], 1), _c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "md": "4",
      "sm": "6",
      "cols": "12"
    }
  }, [_c('customer-contact-card', {
    staticStyle: {
      "height": "355px"
    },
    attrs: {
      "userId": _vm.selectedUser._id,
      "companyName": _vm.selectedUser.company,
      "customerName": _vm.selectedUser.firstName + ' ' + _vm.selectedUser.lastName,
      "address": _vm.selectedUser.address,
      "email": _vm.selectedUser && _vm.selectedUser.contact ? _vm.selectedUser.contact.email : '',
      "phone": _vm.selectedUser && _vm.selectedUser.contact ? _vm.selectedUser.contact.phone : ''
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "4",
      "sm": "6",
      "cols": "12"
    }
  }, [_c('thg-charging-station-information-card', {
    staticStyle: {
      "height": "355px"
    },
    attrs: {
      "showCarsPerDay": true,
      "chargingStation": _vm.chargingStation,
      "nav": true,
      "hideEdit": true
    }
  })], 1), _c('v-col', {
    attrs: {
      "md": "4",
      "sm": "6",
      "cols": "12"
    }
  }, [_c('card', {
    attrs: {
      "title": "".concat(_vm.$t('components.thg.ThgQuoteMeterReadingWorkItemCard.geoCardTitle'), "\n                  ").concat(_vm.loadingLocation ? ' 🛩️' : ' 🗺️')
    }
  }, [!_vm.loadingLocation ? _c('partner-map', {
    attrs: {
      "height": 284,
      "coordinates": _vm.chargingStation.address.geo,
      "colour": _vm.$vuetify.theme.currentTheme.success
    }
  }) : _c('v-skeleton-loader', {
    staticStyle: {
      "margin-top": "42px",
      "margin-bottom": "42px",
      "margin-left": "10px",
      "margin-right": "10px"
    },
    attrs: {
      "type": "image"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "md": "4",
      "sm": "6",
      "cols": "12"
    }
  }, [_c('card', {
    staticStyle: {
      "min-height": "355px"
    },
    attrs: {
      "title": "Meter Reading"
    }
  }, [_c('v-card-text', [_c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-speedometer")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.selectedThg.meterReading ? _vm.selectedThg.meterReading.amountInKwh : "") + " " + _vm._s(_vm.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.kwh")) + " ")])], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-delta")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(Math.round(_vm.kwhPerDay)) + " " + _vm._s(_vm.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.kwhPerDay")) + " ")])], 1), _c('v-list-item', [_c('v-list-item-icon', [_c('v-icon', [_vm._v("mdi-car-multiple")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.amountOfCarsPerDay) + " " + _vm._s(_vm.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.carsPerDay")) + " ")])], 1), _c('v-list-item', [_c('v-list-item-icon', [_vm.isYearBreak ? _c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]) : _vm.overlap.length ? _c('v-icon', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("mdi-alert")]) : _c('v-icon', {
    attrs: {
      "color": "success"
    }
  }, [_vm._v("mdi-check-bold")])], 1), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.startDate) + " - " + _vm._s(_vm.endDate) + " (" + _vm._s(_vm.dateDifference) + " " + _vm._s(_vm.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.days")) + ") "), _vm.overlap.length ? _c('div', [_c('div', [_vm._v("Overlap")]), _vm._l(_vm.overlap, function (id) {
    return _c('div', {
      key: "overlap".concat(id),
      staticClass: "ml-2"
    }, [_c('a', {
      on: {
        "click": function click($event) {
          return _vm.toMeterReading(id);
        }
      }
    }, [_vm._v(_vm._s(id))])]);
  })], 2) : _vm._e()])], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "md": "8",
      "sm": "12",
      "cols": "12"
    }
  }, [_c('thg-quote-meter-reading-work-item-card-chart', {
    attrs: {
      "meterReadings": _vm.meterReadings,
      "meterReading": _vm.selectedThg
    }
  })], 1)], 1)], 1)]), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticStyle: {
      "z-index": "1"
    },
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-row', {
    staticClass: "ma-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    ref: "startTextFieldRef",
    attrs: {
      "label": _vm.$t('components.thg.ThgChargingStationCard.operator'),
      "persistent-hint": "",
      "rules": _vm.required,
      "required": "",
      "outlined": "",
      "data-test-form-operator": "",
      "auto-select-first": ""
    },
    on: {
      "blur": function blur($event) {
        _vm.chargingStation.chargingPointOperator.name = $event.target.value;
      }
    },
    model: {
      value: _vm.chargingStation.chargingPointOperator.name,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.chargingPointOperator, "name", $$v);
      },
      expression: "chargingStation.chargingPointOperator.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('components.thg.ThgChargingStationForm.bnetzaOperatorId'),
      "persistent-hint": "",
      "rules": _vm.bnetzaOperatorIdRule,
      "outlined": "",
      "data-test-form-bnetza-operator-id": ""
    },
    on: {
      "blur": function blur($event) {
        _vm.chargingStation.chargingPointOperator.bnetzaOperatorId = $event.target.value;
      }
    },
    model: {
      value: _vm.chargingStation.chargingPointOperator.bnetzaOperatorId,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.chargingPointOperator, "bnetzaOperatorId", $$v);
      },
      expression: "chargingStation.chargingPointOperator.bnetzaOperatorId"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n3",
    attrs: {
      "cols": "2",
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-btn', {
    staticClass: "mr-n4",
    attrs: {
      "x-large": "",
      "icon": ""
    },
    on: {
      "click": _vm.getLocation
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v(" mdi-map-marker ")])], 1)], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.geoLatRule,
      "min": _vm.minLat,
      "max": _vm.maxLat,
      "label": _vm.$t('components.thg.ThgChargingStationForm.geoLat'),
      "type": "number",
      "required": "",
      "outlined": "",
      "persistent-hint": "",
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.loadingLocation
    },
    on: {
      "blur": function blur($event) {
        _vm.chargingStation.address.geo.lat = +$event.target.value;
      }
    },
    model: {
      value: _vm.chargingStation.address.geo.lat,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address.geo, "lat", _vm._n($$v));
      },
      expression: "chargingStation.address.geo.lat"
    }
  })], 1), _c('v-col', {
    staticClass: "mt-n4",
    attrs: {
      "cols": "5"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.geoLngRule,
      "min": _vm.minLng,
      "max": _vm.maxLng,
      "label": _vm.$t('components.thg.ThgChargingStationForm.geoLng'),
      "type": "number",
      "required": "",
      "outlined": "",
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.loadingLocation
    },
    on: {
      "blur": function blur($event) {
        _vm.chargingStation.address.geo.lng = +$event.target.value;
      }
    },
    model: {
      value: _vm.chargingStation.address.geo.lng,
      callback: function callback($$v) {
        _vm.$set(_vm.chargingStation.address.geo, "lng", _vm._n($$v));
      },
      expression: "chargingStation.address.geo.lng"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-checkbox', {
    staticClass: "mt-n4 ml-2",
    attrs: {
      "label": _vm.$t('components.thg.ThgQuoteMeterReadingWorkItemCard.isReadingRealistic'),
      "rules": _vm.required
    },
    model: {
      value: _vm.isRealistic,
      callback: function callback($$v) {
        _vm.isRealistic = $$v;
      },
      expression: "isRealistic"
    }
  }), _c('v-checkbox', {
    staticClass: " ml-2",
    attrs: {
      "label": _vm.$t('components.thg.ThgQuoteMeterReadingWorkItemCard.isDateRangeRealistic'),
      "rules": _vm.required
    },
    model: {
      value: _vm.isDateAight,
      callback: function callback($$v) {
        _vm.isDateAight = $$v;
      },
      expression: "isDateAight"
    }
  }), _c('thg-charging-station-form', {
    ref: "chargingStationForm",
    attrs: {
      "detail": true,
      "hidden": ""
    },
    model: {
      value: _vm.chargingStation,
      callback: function callback($$v) {
        _vm.chargingStation = $$v;
      },
      expression: "chargingStation"
    }
  })], 1), _c('v-btn', {
    staticClass: "ma-2 mt-0",
    staticStyle: {
      "color": "white"
    },
    attrs: {
      "color": "success",
      "block": "",
      "x-large": "",
      "disabled": !_vm.canBeConfirmed
    },
    on: {
      "click": function click($event) {
        return _vm.submit(_vm.ThgStatusEnum.CONFIRMED);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.confirm")) + " ")]), _c('v-switch', {
    staticClass: "ml-4",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemBillingResult.setNotifyUsers')
    },
    on: {
      "blur": function blur($event) {
        _vm.userNotification = $event.target.value;
      }
    },
    model: {
      value: _vm.userNotification,
      callback: function callback($$v) {
        _vm.userNotification = $$v;
      },
      expression: "userNotification"
    }
  }), _c('v-switch', {
    staticClass: "ml-2",
    attrs: {
      "label": _vm.$t('components.thg.ThgQuoteMeterReadingWorkItemCard.reject')
    },
    model: {
      value: _vm.isRejected,
      callback: function callback($$v) {
        _vm.isRejected = $$v;
      },
      expression: "isRejected"
    }
  }), _vm._l(_vm.failStates, function (failState) {
    return _c('div', {
      key: failState,
      staticStyle: {
        "width": "100%"
      }
    }, [_c('v-btn', {
      staticClass: "ma-2",
      staticStyle: {
        "color": "white"
      },
      attrs: {
        "color": "error",
        "block": "",
        "x-large": "",
        "disabled": !_vm.isRejected
      },
      on: {
        "click": function click($event) {
          return _vm.submit(failState);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteMeterReadingWorkItemCard.".concat(failState))) + " ")])], 1);
  })], 2)], 1)], 1)], 1)] : _c('v-skeleton-loader', {
    staticClass: "mx-auto pt-4",
    attrs: {
      "type": "card, article, article"
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }