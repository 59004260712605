























import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgTransferChargingStationDtoGen } from "@/services/thg/v1/data-contracts";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgChargingStationTransferDialog extends DarkModeHighlightMixin {
  isLoading = false;
  isDialogActive = false;

  userId = "";

  title = "components.thg.ThgChargingStationTransferDialog.title";
  content = "components.thg.ThgChargingStationTransferDialog.content";
  btnText = "components.thg.ThgChargingStationTransferDialog.btnText";

  get subTitle() {
    return `${this.$t(this.content)} ${this.chargingStationId}`;
  }

  get requiredRules() {
    return [requiredRule()];
  }

  get chargingStationId() {
    return ThgPortalModule.selectedChargingStation.id;
  }

  async transfer() {
    this.isLoading = true;
    let dto: ThgTransferChargingStationDtoGen | undefined = undefined;

    try {
      dto = { userId: this.userId };

      const updatedChargingStation = await ThgPortalModule.transferChargingStation({
        id: this.chargingStationId,
        data: dto
      });

      this.$toast.success("👍");

      this.$router.push({
        name: "ThgChargingStationDetailView",
        params: { chargingStationId: updatedChargingStation.id }
      });

      this.isDialogActive = false;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgChargingStationTransferDialog.errorText"));
    } finally {
      this.isLoading = false;
    }
  }
}
