



















































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import ThgBatchSelectDialog from "@/components/thg/ThgBatchSelectDialog.vue";
import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { BatchModule } from "@/store/modules/batch.store";
import { Component } from "vue-property-decorator";

@Component({
  components: { AnalyticsDateRangeSelectorDialog, ThgBatchSelectDialog }
})
export default class ThgBatchCreateDialog extends DarkModeHighlightMixin {
  isLoading = false;
  dialog = false;
  batchName = "";
  selectedThg: IThg[] = [];

  type = BatchTypeEnum.vehicle;

  get lengthOfSelectedThg() {
    return this.selectedThg.length.toString();
  }

  status = ThgStatusEnum.CREATED;
  stati = Object.keys(ThgStatusEnum).map(k => ThgStatusEnum[k as any]);

  get loading() {
    return this.isLoading;
  }

  /**
   * Rules for required data
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  /**
   * Disables the Button if required fields are not filled
   */
  get isDisabled() {
    return !this.batchName;
  }

  /**
   * Initializes the dialog and set defaul values
   */
  async initialize() {
    this.dialog = true;
    this.isLoading = true;
    this.batchName = "";
    this.isLoading = false;
  }

  /**
   * closes the dialog
   */
  async create() {
    try {
      this.isLoading = true;
      const items = [];

      for (const item of this.selectedThg) {
        items.push(item.id);
      }

      const newBatch = await BatchModule.create({ type: this.type, itemIds: items, name: this.batchName });
      this.$toast.success(this.$t("components.thg.ThgBatchCreateDialog.success"));
      this.$router.push({ name: "ThgBatchDetail", params: { batchId: newBatch.batch.id } });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgBatchCreateDialog.error"));
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }

  /**
   * set the selected THG to assign
   */
  setSelectedThg(selectedThg: IThg[]) {
    this.selectedThg = selectedThg;
  }
}
