

























































































































































































































































import ThgRegistrationUploadCard from "@/components/thg/ThgRegistrationUploadCard.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import { NetworkError } from "@/lib/exceptions/http/NetworkError";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Ref, Vue } from "vue-property-decorator";
import FileExample from "../files/FileExample.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    Debug,
    ConfirmActionDialog,
    ThgRegistrationUploadCard,
    FileExample
  }
})
export default class ThgFleetAssistantDialog extends Vue {
  /**
   * Show dialog
   */
  isDialogActive = false;

  /**
   * Active window
   */
  page = 0;

  /**
   * Last active page (to hide the submit button)
   */
  LAST_PAGE = 2;

  /**
   * Is submitting a THG
   */
  isLoading = false;

  /**
   * Is fetching years/prices
   */
  isLoadingPrices = false;

  /**
   * Selected prices
   */
  years = [];

  /**
   * Form validation
   */
  isValid = false;

  /**
   * All numberplates submitted in this session
   */
  numberplates: string[] = [];

  @Ref("numberplate")
  private readonly numberplateField!: HTMLElement;

  get AssetEnum() {
    return AssetEnum;
  }

  get registrationImage() {
    return AssetRepository.getAsset(this.$vuetify.theme.dark, AssetEnum.registration);
  }

  get getPreview() {
    return URL.createObjectURL(ThgCreateModule.registrationImageFront[0].file);
  }

  get hasPreview() {
    return ThgCreateModule.registrationImageFront.length >= 1;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get numberplateRule() {
    return [numberplateRule(), requiredRule()];
  }

  get availableProducts() {
    return PartnerActiveOfferModule.priceConfig.getYearCombinationsByVehicleClass(
      ThgCreateModule.vehicleClass || ThgVehicleTypes.M1
    );
  }

  get frontImageId() {
    if (ThgCreateModule.registrationImageFront.length <= 0) return "";

    return ThgCreateModule.registrationImageFront[0].uploadId;
  }

  get backImageId() {
    if (ThgCreateModule.registrationImageBack.length <= 0) return "";

    return ThgCreateModule.registrationImageBack[0].uploadId;
  }

  set numberplate(numberplate: string) {
    ThgCreateModule.setNumberplate(numberplate);
  }

  get numberplate() {
    return ThgCreateModule.numberplate;
  }

  get canSubmit() {
    return (
      this.isValid &&
      this.years.length >= 1 &&
      ThgCreateModule.registrationImageBack.length >= 1 &&
      ThgCreateModule.registrationImageFront.length >= 1
    );
  }

  uploaded(event: { imageResult: IImageUploaded; file: File }) {
    this.$log.debug(event.file, event.imageResult);

    if (!this.numberplate) {
      this.numberplate = event.file.name.split(".")[0];
    }
  }

  transformYearsToString(arr: number[]): string {
    if (arr.length === 0) {
      return "";
    } else if (arr.length === 1) {
      return arr[0].toString();
    } else if (arr.length === 2) {
      return arr.join(" & ");
    } else {
      const lastTwo = arr.slice(-2).join(" & ");
      const remaining = arr.slice(0, -2).join(", ");
      return `${remaining}, ${lastTwo}`;
    }
  }

  activeCardStyle(years: number[]) {
    if (JSON.stringify(this.years) === JSON.stringify(years)) {
      return "primary";
    }
    return "";
  }

  numberToWord(num: number) {
    const germanWords = [
      "common.numbers.none",
      "common.numbers.one",
      "common.numbers.two",
      "common.numbers.three",
      "common.numbers.four",
      "common.numbers.five"
    ];

    if (num >= 0 && num <= 5) {
      return this.$t(germanWords[num]);
    } else {
      return num.toString();
    }
  }

  focusNumberplate() {
    this.numberplateField.focus();
  }

  reset() {
    this.page = 0;
    this.numberplates = [];
    this.isDialogActive = false;
  }

  get hideRight() {
    return this.page === this.LAST_PAGE;
  }

  left() {
    if (this.page >= 1) {
      this.page--;
    } else {
      this.reset();
    }
  }

  right() {
    this.page++;
    if (this.page > this.LAST_PAGE) {
      this.reset();
    }
  }

  async fetchPrices(partnerId: string = ReportModule.partner.id) {
    try {
      this.isLoadingPrices = true;
      await PartnerActiveOfferModule.loadDocuments(partnerId);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("report.thgProducts.loadingError"));
    }

    this.isLoadingPrices = false;
  }

  async create() {
    this.isLoading = true;

    try {
      ThgCreateModule.setYears(this.years);
      ThgCreateModule.setIsConfirmed(true);

      const tempNumberplate = ThgCreateModule.numberplate;
      await ThgCreateModule.submitIfAuthenticated();

      this.$toast.success(`THG ${tempNumberplate}  für ${this.years.join(" & ")} beantragt 👍`);

      this.focusNumberplate();

      this.numberplates.push(tempNumberplate);
    } catch (error) {
      this.$log.error(error);

      if (error instanceof NetworkError) {
        this.$toast.error("Fehler mit der Verbindung. Bitte zu einem späteren Zeitpunkt erneut versuchen.");
      } else {
        this.$toast.error("Fehler beim Senden. Bitte Eingaben noch einmal prüfen.");
      }
    } finally {
      this.isLoading = false;
    }
  }

  async mounted() {
    if (ReportModule.partner.id) {
      await this.fetchPrices();
    }
  }
}
