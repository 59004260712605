var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.allowedTypes.includes(_vm.billing.type) ? _c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.cancelBillingDialog = true;
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-recycle")])], 1) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingRecreationDialog.cancelTooltip")) + " ")])]), _vm.cancelBillingDialog ? _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    model: {
      value: _vm.cancelBillingDialog,
      callback: function callback($$v) {
        _vm.cancelBillingDialog = $$v;
      },
      expression: "cancelBillingDialog"
    }
  }, [_c('card', {
    attrs: {
      "title": _vm.$t('components.thg.ThgBillingRecreationDialog.cancelTitle'),
      "subtitle": '#' + _vm.billing.billingNumber,
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "main",
      fn: function fn() {
        return [_c('v-card-text', [_c('div', {
          staticClass: "ml-3"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingRecreationDialog.confirm")) + " ")]), _c('v-text-field', {
          staticClass: "mr-2 ml-2",
          attrs: {
            "outlined": ""
          },
          model: {
            value: _vm.billingNumberConfirmation,
            callback: function callback($$v) {
              _vm.billingNumberConfirmation = $$v;
            },
            expression: "billingNumberConfirmation"
          }
        })], 1), _c('v-card-actions', [_c('v-btn', {
          attrs: {
            "text": "",
            "outlined": ""
          },
          on: {
            "click": _vm.cancelBillingRecreation
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingRecreationDialog.cancelRecreation")) + " ")]), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.loading,
            "disabled": _vm.billingNumberConfirmation !== _vm.billing.billingNumber.toString(),
            "text": "",
            "outlined": ""
          },
          on: {
            "click": _vm.confirmBillingRecreation
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBillingRecreationDialog.confirmRecreation")) + " ")])], 1)];
      },
      proxy: true
    }], null, false, 2378319313)
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }