












































import FilterCard from "@/components/filter/FilterCard.vue";
import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import { IThgStatus } from "@/lib/interfaces/thg/thg-status.interface";
import { simpleDate } from "@/lib/utility/date-helper";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { IThg } from "@/models/thg.entity";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import { PaginatedBaseStore } from "@/store/paginated-base.store";

@Component({ components: { ThgQuoteExportDialog, FilterCard, TheLayoutPortalList } })
export default class ThgQuoteListCard extends mixins(DarkModeHighlightMixin, PaginationListMixin, PermissionMixin) {
  getModule(): PaginatedBaseStore<any, any> {
    return ThgPortalModule;
  }

  get quotes() {
    return ThgPortalModule.allThgs;
  }

  thgStatus(thgStatusEnum: any): IThgStatus {
    return (
      thgStatusMap.get(thgStatusEnum) || {
        text: "Alles im grünen Bereich.",
        icon: "mdi-help",
        portalText: thgStatusEnum,
        color: "error"
      }
    );
  }

  isSelected(item: IThg) {
    return item.id === ThgPortalModule.selectedThg.id ? `grey ${this.color}` : "";
  }

  date(item: IThg): string {
    return simpleDate(item.timestamp?.created);
  }
}
