/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { deepCopy } from "@/lib/utility/deep-copy";
import {
  ThgAdvertisementContentDtoGen,
  ThgAdvertisementViewmodelGen,
  ThgCreateAdvertisementDtoGen,
  ThgTargetGroupConditionDtoGen
} from "@/services/thg/v1/data-contracts";

/**
 * Creates a driver
 */
export class CreateOrUpdateAdvertisement implements ThgCreateAdvertisementDtoGen {
  /**
   *
   */
  tags: string[] = [];

  /**
   *
   */
  monthlyBudget = 0;

  /**
   *
   */
  targetGroupConditions: ThgTargetGroupConditionDtoGen[] = [];

  /**
   *
   */
  content: ThgAdvertisementContentDtoGen = {
    type: "text",
    bannerUrl: "",
    targetUrl: "",
    text: ""
  };

  /**
   * Creates the object
   * @class
   */
  constructor(ad?: ThgAdvertisementViewmodelGen) {
    if (ad) {
      ad = deepCopy(ad);
      this.tags = ad.tags;
      this.monthlyBudget = ad.monthlyBudget;
      this.targetGroupConditions = ad.targetGroupConditions;
      this.content = ad.content;
    }
  }
}
