
























import ThgBillingPartnerList from "@/components/thg/ThgBillingPartnerList.vue";
import ThgBillingNumberPartnerDetail from "@/components/thg/ThgBillingNumberPartnerDetail.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PaginationListVueMixin from "@/mixins/PaginationListViewMixin.vue";
import { Component } from "vue-property-decorator";
import { PartnerBillingModule } from "@/store/modules/partner-billing.store";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    TheLayoutPortalSplit,
    ThgBillingPartnerList,
    ThgBillingNumberPartnerDetail
  }
})
export default class ThgBillingPartnerListView extends PaginationListVueMixin {
  isLoadingDetail = true;

  getModule() {
    return PartnerBillingModule;
  }

  async updateBillingType(billingType: BillingTypeEnum) {
    await this.loadAll(billingType);
  }

  async mounted() {
    await this.loadAll(BillingTypeEnum.CREDIT_PARTNER);
  }

  async loadAll(billingType: BillingTypeEnum) {
    this.isLoadingDetail = true;
    await this.refresh({ partnerId: this.$route.params.partnerId, billingType }).catch(handleError);

    const billing = this.getModule().paginationList[0];
    if (billing) {
      PartnerBillingModule.loadDocument({
        partnerId: this.$route.params.partnerId,
        billingNumber: billing.billingNumber
      }).catch(handleError);
    } else {
      PartnerBillingModule.emptyDocument();
    }
    this.isLoadingDetail = false;
  }

  async set(item: any) {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.$router.push({
        name: "ThgBillingNumberPartnerDetailView",
        params: { partnerId: this.$route.params.partnerId, billingNumber: item.billingNumber }
      });
    } else {
      try {
        this.isLoadingDetail = true;
        await PartnerBillingModule.loadDocument({
          partnerId: this.$route.params.partnerId,
          billingNumber: item.billingNumber
        });
      } catch (e) {
        handleError(e);
      } finally {
        this.isLoadingDetail = false;
      }
    }
  }
}
