var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_vm.billingDataLoader ? _c('thg-billing-number-detail', {
    attrs: {
      "billingDocuments": _vm.billingDataLoader.documents
    }
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }