














// import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { Component, Vue, Prop } from "vue-property-decorator";
import SideCard from "../utility/SideCard.vue";
import ThgBillingCard from "./ThgBillingCard.vue";
import { IBilling } from "@/models/billing.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { PartnerModule } from "@/store/modules/partner";
import { MrfiktivPartnerViewModelGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgShortUserViewModelGen } from "@/services/thg/v1/data-contracts";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: { SideCard, ThgBillingCard }
})
export default class ThgBillingSideCard extends Vue {
  @Prop()
  value!: IBilling;

  isLoading = false;

  isUserLoading = false;
  isPartnerLoading = false;
  partner: MrfiktivPartnerViewModelGen | null = null;
  user: ThgShortUserViewModelGen | null = null;

  async mounted() {
    this.isLoading = true;

    await Promise.all([this.loadUser(), this.loadParter(), this.loadBilling()]);

    this.isLoading = false;
  }

  async loadBilling() {
    await this.value.fetch();
    await this.value.fetchPdf();
  }

  async loadUser() {
    if (!this.value.userId) {
      return;
    }

    try {
      this.isUserLoading = true;
      let user = AdminUserPaginationModule.maps.id.get(this.value.userId)[0] ?? null;
      if (!user) {
        user = (await AdminUserPaginationModule.getSelectedUser(this.value.userId)) ?? null;
      }

      this.user = user;
    } catch (e) {
      handleError(e);
    } finally {
      this.isUserLoading = false;
    }
  }

  async loadParter() {
    if (!this.value.partnerId) {
      return;
    }

    try {
      this.isPartnerLoading = true;
      let partner = PartnerModule.partners.find(p => p.id === this.value.partnerId) ?? null;
      if (!partner) {
        partner = (await PartnerModule.getPartnerById(this.value.partnerId)) ?? null;
      }

      this.partner = partner;
    } catch (e) {
      handleError(e);
    } finally {
      this.isPartnerLoading = false;
    }
  }
}
