





































import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import {
  ThgOperationIdViewModelGen,
  ThgThgControllerUpdateStatusAsyncParamsGen,
  ThgUpdateThgStatusOfThgIdsDtoGen
} from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { MeterReadingModule } from "@/store/modules/meter-reading.store";
import { ThgModule } from "@/store/modules/thg.store";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import { IThg } from "@/models/thg.entity";

@Component({
  components: { ConfirmActionDialog }
})
export default class ThgBatchUpdateSelectedStatusDialog extends DarkModeHighlightMixin {
  @Prop()
  thgs!: IThg[];

  @Prop()
  isLoadingUpdate!: boolean;

  @Prop()
  type!: BatchTypeEnum;

  loading = false;

  isDialogActive = false;
  userNotification = false;

  status = ThgStatusEnum.CREATED;

  isValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  /**
   * All available status
   */
  items = Object.keys(ThgStatusEnum).map(k => ThgStatusEnum[k as any]);

  toOperationDetail(operationId: string) {
    this.$router.push({ name: "OperationView", params: { operationId: operationId } });
  }

  /**
   * Update status of selected thgs
   */
  async updateStatus() {
    try {
      this.isLoadingUpdate = true;

      const data: ThgUpdateThgStatusOfThgIdsDtoGen = {
        status: this.status,
        thgIds: this.thgs.map(t => t.id)
      };
      const query: ThgThgControllerUpdateStatusAsyncParamsGen = {
        userNotification: this.userNotification
      };
      let operation: ThgOperationIdViewModelGen;
      if (this.type === BatchTypeEnum.vehicle) {
        operation = await ThgModule.updateStatus({ data, query });
      } else {
        operation = await MeterReadingModule.updateStatus({ data, query });
      }
      this.$toast.success(this.$t("components.thg.thgBillingCsvSelection.operationDetail"), {
        onClick: () => this.toOperationDetail(operation.operationId)
      });
    } catch (e) {
      this.$log.error(e);
      this.$toast((e as any).message);
    } finally {
      this.isLoadingUpdate = false;
      this.isDialogActive = false;
    }
  }
}
