var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('thg-billing-list', {
          attrs: {
            "loading": _vm.getModule().isLoading && _vm.getModule().paginationList.length === 0,
            "isLoadingDetail": _vm.isLoadingDetail,
            "isLoadingMore": _vm.isLoadingMore,
            "getModule": _vm.getModule
          },
          on: {
            "set": _vm.set,
            "loadMore": _vm.loadMore,
            "refresh": _vm.refresh
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_vm.billingDataLoader ? _c('thg-billing-number-detail', {
          key: _vm.billingNumber,
          attrs: {
            "billingDocuments": _vm.billingDataLoader.documents
          }
        }) : _vm._e()];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }