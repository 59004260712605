import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { ThgThgMeterReadingViewModelGen, ThgThgViewModelGen } from "@/services/thg/v1/data-contracts";
import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { ActivePrice } from "@/store/models/thg/active-price";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";
import Vue from "vue";

export function thgTotalKwhHelper(
  batchType: BatchTypeEnum,
  thgList: (ThgThgViewModelGen | ThgThgMeterReadingViewModelGen)[]
) {
  try {
    let amount = 0;
    if (batchType == BatchTypeEnum.meterReading) {
      for (const meterReading of thgList) {
        amount = amount + (meterReading?.meterReading?.amountInKwh || 0);
      }
      return (amount / 1000).toLocaleString() + " MWh";
    }

    if (batchType == BatchTypeEnum.vehicle) {
      for (const vehicle of thgList) {
        const countryCode = vehicle.countryCode || CountryCodeEnum.germany;
        const activePrice = new ActivePrice([], countryCode as CountryCodeEnum);
        const amountInMwh = activePrice.mwhPerVehicleType(
          ((vehicle as ThgThgViewModelGen)?.registration?.vehicleClass || "") as ThgVehicleTypes
        );
        amount = amount + amountInMwh;
      }
      return amount.toLocaleString() + " MWh";
    }
  } catch (error) {
    Vue.$log.error("Error calculation MWh Amount");
  }

  return "";
}
