




















import { Component, Vue, Prop } from "vue-property-decorator";
import MDetailForm from "./mmmint/MDetailForm.vue";
import { setNestedObjectValues } from "@/lib/objectPath-helper";
import { IFormableClass, MDetailFormConfig } from "@/lib/formable";
import SideCard from "./SideCard.vue";
import { UpdateDto } from "@/lib/utility/data/update-dto.interface";

@Component({
  components: {
    SideCard,
    MDetailForm
  },
  filters: {}
})
export default class UpdateForm<T> extends Vue {
  /**
   * Initial value
   */
  @Prop()
  value!: T;

  /**
   * Class to update the item
   */
  @Prop()
  dto!: { new (value: T): UpdateDto<T> } & IFormableClass;

  model = new this.dto(this.value);

  isLoading = false;

  get config() {
    return this.dto.formables;
  }

  async mounted() {
    await this.abortChanges();
  }

  /**
   * Syncs the changes from the form to the value
   * and show the update confirmation dialog.
   */
  async syncChanges() {
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      setNestedObjectValues(this.model, key, config.getConfigValueByKey(key));
    }

    this.isLoading = true;
    await this.model.update();
    this.$toast.success("👍");
    this.isLoading = false;
  }

  async abortChanges() {
    const config = new MDetailFormConfig(this.config);
    const configKeys = config.getConfigKeys();

    for (const key of configKeys) {
      config.configValueByKey = { key: key, value: this.model[key] };
    }
  }
}
