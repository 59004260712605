var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "pdfContainer",
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionItemPdfResult.title'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.billing ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "disabled": _vm.locked
                },
                on: {
                  "click": function click($event) {
                    _vm.uploadDialog = true;
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }], null, false, 462857177)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionItemPdfResult.uploadPdfDialog")))])]) : _vm._e(), _vm.billing && _vm.billing.type !== _vm.BillingTypeEnum.INVOICE ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "loading": _vm.pdfLoading,
                  "disabled": _vm.locked
                },
                on: {
                  "click": function click($event) {
                    _vm.isRegenerateDialogActive = true;
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-refresh")])], 1)];
            }
          }], null, false, 2640197939)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionItemPdfResult.regenerate")))])]) : _vm._e(), _c('confirm-action-dialog', {
          attrs: {
            "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
            "isDialogActive": _vm.isRegenerateDialogActive,
            "rightLoading": _vm.pdfLoading,
            "supressKeyboardCancel": true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isRegenerateDialogActive = $event;
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isRegenerateDialogActive = $event;
            },
            "leftClick": function leftClick($event) {
              _vm.isRegenerateDialogActive = false;
            },
            "rightClick": _vm.regenerate
          }
        }, [_c('v-text-field', {
          attrs: {
            "rounded": "",
            "outlined": "",
            "type": "date",
            "label": "PDF Date?"
          },
          model: {
            value: _vm.dateString,
            callback: function callback($$v) {
              _vm.dateString = $$v;
            },
            expression: "dateString"
          }
        }), _vm.billing.type === _vm.BillingTypeEnum.CREDIT_PARTNER ? _c('div', _vm._l(_vm.partnerCommissions, function (partnerCommission) {
          return _c('div', {
            key: partnerCommission.year
          }, [_c('v-text-field', {
            attrs: {
              "rounded": "",
              "outlined": "",
              "type": "number",
              "label": "Commission ".concat(partnerCommission.year)
            },
            model: {
              value: partnerCommission.partnerCommission,
              callback: function callback($$v) {
                _vm.$set(partnerCommission, "partnerCommission", $$v);
              },
              expression: "partnerCommission.partnerCommission"
            }
          })], 1);
        }), 0) : _vm._e(), _vm.billing.type === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION ? _c('v-text-field', {
          attrs: {
            "rounded": "",
            "outlined": "",
            "type": "number",
            "label": "pricePerKwh"
          },
          model: {
            value: _vm._pricePerKwh,
            callback: function callback($$v) {
              _vm._pricePerKwh = $$v;
            },
            expression: "_pricePerKwh"
          }
        }) : _vm._e()], 1), _vm.billing ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('v-btn', _vm._g({
                staticClass: "mr-4",
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.locked = !_vm.locked;
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.locked ? "mdi-lock-outline" : "mdi-lock-open"))])], 1)];
            }
          }], null, false, 1644607086)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionBatchData.unlock")) + " ")])]) : _vm._e(), _vm.billing && _vm.pdf ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.openPdf
                }
              }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, false, 3885161803)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerFormDetail.tooltip.toDetailView")) + " ")])]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [_vm.pdf ? _c('iframe', {
    staticClass: "pdf",
    style: "min-height:740px; border: none; width: ".concat(_vm.innerWidth),
    attrs: {
      "title": "pdf",
      "src": _vm.pdf
    }
  }) : _c('latest-entries-card-empty')], 1), _c('v-dialog', {
    attrs: {
      "max-width": "600"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.uploadDialog,
      callback: function callback($$v) {
        _vm.uploadDialog = $$v;
      },
      expression: "uploadDialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelectionItemBillingResult.uploadPdfDialog")) + " ")]), _c('v-card-actions', [_c('input', {
    ref: "uploader",
    attrs: {
      "name": "file",
      "type": "file",
      "accept": "application/pdf, .pdf"
    },
    on: {
      "change": _vm.onFileInput
    }
  })]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.uploadDialog = false;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelectionItemPdfResult.abort")) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "loading": _vm.uploading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingSelectionItemPdfResult.uploadPdfDialog")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }