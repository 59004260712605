












import ImageDialog from "@/components/utility/ImageDialog.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component } from "vue-property-decorator";
import ThgCropImage from "./ThgCropImage.vue";
import ThgCroppedImage from "./ThgCroppedImage.vue";
import ThgCroppedImageStylingOptions from "./ThgCroppedImageStylingOptions.vue";

@Component({
  components: {
    ImageDialog,
    ThgCropImage,
    ThgCroppedImage,
    ThgCroppedImageStylingOptions
  }
})
export default class ThgEditImage extends DarkModeHighlightMixin {
  height = "";
  resize() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.height = `${window.innerHeight - 300}px`;
    } else {
      this.height = `${30}%`;
    }
  }
  mounted() {
    this.resize();
  }
}
