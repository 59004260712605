





















































































































































































import { ThgCroppingIsCompleteCheck } from "@/lib/thg/ThgCroppingIsCompleteCheck";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import ImageCropMixin from "@/mixins/ImageCropMixin.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IThg } from "@/models/thg.entity";
import { ThgEditThgImageDataDtoGen } from "@/services/thg/v1/data-contracts";
import { ClipperModule } from "@/store/modules/clipper.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import debounce from "debounce";
import { mixins } from "vue-class-component";
import { Component, Prop, Watch } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ContextMenu from "../utility/ContextMenu.vue";
import Tooltip from "../utility/tooltip.vue";
import ThgCropImage from "./ThgCropImage.vue";
import ThgCropImageActions from "./ThgCropImageActions.vue";
import ThgCroppedImage from "./ThgCroppedImage.vue";
import ThgCroppedImageStylingOptions from "./ThgCroppedImageStylingOptions.vue";
import ThgEditImage from "./ThgEditImage.vue";
import ThgWorkItemRejectionCard from "./ThgWorkItemRejectionCard.vue";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    ConfirmActionDialog,
    ThgEditImage,
    ThgCropImage,
    ThgCroppedImage,
    ThgCroppedImageStylingOptions,
    ThgCropImageActions,
    ThgWorkItemRejectionCard,
    Card,
    Tooltip,
    ContextMenu
  }
})
export default class ThgEditImagesDialog extends mixins(DarkModeHighlightMixin, ImageCropMixin) {
  @Prop()
  thgs!: IThg[];

  thgsCopy: IThg[] = [];

  thgCroppingIsCompleteCheck!: ThgCroppingIsCompleteCheck;

  @Watch("thgs", { deep: true })
  setData() {
    this.thgsCopy.splice(0, this.thgsCopy.length, ...this.thgs);
    if (this.thgsCopy.length > 0 && !this.selectedThg) {
      this.selectThg(this.filteredThgsCopy[0]);
    }
  }

  @Watch("selectedThg", { deep: true })
  setSelectedThgInList() {
    if (this.selectedThg) {
      // update thg in list
      const index = this.thgsCopy.findIndex(t => t.id === this.selectedThg?.id);
      this.thgsCopy.splice(index, 1, this.selectedThg);

      // select next thg if current thg is not in filtered list anymore
      if (!this.filteredThgsCopy.find(t => t.id === this.selectedThg?.id)) {
        if (this.filteredThgsCopy.length > 0) {
          this.selectThg(this.filteredThgsCopy[0]);
        } else {
          this.isNoFrontFilter = false;
          this.isNoImageFilter = false;
          this.isNoCropFilter = false;
        }
      }
    }
  }

  isEditDialogActive = false;
  height = 0;
  rejectMenu = false;
  selectedThgId: string | null = "";
  selectedThgPartnerId: string | null = "";
  selectedThg: IThg | null = null;
  selectedUser: IAdminUser | null = null;
  newWorkitem = true;
  userNotification = true;
  loading = true;
  savingEdits = true;
  imageLabels: ReportImageType[] = [];
  debounceOnRotate = debounce(this.onCrop, 400, false);

  isNoFrontFilter = false;
  isNoImageFilter = false;
  isNoCropFilter = false;

  matchesFilter(t: IThg) {
    if (!this.isNoFrontFilter && !this.isNoImageFilter && !this.isNoCropFilter) return true;

    // show if filter is active and condition is met
    if (this.isNoFrontFilter && !this.thgCroppingIsCompleteCheck.hasRegistrationFront(t)) return true;
    if (this.isNoImageFilter && this.thgCroppingIsCompleteCheck.getHasNoImages(t)) return true;
    if (this.isNoCropFilter && !this.thgCroppingIsCompleteCheck.getHasImageEdits(t)) return true;

    // hide if no condition is met
    return false;
  }

  get noFrontCount() {
    return this.thgsCopy.filter(t => !this.thgCroppingIsCompleteCheck.hasRegistrationFront(t)).length;
  }

  get noImageCount() {
    return this.thgsCopy.filter(t => this.thgCroppingIsCompleteCheck.getHasNoImages(t)).length;
  }

  get noCropCount() {
    return this.thgsCopy.filter(t => !this.thgCroppingIsCompleteCheck.getHasImageEdits(t)).length;
  }

  get filteredThgsCopy() {
    return this.thgsCopy.filter(this.matchesFilter);
  }

  get currentThgIndex() {
    let index = this.filteredThgsCopy.findIndex(t => {
      return t.id === this.selectedThgId;
    });

    if (index === -1 && this.filteredThgsCopy.length) {
      index = 0;
    }
    return index;
  }
  get title() {
    if (this.loading) {
      return "...";
    }

    const vehicleClass = this.selectedThg?.registration?.vehicleClass || "";

    return `${this.selectedThg?.numberplate || this.selectedThg?.registration.numberplate}
     ${this.selectedThg?.registration.identificationnumber} (${this.selectedThg?.year}) - ${vehicleClass}.
    Image ${this.currentThgImageIndex + 1}/${this.currentThgImages?.length}`;
  }
  get subtitle() {
    if (this.loading) {
      return "...";
    }

    return `ThgId: ${this.selectedThgId} PartnerId: ${this.selectedThgPartnerId}`;
  }
  get isCropActive() {
    return ClipperModule.isCroppingActive;
  }
  get isCroppingInitiallyActive() {
    return ClipperModule.isCroppingInitiallyActive;
  }
  get currentThgImageIndex() {
    return ClipperModule.currentImageIndex;
  }
  get leftButtonDisabled() {
    return this.currentThgIndex === 0;
  }
  get rightButtonDisabled() {
    return this.currentThgIndex == this.filteredThgsCopy.length - 1;
  }
  get dialogLeftButtonHide() {
    return this.filteredThgsCopy.length <= 1;
  }
  get currentThgImages() {
    return this.selectedThg?.registrationImages;
  }
  get selectedImageIndex() {
    return ClipperModule.currentImageIndex;
  }

  async mounted() {
    this.setData();
    this.selectThg(this.filteredThgsCopy[0]);

    this.thgCroppingIsCompleteCheck = new ThgCroppingIsCompleteCheck(this.filteredThgsCopy);

    ClipperModule.setIsCroppingInitiallyActive(true);
    document.addEventListener("keyup", this.handleKey);
  }
  beforeUnmount() {
    document.removeEventListener("keyup", this.handleKey);
  }

  handleKey(event: KeyboardEvent) {
    if (this.loading) {
      return;
    }

    if (event.key === "ArrowLeft" || event.key === "a") {
      this.previousThg();
    }

    if (event.key === "ArrowRight" || event.key === "d") {
      this.nextThg();
    }
  }
  isSelected(thg: IThg) {
    return this.selectedThg?.id === thg.id;
  }

  hasRegistrationFront(thg: IThg) {
    for (const registrationImage of thg.registrationImages) {
      if ((registrationImage.type as any) === ReportImageType.registration) {
        return true;
      }
    }
    return false;
  }
  previousThg() {
    if (this.currentThgIndex !== 0) {
      this.selectThg(this.filteredThgsCopy[this.currentThgIndex - 1]);
    }
  }
  nextThg() {
    if (this.currentThgIndex !== this.filteredThgsCopy.length - 1) {
      this.selectThg(this.filteredThgsCopy[this.currentThgIndex + 1]);
    }
  }
  resize() {
    this.height = window.innerHeight;
  }

  async onCrop() {
    try {
      this.loading = true;
      const data: ThgEditThgImageDataDtoGen = { edits: ClipperModule.edits, type: this.imageLabels };
      const partnerId = this.selectedThgPartnerId;
      const thgId = this.selectedThgId;
      if (thgId && partnerId) {
        const thg = await ThgPortalModule.editSelectedRegistrationImages({ thgId, partnerId, data });
        await this.setSelectedThg(thg);
        this.filteredThgsCopy.splice(this.currentThgIndex, 1, thg);
      }
      ClipperModule.setPreviousEdit({
        crop: ClipperModule.crop,
        rotation: ClipperModule.rotation
      });
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.$log.debug("Cropped");
      this.loading = false;
    }
  }
  selectThg(thg: IThg) {
    this.selectedThgId = thg.id;
    this.selectedThgPartnerId = thg.partnerId;
  }
  @Watch("selectedThgId")
  async loadSelectedThg(newValue?: string, oldValue?: string) {
    if (newValue && oldValue && newValue === oldValue) {
      return;
    }

    this.$log.debug("loadSelectedThg", this.selectedThgId, this.selectedThgPartnerId);
    await this.setSelectedThg(null);
    this.loading = true;
    if (this.selectedThgId && this.selectedThgPartnerId) {
      try {
        this.resize();

        const thg = await ThgPortalModule.getSelectedThgByPartnerID({
          thgId: this.selectedThgId,
          partnerId: this.selectedThgPartnerId
        });
        this.imageLabels.splice(
          0,
          this.imageLabels.length,
          ...((thg.registrationImages.map(r => r.type) as any) as ReportImageType[])
        );
        ClipperModule.initializeForThg(thg);
        if (
          this.isCroppingInitiallyActive &&
          thg &&
          !this.thgCroppingIsCompleteCheck.getHasImageEdits(thg) &&
          !this.thgCroppingIsCompleteCheck.getHasNoImages(thg)
        ) {
          ClipperModule.setIsCroppingActive(true);
        } else {
          ClipperModule.setIsCroppingActive(false);
        }
        await this.setSelectedThg(thg);
      } catch (e) {
        this.$log.error(e);
        this.$toast.error((e as any).message);
      } finally {
        this.loading = false;
      }
    }
  }

  async setSelectedThg(thg: IThg | null) {
    this.selectedThg = thg;
    ThgPortalModule.setThgWorkItem(thg);
    if (thg) {
      this.selectedUser = await AdminUserPaginationModule.getSelectedUser(thg?.userId);
    }
  }

  get possibleLabels() {
    return [ReportImageType.registration, ReportImageType.registrationBack];
  }

  get currentLabel() {
    return this.imageLabels[this.selectedImageIndex];
  }

  set currentLabel(label: ReportImageType) {
    this.imageLabels.splice(this.selectedImageIndex, 1, label);
  }

  label(label: ReportImageType) {
    if (this.possibleLabels.includes[label]) {
      return this.$t(`components.thg.ThgQuoteWorkitemImageCard.${label}`);
    }

    return label;
  }
}
