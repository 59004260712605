
































































import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgUpdateThgAdminDtoGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";
import { IThgImpact } from "@/views/thg/interface/ThgImpact.interface";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    ThgQuoteWorkitemRegistrationCard
  }
})
export default class ThgQuoteUpdateDialog extends DarkModeHighlightMixin {
  isLoading = false;
  dialog = false;
  valid = false;

  title = "components.thg.ThgQuoteUpdateDialog.title";
  btnText = "components.thg.ThgQuoteUpdateDialog.btnText";

  updateThgDto: ThgUpdateThgAdminDtoGen = {
    numberplate: "",
    impactType: ImpactTypeEnum.payout,
    impactFactor: 0,
    code: ""
  };

  activateImpactItem: IThgImpact = ThgCreateModule.impactTypeItems[0];
  ImpactTypeEnum = ImpactTypeEnum;

  get activeImpactTypeItems() {
    return ThgCreateModule.impactTypeItems;
  }

  get loading() {
    return this.isLoading;
  }

  get partners() {
    return PartnerModule.partners;
  }

  get showImpactFactor() {
    return ThgCreateModule.impactTypeItems.find(iti => iti.impactType === this.activateImpactItem.impactType)
      ?.showImpactFactorSlider;
  }

  get baseFactor() {
    return ThgCreateModule.impactTypeItems.find(iti => iti.impactType === this.activateImpactItem.impactType)
      ?.baseImpactFactor;
  }

  async transfer() {
    this.isLoading = true;
    try {
      this.updateThgDto.impactType = this.activateImpactItem.impactType;

      // Set impact type with no slide to base impact value
      if (!this.showImpactFactor) {
        this.updateThgDto.impactFactor = this.baseFactor;
      }

      await ThgPortalModule.updateThg(this.updateThgDto);
    } catch (error) {
      handleError(error);
    } finally {
      this.isLoading = false;
      this.dialog = false;
    }
  }

  async initialize() {
    this.isLoading = true;
    this.updateThgDto.numberplate = deepCopy(ThgPortalModule.selectedThg.numberplate);
    this.updateThgDto.impactType = deepCopy(ThgPortalModule.selectedThg.impactType);
    this.updateThgDto.impactFactor = deepCopy(ThgPortalModule.selectedThg.impactFactor);
    this.updateThgDto.code = deepCopy(ThgPortalModule.selectedThg.code);

    this.activateImpactItem = ThgCreateModule.impactTypeItems.filter(
      item => item.impactType === this.updateThgDto.impactType
    )[0];

    this.isLoading = false;
  }

  close() {
    this.dialog = false;
  }

  /**
   * Rules
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get impactFactorRules() {
    const rules = [];

    rules.push(requiredRule());

    rules.push(numberLowerRule(100));
    rules.push(numberHigherRule(0));

    return rules;
  }
}
