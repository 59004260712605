









































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import ThgChargingStationInformationCard from "@/components/thg/ThgChargingStationInformationCard.vue";
import ThgMeterReadingCreateDialog from "@/components/thg/ThgMeterReadingCreateDialog.vue";
import ThgMeterReadingDetailTable from "@/components/thg/ThgMeterReadingDetailTable.vue";
import Card from "@/components/utility/Card.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { HttpException } from "@/lib/exceptions/http";
import { detailedDate } from "@/lib/utility/date-helper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { CreateMeterReadingAdmin } from "@/store/models/thg/meter-reading";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { Component } from "vue-property-decorator";
import { PartnerModule } from "@/store/modules/partner";

@Component({
  components: {
    LayoutSimple,
    Card,
    ThgChargingStationInformationCard,
    CustomerContactCard,
    ThgMeterReadingDetailTable,
    ThgMeterReadingCreateDialog
  }
})
export default class ThgChargingStationDetailView extends DarkModeHighlightMixin {
  loading = false;

  async mounted() {
    this.loading = true;
    try {
      const chargingStation = await ThgPortalModule.getSelectedChargingStation(this.$route.params.chargingStationId);
      await ThgPortalModule.getMeterReadingForChargingStation(this.$route.params.chargingStationId);
      await AdminUserPaginationModule.getSelectedUser(chargingStation.userId);
    } catch {
      this.$toast.error("Fehler beim Laden der Thg-Quote");
    } finally {
      this.loading = false;
    }
  }

  get chargingStation() {
    return ThgPortalModule.selectedChargingStation;
  }

  get meterReadings() {
    return ThgPortalModule.meterReadingForChargingStation;
  }

  get selectedUser() {
    return AdminUserPaginationModule.maps.id.get(this.chargingStation.userId)[0];
  }

  get fullname() {
    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  get createdDate() {
    return detailedDate(this.chargingStation.timestamp["created"]);
  }

  get partner() {
    return PartnerModule.partner;
  }

  async createMeterReading(data: CreateMeterReadingAdmin) {
    this.$log.debug(data);
    this.loading = true;

    try {
      const partnerId = this.partner.id;
      const chargingStationId = ThgPortalModule.selectedChargingStation.id;
      data.userId = this.selectedUser?._id;

      await ThgPortalModule.createMeterReading({ partnerId, chargingStationId, dto: data });

      await ThgPortalModule.getMeterReadingForChargingStation(chargingStationId);

      this.$toast.success("👍");
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
