













import BatchDetailCard from "@/components/thg/BatchDetailCard.vue";
import BatchListCard from "@/components/thg/BatchListCard.vue";
import Logo from "@/components/utility/Logo.vue";
import TheLayoutPortalSplit from "@/layouts/TheLayoutPortalSplit.vue";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { Component } from "vue-property-decorator";
import { BatchModule } from "@/store/modules/batch.store";

@Component({
  components: {
    TheLayoutPortalSplit,
    Logo,
    BatchListCard,
    BatchDetailCard
  }
})
export default class ThgBatch extends PartnerFallbackMixin {
  loadingSelectedBatch = false;

  get loadingAll() {
    return BatchModule.isLoading;
  }

  set loadingAll(loading: boolean) {
    BatchModule.setIsLoading(loading);
  }

  get getloadingSelectedBatch() {
    return this.loadingSelectedBatch;
  }

  setloadingSelectedBatch(isLoading: any) {
    this.loadingSelectedBatch = isLoading;
  }

  async mounted() {
    this.loadingAll = true;
    this.loadingSelectedBatch = true;

    try {
      await BatchModule.findAll();
      this.loadingAll = false;
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("views.ThgBatchView.errorBatchList").toString());
    } finally {
      this.loadingAll = false;
    }

    if (BatchModule.allBatches.length === 0) {
      this.loadingSelectedBatch = false;
      return;
    }

    try {
      await BatchModule.find(BatchModule.allBatches[0].id);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("views.ThgBatchView.errorDetail").toString());
    }

    try {
      await BatchModule.getPartnerDistribution(BatchModule.allBatches[0].id);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("views.ThgBatchView.errorPartnerDistribution").toString());
    } finally {
      this.loadingSelectedBatch = false;
    }
  }
}
