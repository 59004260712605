import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import batchService from "@/services/thg/services/batchService";
import thgService from "@/services/thg/services/thgService";
import {
  ThgBatchDetailViewmodelGen,
  ThgBatchDetailViewModelWithOperationGen,
  ThgBatchDtoGen,
  ThgBatchOperationViewModelGen,
  ThgBatchPartnerDistributionViewmodelGen,
  ThgBatchViewmodelGen,
  ThgBatchViewmodelItemGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ThgStatusEnum } from "../enum/thg/thg-status.enum";
import {
  IBatchUpdateItems,
  IBatchUpdatePricing,
  IBatchUpdateStatus
} from "../interface/thg/batch-update-items.interface";
import { IThg, Thg } from "@/models/thg.entity";

@Module({
  dynamic: true,
  namespaced: true,
  name: "batch",
  store
})
export class BatchStore extends VuexModule {
  /**
   * Select Data from date
   */
  private _dateRange: string[] = [
    new Date().toISOString(),
    new Date(new Date().setDate(new Date().getDate() - 29)).toISOString()
  ];

  get dateRange(): string[] {
    return this._dateRange;
  }

  get from(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.min.apply(null, dates)).setUTCHours(0, 0, 0, 0)).toISOString();
    return tempo;
  }

  get to(): string {
    const dates: number[] = [];
    for (const date of this.dateRange) {
      dates.push(new Date(date).getTime());
    }
    const tempo = new Date(new Date(Math.max.apply(null, dates)).setUTCHours(20, 59, 59, 999)).toISOString();
    return tempo;
  }

  /**
   * Section helps to select the thg you want to batch
   */
  private _allThgsToBatch: IThg[] = [];

  get allThgsToBatch(): IThg[] {
    return this._allThgsToBatch;
  }

  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  /**
   * All Batches
   */
  private _allBatches: ThgBatchViewmodelGen = { data: [] };

  get allBatches(): ThgBatchViewmodelItemGen[] {
    return this._allBatches.data;
  }

  /**
   * Selected Batch
   */
  private _selectedBatch: ThgBatchDetailViewmodelGen = {
    batch: {
      type: BatchTypeEnum.meterReading,
      id: "",
      name: "",
      itemIds: [],
      status: ThgStatusEnum.CREATED,
      changeHistory: [],
      timestamp: { created: "", lastModified: "", modified: [] }
    },
    thgList: []
  };

  get selectedBatch(): ThgBatchDetailViewmodelGen {
    return this._selectedBatch;
  }

  /**
   * Partner Distribution
   */
  private _partnerDistribution: ThgBatchPartnerDistributionViewmodelGen = { data: [], chartData: [] };

  get partnerDistribution(): ThgBatchPartnerDistributionViewmodelGen {
    return this._partnerDistribution;
  }

  @Mutation
  _mutateDateRange(dateRange: string[]) {
    this._dateRange = dateRange;
  }

  @Action
  setDateRange(dateRange: string[]) {
    this.context.commit("_mutateDateRange", dateRange);
  }

  @Mutation
  _mutateAllThgs(allThgs: IThg[]) {
    this._allThgsToBatch = allThgs;
  }

  @Action
  async getThgsByStatusAndDateRange(status: ThgStatusEnum): Promise<IThg[]> {
    const response = await thgService.getThgsByStatusAndDateRange(status, false, this.from, this.to);

    const thgs = response.map(thg => new Thg(thg));
    this.context.commit("_mutateAllThgs", thgs);

    return thgs;
  }

  @Mutation
  _mutateIsLoading(isLaoding: boolean) {
    this._isLoading = isLaoding;
  }

  @Action
  setIsLoading(isLaoding: boolean) {
    this.context.commit("_mutateIsLoading", isLaoding);
  }

  @Mutation
  _mutateAllBatches(allThgs: ThgBatchViewmodelGen) {
    this._allBatches = allThgs;
  }

  @Action
  async findAll(): Promise<ThgBatchViewmodelGen> {
    const response = await batchService.findAll();

    response.data.sort(function(a: any, b: any) {
      return a.timestamp?.created > b.timestamp?.created ? -1 : a.timestamp?.created > b.timestamp?.created ? 1 : 0;
    });

    this.context.commit("_mutateAllBatches", response);

    return response;
  }

  @Mutation
  _mutateSelectedBatch(allThgs: ThgBatchDetailViewmodelGen) {
    this._selectedBatch = allThgs;
    const index = this._allBatches.data.findIndex(b => b.id === allThgs.batch.id);
    if (index > -1) {
      this._allBatches.data.splice(index, 1, allThgs.batch);
    }
  }

  @Mutation
  _mutateSelectedBatchWithoutThgs(batch: ThgBatchViewmodelItemGen) {
    this._selectedBatch = { ...this._selectedBatch, batch };
  }

  @Action
  async find(id: string): Promise<ThgBatchDetailViewmodelGen> {
    const response = await batchService.findbyId(id);
    this.context.commit("_mutateSelectedBatch", response);

    return response;
  }

  @Action
  async create(dto: ThgBatchDtoGen): Promise<ThgBatchDetailViewmodelGen> {
    const response = await batchService.create(dto);
    this.context.commit("_mutateSelectedBatch", response);

    return response;
  }

  @Action
  async updateItems(dto: IBatchUpdateItems): Promise<ThgBatchDetailViewModelWithOperationGen> {
    const response = await batchService.updateItems(dto.id, dto.dto);

    this.context.commit("_mutateSelectedBatch", response.batchDetail);

    return response;
  }

  @Action
  async updatePricing(dto: IBatchUpdatePricing): Promise<ThgBatchDetailViewmodelGen> {
    const response = await batchService.updatePriceInformation(dto.id, dto.dto);
    this.context.commit("_mutateSelectedBatch", response);

    return response;
  }

  @Action
  async updateStatus(dto: IBatchUpdateStatus): Promise<ThgBatchDetailViewmodelGen> {
    const response = await batchService.updateStatus(this.selectedBatch.batch.id, dto.noUserNotification, {
      status: dto.status
    });
    this.context.commit("_mutateSelectedBatch", response);

    return response;
  }

  @Action
  async updateStatusAsync(dto: IBatchUpdateStatus): Promise<ThgBatchOperationViewModelGen> {
    const response = await batchService.updateStatusAsync(this.selectedBatch.batch.id, dto.noUserNotification, {
      status: dto.status
    });
    this.context.commit("_mutateSelectedBatchWithoutThgs", response?.batch);

    return response;
  }

  @Mutation
  _mutatePartnerDistribution(partnerDistribution: ThgBatchPartnerDistributionViewmodelGen) {
    this._partnerDistribution = partnerDistribution;
  }

  @Action
  async getPartnerDistribution(id: string): Promise<ThgBatchPartnerDistributionViewmodelGen> {
    const response = await batchService.partnerDistribution(id);
    this.context.commit("_mutatePartnerDistribution", response);

    return response;
  }
}

export const BatchModule = getModule(BatchStore);
