











































import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import ThgBillingCreditorSelectionUserList from "./ThgBillingCreditorSelectionUserList.vue";
import ThgBillingPlausibilityCheckDialog from "./ThgBillingPlausibilityCheckDialog.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import { CounterModule } from "@/store/modules/counter.store";
import { ThgAccountDtoGen } from "@/services/thg/v1/data-contracts";
import { AccountModule } from "@/store/modules/account.store";
import { ConflictException } from "@/lib/exceptions/http";
import ThgBillingCreditorSelectionPartnerList from "./ThgBillingCreditorSelectionPartnerList.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    Debug,
    ThgBillingCreditorSelectionUserList,
    ThgBillingCreditorSelectionPartnerList,
    ThgBillingPlausibilityCheckDialog,
    TableWrapper,
    ConfirmActionDialog
  }
})
export default class ThgBillingCreditorCreateDialog extends DarkModeHighlightMixin {
  @Prop()
  isDialogActive!: boolean;

  userOrPartner = 0;
  accountDto: ThgAccountDtoGen = {
    userId: undefined,
    partnerId: undefined
  };
  creatingCreditor = false;

  get accountNumber(): number | undefined {
    return CounterModule.counterSequence;
  }

  get dialogActive() {
    return this.isDialogActive;
  }
  set dialogActive(value: boolean) {
    this.$emit("update:isDialogActive", value);
  }

  counterLoading = false;

  counterUpdating = false;

  loadingDialog = false;

  verifyData = true;

  async mounted() {
    try {
      await CounterModule.getCounterController("account");
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.loadingDialog = false;
    }
  }

  async createCreditor() {
    try {
      this.creatingCreditor = true;
      await AccountModule.createAccount({ query: { force: !this.verifyData }, dto: this.accountDto });
      this.$toast.success("Success");
    } catch (e) {
      this.$log.error(e);
      if (e instanceof ConflictException) {
        this.$toast.error(this.$t("components.thg.thgCreditorCsvSelection.conflict"));
      } else {
        this.$toast.error((e as any).message);
      }
    } finally {
      this.dialogActive = false;
      this.creatingCreditor = false;
    }
  }
}
