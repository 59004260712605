










import ThgBillingNumberPartnerDetail from "@/components/thg/ThgBillingNumberPartnerDetail.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { handleError } from "@/lib/utility/handleError";
import { PartnerBillingModule } from "@/store/modules/partner-billing.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    ThgBillingNumberPartnerDetail
  }
})
export default class ThgBillingNumberPartnerDetailView extends Vue {
  isLoadingDetail = true;

  get billingNumber() {
    return this.$route.params.billingNumber;
  }

  async mounted() {
    this.isLoadingDetail = false;

    await PartnerBillingModule.loadDocument({
      partnerId: this.$route.params.partnerId,
      billingNumber: parseInt(this.billingNumber)
    }).catch(handleError);

    this.isLoadingDetail = false;
  }
}
