var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm._title,
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-spacer'), _vm.isAdmin ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "loading": _vm.bankingLoading,
                  "disabled": _vm.locked
                },
                on: {
                  "click": _vm.createNew
                }
              }, on), [_vm.locked ? _c('v-icon', [_vm._v("mdi-plus")]) : _c('v-icon', {
                staticClass: "unsavedData",
                attrs: {
                  "color": "red",
                  "x-large": ""
                }
              }, [_vm._v("mdi-plus")])], 1)];
            }
          }], null, false, 3169719608)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionItemPayoutData.createNew")) + " ")])]) : _vm._e(), _vm.isAdmin ? _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.locked = !_vm.locked;
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.locked ? "mdi-lock-outline" : "mdi-lock-open"))])], 1)];
            }
          }], null, false, 3431616200)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionBatchData.unlock")) + " ")])]) : _vm._e()];
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [!_vm.bankingLoading ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionBankingData.bank'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.bankingData.bank ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 187013800),
    model: {
      value: _vm.bankingData.bank,
      callback: function callback($$v) {
        _vm.$set(_vm.bankingData, "bank", $$v);
      },
      expression: "bankingData.bank"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionBankingData.iban'),
      "rules": _vm.ibanRules,
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.bankingData.iban ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 327373674),
    model: {
      value: _vm.bankingData.iban,
      callback: function callback($$v) {
        _vm.$set(_vm.bankingData, "iban", $$v);
      },
      expression: "bankingData.iban"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionBankingData.name'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.bankingData.name ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 4273390185),
    model: {
      value: _vm.bankingData.name,
      callback: function callback($$v) {
        _vm.$set(_vm.bankingData, "name", $$v);
      },
      expression: "bankingData.name"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.bankingData
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }