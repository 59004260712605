
















































































































































































































































































import CustomerContactCard from "@/components/partner/CustomerContactCard.vue";
import UserAdministrationDetailPermissions from "@/components/partner/UserAdministrationDetailPermissions.vue";
import UserAdministrationDetailRoles from "@/components/partner/UserAdministrationDetailRoles.vue";
import ThgQuoteWorkitemRegistrationCard from "@/components/thg/ThgQuoteWorkitemRegistrationCard.vue";
import ImageDialog from "@/components/utility/ImageDialog.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { MAX_LAT, MAX_LNG, MIN_LAT, MIN_LNG } from "@/lib/rules/geoRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { calculateDayDifference, simpleDate } from "@/lib/utility/date-helper";
import { deepCopy } from "@/lib/utility/deep-copy";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import {
  ThgChargingStationViewModelGen,
  ThgThgMeterReadingViewModelGen,
  ThgUpdateChargingStationDtoGen
} from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { ChargingStationAdminModule } from "@/store/modules/charging-station-admin.store";
import { ConfigModule } from "@/store/modules/config";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop, Watch } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import Card from "../utility/Card.vue";
import ThgChargingStationForm from "./ThgChargingStationForm.vue";
import ThgChargingStationInformationCard from "./ThgChargingStationInformationCard.vue";
import ThgQuoteMeterReadingWorkItemCardChart from "./ThgQuoteMeterReadingWorkItemCardChart.vue";
import ThgWorkItemRejectionCard from "./ThgWorkItemRejectionCard.vue";
import { calculateChargedCarsPerDayByCharge } from "@/lib/utility/calculate-charged-cars-per-day";
import { latLng } from "leaflet";
import { VSkeletonLoader } from "vuetify/lib/components";
import { handleError } from "@/lib/utility/handleError";
const PartnerMap = () => ({
  component: import(/* webpackPrefetch: true  */ /* webpackChunkName: "map" */ "@/components/form/PartnerMap.vue"),
  loading: VSkeletonLoader
});
import { IThg } from "@/models/thg.entity";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    ThgChargingStationInformationCard,
    CustomerContactCard,
    UserAdministrationDetailPermissions,
    UserAdministrationDetailRoles,
    ThgQuoteWorkitemRegistrationCard,
    ImageDialog,
    TemplateDialog,
    ThgWorkItemRejectionCard,
    ThgQuoteMeterReadingWorkItemCardChart,
    ThgChargingStationForm,
    Card,
    PartnerMap
  },
  filters: {
    getFlagEmoji
  }
})
export default class ThgQuoteMeterReadingWorkItemCard extends DarkModeHighlightMixin {
  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedThg!: IThg;

  @Prop()
  loading!: boolean;

  @Prop()
  selectedUser!: IAdminUser;

  userNotification = true;
  isRejected = false;
  isRealistic = false;
  isDateAight = false;
  isFormValid = false;
  loadingLocation = false;

  get failStates() {
    return [ThgStatusEnum.NO_PUBLIC_ACCESS, ThgStatusEnum.METER_READING_CHANGE_REQUESTED, ThgStatusEnum.IMPLAUSIBLE];
  }

  get canBeConfirmed() {
    if (!this.isRealistic) {
      return false;
    }

    if (!this.isDateAight) {
      return false;
    }

    return this.isFormValid;
  }

  get marker() {
    return latLng(this.chargingStation?.address.geo.lat || 0, this.chargingStation?.address.geo.lng || 0);
  }

  get required() {
    return [requiredRule()];
  }

  get partner(): PartnerEntity {
    return PartnerModule.partner;
  }

  get chargingStation(): ThgChargingStationViewModelGen | undefined {
    return ChargingStationAdminModule.activeChargingStation;
  }

  get meterReadings(): ThgThgMeterReadingViewModelGen[] {
    return ThgPortalModule.meterReadingForChargingStation;
  }

  get bnetzaOperatorIdRule() {
    return [
      isNumberRule("components.thg.ThgChargingStationForm.bnetzaOperatorIdNumberRule"),
      numberLowerRule(100000, "components.thg.ThgChargingStationForm.bnetzaOperatorIdNumberLowerRule")
    ];
  }

  get registrationDate() {
    return this.chargingStation?.registrationDate ? simpleDate(this.chargingStation?.registrationDate) : "";
  }

  get geoLatRule() {
    return [isNumberRule(), requiredRule(), numberHigherRule(MIN_LAT), numberLowerRule(MAX_LAT)];
  }

  get geoLngRule() {
    return [isNumberRule(), requiredRule(), numberHigherRule(MIN_LNG), numberLowerRule(MAX_LNG)];
  }

  get maxLat() {
    return MAX_LAT;
  }

  get minLat() {
    return MIN_LAT;
  }

  get maxLng() {
    return MAX_LNG;
  }

  get minLng() {
    return MIN_LNG;
  }

  get date(): string {
    const timestamp = deepCopy<any>(this.selectedThg.timestamp);
    return simpleDate(timestamp.created);
  }

  get startDate(): string {
    return this.selectedThg.meterReading?.startDate ? simpleDate(this.selectedThg.meterReading.startDate) : "";
  }

  get endDate(): string {
    return this.selectedThg.meterReading?.endDate ? simpleDate(this.selectedThg.meterReading.endDate) : "";
  }

  get isYearBreak() {
    return (
      new Date(this.selectedThg.meterReading?.startDate || "").getFullYear() !==
      new Date(this.selectedThg.meterReading?.endDate || "").getFullYear()
    );
  }

  get overlap() {
    if (!this.selectedThg.meterReading) {
      return [];
    }
    const overlaps: string[] = [];

    for (const meterReading of this.meterReadings) {
      if (meterReading.id === this.selectedThg.id) {
        continue;
      }

      const selectedEnd = new Date(this.selectedThg.meterReading.endDate);
      const selectedStart = new Date(this.selectedThg.meterReading.startDate);
      const meterEnd = new Date(meterReading.meterReading.endDate);
      const meterStart = new Date(meterReading.meterReading.startDate);

      if (
        (meterEnd < selectedEnd && meterEnd > selectedStart) ||
        (meterStart > selectedStart && meterStart < selectedEnd)
      ) {
        overlaps.push(meterReading.id);
      }
    }
    return overlaps;
  }

  get dateDifference() {
    if (!this.selectedThg.meterReading) {
      return 0;
    }
    return calculateDayDifference(
      new Date(this.selectedThg.meterReading.endDate),
      new Date(this.selectedThg.meterReading.startDate)
    );
  }

  get kwhPerDay() {
    if (!this.selectedThg.meterReading) {
      return 0;
    }
    if (!this.dateDifference) {
      return this.selectedThg.meterReading.amountInKwh;
    }
    return Math.abs(this.selectedThg.meterReading.amountInKwh / this.dateDifference);
  }

  get amountOfCarsPerDay() {
    const batteryCapacityInkWh = 50; // Like a standard Tesla Model 3

    return calculateChargedCarsPerDayByCharge(this.kwhPerDay, batteryCapacityInkWh);
  }

  get ThgStatusEnum() {
    return ThgStatusEnum;
  }

  mounted() {
    this.setFocus();
  }

  @Watch("selectedThg")
  setFocus() {
    (this.$refs.startTextFieldRef as any).focus();
  }

  async toThgDetailView() {
    await new GoToHelper(this.$router).goToMeterReadingDetail(this.partner._id, this.selectedThg.id, true);
  }

  async toMeterReading(thgId: string) {
    await new GoToHelper(this.$router).goToMeterReadingDetail(this.partner._id, thgId, true);
  }

  toPartnerHomeView() {
    const roueData = this.$router.resolve({
      name: ConfigModule.partnerSelectorDestinationVue,
      params: { partnerId: this.selectedThg.partnerId }
    });

    window.open(roueData.href, "_blank");
  }

  async copyEmailToClipboard() {
    await navigator.clipboard.writeText(this.selectedUser?.userName ?? "");
    this.$toast.info("In Zwischenablage kopiert.");
  }

  async copyName() {
    await navigator.clipboard.writeText(`${this.selectedUser?.firstName} ${this.selectedUser?.lastName}`);
    this.$toast.info("In Zwischenablage kopiert.");
  }

  async copyCompany() {
    await navigator.clipboard.writeText(this.selectedUser?.company || "");
    this.$toast.info("In Zwischenablage kopiert.");
  }

  // TODO: Check this: if we need the charging location why not display geo object?
  async getLocation() {
    this.loadingLocation = true;
    await (this.$refs.chargingStationForm as ThgChargingStationForm).getLocation();
    this.loadingLocation = false;
  }

  async submit(status: ThgStatusEnum) {
    if (!this.chargingStation) {
      return;
    }
    this.$emit("update:loading", true);
    const id = this.chargingStation.id;
    let data: ThgUpdateChargingStationDtoGen = {};
    if (this.chargingStation.address.geo) {
      data.address = this.chargingStation.address;
    }
    if (this.chargingStation.chargingPointOperator) {
      const address = this.chargingStation.chargingPointOperator.address;
      if (address) {
        delete (address as any)._id;
        const chargingPointOperator = { ...this.chargingStation.chargingPointOperator, address };
        delete (chargingPointOperator as any)._id;
        data = {
          ...data,
          chargingPointOperator
        };
      }
    }

    try {
      await ChargingStationAdminModule.updateChargingStation({
        id,
        data
      });

      await ThgPortalModule.updateSelectedThgMeterReadingStatus({
        dto: { status },
        userNotification: this.userNotification,
        meterReadingId: this.selectedThg.id,
        partnerId: this.selectedThg.partnerId
      });
      this.askForWork();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  askForWork() {
    this.$emit("askForWork");
  }
}
