






























































































import { Component, Vue, Prop } from "vue-property-decorator";
import MHeader, { IAction } from "@/components/utility/mmmint/MHeader.vue";
import MDetailViewGrid from "@/components/utility/mmmint/MDetailViewGrid.vue";
import MActionList from "@/components/utility/mmmint/MActionList.vue";
import MDetailTable, {
  IMDetailTableConfig,
  DetailTableTypeEnum,
  DetailTableVisualizationEnum
} from "@/components/utility/mmmint/MDetailTable.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";

import BookingCustomerInformationTimeLineElement from "@/components/booking/portal-detail-card/BookingCustomerInformationTimeLineElement.vue";

import { ThgEfahrerOrderPopulatedViewModelGen, ThgEfahrerFileViewModelGen } from "@/services/thg/v1/data-contracts";
import { EfahrerOrderMDetailActionEnum } from "./ui-types";
import { simpleDoubleDigitDate, formatHoursAndMinutes } from "@/lib/utility/date-helper";

import EfahrerImage from "./EfahrerImage.vue";
import EfahrerCreateThgForm from "./EfahrerCreateThgForm.vue";

import { downloadFileViaAnchor } from "@/lib/utility/downloadFileFunc";
import { ImageSwipeImage } from "@/lib/interfaces/vue-image-swipe.interface";
import { EfahrerOrderStatusEnum } from "@/store/enum/thg/efahrer-order-status.enum";
import { handleError } from "@/lib/utility/handleError";
import { EfahrerModule } from "@/store/modules/efahrer.store";
import { PartnerModule } from "@/store/modules/partner";
import { CreateAnonymousThgQuote } from "@/store/models/thg/create-thg-quote";
import { ThgCreateModule } from "@/store/modules/thg.create.store";

import Debug from "@/components/utility/Debug.vue";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    MHeader,
    MDetailViewGrid,
    MActionList,
    MDetailTable,
    ConfirmActionDialog,
    EfahrerImage,
    EfahrerCreateThgForm,
    BookingCustomerInformationTimeLineElement,
    Debug
  }
})
export default class EfahrerDetailCard extends Vue {
  @Prop()
  value!: ThgEfahrerOrderPopulatedViewModelGen;
  @Prop()
  loading!: boolean;

  /**
   * Update status variables
   */
  isUpdateStatusDialogActive = false;
  loadingStatusUpdate = false;
  statusItems: string[] = Object.values(EfahrerOrderStatusEnum);
  tempStatus = this.value.order.status;

  /**
   * Create thg variables
   */
  isCreateThgDialogActive = false;
  loadingCreateThg = false;
  thgQuote = new CreateAnonymousThgQuote();
  partnerId = "";

  /**
   * Collect images created by the component.
   * Used for downloading all images.
   */
  swipeImages: ImageSwipeImage[] = [];
  imageFiles: Map<string, File> = new Map();

  readonly actionRecord: Record<EfahrerOrderMDetailActionEnum, IAction> = {
    [EfahrerOrderMDetailActionEnum.DOWNLOAD]: {
      text: "download",
      key: EfahrerOrderMDetailActionEnum.DOWNLOAD,
      icon: "mdi-download"
    },
    [EfahrerOrderMDetailActionEnum.CREATE_THG]: {
      text: "create thg",
      key: EfahrerOrderMDetailActionEnum.CREATE_THG,
      icon: "mdi-car"
    }
  };

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  formatHoursAndMinutes(date: Date) {
    return formatHoursAndMinutes(date);
  }

  get title() {
    return `${this.value.order.customer.firstName} ${this.value.order.customer.lastName} - ${this.value.order.proprietaryId}`;
  }

  get chips() {
    const chips = [];

    chips.push({
      text: this.value.order.status,
      color: "blue",
      icon: ""
    });

    chips.push({
      text: simpleDoubleDigitDate(this.value.order.created_at),
      color: "blue"
    });

    return chips;
  }

  get actions() {
    const actions: IAction[] = [];

    actions.push(this.actionRecord.download);

    if (this.value.order.status === EfahrerOrderStatusEnum.CREATED) {
      actions.push(this.actionRecord.create_thg);
    }

    return actions;
  }

  get detailTableConfig(): IMDetailTableConfig[] {
    const fields: IMDetailTableConfig[] = [
      {
        key: "order.bonusAmount",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "order.bonusType",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.TEXT
      },
      {
        key: "order.created_at",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      },
      {
        key: "order.status",
        type: DetailTableTypeEnum.PROPERTY,
        visualization: DetailTableVisualizationEnum.SLOT
      }
    ];

    return fields;
  }

  get customer() {
    return this.value.order.customer;
  }

  openStatusDialog() {
    this.isUpdateStatusDialogActive = true;
  }

  async changeOrderStatus() {
    this.loadingStatusUpdate = true;

    try {
      await EfahrerModule.setStatus({ id: this.value.order.id, status: { status: this.tempStatus } });
      this.isUpdateStatusDialogActive = false;
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingStatusUpdate = false;
    }
  }

  cancelChangeOrderStatus() {
    this.isUpdateStatusDialogActive = false;
    this.tempStatus = this.value.order.status;
  }

  onExportImages(images: Map<string, File>) {
    this.imageFiles = images;
  }

  onExportSwipeImages(images: ImageSwipeImage[]) {
    this.swipeImages = images;
  }

  /**
   * Download the displayed images on the screen one after the other.
   * We expect only two(one for front one for back)
   */
  downloadImages() {
    for (const image of this.imageFiles.values()) {
      downloadFileViaAnchor(image);
    }
  }

  openCreateThgFromOrder() {
    this.isCreateThgDialogActive = true;
  }

  partnerChange(partnerId: string) {
    this.partnerId = partnerId;
  }

  thgYearChange(year: string) {
    if (isNaN(parseInt(year))) return;

    this.thgQuote.years = [parseInt(year)];
  }

  thgCodeChange(code: string) {
    this.thgQuote.code = code;
  }

  thgCampaignChange(campaign: string) {
    this.thgQuote.campaign = campaign;
  }

  async createThgFromOrder() {
    this.loadingCreateThg = true;

    if (!this.partnerId) {
      this.$toast.error("Partner not set");
      this.loadingCreateThg = false;
      throw new Error("Partner not set");
    }

    /**
     * Set the partner in ReportModule to the one selected from partner selection
     */
    const selectedPartner = PartnerModule.partners.find(partner => (partner.id || partner._id) === this.partnerId);

    if (selectedPartner) {
      ReportModule.setPartner(selectedPartner);
    } else {
      this.$toast.error("Partner not found in partners");
      this.loadingCreateThg = false;
      throw new Error("Partner not found in partners");
    }

    try {
      const convertedFilesIds = Array.from(this.imageFiles.keys());

      this.assertImagesOrder(this.value.images, convertedFilesIds);

      const [efahrerFrontImage, efahrerBackImage] = this.imageFiles.values();

      await Promise.all([
        ThgCreateModule.addRegistrationFront(efahrerFrontImage),
        ThgCreateModule.addRegistrationBack(efahrerBackImage)
      ]);

      /**
       * Get the uploaded images in our system
       */
      const registrationImageFrontId = ThgCreateModule.registrationImageFront[0].uploadId;
      const registrationImageBackId = ThgCreateModule.registrationImageBack[0].uploadId;

      const order = this.value.order;

      this.thgQuote.createUserDto = {
        firstName: order.customer.firstName,
        lastName: order.customer.lastName,
        userName: order.customer.email,
        company: order.customer.companyName,
        isCompany: order.customer.vehicleOwnerType === "Geschäftskunde"
      };

      this.thgQuote.numberplate = order.proprietaryId;
      this.thgQuote.registrationImages = [registrationImageFrontId, registrationImageBackId];
      this.thgQuote.impactFactor = 0;

      await ThgCreateModule.createAnonymous({ partnerId: this.partnerId, thgQuote: this.thgQuote });

      this.$toast.success("🤩 👍");

      // Update status
      await EfahrerModule.setStatus({
        id: this.value.order.id,
        status: {
          status: EfahrerOrderStatusEnum.FETCHED
        }
      });

      this.$toast.success("Efahrer status updated 👍");

      this.isCreateThgDialogActive = false;

      this.$emit("thgCreated");
    } catch (error) {
      handleError(error);
    } finally {
      this.loadingCreateThg = false;
    }
  }

  cancelCreateThgFromOrder() {
    this.isCreateThgDialogActive = false;
  }

  async processAction(action: IAction) {
    switch (action.key) {
      case EfahrerOrderMDetailActionEnum.DOWNLOAD:
        this.downloadImages();
        break;
      case EfahrerOrderMDetailActionEnum.CREATE_THG:
        this.openCreateThgFromOrder();
        break;
      default:
        this.$emit("goTo", { action: action.key, order: null });
    }
  }

  /**
   * Assert that images from the efahrer object are correctly converted in our image files.
   * The length and ids should match.
   * @param efarherImages images from value.images
   * @param imageFilesIds ids of the converted images
   */
  private assertImagesOrder(efarherImages: ThgEfahrerFileViewModelGen[], imageFilesIds: string[]) {
    const orderImagesFileIds = efarherImages.map(image => image.id);
    const areFileIdsEqual = imageFilesIds.every((value, index) => value === orderImagesFileIds[index]);

    if (orderImagesFileIds.length !== imageFilesIds.length || !areFileIdsEqual) {
      this.$toast.error("Error in images from order.");
      throw new Error("Error in images from order.");
    }
  }
}
