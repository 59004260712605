














import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IThg } from "@/models/thg.entity";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop, Watch } from "vue-property-decorator";
import ThgEditImage from "./ThgEditImage.vue";
import { debounce } from "debounce";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { ClipperModule } from "@/store/modules/clipper.store";

@Component({
  components: {
    ThgEditImage
  }
})
export default class ThgQuoteWorkitemImageCard extends DarkModeHighlightMixin {
  @Prop()
  selectedThg!: IThg;

  imageLabels: ReportImageType[] = [];

  debounceTimeout = 1000;

  get images() {
    return ClipperModule.images;
  }

  mounted() {
    this.setLabels();
  }

  @Watch("selectedThg", { deep: true })
  setLabels() {
    this.$log.debug("setting labels");
    this.imageLabels = this.images.map(i => (i.type as any) as ReportImageType);
  }

  get selectedImageIndex() {
    return ClipperModule.currentImageIndex;
  }

  get edits() {
    return ClipperModule.edits;
  }

  get possibleLabels() {
    return [ReportImageType.registration, ReportImageType.registrationBack];
  }

  get currentLabel() {
    return this.imageLabels[this.selectedImageIndex];
  }

  set currentLabel(label: ReportImageType) {
    this.imageLabels.splice(this.selectedImageIndex, 1, label);
  }

  label(label: ReportImageType) {
    if (this.possibleLabels.includes[label]) {
      return this.$t(`components.thg.ThgQuoteWorkitemImageCard.${label}`);
    }

    return label;
  }

  @Watch("edits")
  setEdits() {
    this.debounceUpdate();
  }

  async update() {
    const edits = this.edits;
    const type = this.imageLabels;
    const data = { edits, type };

    ClipperModule.setPreviousEdit({ rotation: ClipperModule.rotation, crop: ClipperModule.crop });
    ClipperModule.setIsLoading(true);
    this.$log.debug("updating");
    const partnerId = this.selectedThg.partnerId;
    const thgId = this.selectedThg.id;
    try {
      await ThgPortalModule.editSelectedRegistrationImages({
        partnerId,
        thgId,
        data
      });
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      ClipperModule.setIsLoading(false);
    }
  }

  /**
   * Debounce the update of edit data to the backend
   */
  debounceUpdate = debounce(() => this.update(), this.debounceTimeout, false);
}
