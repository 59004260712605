












































































import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { HttpException, InternalServerErrorException } from "@/lib/exceptions/http";
import { requiredRule } from "@/lib/rules/requiredRule";
import { handleError } from "@/lib/utility/handleError";
import { PartnerEntity } from "@/models/partnerEntity";
import { ThgPromotionViewModelGen } from "@/services/thg/v1/data-contracts";
import { CreatePromotionDto, ICreatePromotionDto } from "@/store/models/thg/create-promotion.dto";
import { PartnerModule } from "@/store/modules/partner";
import { PromotionModule } from "@/store/modules/promotion.store";
import { Component, Vue } from "vue-property-decorator";
import * as XLSX from "xlsx";

/**
 * Starts operation in backend to credit-bill thgs automatically
 */
@Component({ components: { ConfirmActionDialog } })
export default class ThgPromotionImport extends Vue {
  dialog = false;

  activeWindow = 0;
  inputFile: File | undefined = undefined;
  importWorkBook: any = undefined;
  isFileLoaded = false;
  content: ArrayBuffer | undefined | string | null = undefined;
  worksheetAsJson = [];
  promotionDtos: ICreatePromotionDto[] = [];

  importColumnMap = {
    partnerId: "",
    value: "",
    currency: "",
    type: "",
    title: "",
    subtitle: "",
    description: "",
    hint: "",
    startDate: ""
  };
  progressCounter = 0;
  isDialogLoading = false;

  isColumnMapValid = false;

  get requiredRule() {
    return [requiredRule()];
  }

  importError: { progressCounter: number; text: string }[] = [];
  successDto: ICreatePromotionDto[] = [];
  errorDto: ICreatePromotionDto[] = [];

  get headers() {
    return [
      { text: "Nr.", value: "progressCounter" },
      { text: "Fehler", value: "text" }
    ];
  }

  async createPromotions() {
    this.isDialogLoading = true;
    this.progressCounter = 0;
    this.activeWindow = 3;

    try {
      for (const promotion of this.promotionDtos) {
        try {
          const createDto = new CreatePromotionDto();

          createDto.partnerId = promotion.partnerId;
          createDto.value = promotion.value;
          createDto.isActive = promotion.isActive;
          createDto.isArchived = promotion.isArchived;
          createDto.startDate = promotion.startDate;
          createDto.currency = promotion.currency;
          createDto.type = promotion.type;
          createDto.title = promotion.title;
          createDto.subtitle = promotion.subtitle;
          createDto.description = promotion.description;
          createDto.hint = promotion.hint;

          await createDto.createUnhandled();

          this.successDto.push(promotion);
        } catch (error) {
          if (error instanceof HttpException) {
            this.errorDto.push(promotion);
            if (error instanceof InternalServerErrorException) {
              this.importError.push({ progressCounter: this.progressCounter, text: "InternalServerErrorException" });
            } else {
              this.importError.push({ progressCounter: this.progressCounter, text: error.message });
            }
          }
        }

        this.progressCounter++;
      }
    } catch (error) {
      handleError(error);
    } finally {
      this.$emit("finished", true);
      this.isDialogLoading = false;
    }
  }

  createPromotionDto() {
    this.promotionDtos = [];
    for (const promotionData of this.worksheetAsJson) {
      const promotionDto = new CreatePromotionDto();

      promotionDto.partnerId = promotionData[this.importColumnMap.partnerId];
      promotionDto.value = promotionData[this.importColumnMap.value];
      promotionDto.currency = promotionData[this.importColumnMap.currency];
      promotionDto.type = promotionData[this.importColumnMap.type];
      promotionDto.title = promotionData[this.importColumnMap.title];
      promotionDto.subtitle = promotionData[this.importColumnMap.subtitle];
      promotionDto.description = promotionData[this.importColumnMap.description];
      promotionDto.hint = promotionData[this.importColumnMap.hint];
      promotionDto.startDate = promotionData[this.importColumnMap.startDate];

      this.promotionDtos.push(promotionDto);
    }
    this.activeWindow = 2;
  }

  get promotions(): ThgPromotionViewModelGen[] {
    return PromotionModule.paginationList;
  }

  get partners(): PartnerEntity[] {
    return PartnerModule.partners;
  }

  closeAndReset() {
    this.dialog = false;
    this.reset();
  }

  reset() {
    this.progressCounter = 0;
    this.activeWindow = 0;
    this.inputFile = undefined;
    this.importWorkBook = undefined;
    this.isFileLoaded = false;
    this.content = undefined;
    this.worksheetAsJson = [];
    this.promotionDtos = [];
    this.importError = [];
    this.successDto = [];
    this.errorDto = [];
    this.importColumnMap = {
      partnerId: "",
      value: "",
      currency: "",
      type: "",
      description: "",
      subtitle: "",
      title: "",
      hint: "",
      startDate: ""
    };
  }

  get keys() {
    if (this.worksheetAsJson.length) {
      return Object.getOwnPropertyNames(this.worksheetAsJson[0]);
    } else {
      return [];
    }
  }

  async importFileAsExcel() {
    if (this.inputFile) {
      await this.readFile();
      this.importWorkBook = XLSX.read(this.content);
      if (this.importWorkBook) {
        const wsname = this.importWorkBook.SheetNames[0];
        this.worksheetAsJson = XLSX.utils.sheet_to_json(this.importWorkBook.Sheets[wsname], { defval: null });
        this.activeWindow = 1;
      }
    }
  }

  async readFile() {
    const reader = new FileReader();
    if (this.inputFile) {
      reader.onload = res => {
        this.content = res?.target?.result;
        this.isFileLoaded = true;
      };
      reader.onerror = err => this.$log.error(err);
      reader.readAsArrayBuffer(this.inputFile);
    }
  }

  getPartner(partnerId: string) {
    return PartnerModule.partnersMap.get(partnerId);
  }

  preview(previewColumn: string) {
    let counter = 0;
    const preview: any = [];
    for (const promotionData of this.worksheetAsJson) {
      counter++;
      if (counter > 3) {
        break;
      }
      preview.push(promotionData[previewColumn]);
    }

    return preview;
  }
}
