/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { PartnerEntity } from "@/models/partnerEntity";
import { ThgCreatePriceDtoGen, ThgPriceViewModelGen } from "@/services/thg/v1/data-contracts";

/**
 *
 */
export class CreatePriceDto implements ThgCreatePriceDtoGen {
  /**
   *The partner
   */
  partner?: PartnerEntity;

  /**
   * @inheritdoc
   */
  title: string;

  /**
   * @inheritdoc
   */
  value: number;

  /**
   * @inheritdoc
   */
  commission: number;

  /**
   * @inheritdoc
   */
  isFixed: boolean;

  /**
   * When set overrides value
   */
  _manualValue?: number;

  /**
   * @inheritdoc
   */
  year: number;

  /**
   * @inheritdoc
   */
  currency: "CENT" | "EUR" | "TEUR";

  /**
   * @inheritdoc
   */
  unit: "kWh" | "MWh" | "piece";

  /**
   * @inheritdoc
   */
  isActive?: boolean | undefined;

  constructor(price?: ThgPriceViewModelGen, partner?: PartnerEntity) {
    this.title = "GHG";
    this.currency = "EUR";
    this.unit = "MWh";

    this.year = new Date().getFullYear();

    this.value = 0;
    this.commission = 0;
    this.isFixed = false;
    this.isActive = false;

    if (partner) {
      this.partner = partner;
    }

    if (price) {
      this.title = price.title;
      this.currency = price.currency;
      this.unit = price.unit;

      this.year = price.year;

      this.value = price.value;
      this.commission = price.commission;
      this.isFixed = price.isFixed;
      this.isActive = price.isActive;
    }
  }

  toDto(): ThgCreatePriceDtoGen {
    const dto: ThgCreatePriceDtoGen = {
      title: this.title,
      value: this.value,
      commission: this.commission,
      isFixed: this.isFixed,
      year: this.year,
      currency: this.currency,
      unit: this.unit,
      isActive: this.isActive
    };

    return dto;
  }
}
