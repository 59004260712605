import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";

/**
 * Calculates the charging time on a charging station
 * assumes equal distribution of "charging per percent"
 *
 * @param chargingStation
 * @param batteryCapacityInkWh the battery capacity of a vehicle
 * @returns
 */
export function getChargingTime(chargingStation: ThgChargingStationViewModelGen, batteryCapacityInkWh = 50) {
  const chargingDurationPerBattery = batteryCapacityInkWh / chargingStation.connectionPowerInKwh;

  return chargingDurationPerBattery;
}

/**
 * Calculates the amount of cars that can be charged in a day
 * assumes 100% utilization
 * assumes that each charging point works at max power
 * @param chargingStation the charging station with
 * @param batteryCapacityInkWh the battery capacity of a vehicle
 * @returns
 */
export function calculateChargedCarsPerDayByChargingStation(
  chargingStation: ThgChargingStationViewModelGen,
  batteryCapacityInkWh = 50
) {
  const chargingDurationPerBattery = getChargingTime(chargingStation, batteryCapacityInkWh);
  const carsPerDay = 24 / chargingDurationPerBattery;
  const roundedCarsPerDay = Math.round(carsPerDay * 10) / 10;

  return roundedCarsPerDay * chargingStation.chargingPointCount;
}

/**
 * Calculates how many cars were charged by a charging station that gave out charge amount of kwh
 * assumes 100% utilization
 * assumes that each charging point works at max power
 * @param chargeInKwh how much charge a charging point gave out
 * @param batteryCapacityInkWh the battery capacity of a vehicle
 * @returns
 */
export function calculateChargedCarsPerDayByCharge(chargeInKwh: number, batteryCapacityInkWh = 50) {
  const amountOfVehicles = chargeInKwh / batteryCapacityInkWh;
  const roundedCarsPerDay = Math.round(amountOfVehicles * 10) / 10;

  return roundedCarsPerDay;
}
