












































































































import { advertismentContentType } from "@/lib/types/advertisementContentType";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { CreateOrUpdateAdvertisement } from "@/models/Advertisment/create-advertisment";
import { PublicImageImageViewmodelGen } from "@/services/image/v1/data-contracts";
import { ThgAdvertisementViewmodelGen, ThgCreateAdvertisementDtoGen } from "@/services/thg/v1/data-contracts";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { Component, Prop } from "vue-property-decorator";
import PublicImageUploadDialog from "../public-image/PublicImageUploadDialog.vue";
import Card from "../utility/Card.vue";

@Component({
  components: { Card, PublicImageUploadDialog }
})
export default class ThgAdvertismentInformationCard extends DarkModeHighlightMixin {
  @Prop()
  ad!: ThgAdvertisementViewmodelGen;

  @Prop({ default: true })
  disabled!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  contentTypeItems = advertismentContentType;

  createOrUpdateAdvertisement: ThgCreateAdvertisementDtoGen = new CreateOrUpdateAdvertisement(this.ad);

  isEditting = false;

  get isBanner() {
    return this.isBannerType(this.ad);
  }

  get isText() {
    return this.isTextType(this.ad);
  }

  get monthlyBudget() {
    return this.ad.monthlyBudget.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }

  edit() {
    this.createOrUpdateAdvertisement = new CreateOrUpdateAdvertisement(this.ad);
    this.isEditting = true;
  }

  close() {
    this.isEditting = false;
  }

  handleImage(image: PublicImageImageViewmodelGen) {
    if (image && image.cdnUrl) {
      this.createOrUpdateAdvertisement.content.bannerUrl = image.cdnUrl;
    }
  }

  isTextType(ad: ThgAdvertisementViewmodelGen | ThgCreateAdvertisementDtoGen): boolean {
    if (["text", "bannerAndText"].includes(ad.content.type)) {
      return true;
    }
    return false;
  }

  isBannerType(ad: ThgAdvertisementViewmodelGen | ThgCreateAdvertisementDtoGen): boolean {
    if (["banner", "bannerAndText"].includes(ad.content.type)) {
      return true;
    }
    return false;
  }

  async save() {
    this.loading = true;
    this.isEditting = false;
    try {
      await AdvertisementAdminModule.updateSelected(this.createOrUpdateAdvertisement);
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.loading = false;
      this.isEditting = false;
    }
  }
}
