




























































































































































































import { noNumberRule } from "@/lib/rules/contactRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { vinRuleChecksum, vinRuleLength, vinRuleNotAllowedCharacter } from "@/lib/rules/vinRule";
import { calculateVinChecksum, VinChecksum, legitChecksums } from "@/lib/utility/calculateVinChecksum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgRegistration } from "@/store/models/thg/registration";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgRegistrationForm extends DarkModeHighlightMixin {
  @Prop()
  registration!: ThgRegistration;

  vehicleClass = ThgRegistration.VEHICLE_CLASS;
  valid = false;
  driveType = ThgRegistration.DRIVE_TYPE;
  day = ThgRegistration.DAY;
  month = ThgRegistration.MONTH;
  year = ThgRegistration.YEAR;

  checksum: VinChecksum | "" = "";
  calculatedChecksum = 0;

  @Watch("registration", { deep: true })
  registrationUpdate() {
    this.$log.debug(JSON.stringify(this.registration));
  }

  @Watch("valid")
  emitValid() {
    this.$emit("valid", this.valid);
  }

  @Ref("fin")
  finRef!: Vue & { validate: () => boolean };

  get formFin(): Vue & { validate: () => boolean } {
    return this.$refs.fin as Vue & { validate: () => boolean };
  }

  get legitChecksums() {
    return legitChecksums;
  }

  get _checksum() {
    if (this.checksum === "") {
      return this.checksum;
    }
    if (!isNaN(Number(this.checksum))) {
      return Number(this.checksum) as VinChecksum;
    }
    return this.checksum;
  }
  set _checksum(checksum: VinChecksum | "") {
    this.checksum = checksum;
  }

  validate() {
    this.formFin.validate();
  }

  reset() {
    this.calculatedChecksum = 0;
    this._checksum = "";
    this.registration = new ThgRegistration();
  }

  uppercase() {
    this.registration.identificationnumber = this.registration.identificationnumber.toUpperCase();
  }

  get getChecksumText() {
    return "Check:" + calculateVinChecksum(this.registration.identificationnumber || "");
  }

  checkfin() {
    this.calculatedChecksum = calculateVinChecksum(this.registration.identificationnumber || "") as number;

    this.validate();
  }

  blurChecksum(e: any) {
    this._checksum = e.target?.value || "";

    this.validate();
  }

  async submit() {
    this.$emit("submit", this.registration);
    this.reset();
  }

  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get vinRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(vinRuleNotAllowedCharacter());
    rules.push(vinRuleLength());
    rules.push(vinRuleChecksum("Prüfziffern stimmen nicht überein", this.checksum as VinChecksum));

    return rules;
  }

  get noNumberAndRequiredRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(noNumberRule());

    return rules;
  }

  get noNumberRules() {
    const rules = [];
    rules.push(noNumberRule());

    return rules;
  }
}
