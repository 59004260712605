

































































import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { numberLowerRule } from "@/lib/rules/numberLowerRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import { BatchModule } from "@/store/modules/batch.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgBatchUpdatePriceDialog extends DarkModeHighlightMixin {
  dialog = false;
  loading = false;

  unit: CurrencyUnitEnum = CurrencyUnitEnum.EUR;
  value = 0;
  commission = 10;

  currencyUnits = Object.keys(CurrencyUnitEnum).map(k => CurrencyUnitEnum[k as any]);

  close() {
    this.dialog = false;
  }

  get isPriceInformation() {
    return !BatchModule.selectedBatch.batch?.pricePerKwh;
  }

  async update() {
    try {
      this.loading = true;
      await BatchModule.updatePricing({
        id: BatchModule.selectedBatch.batch.id,
        dto: { unit: this.unit, value: +this.value, commission: +this.commission }
      });
      this.$toast.success(this.$t("components.thg.ThgBatchUpdatePriceDialog.success").toString());
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgBatchUpdatePriceDialog.error").toString());
    } finally {
      this.loading = false;
      this.close();
    }
  }

  /**
   * Rules for required data
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get commissionRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberLowerRule(100));
    rules.push(numberHigherRule(0));

    return rules;
  }
}
