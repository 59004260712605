
































































import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import ThgBatchUpdateItemsDialog from "@/components/thg/ThgBatchUpdateItemsDialog.vue";
import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import { BatchTypeEnum } from "@/lib/enum/batch-type.enum";
import { deepCopy } from "@/lib/utility/deep-copy";
import { GoToHelper } from "@/lib/utility/goToHelper";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { BatchModule } from "@/store/modules/batch.store";
import { Component, Prop } from "vue-property-decorator";
import TableWrapper from "../utility/TableWrapper.vue";
import ThgBatchUpdateItemsByVinDialog from "./ThgBatchUpdateItemsByVinDialog.vue";
import ThgBatchUpdateSelectedStatusDialog from "./ThgBatchUpdateSelectedStatusDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import { IThgMeterReading } from "@/models/thg-meter-reading.entity";
import { downloadAsXlsx } from "@/lib/download-as-xlsx";

@Component({
  components: {
    ThgBatchUpdateItemsDialog,
    DeleteDialog,
    ThgQuoteExportDialog,
    TableWrapper,
    ThgBatchUpdateSelectedStatusDialog,
    ThgBatchUpdateItemsByVinDialog,
    Debug
  }
})
export default class ThgBatchDetailInformationThgTableCard extends DarkModeHighlightMixin {
  @Prop()
  data!: IThg[];

  thgsToAdd: IThg[] = [];
  selectedThgs: IThg[] = [];

  isLoadingThg = false;
  isLoadingUpdate = false;

  search = "";

  isUbaFormat = false;

  get isVehicle() {
    return BatchModule.selectedBatch.batch.type === BatchTypeEnum.vehicle;
  }

  get isMeterreading() {
    return BatchModule.selectedBatch.batch.type === BatchTypeEnum.meterReading;
  }
  get batch() {
    return BatchModule.selectedBatch;
  }

  get headers() {
    const headers = [
      { text: "ID", value: "id" },
      { text: "UserID", value: "userId" }
    ];
    if (this.isVehicle) {
      headers.push(
        {
          text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.numberplate").toString(),
          value: "numberplate"
        },
        {
          text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.identificationnumber").toString(),
          value: "registration.identificationnumber"
        },
        {
          text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.name").toString(),
          value: "registration.name"
        }
      );
    }
    headers.push({
      text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.status").toString(),
      value: "status"
    });
    if (this.isVehicle) {
      headers.push({
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.vehicleClass").toString(),
        value: "registration.vehicleClass"
      });
    }
    if (this.isMeterreading) {
      headers.push({
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.amountInKwh").toString(),
        value: "meterReading.amountInKwh"
      });
      headers.push({
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.startDate").toString(),
        value: "meterReading.startDate"
      });
      headers.push({
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.endDate").toString(),
        value: "meterReading.endDate"
      });
    }
    headers.push(
      {
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.isFixPayout").toString(),
        value: "payoutConfiguration.isFixed"
      },
      {
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.amount").toString(),
        value: "payoutConfiguration.revenue"
      }
    );

    headers.push(
      // {
      //   text: this.$t("thg.PriceTable.commission").toString(),
      //   value: "partnerCommission"
      // },
      // {
      //   text: this.$t("components.thg.ThgVehicleCard.payout").toString(),
      //   value: "payoutAmount"
      // },
      {
        text: this.$t("components.thg.ThgAnalyticsCustomerDataTableCard.promotion").toString(),
        value: "promotion"
      }
    );

    return headers;
  }

  dialog = false;

  get isDeleteDisabled() {
    return !this.selectedThgs.length;
  }

  get deleteDialogTitle() {
    return this.selectedThgs.length + " Items vom Batch entfernen?";
  }

  updateSelected(selectedThgs: IThg[]) {
    this.selectedThgs = selectedThgs;
  }

  goToOperationDetail(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, undefined, true);
  }

  /**
   * Remove Items from batch
   */
  async removeItemsFromBatch(reason: string) {
    try {
      this.isLoadingThg = true;
      const items = deepCopy(BatchModule.selectedBatch.batch.itemIds);
      const newIds = [];
      const idsToRemove = [];

      for (const idRoRemove of this.selectedThgs) {
        idsToRemove.push(idRoRemove.id);
      }

      for (const item of items) {
        if (!idsToRemove.includes(item)) {
          newIds.push(item);
        }
      }
      const result = await BatchModule.updateItems({
        id: BatchModule.selectedBatch.batch.id,
        dto: { reason: reason || "No specific reason", itemIds: newIds }
      });
      this.$toast.success(this.$t("components.thg.ThgBatchDetailInformationThgTableCard.success"), {
        onClick: () => this.goToOperationDetail(result.operationId)
      });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgBatchDetailInformationThgTableCard.error"));
    } finally {
      this.isLoadingThg = false;
      this.selectedThgs = [];
    }
  }

  toOperationDetail(operationId: string) {
    this.$router.push({ name: "OperationView", params: { operationId: operationId } });
  }

  get goToHelper() {
    return new GoToHelper(this.$router);
  }

  goToThg(thg: IThg) {
    this.goToHelper.goToThgDetail(thg.partnerId, thg.id, true);
  }

  goToMeterReading(thg: IThg) {
    this.goToHelper.goToMeterReadingDetail(thg.partnerId, thg.id, true);
  }

  goToUser(thg: IThg) {
    this.goToHelper.goToUserDetail(thg.userId, true);
  }

  calculateCommissionForItem(item: IThg | IThgMeterReading) {
    const commissionInPercent = (this.batch.batch.pricePerKwh?.commission ?? 0) / 100;

    return (
      (this.calculatePayoutAmount(item) * commissionInPercent).toFixed(2) +
      " " +
      (this.batch.batch.pricePerKwh?.unit ?? "")
    );
  }

  calculatePayoutAmount(item: IThg | IThgMeterReading) {
    if (item.payoutConfiguration.isFixed) {
      return item.payoutConfiguration.revenue;
    }

    const pricePerKwh = this.batch.batch.pricePerKwh?.value ?? 0;
    let kwh = (item as IThg).exactEnergyAmountInKwH ?? 0;
    if (!kwh) {
      kwh = (item as IThgMeterReading).meterReading.amountInKwh;
    }

    return pricePerKwh * kwh;
  }

  calculatePayoutAmountReadable(item: IThg | IThgMeterReading) {
    return this.calculatePayoutAmount(item).toFixed(2) + " " + (this.batch.batch.pricePerKwh?.unit ?? "");
  }

  download() {
    downloadAsXlsx(this.data);
  }
}
