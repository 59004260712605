

























































import FilterCard from "@/components/filter/FilterCard.vue";
import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";
import { simpleDate } from "@/lib/utility/date-helper";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({ components: { ThgQuoteExportDialog, FilterCard } })
export default class ThgChargingStationListCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  search = "";
  selected: ThgChargingStationViewModelGen = ThgPortalModule.selectedChargingStation;

  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  get chargingStations() {
    return ThgPortalModule.allChargingStations;
  }

  isActive(item: ThgChargingStationViewModelGen) {
    return item.id === ThgPortalModule.selectedChargingStation.id ? `grey ${this.color}` : "";
  }

  date(item: ThgChargingStationViewModelGen | any): string {
    return simpleDate(item.timestamp?.created);
  }

  isLoadingDetail(status: boolean) {
    this.$emit("isLoadingDetail", status);
  }

  async set(item: ThgChargingStationViewModelGen) {
    this.isLoadingDetail(true);
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({ name: "ThgChargingStationDetailView", params: { chargingStationId: item.id } });
    } else {
      const thg = await ThgPortalModule.getSelectedChargingStation(item.id);
      if (!thg) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      const meterReadings = await ThgPortalModule.getMeterReadingForChargingStation(item.id);
      if (!meterReadings) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noQuoteFound")));
      }
      const user = await AdminUserPaginationModule.getSelectedUser(item.userId);
      if (!user) {
        Vue.$toast.error(String(this.$t("components.partner.PartnerReportList.noUserFound")));
      }
    }
    this.isLoadingDetail(false);
  }

  //Filter:

  /**
   * The available categories visible in the filter menu
   */
  filterList = getDateFilterList("timestamp");

  items = this.chargingStations;

  get filteredItems() {
    return this.items;
  }
}
