import { PromotionTypeEnum } from "@/lib/enum/promotion-type.enum";
import { Filter, FilterConfig, FilterTypes, IsFilterable } from "@/lib/filterable";
import { IEntity } from "@/lib/utility/data/entity.interface";
import { handleError } from "@/lib/utility/handleError";
import promotionService from "@/services/thg/services/promotionService";
import {
  ThgImageDocumentGen,
  ThgPromotionViewModelGen,
  ThgTimestampDocumentGen
} from "@/services/thg/v1/data-contracts";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import { Timestamp } from "./timestamp.entity";
import { PromotionDataAccessLayer } from "@/store/modules/access-layers/promotion.access-layer";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { PartnerModule } from "@/store/modules/partner";
import Vue from "vue";
import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";

@IsFormable
@IsFilterable
class PromotionBase implements ThgPromotionViewModelGen, Partial<IEntity<IPromotion>> {
  id: string;

  @FilterConfig({
    displayName: "objects.promotion.id",
    type: FilterTypes.STRING
  })
  get _id() {
    return this.id;
  }

  @FilterConfig({
    displayName: "objects.promotion.partnerId",
    type: FilterTypes.STRING
  })
  partnerId: string;

  @FilterConfig({
    displayName: "objects.promotion.type",
    type: FilterTypes.ENUM,
    config: { items: Object.values(PromotionTypeEnum) }
  })
  type: PromotionTypeEnum;

  @FilterConfig({
    displayName: "objects.promotion.value",
    type: FilterTypes.NUMBER
  })
  value: number;

  @FilterConfig({
    displayName: "objects.promotion.currency",
    type: FilterTypes.ENUM,
    config: { items: Object.values(CurrencyUnitEnum) }
  })
  currency: "CENT" | "EUR" | "TEUR";

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.promotion.title"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.promotion.title"
    }
  })
  @FilterConfig({
    displayName: "objects.promotion.title",
    type: FilterTypes.STRING
  })
  title: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.promotion.subtitle"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.promotion.subtitle"
    }
  })
  @FilterConfig({
    displayName: "objects.promotion.subtitle",
    type: FilterTypes.STRING
  })
  subtitle: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.promotion.description"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.promotion.description"
    }
  })
  @FilterConfig({
    displayName: "objects.promotion.description",
    type: FilterTypes.STRING
  })
  description: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.promotion.hint"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.promotion.hint"
    }
  })
  @FilterConfig({
    displayName: "objects.promotion.hint",
    type: FilterTypes.STRING
  })
  hint: string;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.promotion.imageId"],
    type: DetailFormComponentsEnum.PARTNER_SINGLE_IMAGE_UPLOAD,
    props: {
      label: "objects.promotion.hint",
      getPartnerId: () => PartnerModule.partner.id
    }
  })
  imageId: string;

  image?: ThgImageDocumentGen;

  @FilterConfig({
    displayName: "objects.promotion.isActive",
    type: FilterTypes.BOOLEAN
  })
  isActive: boolean;

  @FilterConfig({
    displayName: "objects.promotion.isArchived",
    type: FilterTypes.BOOLEAN
  })
  isArchived: boolean;

  @FilterConfig({
    displayName: "objects.promotion.startDate",
    type: FilterTypes.DATE
  })
  startDate?: string | undefined;

  @FilterConfig({
    displayName: "objects.promotion.endDate",
    type: FilterTypes.DATE
  })
  endDate?: string | undefined;

  @FilterConfig({
    displayName: "objects.promotion.timestamp",
    type: Timestamp
  })
  timestamp: ThgTimestampDocumentGen;

  get isUpdateable() {
    return !this.isActive;
  }

  get isActivateable() {
    return !this.isActive;
  }

  get isDeletable() {
    return !this.isArchived;
  }

  constructor(promotion?: Partial<ThgPromotionViewModelGen>) {
    this.id = promotion?.id ?? "";
    this.partnerId = promotion?.partnerId ?? "";
    this.type = promotion?.type as PromotionTypeEnum;
    this.value = promotion?.value ?? 0;
    this.currency = promotion?.currency as CurrencyUnitEnum;
    this.title = promotion?.title ?? "";
    this.subtitle = promotion?.subtitle ?? "";
    this.description = promotion?.description ?? "";
    this.hint = promotion?.hint ?? "";
    this.imageId = promotion?.imageId ?? "";
    this.image = promotion?.image ?? ({} as ThgImageDocumentGen);
    this.isActive = promotion?.isActive ?? false;
    this.isArchived = promotion?.isArchived ?? false;
    this.startDate = promotion?.startDate ?? "";
    this.endDate = promotion?.endDate ?? "";
    this.timestamp = promotion?.timestamp as ThgTimestampDocumentGen;
  }

  private map(promotion?: Partial<ThgPromotionViewModelGen>) {
    this.id = promotion?.id ?? "";
    this.partnerId = promotion?.partnerId ?? "";
    this.type = promotion?.type as PromotionTypeEnum;
    this.value = promotion?.value ?? 0;
    this.currency = promotion?.currency as CurrencyUnitEnum;
    this.title = promotion?.title ?? "";
    this.subtitle = promotion?.subtitle ?? "";
    this.description = promotion?.description ?? "";
    this.hint = promotion?.hint ?? "";
    this.imageId = promotion?.imageId ?? "";
    this.image = promotion?.image ?? ({} as ThgImageDocumentGen);
    this.isActive = promotion?.isActive ?? false;
    this.isArchived = promotion?.isArchived ?? false;
    this.startDate = promotion?.startDate ?? "";
    this.endDate = promotion?.endDate ?? "";
    this.timestamp = promotion?.timestamp as ThgTimestampDocumentGen;
  }

  async update(silent?: boolean) {
    this._isLoading = true;
    let promotion: IPromotion | undefined = undefined;
    try {
      const created = await promotionService.updateByPartnerId(this.partnerId, this.id, {
        title: this.title,
        subtitle: this.subtitle,
        description: this.description,
        hint: this.hint,
        imageId: this.imageId
      });
      promotion = new Promotion(created);
      PromotionDataAccessLayer.set(promotion);
    } catch (e) {
      handleError(e);

      if (!silent) throw e;
    }

    this._isLoading = false;
    return promotion;
  }

  async fetch() {
    this._isLoading = true;
    await promotionService
      .findOneByPartnerId(this.partnerId, this.id)
      .then(p => this.map(p))
      .catch(handleError);

    PromotionDataAccessLayer.set(this);

    this._isLoading = false;
    return this;
  }

  async activate() {
    this._isLoading = true;
    await promotionService
      .updateByPartnerId(this.partnerId, this.id, {
        isActive: true
      })
      .then(p => this.map(p))
      .catch(handleError);

    // refresh all promos in the background that were previously active and have the same type as the new one is now active and the old ones should be deactivated
    const promosToRefresh = PromotionDataAccessLayer.entities.filter(
      p => p.type === this.type && p.isActive === true && p.isArchived === false && p.partnerId === this.partnerId
    );
    for (const promo of promosToRefresh) {
      promo.fetch().catch(Vue.$log.error);
    }

    PromotionDataAccessLayer.set(this);

    this._isLoading = false;
    return this;
  }

  async delete() {
    this._isLoading = true;
    await promotionService
      .removeByPartnerId(this.partnerId, this.id)
      .then(p => {
        if (!this.isActive) PromotionDataAccessLayer.delete(this);
        else this.map(p);
      })
      .catch(handleError);

    this._isLoading = false;
  }

  private _isLoading = false;
  get isLoading() {
    return this._isLoading;
  }
}
type IPromotion = PromotionBase;
const Promotion = Form.createForClass(Filter.createForClass(PromotionBase));

export { IPromotion, Promotion };
