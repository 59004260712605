



























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import ThgQuoteUpdateDialog from "@/components/thg/ThgQuoteUpdateDialog.vue";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";

@Component({
  components: {
    ThgQuoteUpdateDialog,
    Card
  }
})
export default class ThgQuoteDetailInformationImpactFactorCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  thg!: IThg;
}
