var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn-toggle', {
    attrs: {
      "rounded": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.isMultipleImages
    },
    on: {
      "click": _vm.previousImage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-left")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.rotateImage(-90);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-rotate-left")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.rotateImage(90);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-rotate-right")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.scaleImage(-0.2);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-minus")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.scaleImage(0.2);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.translateImageX(-5);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.translateImageX(5);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-right")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.translateImageY(-5);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-down")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.translateImageY(5);
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-arrow-up")])], 1), _c('v-btn', {
    staticClass: "d-none d-md-block",
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.resetImageTransformation();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.crop
    }
  }, [_c('v-icon', [_vm._v("mdi-crop")])], 1), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.isMultipleImages
    },
    on: {
      "click": _vm.nextImage
    }
  }, [_c('v-icon', [_vm._v("mdi-chevron-right")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }