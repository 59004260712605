












import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ImageSwipeImage } from "@/lib/interfaces/vue-image-swipe.interface";
import { ThgEfahrerFileViewModelGen } from "@/services/thg/v1/data-contracts";
import { convertB64ToBites, convertB64ToFile, convertUrlToFile } from "@/lib/files/PdfHelper";
import { convertPdfToImagePromises } from "@/lib/utility/convertPdfToImage";

@Component({
  components: {}
})
export default class EfahrerImage extends Vue {
  @Prop()
  images!: ThgEfahrerFileViewModelGen[];
  @Prop()
  loading!: boolean;

  swipeImages: ImageSwipeImage[] = [];
  /**
   * Map to store the id of efahrer image and the converted image file
   */
  imagesAsFilesMap: Map<string, File> = new Map();

  imageLoading = false;

  @Watch("images", { deep: true })
  async processAndEmitImages() {
    this.swipeImages = [];
    this.imagesAsFilesMap = new Map();

    this.imageLoading = true;

    for (const image of this.images) {
      if (image.ext === "pdf") {
        await this.processPdfImages(image);
      } else {
        this.processStandardImages(image);
      }
    }

    this.emitImages();
    this.imageLoading = false;
  }

  async mounted() {
    this.imageLoading = true;

    for (const image of this.images) {
      if (image.ext === "pdf") {
        await this.processPdfImages(image);
      } else {
        this.processStandardImages(image);
      }
    }

    this.emitImages();
    this.imageLoading = false;
  }

  async processPdfImages(image: ThgEfahrerFileViewModelGen) {
    const pdf = convertB64ToBites(image.base64);
    const pages = await convertPdfToImagePromises(pdf);

    if (pages.length === 0) {
      return;
    }

    const name = image.originalname.split(".")[0];
    const jpgImageOriginalName = `${name}.jpg`;

    const urls = await Promise.all(pages);

    for (const url of urls) {
      this.swipeImages.push({
        src: url,
        thumbnail: url,
        w: 0,
        h: 0,
        title: jpgImageOriginalName
      });

      const pdfToImageFile = await convertUrlToFile(url, jpgImageOriginalName, "image/jpg");
      this.imagesAsFilesMap.set(image.id, pdfToImageFile);
    }
  }

  processStandardImages(image: ThgEfahrerFileViewModelGen) {
    const imageAsFile = convertB64ToFile(image.base64, image.originalname, image.mime);
    const url = URL.createObjectURL(imageAsFile);

    this.imagesAsFilesMap.set(image.id, imageAsFile);

    this.swipeImages.push({
      src: url,
      thumbnail: url,
      w: 0,
      h: 0,
      title: image.originalname
    });
  }

  /**
   * Shares the image arrays with the parent.
   * One is the "swipe" type image which is intended for rendering
   * The other is a map which links the processed image(swipe image) with the original
   * efahrer one.
   */
  emitImages() {
    this.$emit("exportSwipeImages", this.swipeImages);
    this.$emit("exportImages", this.imagesAsFilesMap);
  }
}
