var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "transition": "dialog-bottom-transition",
      "scrollable": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attr = _ref.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attr, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1), _c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgBatchCreateDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "disabled": _vm.isDisabled,
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.create
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchCreateDialog.create")) + " ")])], 1), _c('v-divider'), _c('v-card-text', [_c('p', {
    staticClass: "mt-4"
  }), _c('v-row', {
    attrs: {
      "dense": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchCreateDialog.subTitle")) + " ")]), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": _vm.$t('components.thg.ThgBatchCreateDialog.batchName'),
      "rules": _vm.requiredRules,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.batchName,
      callback: function callback($$v) {
        _vm.batchName = $$v;
      },
      expression: "batchName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('p', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchCreateDialog.instruction")) + " "), _c('thg-batch-select-dialog', {
    attrs: {
      "type": _vm.type
    },
    on: {
      "update:type": function updateType($event) {
        _vm.type = $event;
      },
      "select": _vm.setSelectedThg
    }
  })], 1), _c('p', [_vm._v(_vm._s(_vm.$t("components.thg.ThgBatchCreateDialog.addedQuotes")) + " " + _vm._s(_vm.lengthOfSelectedThg))])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }