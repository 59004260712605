






















































import FilterCard from "@/components/filter/FilterCard.vue";
import ThgQuoteExportDialog from "@/components/thg/ThgQuoteExportDialog.vue";
import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import { IFilterListElement } from "@/lib/interfaces/filter/filterListElement.interface";
import { CancelToken } from "@/lib/utility/cancelToken";
import { getDateFilterList } from "@/lib/utility/filter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgAdvertisementViewmodelGen } from "@/services/thg/v1/data-contracts";
import { AdvertisementPaginatedAdminModule } from "@/store/modules/advertisment-admin-paginated-store";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { PartnerModule } from "@/store/modules/partner";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({ components: { TheLayoutPortalList, ThgQuoteExportDialog, FilterCard } })
export default class ThgAdvertismentListCardPaginated extends DarkModeHighlightMixin {
  loading = false;

  isLoadingDetail = false;

  dateFilter = getDateFilterList("timestamp");

  /**
   * The available categories visible in the filter menu
   */
  get filterList(): IFilterListElement[] {
    return [...this.dateFilter];
  }

  get items(): ThgAdvertisementViewmodelGen[] {
    return this.getModule().paginationList;
  }

  getModule() {
    return AdvertisementPaginatedAdminModule;
  }

  subTitleClass(listItem: ThgAdvertisementViewmodelGen) {
    if (listItem.isOutOfBudget) {
      return "red--text text--lighten-1";
    } else {
      return "";
    }
  }

  async mounted() {
    this.loading = true;
    this.setIsLoadingDetail(true);
    //load from beginning
    await this.refresh();
    // set user detail
    if (this.items.length === 0) {
      this.loadSelected({});
    } else {
      this.loadSelected(this.items[0]);
    }
    this.loading = false;
  }

  async loadSelected(item: any) {
    this.setIsLoadingDetail(true);
    try {
      if (AdvertisementPaginatedAdminModule.paginationList.length !== 0) {
        const ad = await AdvertisementAdminModule.getAdById({ partnerId: item.partnerId, id: item.id });
        if (!ad) {
          Vue.$toast.error(String("Ad not found"));
        }

        if (ad) {
          await PartnerModule.getPartnerById(ad.partnerId);
        }
      }
    } catch (error) {
      this.$log.error(error);
    } finally {
      this.setIsLoadingDetail(false);
    }
  }

  async set(item: any) {
    this.setIsLoadingDetail(true);
    if (["xs", "sm"].includes(this.$vuetify.breakpoint.name)) {
      this.$router.push({
        name: "ThgAdvertisementDetail",
        params: { partnerId: item.partnerId, advertisementId: item.id }
      });
    } else {
      await this.loadSelected(item);
    }
    this.setIsLoadingDetail(false);
  }

  async refresh() {
    this.loading = true;
    try {
      AdvertisementPaginatedAdminModule.emptyList();
      await AdvertisementPaginatedAdminModule.fetchAllFromBeginning({});
    } catch (error) {
      this.$toast.error("Error fetching Advertisments");
      this.$log.error(error);
    } finally {
      this.loading = false;
    }
  }

  async loadAllFromLastId() {
    if (this.items.length === 0) {
      await AdvertisementPaginatedAdminModule.fetchAllFromBeginning({});
    } else {
      AdvertisementPaginatedAdminModule.cancelToken?.requestCancellation();
      const cancelToken = new CancelToken();
      AdvertisementPaginatedAdminModule.setCancelToken(cancelToken);
      await AdvertisementPaginatedAdminModule.fetchRest({
        query: {},
        cancelToken: cancelToken
      });
    }
  }

  async loadMore() {
    try {
      this.isLoadingMore = true;
      await this.loadAllFromLastId();
    } catch (e) {
      this.$toast.error((e as any).message);
    } finally {
      this.isLoadingMore = false;
    }
  }

  setIsLoadingDetail(status: boolean) {
    this.isLoadingDetail = status;
    this.$emit("isLoadingDetail", status);
  }

  isLoadingMore = false;
}
