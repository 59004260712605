var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('paginated-table', {
    attrs: {
      "title": _vm.$t('thg.ThgTable.title'),
      "headers": _vm.headers,
      "store": _vm.store,
      "showSelect": true,
      "selectedItems": _vm.selected
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "headerActions",
      fn: function fn() {
        return [_c('thg-batch-update-items-by-vin-dialog', {
          staticClass: "ml-1 mr-1",
          attrs: {
            "thgs": _vm.thgs
          },
          on: {
            "parsed": _vm.updateSelected
          }
        }), _c('thg-batch-update-dialog', {
          staticClass: "ml-1 mr-1",
          attrs: {
            "thgs": _vm.selected
          },
          on: {
            "failed": _vm.selectFailed
          }
        }), _c('thg-multi-quick-checkout-dialog', {
          staticClass: "ml-1 mr-1",
          attrs: {
            "thgs": _vm.selected
          },
          on: {
            "created": _vm.onCreated
          }
        }), _c('thg-billing-recreation-individual-dialog', {
          staticClass: "ml-1 mr-1",
          attrs: {
            "thgs": _vm.selected
          }
        }), _c('thg-quote-transfer-dialog', {
          attrs: {
            "value": _vm.selected,
            "icon": false
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }