var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionRegistrationData.title'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "loading": _vm.updatingRegistrationResults,
                  "disabled": _vm.locked
                },
                on: {
                  "click": _vm.confirmEdit
                }
              }, on), [_vm.locked ? _c('v-icon', [_vm._v("mdi-check")]) : _c('v-icon', {
                staticClass: "unsavedData",
                attrs: {
                  "color": "red",
                  "x-large": ""
                }
              }, [_vm._v("mdi-check")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionRegistrationData.confirmEdit")) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.locked = !_vm.locked;
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.locked ? "mdi-lock-outline" : "mdi-lock-open"))])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionBatchData.unlock")) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [_vm.registration ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.numberplate'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "numberplate", $$v);
      },
      expression: "registration.numberplate"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.name'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.name,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "name", $$v);
      },
      expression: "registration.name"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.identificationnumber'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.registration.identificationnumber ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1829637728),
    model: {
      value: _vm.registration.identificationnumber,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "identificationnumber", $$v);
      },
      expression: "registration.identificationnumber"
    }
  }), _c('v-row', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-1 mb-1 ml-3 mr-3",
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.firstregistrationDay'),
      "type": "number",
      "outlined": "",
      "dense": "",
      "flat": "",
      "min": "1",
      "max": "31",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.firstregistrationDay,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstregistrationDay", $$v);
      },
      expression: "registration.firstregistrationDay"
    }
  }), _c('v-text-field', {
    staticClass: "mt-1 mb-1 mr-3",
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.firstregistrationMonth'),
      "type": "number",
      "min": "1",
      "max": "12",
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.firstregistrationMonth,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstregistrationMonth", $$v);
      },
      expression: "registration.firstregistrationMonth"
    }
  }), _c('v-text-field', {
    staticClass: "mt-1 mb-1 mr-n3",
    staticStyle: {
      "width": "20%"
    },
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.firstregistrationYear'),
      "type": "number",
      "min": "1",
      "max": "31",
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.firstregistrationYear,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "firstregistrationYear", $$v);
      },
      expression: "registration.firstregistrationYear"
    }
  })], 1), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.driveTyp'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.driveTyp,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "driveTyp", $$v);
      },
      expression: "registration.driveTyp"
    }
  }), _c('v-combobox', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.vehicleClass'),
      "items": ['M1', 'M3', 'N1', 'N2', 'N3', 'other'],
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.registration.vehicleClass ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2307793663),
    model: {
      value: _vm.registration.vehicleClass,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "vehicleClass", $$v);
      },
      expression: "registration.vehicleClass"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.street'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.street,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "street", $$v);
      },
      expression: "registration.street"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.zip'),
      "type": "number",
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.zipCode,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "zipCode", $$v);
      },
      expression: "registration.zipCode"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionRegistrationData.city'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.registration.city,
      callback: function callback($$v) {
        _vm.$set(_vm.registration, "city", $$v);
      },
      expression: "registration.city"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.registration
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }