var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionItemPayoutData.title'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "disabled": _vm.locked
                },
                on: {
                  "click": _vm.editPayoutConfiguration
                }
              }, on), [_vm.locked ? _c('v-icon', [_vm._v("mdi-check")]) : _c('v-icon', {
                staticClass: "unsavedData",
                attrs: {
                  "color": "red",
                  "x-large": ""
                }
              }, [_vm._v("mdi-check")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionItemPayoutData.confirmEdit")) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.locked = !_vm.locked;
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.locked ? "mdi-lock-outline" : "mdi-lock-open"))])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionBatchData.unlock")) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [!_vm.updatingRegistrationResults ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemPayoutData.revenue'),
      "type": "number",
      "step": "0.01",
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.thg.payoutConfiguration.revenue,
      callback: function callback($$v) {
        _vm.$set(_vm.thg.payoutConfiguration, "revenue", $$v);
      },
      expression: "thg.payoutConfiguration.revenue"
    }
  }), _c('v-combobox', {
    staticClass: "mb-n2",
    attrs: {
      "items": [false, true],
      "label": _vm.$t('components.thg.thgBillingSelectionItemPayoutData.isFixed'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.thg.payoutConfiguration.isFixed,
      callback: function callback($$v) {
        _vm.$set(_vm.thg.payoutConfiguration, "isFixed", $$v);
      },
      expression: "thg.payoutConfiguration.isFixed"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemPayoutData.impactFactor'),
      "type": "number",
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.thg.impactFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.thg, "impactFactor", $$v);
      },
      expression: "thg.impactFactor"
    }
  }), _c('v-combobox', {
    staticClass: "mb-n2",
    attrs: {
      "items": _vm.impactTypeEnumValues,
      "label": _vm.$t('components.thg.thgBillingSelectionItemPayoutData.impactType'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.thg.impactType,
      callback: function callback($$v) {
        _vm.$set(_vm.thg, "impactType", $$v);
      },
      expression: "thg.impactType"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.thg
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }