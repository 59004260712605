

























import { Component, Vue } from "vue-property-decorator";
import { ZipModule } from "@/store/modules/zip.store";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { ThgPartnerZipViewmodelGen, ThgCreatePartnerZipDtoGen, ThgZipDtoGen } from "@/services/thg/v1/data-contracts";
import TableWrapper, { IControlElements } from "@/components/utility/TableWrapper.vue";
import ZipImportDialog from "@/components/thg/ZipImportDialog.vue";

@Component({
  components: {
    LayoutSimple,
    TableWrapper,
    ZipImportDialog
  }
})
export default class PartnerZip extends Vue {
  zipsLoaded = false;
  zip: ThgPartnerZipViewmodelGen | undefined;
  selected: ThgPartnerZipViewmodelGen[] = [];

  get i18n() {
    return this.$t("views.portal.partnerZip");
  }

  get partnerId() {
    return this.$route.params.partnerId;
  }

  get emptyZip() {
    return { zip: "", city: "" };
  }

  async deleteItem(item: any) {
    if (!this.zip?.zipList) {
      return;
    }

    const index = (this.zip?.zipList || []).findIndex(e => e.zip === item.zip);

    if (index < 0) {
      return;
    }

    this.zip.zipList.splice(index, 1);
    await this.confirmNewZips([]);
  }

  async confirmNewZips(item: ThgZipDtoGen[]) {
    try {
      this.zipsLoaded = false;
      const data: ThgCreatePartnerZipDtoGen = {
        zipList: [...item, ...(this.zip?.zipList || [])]
      };
      this.zip = await ZipModule.create({ partnerId: this.partnerId, data: data });
    } catch (e) {
      this.$log.error(e);
      this.$toast((e as any).message);
    } finally {
      this.zipsLoaded = true;
    }
  }

  get headers() {
    return [
      { text: this.i18n["zip"], value: "zip", align: "left" },
      { text: this.i18n["city"], value: "city", align: "left" },
      { text: "", value: "controls", sortable: false, align: "end" }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-trash-can",
        action: this.deleteItem,
        loading: false,
        text: "delete"
      }
    ];
  }

  async loadAll() {
    try {
      this.zipsLoaded = false;
      this.zip = await ZipModule.getForPartner({ partnerId: this.partnerId });
    } catch (e) {
      this.$log.error(e);
      this.$toast((e as any).message);
    } finally {
      this.zipsLoaded = true;
    }
  }

  async mounted() {
    await this.loadAll();
  }
}
