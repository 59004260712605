var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "transition": "dialog-bottom-transition",
      "scrollable": "",
      "persistent": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attr = _ref.attr;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "elevation": "0",
            "color": "info",
            "small": ""
          },
          on: {
            "click": function click($event) {
              return _vm.initialize();
            }
          }
        }, 'v-btn', attr, false), on), [_vm._v(" Download ")])];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "title pt-4",
    class: _vm.color
  }, [_c('h4', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.$t("components.thg.ThgBatchDownloadDialog.title")))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDownloadDialog.options.maxFiles")) + " ")]), _c('v-slider', {
    attrs: {
      "disabled": _vm.isDownloadStarted,
      "max": "2000",
      "min": "100",
      "step": "100",
      "ticks": "",
      "thumb-label": "always"
    },
    model: {
      value: _vm.batchSize,
      callback: function callback($$v) {
        _vm.batchSize = $$v;
      },
      expression: "batchSize"
    }
  }), _c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDownloadDialog.options.dataType")) + " ")]), _c('v-radio-group', {
    attrs: {
      "disabled": _vm.isDownloadStarted
    },
    model: {
      value: _vm.fileType,
      callback: function callback($$v) {
        _vm.fileType = $$v;
      },
      expression: "fileType"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": _vm.$t('components.thg.ThgBatchDownloadDialog.options.image'),
      "value": "image"
    }
  }), _c('v-radio', {
    attrs: {
      "label": _vm.$t('components.thg.ThgBatchDownloadDialog.options.pdf'),
      "value": "pdf"
    }
  })], 1), _c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDownloadDialog.options.additional")) + " ")]), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('components.thg.ThgBatchDownloadDialog.options.compression'),
      "disabled": _vm.isDownloadStarted
    },
    model: {
      value: _vm.compression,
      callback: function callback($$v) {
        _vm.compression = $$v;
      },
      expression: "compression"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "disabled": _vm.isDownloadStarted,
      "color": "sucess",
      "elevation": "0",
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.download();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDownloadDialog.start")))]), _vm.isDownloadStarted ? _c('v-btn', {
    attrs: {
      "disabled": _vm.isFinished,
      "color": "error",
      "elevation": "0",
      "dense": ""
    },
    on: {
      "click": function click($event) {
        return _vm.abortDownload();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDownloadDialog.abort")))]) : _vm._e()], 1), _vm.isDownloadStarted ? _c('v-card-text', [_c('v-progress-linear', {
    staticClass: "mt-4",
    attrs: {
      "height": "25",
      "indeterminate": _vm.isCreatingZip
    },
    model: {
      value: _vm.relativeProgress,
      callback: function callback($$v) {
        _vm.relativeProgress = $$v;
      },
      expression: "relativeProgress"
    }
  }, [_c('strong', [_vm._v(_vm._s(Math.ceil(_vm.relativeProgress)) + "% ( " + _vm._s(_vm.progress) + "/" + _vm._s(_vm.numberOfDownloads) + ")")])]), _c('v-subheader', {
    staticClass: "pl-0"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _vm.failedDownloads.length ? _c('v-data-table', {
    staticClass: "mt-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.failedDownloads,
      "item-key": "item.id",
      "sortBy": "timestamp.created",
      "sortDesc": true,
      "items-per-page": 10
    }
  }) : _vm._e()], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }