






















import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { handleError } from "@/lib/utility/handleError";
import { ThgBillingInfoGen } from "@/services/thg/v1/data-contracts";
import { BillingModule } from "@/store/modules/billing.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog }
})
/**
 * A view where a user can review the data of a billed user and billed documents and generate the billing while validating its correctness
 */
export default class ThgBillingRejectButton extends Vue {
  @Prop()
  thgIds!: string[];
  @Prop()
  billingType!: BillingTypeEnum;
  @Prop()
  loading!: boolean;

  isDialogActive = false;
  isLoading = false;
  async reject() {
    this.isLoading = true;
    const data: ThgBillingInfoGen = {
      type: this.billingType
    };
    for (const thgId of this.thgIds) {
      try {
        await BillingModule.addBillingInfoToThg({
          thgId,
          data
        });
        this.$emit("reject", thgId);
      } catch (e) {
        handleError(e);
      }
    }
    this.isDialogActive = false;

    this.$toast.success("👍");

    this.isLoading = false;
  }
}
