var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "depressed": "",
      "color": "success",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchUpdateSelectedStatusDialog.btn")) + " ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "supressKeyboardActions": true,
      "rightLoading": _vm.isLoadingUpdate
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.updateStatus
    }
  }, [_c('v-form', {
    ref: "form",
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.items,
      "rules": _vm.requiredRule,
      "label": _vm.$t('components.thg.ThgQuoteDetailInformationStatusCard.status'),
      "hint": _vm.$t('components.thg.ThgQuoteDetailInformationStatusCard.statusHint'),
      "persistent-hint": "",
      "required": "",
      "outlined": "",
      "solo": "",
      "data-test-status": ""
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  }), _c('v-checkbox', {
    attrs: {
      "label": _vm.$t('components.thg.ThgQuoteDetailInformationStatusCard.userNotification'),
      "hint": _vm.$t('components.thg.ThgQuoteDetailInformationStatusCard.userNotificationHint'),
      "persistent-hint": ""
    },
    model: {
      value: _vm.userNotification,
      callback: function callback($$v) {
        _vm.userNotification = $$v;
      },
      expression: "userNotification"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }