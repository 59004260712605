










import ThgMeterReadingDetailCard from "@/components/thg/ThgMeterReadingDetailCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { IAdminUser } from "@/models/user.entity";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    ThgMeterReadingDetailCard
  }
})
export default class ThgMeterReadingDetailView extends Vue {
  loading = false;
  selectedUser: IAdminUser | null = null;

  async mounted() {
    this.loading = true;
    try {
      await ThgPortalModule.getSelectedMeterReading(this.$route.params.meterReadingId);
      this.selectedUser = await AdminUserPaginationModule.getSelectedUser(ThgPortalModule.selectedMeterReading.userId);
    } catch {
      this.$toast.error("Fehler beim Laden der Thg-Quote");
    } finally {
      this.loading = false;
    }
  }

  get meterReadings() {
    return ThgPortalModule.meterReadingForChargingStation;
  }
}
