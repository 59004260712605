


























import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { Component, Vue } from "vue-property-decorator";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { ThgDuplicateThgViewModelGen } from "@/services/thg/v1/data-contracts";
import TableWrapper, { ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import { ThgPaginationModule } from "@/store/modules/thg-pagination.store";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { PageFilterOperationEnum } from "@/lib/utility/data/page-filter-operation.enum";
import { PageFilterElement } from "@/models/page-filter-element.entity";

@Component({
  components: { TheLayoutPortal, TableWrapper }
})
export default class ThgDuplicateView extends Vue {
  loading = false;
  year = new Date().getFullYear();

  selectedDups: ThgDuplicateThgViewModelGen[] = [];

  get years() {
    const currentYear = new Date().getFullYear();
    return [currentYear - 1, currentYear, currentYear + 1];
  }

  get dups(): ThgDuplicateThgViewModelGen[] {
    return ThgPortalModule.duplicateThg;
  }

  readonly headers: ITableWrapperHeader[] = [
    {
      text: "",
      value: "",
      sortable: false,
      width: 100
    },
    { text: this.i18n["count"], value: "count" },
    { text: this.i18n["vin"], value: "vin" }
  ];

  get i18n() {
    return this.$t("views.ThgDuplicateView") || {};
  }

  async mounted() {
    this.getDups();
  }

  async getDups() {
    this.loading = true;
    try {
      await ThgPortalModule.getDuplicateThg(this.year);
    } catch (error) {
      this.$toast.error(error as any);
    } finally {
      this.loading = false;
    }
  }

  async goToDups(item: ThgDuplicateThgViewModelGen) {
    ThgPaginationModule.setFilter([
      new PageFilterElement({
        key: "registration.identificationnumber",
        operation: PageFilterOperationEnum.EQUAL,
        value: item.vin
      })
    ]);
    const goToHelper = new GoToHelper(this.$router);

    return goToHelper.goToThgAdminList();
  }
}
