

















import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { HttpException } from "@/lib/exceptions/http";
import { PartnerModule } from "@/store/modules/partner";
import { requiredRule } from "@/lib/rules/requiredRule";
import { PartnerEntity } from "@/models/partnerEntity";

@Component({ components: { Debug } })
export default class ThgBillingCreditorSelectionPartnerList extends DarkModeHighlightMixin {
  @Prop()
  partnerId!: string;

  loadingAll = true;

  inputValue = "";

  showList = false;

  get items() {
    return PartnerModule.partners;
  }
  get rules() {
    return [requiredRule()];
  }

  filter(user: PartnerEntity, searchString: string) {
    return JSON.stringify(user)
      .toLowerCase()
      .includes(searchString.toLowerCase());
  }

  async mounted() {
    this.loadingAll = true;
    try {
      if (this.items?.length === 0) {
        await PartnerModule.getPartners();
      }
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loadingAll = false;
    }
  }
  get selected(): string {
    return this.partnerId;
  }
  set selected(selected: string) {
    this.$emit("update:partnerId", selected);
  }
}
