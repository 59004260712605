var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [!_vm.isTableItemsLoading ? _c('table-wrapper', {
    attrs: {
      "title": _vm.i18n['title'],
      "showSelect": true,
      "headers": _vm.headers,
      "allItems": _vm.items,
      "selectedItems": _vm.selected
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "bodyActions",
      fn: function fn() {
        return [_c('thg-billing-credit-auto-bill-dialog', {
          attrs: {
            "isBatch": true
          }
        }), _c('v-select', {
          staticClass: "mb-n7",
          attrs: {
            "rounded": "",
            "filled": "",
            "dense": "",
            "outlined": "",
            "items": _vm.batchableBillingTypes,
            "selectedItems": _vm.selectedBatchableBillingTypes
          },
          on: {
            "change": _vm.updateTableItems,
            "update:selectedItems": function updateSelectedItems($event) {
              _vm.selectedBatchableBillingTypes = $event;
            },
            "update:selected-items": function updateSelectedItems($event) {
              _vm.selectedBatchableBillingTypes = $event;
            }
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref) {
              var item = _ref.item;
              return [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingCreateNewBillingManual.".concat(item))) + " ")];
            }
          }, {
            key: "item",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgBillingCreateNewBillingManual.".concat(item))) + " ")];
            }
          }], null, false, 2840114396),
          model: {
            value: _vm.billingType,
            callback: function callback($$v) {
              _vm.billingType = $$v;
            },
            expression: "billingType"
          }
        }), _c('v-badge', {
          attrs: {
            "bordered": "",
            "content": _vm.filterCount,
            "value": _vm.filterCount
          }
        }, [_c('context-menu', [_c('v-text-field', {
          attrs: {
            "label": _vm.i18n['from'],
            "type": "date",
            "clearable": "",
            "rounded": "",
            "filled": "",
            "dense": "",
            "outlined": ""
          },
          model: {
            value: _vm.from,
            callback: function callback($$v) {
              _vm.from = $$v;
            },
            expression: "from"
          }
        }), _c('v-text-field', {
          attrs: {
            "label": _vm.i18n['to'],
            "type": "date",
            "clearable": "",
            "rounded": "",
            "filled": "",
            "dense": "",
            "outlined": ""
          },
          model: {
            value: _vm.to,
            callback: function callback($$v) {
              _vm.to = $$v;
            },
            expression: "to"
          }
        }), _c('v-btn', {
          attrs: {
            "block": "",
            "outlined": "",
            "rounded": "",
            "filled": "",
            "dense": ""
          },
          on: {
            "click": _vm.updateTableItems
          }
        }, [_vm._v(_vm._s(_vm.i18n["load"]))])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "selectActions",
      fn: function fn(_ref3) {
        var selected = _ref3.selected;
        return [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "outlined": "",
            "rounded": "",
            "filled": "",
            "dense": "",
            "color": "success"
          },
          on: {
            "click": function click($event) {
              return _vm.beginBilling(selected);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.i18n["billButton"]) + " ")]), _c('v-spacer')];
      }
    }], null, false, 2910372395)
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "table-heading, table-tbody, table-tfoot"
    }
  }), _vm.affiliatesWithoutUserId.length > 0 ? _c('table-wrapper', {
    attrs: {
      "title": _vm.i18n['affiliatesWithoutUserId'],
      "allItems": _vm.affiliatesWithoutUserId,
      "headers": _vm.affiliatesWithoutUserIdHeaders,
      "selectedItems": _vm.selectedAffiliatesWithoutUserId
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedAffiliatesWithoutUserId = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedAffiliatesWithoutUserId = $event;
      }
    }
  }) : _vm._e(), _vm.affiliatesNotFound.length > 0 ? _c('table-wrapper', {
    attrs: {
      "title": _vm.i18n['affiliatesNotFound'],
      "allItems": _vm.affiliatesNotFound,
      "headers": _vm.affiliatesNotFoundHeaders,
      "selectedItems": _vm.selectedAffiliatesNotFound
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selectedAffiliatesNotFound = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selectedAffiliatesNotFound = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }