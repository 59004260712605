












import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class ThgChargingStationCreateCard extends Vue {
  goToChargingStationNewSingle() {
    this.$router.push({
      name: "ThgChargingStationCreateView"
    });
  }
}
