




















import { Component, Prop, Vue } from "vue-property-decorator";
import SideCard from "@/components/utility/SideCard.vue";
import EfahrerDetailCard from "./EfahrerDetailCard.vue";
import { ThgEfahrerOrderPopulatedViewModelGen } from "@/services/thg/v1/data-contracts";

@Component({
  components: {
    SideCard,
    EfahrerDetailCard
  }
})
export default class EfahrerSideCard extends Vue {
  @Prop()
  value!: ThgEfahrerOrderPopulatedViewModelGen;

  @Prop()
  loading!: boolean;

  height = document.documentElement.clientHeight;

  get fullscreenBreakpoint() {
    return this.$vuetify.breakpoint.width < 1500;
  }

  onResize() {
    this.height = document.documentElement.clientHeight;
  }
}
