/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import { MrfiktivUserControllerFindAllPaginatedParamsGen } from "@/services/mrfiktiv/v1/data-contracts";
import billingService from "@/services/thg/services/billingService";
import {
  ThgBillingControllerGetPartnerParamsGen,
  ThgBillingPartnerViewModelGen,
  ThgBillingViewmodelGen,
  ThgPageViewModelGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module, Mutation } from "vuex-module-decorators";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";
import { PageFilterTypes } from "@/lib/utility/data/page-filter-types.enum";

@Module({
  dynamic: true,
  namespaced: true,
  name: "partnerBilling",
  store
})
export class PartnerBillingStore extends BasePagination<
  ThgBillingPartnerViewModelGen,
  MrfiktivUserControllerFindAllPaginatedParamsGen
> {
  private _currentDocument: ThgBillingPartnerViewModelGen | null = null;
  get currentDocument() {
    return this._currentDocument;
  }
  @Mutation
  mutateCurrentDocument(currentDocument: ThgBillingPartnerViewModelGen) {
    this._currentDocument = currentDocument;
  }

  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: ThgBillingViewmodelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = true;
  filterOptions: PaginationFilterListElement[] = [
    { key: "billingNumber", type: PageFilterTypes.NUMBER },
    { key: "type", type: PageFilterTypes.ENUM, config: { items: Object.values(BillingTypeEnum) } },
    { key: "isPublished", type: PageFilterTypes.BOOLEAN },
    { key: "userId", type: PageFilterTypes.OBJECT_ID },
    { key: "accountNumber", type: PageFilterTypes.NUMBER },
    { key: "_id", type: PageFilterTypes.OBJECT_ID }
  ].map(f => new PaginationFilterListElement(f));

  @Action
  protected async loadDocuments(
    query: ThgBillingControllerGetPartnerParamsGen
  ): Promise<
    ThgPageViewModelGen & {
      data?: ThgBillingPartnerViewModelGen[] | undefined;
    }
  > {
    return billingService.getPartnerBillings(query);
  }

  @Action
  async loadDocument(data: { partnerId: string; billingNumber: number }): Promise<ThgBillingPartnerViewModelGen> {
    const billing = await billingService.getPartnerBilling(data.partnerId, data.billingNumber);

    this.context.commit("mutateCurrentDocument", billing);

    return billing;
  }

  @Action
  emptyDocument() {
    this.context.commit("mutateCurrentDocument", null);
  }
}

export const PartnerBillingModule = getModule(PartnerBillingStore);
