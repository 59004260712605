
















































import { Component, Ref } from "vue-property-decorator";
import { BillingModule } from "@/store/modules/billing.store";
import Debug from "../utility/Debug.vue";
import { AccountModule } from "@/store/modules/account.store";
import { ThgAccountViewmodelGen, ThgAccountControllerFindAllParamsGen } from "@/services/thg/v1/data-contracts";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import ThgBillingCreditorSelectionUserList from "./ThgBillingCreditorSelectionUserList.vue";
import { filterJson } from "@/lib/utility/filter";
import { CheckEnum } from "@/lib/enum/check.enum";
import ThgBillingPlausibilityCheckDialog from "./ThgBillingPlausibilityCheckDialog.vue";
import TableWrapper, { IControlElements } from "../utility/TableWrapper.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";
import ThgBillingCreditorCreateDialog from "./ThgBillingCreditorCreateDialog.vue";
import ThgBillingCreditorSelectionPartnerList from "./ThgBillingCreditorSelectionPartnerList.vue";
import ThgBillingCreditorUpdateDialog from "./ThgBillingCreditorUpdateDialog.vue";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";

@Component({
  components: {
    Debug,
    ThgBillingCreditorSelectionUserList,
    ThgBillingCreditorSelectionPartnerList,
    ThgBillingPlausibilityCheckDialog,
    TableWrapper,
    ThgBillingCreditorCreateDialog,
    ThgBillingCreditorUpdateDialog,
    PaginatedTable
  }
})
export default class ThgBillingCreditorSelection extends DarkModeHighlightMixin {
  @Ref("paginationTable")
  paginationTable!: PaginatedTable<ThgAccountViewmodelGen, ThgAccountControllerFindAllParamsGen>;

  get store() {
    return AccountModule;
  }

  allAccounts: ThgAccountViewmodelGen[] = [];
  selected: ThgAccountViewmodelGen[] = [];
  expanded = [];
  showResultsDialog = false;
  updateCreditorItem: ThgAccountViewmodelGen | null = null;
  updateCreditorDialog = false;
  createCreditorDialog = false;

  get CheckEnum() {
    return CheckEnum;
  }

  get headers() {
    return [
      { text: this.$t("components.thg.thgCreditorCsvSelection.created"), value: "timestamp.created", type: "date" },
      { text: this.$t("components.thg.thgCreditorCsvSelection.accountNumber"), value: "accountNumber" },
      { text: this.$t("components.thg.thgCreditorCsvSelection.userId"), value: "userId" },
      { text: this.$t("components.thg.thgCreditorCsvSelection.partnerId"), value: "partnerId" },
      { text: "", value: "controls", align: "end", sortable: false }
    ];
  }

  get controlElements(): IControlElements[] {
    return [
      {
        icon: "mdi-pen",
        text: String(this.$t("components.partner.PartnerManageDetailCard.edit")),
        action: this.openCreditorEditDialog,
        loading: false
      }
    ];
  }

  openCreditorEditDialog(item: ThgAccountViewmodelGen) {
    this.updateCreditorItem = item;
    this.updateCreditorDialog = true;
  }

  async mounted() {
    await AdminUserPaginationModule.getUsers();
  }

  async startCreditorCsvGeneration() {
    const accountNumbers: string[] = [];
    this.selected.forEach(account => {
      accountNumbers.push(account.accountNumber.toString());
    });

    try {
      const operationId = await BillingModule.dispatchCreditorCsv(accountNumbers);
      this.$toast.success(this.$t("components.thg.thgCreditorCsvSelection.startedCreditorCsvGeneration"), {
        onClick: () => this.goToOperationDetail(operationId)
      });
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    }
  }

  openCreateCreditorDialog() {
    this.createCreditorDialog = true;
  }

  goToUserDetail(userId: string) {
    new GoToHelper(this.$router).goToUserDetail(userId, true);
  }

  goToPartnerDetail(partnerId: string) {
    new GoToHelper(this.$router).goToPartnerDetail(partnerId, true);
  }

  goToOperationDetail(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, undefined, true);
  }

  customFilter(value: any, search: string, item: any) {
    return filterJson(value, search, item);
  }
}
