import { AffiliateTypeEnum } from "../enum/AffiliateType.enum";
import { IAffiliateType } from "../interfaces/affiliate/IAffiliateType";

export const affiliateTypeMap = new Map<AffiliateTypeEnum, IAffiliateType>([
  [
    AffiliateTypeEnum.FREINDS,
    {
      type: "components.thg.affiliateTypeMap.friendsType",
      description: "components.thg.affiliateTypeMap.friendsDescription"
    }
  ],
  [
    AffiliateTypeEnum.COOPERATION,
    {
      type: "components.thg.affiliateTypeMap.cooperationType",
      description: "components.thg.affiliateTypeMap.cooperationDescription"
    }
  ],
  [
    AffiliateTypeEnum.OTHER,
    {
      type: "components.thg.affiliateTypeMap.otherType",
      description: "components.thg.affiliateTypeMap.otherDescription"
    }
  ]
]);
