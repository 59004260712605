



















































































import { isNotEmptyArrayRule } from "@/lib/rules/isNotEmptyArray";
import { requiredRule } from "@/lib/rules/requiredRule";
import { MatchingConditions, matchingConditions } from "@/lib/types/matchingConditions";
import { TargetGroupAttributes, targetGroupAttributes } from "@/lib/types/targetGroupAttributes";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { UpdateAdvertisementAdminDto } from "@/models/Advertisment/update-advertisment";
import { ThgAdvertisementViewmodelGen, ThgUpdateAdvertisementAdminDtoGen } from "@/services/thg/v1/data-contracts";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";
import { Component, Prop } from "vue-property-decorator";
import SimpleDeleteDialog from "../dialog/SimpleDeleteDialog.vue";
import Card from "../utility/Card.vue";

@Component({
  components: { Card, SimpleDeleteDialog }
})
export default class ThgAdvertismentTargetGroupInformationCard extends DarkModeHighlightMixin {
  @Prop()
  ad!: ThgAdvertisementViewmodelGen;

  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  loading = false;

  valid = false;
  menu = false;

  addingValues = true;

  updateDto!: ThgUpdateAdvertisementAdminDtoGen;

  condition: MatchingConditions = "match";
  conditions = matchingConditions;

  attribute: TargetGroupAttributes = "userId";
  attributes = targetGroupAttributes;

  values = [];

  close() {
    this.menu = false;
    this.condition = "match";
    this.attribute = "userId";
    this.values = [];
  }

  get rule() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  get arrayRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(isNotEmptyArrayRule());

    return rules;
  }

  async save() {
    this.loading = true;
    try {
      if (!this.addingValues) {
        this.values = ["" as never];
      }
      this.updateDto = new UpdateAdvertisementAdminDto(this.ad);
      this.updateDto.targetGroupConditions.push({
        type: this.attribute,
        condition: this.condition,
        values: this.values
      });
      await AdvertisementAdminModule.updateSelected(this.updateDto);
      this.$toast.info("Targetgroup updated.");
    } catch (error) {
      this.$toast.error("Failed to update targetgroup.");
      this.$log.error(error);
    } finally {
      this.disabled = true;
      this.loading = false;
      this.addingValues = true;
      this.close();
    }
  }

  async remove(index: number) {
    this.loading = true;
    try {
      this.updateDto = new UpdateAdvertisementAdminDto(this.ad);
      this.updateDto.targetGroupConditions.splice(index, 1);
      await AdvertisementAdminModule.updateSelected(this.updateDto);
      this.$toast.info("Condition removed.");
    } catch (error) {
      this.$toast.error("Failed to remove condition.");
      this.$log.error(error);
    } finally {
      this.disabled = true;
      this.loading = false;
    }
  }

  setUpdateDto() {
    this.updateDto = new UpdateAdvertisementAdminDto(this.ad);
  }
}
