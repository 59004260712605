var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    staticClass: "ml-1",
    attrs: {
      "text": "",
      "outlined": "",
      "disabled": !_vm.thgIds.length,
      "loading": _vm.isLoading || _vm.loading,
      "color": "error"
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v(" REJECT ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "rightClick": _vm.reject,
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }