


































import { simpleDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { ThgChargingStationProofDtoGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";

import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import FileInput from "../utility/FileInput.vue";
import ThgAdminUpdatePayoutConfigurationDialog from "./ThgAdminUpdatePayoutConfigurationDialog.vue";
import ThgChargingStationInformationProofs from "./ThgChargingStationInformationProofs.vue";

@Component({
  components: {
    Card,
    ThgChargingStationInformationProofs,
    FileInput,
    ThgAdminUpdatePayoutConfigurationDialog,
    ConfirmActionDialog
  },
  filters: { simpleDate }
})
export default class ThgQuoteDetailInformationProofCard extends Vue {
  @Prop({ default: undefined })
  thg!: IThg;

  updateDialog = false;

  get noProofs() {
    if (!this.thg.proofs?.length) {
      return true;
    }
    return false;
  }

  get isLoading() {
    return ThgPortalModule.isLoadingProofs;
  }

  async onChange(file: File) {
    if (!file) {
      return;
    }
    /** Don't allow uploading the same file twice */

    const dto: ThgChargingStationProofDtoGen = {
      file: file
    };

    try {
      if (!this.thg?.id) {
        this.$toast("THG-ID not set");
        return;
      }
      ThgPortalModule.setProofLoading(true);
      const savedFile = await ThgPortalModule.uploadProofAdmin({
        data: dto,
        thgId: this.thg.id,
        partnerId: this.thg.partnerId
      });

      this.$emit("file-change", savedFile);
    } catch (error) {
      handleError(error);
    } finally {
      ThgPortalModule.setProofLoading(false);
      this.updateDialog = false;
    }
  }

  openUpdateDialog() {
    this.updateDialog = true;
  }
}
