





























































import { requiredRule } from "@/lib/rules/requiredRule";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: { ConfirmActionDialog }
})
export default class ThgBatchUpdateItemsByVinDialog extends DarkModeHighlightMixin {
  @Prop()
  thgs!: IThg[];

  dialog = false;
  resultDialog = false;
  vinListUnparsed = "";
  isFormValid = false;
  foundThgs: IThg[] = [];
  notFoundVins: string[] = [];
  get required() {
    return [requiredRule()];
  }

  initialize() {
    this.dialog = true;
    this.foundThgs = [];
    this.notFoundVins = [];
    this.vinListUnparsed = "";
  }

  /**
   * Parses all vins out of list
   */
  parse(vinList: string): string[] {
    const bits = vinList.replaceAll(".", "").split(" ");
    const vinBits = bits.filter(v => {
      return isNaN(Number(v));
    });

    return vinBits;
  }

  /**
   * Maps vins to items in batch
   * @param vins
   */
  mapVinsToIds(vins: string[]): { foundThgs: IThg[]; notFoundVins: string[] } {
    const thgs: IThg[] = [];
    const notFoundVins: string[] = [];
    for (const vin of vins) {
      const thg = this.thgs.find(v => {
        return v.registration.identificationnumber === vin;
      });

      if (thg) {
        thgs.push(thg);
      } else {
        notFoundVins.push(vin);
      }
    }

    return { foundThgs: thgs, notFoundVins };
  }

  /**
   * Emits thgs that are on list
   *
   * @param thgs
   */
  emit(thgs: IThg[]) {
    this.$emit("parsed", thgs);
    this.dialog = false;
    this.resultDialog = false;
  }

  seeResults() {
    const vins = this.parse(this.vinListUnparsed);
    this.$log.debug("parsed VINs:", vins);
    const data = this.mapVinsToIds(vins);

    this.foundThgs = data.foundThgs;
    this.notFoundVins = data.notFoundVins;
    this.resultDialog = true;
    this.dialog = false;
  }
  confirm() {
    this.emit(this.foundThgs);
  }
}
