/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { deepCopy } from "@/lib/utility/deep-copy";
import {
  ThgAdvertisementContentDtoGen,
  ThgAdvertisementViewmodelGen,
  ThgTargetGroupConditionDtoGen,
  ThgUpdateAdvertisementAdminDtoGen
} from "@/services/thg/v1/data-contracts";

/**
 * Creates a driver
 */
export class UpdateAdvertisementAdminDto implements ThgUpdateAdvertisementAdminDtoGen {
  /**
   *
   */
  tags: string[];

  /**
   *
   */
  monthlyBudget: number;

  /**
   *
   */
  targetGroupConditions: ThgTargetGroupConditionDtoGen[];

  /**
   *
   */
  content: ThgAdvertisementContentDtoGen;

  /**
   * Is activated
   * @example true
   */
  isActive?: boolean;

  /**
   * Currently used budged
   * @example 0.3
   */
  currentlyUsedBudget?: number;

  /**
   * Is out of budged
   * @example true
   */
  isOutOfBudged?: boolean;

  /**
   * Cost per playout
   * @example 0.3
   */
  costPerPlayout?: number;

  /**
   * Is marketing opt in required
   * @example true
   */
  isMarketingOptinRequired?: boolean;

  /**
   * Creates the object
   * @class
   */
  constructor(ad: ThgAdvertisementViewmodelGen) {
    ad = deepCopy(ad);
    this.tags = ad.tags;
    this.monthlyBudget = ad.monthlyBudget;
    this.targetGroupConditions = ad.targetGroupConditions;
    this.content = ad.content;
  }

  activate() {
    this.isActive = true;
  }

  deactivate() {
    this.isActive = false;
  }
}
