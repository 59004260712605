var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    attrs: {
      "loading": _vm.isLoading,
      "elevation": "false",
      "color": "info",
      "small": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v(" Download ")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "title": _vm.$t('components.thg.ThgQuoteExportDialog.title'),
      "leftText": _vm.$t('abort'),
      "rightText": _vm.$t('components.thg.ThgQuoteExportDialog.btnText')
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.exportCsv
    }
  }, [_c('v-radio-group', {
    model: {
      value: _vm.exportType,
      callback: function callback($$v) {
        _vm.exportType = $$v;
      },
      expression: "exportType"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "Excel",
      "value": "xlsx"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "CSV",
      "value": "csv"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }