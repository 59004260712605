




















































import { detailedDateWithDay, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { GoToHelper } from "@/lib/utility/goToHelper";
import { IThg } from "@/models/thg.entity";
import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import FilterCardPagination from "../filter/FilterCardPagination.vue";
import TicketDetailCardAssignees from "../project/TicketDetailCardAssignees.vue";
import CustomFieldValueChip from "../report/CustomFieldValueChip.vue";
import { IRefDetail } from "./RefsList.vue";
import Tooltip from "./tooltip.vue";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import ThgAdminUpdateStatusDialog from "@/components/thg/ThgAdminUpdateStatusDialog.vue";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    FilterCardPagination,
    Tooltip,
    TicketDetailCardAssignees,
    CustomFieldValueChip,
    ThgAdminUpdateStatusDialog
  },
  filters: {
    detailedDateWithDay,
    simpleDoubleDigitDate
  }
})
export default class RefsThg extends mixins(Vue) implements IRefDetail<IThg> {
  @Prop()
  item!: IThg;

  @Prop({ default: false })
  small!: boolean;

  @Ref("thgAdminUpdateStatusDialog")
  thgAdminUpdateStatusDialog!: ThgAdminUpdateStatusDialog;

  get status() {
    return thgStatusMap.get(this.item.status);
  }

  confirmable = false;

  isUpdateStatusLoading = false;

  openUpdateStatusDialog() {
    this.thgAdminUpdateStatusDialog?.openDialog();
  }

  hasClickListener() {
    return Boolean(this.$listeners && this.$listeners.click);
  }

  hasOpenDetailListener() {
    return Boolean(this.$listeners && this.$listeners.openDetail);
  }

  hasActionListener() {
    return Boolean(this.$listeners && this.$listeners.action);
  }

  simpleDoubleDigitDate(date: string) {
    return simpleDoubleDigitDate(date);
  }

  detailedDateWithDay(date: string) {
    return detailedDateWithDay(date);
  }

  emitOpenDetail() {
    this.$emit("openDetail", this.item);
  }

  click() {
    this.$emit("click", this.item);
  }

  action() {
    this.$emit("action", this.item);
  }

  async goToDetail() {
    const partnerId = this.item.partnerId;
    const id = this.item.id;

    new GoToHelper(this.$router).goToThgDetail(partnerId, id, true);
  }

  async updateStatus(status: ThgStatusEnum, userNotification: boolean) {
    this.isUpdateStatusLoading = true;

    this.item.updateStatus(status, userNotification).catch(handleError);

    this.isUpdateStatusLoading = false;
  }
}
