


































import { Component } from "vue-property-decorator";
import { ClipperModule } from "@/store/modules/clipper.store";
import ThgCropImage from "./ThgCropImage.vue";
import ThgCropImageActions from "./ThgCropImageActions.vue";
import Card from "../utility/Card.vue";
import ImageCropMixin from "@/mixins/ImageCropMixin.vue";
import { mixins } from "vue-class-component";

@Component({ components: { ThgCropImage, ThgCropImageActions, Card } })
export default class ThgCropImageDialog extends mixins(ImageCropMixin) {
  height = 0;

  get isDialogActive() {
    return ClipperModule.isCroppingActive;
  }
  set isDialogActive(isDialogActive: boolean) {
    ClipperModule.setIsCroppingActive(isDialogActive);
  }
  get cropData() {
    return ClipperModule.crop;
  }

  mounted() {
    let initiallyActive = ClipperModule.isCroppingInitiallyActive;
    if (initiallyActive && this.cropData) {
      initiallyActive = false;
    }

    this.isDialogActive = initiallyActive;
    this.resize();
  }

  resize() {
    this.height = window.innerHeight;
  }
}
