












































import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";

@Component({ components: { Card } })
export default class ThgBillingRecreationDialog extends Vue {
  @Prop()
  billing!: ThgBillingViewmodelGen;

  cancelBillingDialog = false;

  loading = false;

  billingNumberConfirmation = "";

  get allowedTypes() {
    return [BillingTypeEnum.CREDIT, BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT];
  }

  async confirmBillingRecreation() {
    this.loading = true;
    if (!this.billing.referenceIds && !this.billing.thgId) {
      throw new Error("No IDs in billing");
    }
    const ids = this.billing.referenceIds || [this.billing.thgId as string];
    try {
      const billing = await BillingBatchModule.createBilling({
        query: {},
        data: {
          billingType: this.billing.type,
          createAccountingRecord: true,
          createPdf: true,
          force: true,
          ids: ids
        }
      });
      this.$toast.success(this.$t("components.thg.ThgBillingRecreationDialog.successRecreation"), {
        onClick: () => this.goToBillingDetail(billing.billingNumber)
      });
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.cancelBillingDialog = false;
      this.loading = false;
    }
  }

  goToBillingDetail(billingNumber: number) {
    this.$router.push({
      name: "ThgBillingNumberDetailView",
      params: { billingNumber: billingNumber.toString() }
    });
  }
  cancelBillingRecreation() {
    this.cancelBillingDialog = false;
  }
}
