var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('v-btn', {
    staticClass: "ml-2 mr-2",
    attrs: {
      "text": "",
      "outlined": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.i18n["buttonLabel"]) + " ")]), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.i18n['title'],
      "isDialogActive": _vm.isDialogActive,
      "fullscreen": _vm.$vuetify.breakpoint.smAndDown,
      "supressKeyboardActions": true,
      "rightDisabled": _vm.loading,
      "leftDisabled": _vm.loading,
      "rightLoading": _vm.loading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.autoBill
    }
  }, [_c('v-card-actions', [_vm._v(" " + _vm._s(_vm.i18n["text"]) + " ")]), _c('v-card-actions', [_vm.isBatch ? _c('v-form', [_c('v-combobox', {
    attrs: {
      "multiple": "",
      "items": _vm.typeLabels,
      "type": "string",
      "label": _vm.$t('components.thg.thgBillingList.types'),
      "outlined": "",
      "rounded": ""
    },
    model: {
      value: _vm.billingTypes,
      callback: function callback($$v) {
        _vm.billingTypes = $$v;
      },
      expression: "billingTypes"
    }
  }), _c('v-select', {
    attrs: {
      "items": [true, false],
      "type": "string",
      "label": _vm.$t('components.thg.ThgBillingCreditAutoBillDialog.checkCompletenessOnly'),
      "outlined": "",
      "rounded": ""
    },
    model: {
      value: _vm.checkCompletenessOnly,
      callback: function callback($$v) {
        _vm.checkCompletenessOnly = $$v;
      },
      expression: "checkCompletenessOnly"
    }
  })], 1) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }