/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { DetailFormComponentsEnum } from "@/lib/enum/detail-form-components.enum";
import { PromotionTypeEnum } from "@/lib/enum/promotion-type.enum";
import { Form, FormConfig, IsFormable } from "@/lib/formable";
import { requiredRule } from "@/lib/rules/requiredRule";
import promotionService from "@/services/thg/services/promotionService";
import { ThgCreatePromotionDtoGen } from "@/services/thg/v1/data-contracts";
import { CurrencyUnitEnum } from "@/store/enum/thg/currency-unit.enum";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";
import { PromotionDataAccessLayer } from "@/store/modules/access-layers/promotion.access-layer";
import { IPromotion, Promotion } from "@/models/promotion.entity";
import { handleError } from "@/lib/utility/handleError";
import { PartnerModule } from "@/store/modules/partner";

/**
 *
 */
@IsFormable
class CreatePromotion extends CreateDto<IPromotion> implements ThgCreatePromotionDtoGen {
  @FormConfig({
    category: "common.nouns.partner",
    searchKeywords: ["common.nouns.partner", "objects.promotion.partnerId"],
    type: DetailFormComponentsEnum.AUTO_COMPLETE,
    props: {
      label: "objects.promotion.partnerId",
      getItems: () => PartnerModule.partners,
      itemValue: "id",
      itemText: "companyName",
      rules: [requiredRule()]
    }
  })
  partnerId = "";

  @FormConfig({
    category: "objects.promotion.value",
    searchKeywords: ["objects.promotion.value"],
    type: DetailFormComponentsEnum.TEXT_FIELD,
    props: {
      label: "objects.promotion.value",
      type: "number",
      rules: [requiredRule()]
    }
  })
  value = 0;

  @FormConfig({
    category: "objects.promotion.value",
    searchKeywords: ["objects.promotion.value", "objects.promotion.type"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      label: "objects.promotion.currency",
      items: Object.values(CurrencyUnitEnum),
      rules: [requiredRule()]
    }
  })
  currency = CurrencyUnitEnum.EUR;

  @FormConfig({
    category: "common.nouns.configuration",
    searchKeywords: ["common.nouns.configuration", "objects.promotion.type"],
    type: DetailFormComponentsEnum.SELECT_FIELD,
    props: {
      label: "objects.promotion.type",
      items: Object.values(PromotionTypeEnum),
      rules: [requiredRule()]
    }
  })
  type = PromotionTypeEnum.FIRST_PURCHASE;

  title = "";

  subtitle = "";

  description = "";

  hint = "";

  imageId?: string;

  endDate?: string | undefined;

  isActive?: boolean | undefined;

  isArchived?: boolean | undefined;

  startDate?: string | undefined;

  async create(silent = true) {
    try {
      return await this.createUnhandled();
    } catch (e) {
      handleError(e);
      if (!silent) throw e;
    }
  }

  async createUnhandled() {
    const created = await promotionService.create(this.partnerId, {
      value: Number(this.value),
      currency: this.currency,
      type: this.type,
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      hint: this.hint,
      imageId: this.imageId,
      endDate: this.endDate,
      startDate: this.startDate,
      isActive: this.isActive,
      isArchived: this.isArchived
    });

    const promotion = new Promotion(created);

    PromotionDataAccessLayer.set(promotion);

    return promotion;
  }
}

type ICreatePromotionDto = CreatePromotion;
const CreatePromotionDto = Form.createForClass(CreatePromotion);

export { ICreatePromotionDto, CreatePromotionDto };
