var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "ma-2",
    attrs: {
      "outlined": "",
      "min-width": "1000px",
      "flat": "",
      "title": _vm.$t('components.thg.thgAdvertismentTargetGroupInformationCard.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-menu', {
          attrs: {
            "close-on-content-click": false,
            "nudge-width": 200
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "loading": _vm.loading,
                  "small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
            }
          }]),
          model: {
            value: _vm.menu,
            callback: function callback($$v) {
              _vm.menu = $$v;
            },
            expression: "menu"
          }
        }, [_c('v-card', {
          attrs: {
            "min-width": "400px"
          }
        }, [_c('v-form', {
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v;
            },
            expression: "valid"
          }
        }, [_c('v-card-text', [_c('v-autocomplete', {
          attrs: {
            "rules": _vm.rule,
            "items": _vm.conditions,
            "disabled": _vm.loading,
            "outlined": "",
            "label": _vm.$t('components.thg.thgAdvertismentTargetGroupInformationCard.type')
          },
          model: {
            value: _vm.condition,
            callback: function callback($$v) {
              _vm.condition = $$v;
            },
            expression: "condition"
          }
        }), _c('v-autocomplete', {
          attrs: {
            "rules": _vm.rule,
            "items": _vm.attributes,
            "outlined": "",
            "disabled": _vm.loading,
            "label": _vm.$t('components.thg.thgAdvertismentTargetGroupInformationCard.attribute')
          },
          model: {
            value: _vm.attribute,
            callback: function callback($$v) {
              _vm.attribute = $$v;
            },
            expression: "attribute"
          }
        }), _c('v-switch', {
          model: {
            value: _vm.addingValues,
            callback: function callback($$v) {
              _vm.addingValues = $$v;
            },
            expression: "addingValues"
          }
        }), _vm.addingValues ? _c('v-combobox', {
          attrs: {
            "rules": _vm.arrayRule,
            "disabled": _vm.loading,
            "label": _vm.$t('components.thg.thgAdvertismentTargetGroupInformationCard.value'),
            "multiple": "",
            "outlined": "",
            "chips": ""
          },
          model: {
            value: _vm.values,
            callback: function callback($$v) {
              _vm.values = $$v;
            },
            expression: "values"
          }
        }) : _vm._e()], 1), _c('v-card-actions', {
          staticClass: "mt-n8"
        }, [_c('v-btn', {
          attrs: {
            "text": "",
            "disabled": _vm.loading
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgAdvertismentTargetGroupInformationCard.cancel")) + " ")]), _c('v-btn', {
          attrs: {
            "color": "primary",
            "text": "",
            "loading": _vm.loading,
            "disabled": !_vm.valid
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgAdvertismentTargetGroupInformationCard.save")) + " ")])], 1)], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, [_vm.ad.targetGroupConditions.length === 0 ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgAdvertismentTargetGroupInformationCard.hint")) + " ")]) : _c('v-list', _vm._l(_vm.ad.targetGroupConditions, function (targetGroupCondition, i) {
    return _c('v-list-item', {
      key: i
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(targetGroupCondition.type) + " (" + _vm._s(targetGroupCondition.condition) + ")")]), targetGroupCondition.values ? _c('v-list-item-subtitle', [_c('v-chip-group', _vm._l(targetGroupCondition.values, function (value) {
      return _c('v-chip', {
        key: value,
        attrs: {
          "small": ""
        }
      }, [_vm._v(" " + _vm._s(value) + " ")]);
    }), 1)], 1) : _vm._e()], 1), _c('v-list-item-action', [_c('simple-delete-dialog', {
      attrs: {
        "disabled": _vm.loading,
        "itemToDelete": i
      },
      on: {
        "remove": function remove($event) {
          return _vm.remove(i);
        }
      }
    })], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }