var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "loading": _vm.isLoading,
            "icon": ""
          },
          on: {
            "click": _vm.initialize
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [!_vm.isLoading ? _c('v-card', [_c('v-card-title', [_c('span', [_vm._v(_vm._s(_vm.$t(_vm.title)))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-form', {
    ref: "form",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "rules": _vm.requiredRules,
      "type": "string",
      "label": "Numberplate *",
      "outlined": ""
    },
    model: {
      value: _vm.updateThgDto.numberplate,
      callback: function callback($$v) {
        _vm.$set(_vm.updateThgDto, "numberplate", $$v);
      },
      expression: "updateThgDto.numberplate"
    }
  }), _vm.showImpactFactor ? _c('v-text-field', {
    attrs: {
      "rules": _vm.impactFactorRules,
      "type": "number",
      "label": "Impactfactor *",
      "outlined": ""
    },
    model: {
      value: _vm.updateThgDto.impactFactor,
      callback: function callback($$v) {
        _vm.$set(_vm.updateThgDto, "impactFactor", _vm._n($$v));
      },
      expression: "updateThgDto.impactFactor"
    }
  }) : _c('v-text-field', {
    attrs: {
      "value": _vm.baseFactor,
      "readonly": "",
      "outlined": "",
      "label": "Impactfactor"
    }
  }), _c('v-select', {
    attrs: {
      "rules": _vm.requiredRules,
      "items": _vm.activeImpactTypeItems,
      "item-text": "impactType",
      "type": "text",
      "label": "Impacttype *",
      "return-object": "",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.activateImpactItem,
      callback: function callback($$v) {
        _vm.activateImpactItem = $$v;
      },
      expression: "activateImpactItem"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "string",
      "label": "Code",
      "outlined": ""
    },
    model: {
      value: _vm.updateThgDto.code,
      callback: function callback($$v) {
        _vm.$set(_vm.updateThgDto, "code", $$v);
      },
      expression: "updateThgDto.code"
    }
  })], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "mx-4 mb-4",
    attrs: {
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.transfer
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.btnText)) + " ")])], 1)], 1)], 1) : _vm._e(), _c('v-card', [_vm.isLoading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, article, actions"
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }