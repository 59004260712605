var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('m-header', {
    attrs: {
      "title": "Ladestrom",
      "subtitle": _vm.subtitle,
      "breadCrumbs": _vm.breadCrumbs
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('analytics-date-range-selector-dialog', {
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "dates": _vm.dates
          },
          on: {
            "setDate": _vm.loadDataForDateRange
          }
        })];
      },
      proxy: true
    }])
  }), _c('thg-analytics-meter-reading-table', {
    attrs: {
      "loading": _vm.dataHandler.thgCustomerData.isLoading
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }