var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', [_c('batch-detail-card', {
    attrs: {
      "loading": _vm.getloadingSelectedBatch
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }