



































































































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgAdvertisementViewmodelGen, ThgUpdateAdvertisementAdminDtoGen } from "@/services/thg/v1/data-contracts";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { UpdateAdvertisementAdminDto } from "@/models/Advertisment/update-advertisment";
import { AdvertisementAdminModule } from "@/store/modules/advertisment-admin-store";

@Component({
  components: { Card }
})
export default class ThgAdvertismentAdminInformationCard extends DarkModeHighlightMixin {
  @Prop()
  ad!: ThgAdvertisementViewmodelGen;

  @Prop({ default: true })
  disabled!: boolean;

  @Prop({ default: false })
  loading!: boolean;

  updateDto!: ThgUpdateAdvertisementAdminDtoGen;

  enableChanges() {
    this.setUpdateDto();
    this.disabled = false;
  }

  close() {
    this.disabled = true;
    this.setUpdateDto();
  }

  async save() {
    this.loading = true;
    await AdvertisementAdminModule.updateSelected(this.updateDto);
    this.disabled = true;
    this.loading = false;
  }

  setUpdateDto() {
    this.updateDto = new UpdateAdvertisementAdminDto(this.ad);
    this.updateDto.costPerPlayout = this.ad.costPerPlayout;
    this.updateDto.currentlyUsedBudget = this.ad.currentlyUsedBudget;
    this.updateDto.monthlyBudget = this.ad.monthlyBudget;
    this.updateDto.isMarketingOptInRequired = this.ad.isMarketingOptInRequired;
  }

  get costPerPlayout() {
    return this.ad.costPerPlayout.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }

  get currentlyUsedBudget() {
    return this.ad.currentlyUsedBudget.toLocaleString("de-DE", {
      style: "currency",
      currency: "EUR"
    });
  }
}
