/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { IPageViewModel } from "@/lib/utility/data/page-view-model.interface";
import efahrerService from "@/services/efahrer/efahrer.service";
import {
  ThgEfahrerControllerFindAllParamsGen,
  ThgEfahrerOrderViewModelGen,
  ThgPageMetaViewModelGen,
  ThgUpdateEfahrerThgOrderStatusDtoGen
} from "@/services/thg/v1/data-contracts";
import store from "@/store/VuexPlugin";
import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "efahrer",
  store
})
export class Efahrer extends VuexModule {
  _orders: ThgEfahrerOrderViewModelGen[] = [];
  _page: IPageViewModel<ThgEfahrerOrderViewModelGen> | null = null;

  _order: ThgEfahrerOrderViewModelGen | null = null;

  get orders(): ThgEfahrerOrderViewModelGen[] {
    return this._orders;
  }

  @Mutation
  mutateOrders(orders: ThgEfahrerOrderViewModelGen[]) {
    this._orders = orders;
  }

  @Mutation
  mutatePage(meta: IPageViewModel<ThgEfahrerOrderViewModelGen>) {
    this._page = meta;
  }

  @Action
  async getOrders(query: ThgEfahrerControllerFindAllParamsGen) {
    const orders: ThgEfahrerOrderViewModelGen[] = [];

    const firstPage = await efahrerService.getAll(query);
    let meta: ThgPageMetaViewModelGen = firstPage.meta;

    if (firstPage.data) {
      orders.push(...firstPage.data);
    }

    if (firstPage.meta.totalPages > 1) {
      for (let page = 2; page <= firstPage.meta.totalPages; page++) {
        const r = await efahrerService.getAll({ ...query, page: page });
        meta = r.meta;
        if (r.data) {
          orders.push(...r.data);
        }
      }
    }

    this.context.commit("mutateOrders", orders);
    this.context.commit("mutatePage", meta);

    return orders;
  }

  get order(): any {
    return this._order;
  }

  @Mutation
  mutateOrder(order: any) {
    this._order = order;
  }

  @Action
  async getOrder(id: number) {
    const order = await efahrerService.get(id);

    this.context.commit("mutateOrder", order);

    return order;
  }

  @Action
  async setStatus(data: { id: number; status: ThgUpdateEfahrerThgOrderStatusDtoGen }) {
    const order = await efahrerService.setStatus(data.id, data.status);

    this.context.commit("mutateOrder", order);

    return order;
  }
}

export const EfahrerModule = getModule(Efahrer);
