









































































































































import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { Component } from "vue-property-decorator";
import Card from "@/components/utility/Card.vue";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { BillingModule } from "@/store/modules/billing.store";
import { ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { BatchModule } from "@/store/modules/batch.store";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { filterJson } from "@/lib/utility/filter";
import { CheckEnum } from "@/lib/enum/check.enum";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import ThgBillingPlausibilityCheckDialog from "@/components/thg/ThgBillingPlausibilityCheckDialog.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Debug from "@/components/utility/Debug.vue";
import ThgBillingCreditAutoBillDialog from "./ThgBillingAutoBillDialog.vue";
import { IThg } from "@/models/thg.entity";

@Component({
  components: {
    Card,
    ThgBillingPlausibilityCheckDialog,
    ThgBillingCreditAutoBillDialog,
    TheLayoutPortal,
    ConfirmActionDialog,
    Debug
  }
})
export default class ThgBillingView extends DarkModeHighlightMixin {
  selected: IThg[] = [];
  public search = "";

  filter = { status: [ThgStatusEnum.CONFIRMED_BY_AGENCY], notBilled: true, dateRange: ["2000-01-01", "2099-12-31"] };

  filterDialog = false;

  billingType = (this.$route.params.billingType as BillingTypeEnum) || BillingTypeEnum.CREDIT;

  isLoading = false;

  get startPayoutTitle() {
    if (this.billingType === BillingTypeEnum.CREDIT) {
      return "THG Auszahlung";
    }
    if (this.billingType === BillingTypeEnum.AFFILIATE) {
      return "Affiliate Auszahlung";
    }
    return "";
  }

  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get billableTypes() {
    return [BillingTypeEnum.AFFILIATE, BillingTypeEnum.CREDIT];
  }

  get thgStatusList() {
    return Object.values(ThgStatusEnum);
  }

  get readyForBilling(): IThg[] | ThgThgMeterReadingViewModelGen[] {
    return BillingModule.readyForBilling;
  }

  get headers() {
    return [
      { text: this.$t("components.thg.thgBillingSelection.created"), value: "timestamp.created" },
      { text: this.$t("components.thg.thgBillingSelection.id"), value: "id" },
      { text: this.$t("components.thg.thgBillingSelection.numberplate"), value: "numberplate" },
      { text: this.$t("components.thg.thgBillingSelection.name"), value: "registration.name" }
    ];
  }

  get CheckEnum() {
    return CheckEnum;
  }

  async refilter() {
    if (this.filter.notBilled) {
      this.loadThgs();
    }
  }

  /**
   * Loads all thgs that are shown in the list depending on the filter
   */
  async loadThgs() {
    try {
      this.isLoading = true;
      if (!this.filter.notBilled) {
        await this.loadBasedOnThgStatus();
      } else {
        await BillingModule.getReadyForBilling({
          billingType: this.billingType,
          from: new Date(this.filter.dateRange[0]).toISOString(),
          to: new Date(this.filter.dateRange[1]).toISOString()
        });
      }
      BillingModule.setReadyForBillingLoading(false);
    } catch (e) {
      this.$log.error(e);
      this.$toast((e as any).message);
    } finally {
      this.isLoading = false;
      this.filterDialog = false;
    }
  }

  async loadBasedOnThgStatus() {
    if (this.filter.dateRange) {
      BatchModule.setDateRange(this.filter.dateRange);
    }

    let thgs: IThg[] = [];
    for (const f of this.filter.status) {
      if ((f as string) !== "not_billed") {
        await BatchModule.getThgsByStatusAndDateRange(f as ThgStatusEnum);
        thgs = thgs.concat(...BatchModule.allThgsToBatch);
      }
    }

    BillingModule.setReadyForBilling(thgs);
  }

  mounted() {
    this.loadThgs();
  }

  startThgPayoutBillingProcess() {
    const createdBillings = new Array(this.selected.length);

    BillingModule.setCreatedBillings(createdBillings);
    BillingModule.setSelectedThgsForBilling(this.selected);

    this.$router.push({ name: "ThgBillingSelectionItemView", params: { billingType: this.billingType, i: "0" } });
  }
  goToThgDetail(partnerId: string, thgId: string) {
    this.$router.push({
      name: "ThgQuoteDetailView",
      params: { partnerId: partnerId, thgId: thgId }
    });
  }

  customFilter(value: any, search: string, item: any) {
    return filterJson(value, search, item);
  }
}
