var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline"
  }, [_c('v-btn', {
    on: {
      "click": function click($event) {
        _vm.isDialogActive = true;
      }
    }
  }, [_vm._v("Import")]), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "width": 1000,
      "hideRight": _vm.hideRight,
      "persistent": true,
      "supressKeyboardConfirm": true,
      "title": "THG-Quote Beantragungsassistent für Flotten",
      "leftText": "Zurück",
      "rightText": "Weiter"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": _vm.left,
      "rightClick": _vm.right,
      "close": _vm.reset
    }
  }, [_c('v-window', {
    model: {
      value: _vm.page,
      callback: function callback($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }, [_c('v-window-item', [_c('v-row', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(" Willkommen im THG-Quoten Beantragungsassistent für Flotten und Fuhrparks. ")]), _c('v-card-text', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap "
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgClosing.confirmationPrivacy")) + " ")]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap "
  }, [_vm._v(" " + _vm._s(_vm.$t("report.thgClosing.confirmation")) + " ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Vorbereitung")]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap "
  }, [_vm._v(" Um den Prozess zu vereinfachen empfehlen wir alle Bilder der Fahrzeugscheine in einem Ordner in dem File-Explorer vorzubereiten. Dazu sollte die Vorderseite des Fahrzeugsschein als Nummernschild, z.B. "), _c('strong', [_vm._v("OS-MW 123.jpg")]), _vm._v(" sowie Rückseite durch ein angehängte Postfix, z.B. "), _c('strong', [_vm._v("OS-MW 123-Rückseite.jpg")]), _vm._v(" gekennzeichnet werden. Wir erlauben die folgenden Dateitypen: .jpg, .jpeg, .png, .webp. ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Vertragskonditionen")]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap "
  }, [_vm._v(" Im ersten Schritt werden die Vertragskonditionen bestimmt. Wähle zwischen verschiedenen Beantragungsjahren. ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Beantragung")]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap "
  }, [_vm._v(" Im zweiten Schritt werden die Fahrzeug sturkturiert durch das jeweilige Hochladen der Vorder- und Rückseite beantragen. ")]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap "
  }, [_c('strong', [_vm._v("Wir übernehmen den Dateinamen der Vorderseite als Nummernschild.")])]), _c('v-list-item-subtitle', [_vm._v(" Alle in diesem Dialog beantragten Fahrzeuge werden in einer Liste angezeigt, um doppelte Anträge zu vermeiden. ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Abschluss und Bestätigung")]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap"
  }, [_vm._v(" Dieser Dialog kann jederzeit geschlossen werden. Ein Vorgang wird durch eine grüne Benachrichtigung bestätigt. Pro erfolgreichem Antrag wird eine E-Mail gesendet. ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('v-window-item', [_c('v-row', [_c('v-list-item', [_c('v-icon', [_vm._v(" mdi-information-variant ")]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap ml-2"
  }, [_vm._v(" Gewünschten Vertragskonditionen. ")])], 1)], 1)], 1), _c('v-row', [_c('v-col', [_c('v-radio-group', {
    model: {
      value: _vm.years,
      callback: function callback($$v) {
        _vm.years = $$v;
      },
      expression: "years"
    }
  }, _vm._l(_vm.availableProducts, function (products, i) {
    return _c('v-sheet', {
      key: i,
      staticClass: "mb-2 py-n6",
      attrs: {
        "outlined": "",
        "rounded": "",
        "color": _vm.activeCardStyle(products.years)
      }
    }, [_c('v-card', {
      attrs: {
        "flat": ""
      },
      on: {
        "click": function click($event) {
          _vm.years = products.years;
        }
      }
    }, [_c('v-list', {
      attrs: {
        "three-line": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.$t(products.title.token, products.title.values)) + " "), products.prices.length > 1 ? _c('v-chip', {
      attrs: {
        "color": "success",
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("report.thgProducts.popular")) + " ")]) : _vm._e()], 1), products.prices.length > 1 ? _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.transformYearsToString(products.years)) + " ")]) : _vm._e(), _vm._l(products.prices, function (price, idx) {
      return _c('div', {
        key: idx
      }, [_c('v-list-item-subtitle', [_c('v-icon', {
        attrs: {
          "small": ""
        }
      }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t(price.paymentDate.token, price.paymentDate.values)) + " ")], 1)], 1);
    }), _c('v-list-item-subtitle', [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check")]), _vm._v(" " + _vm._s(_vm.$t("report.thgProducts.paymentDate")) + " ")], 1)], 2), _c('v-list-item-action', [_c('v-radio', {
      attrs: {
        "value": products.years
      }
    })], 1)], 1), products.prices.length > 1 ? _c('div', {
      staticClass: "text-caption mb-4 mt-4 mx-4"
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-check-decagram")]), _vm._v(" " + _vm._s(_vm.$t("report.thgProducts.multipleYear", {
      amount: _vm.numberToWord(products.prices.length)
    })) + " ")], 1) : _vm._e()], 1)], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('v-window-item', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-list-item', [_c('v-icon', [_vm._v(" mdi-information-variant ")]), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "text-wrap ml-2"
  }, [_vm._v(" Wir empfehlen die Vorderseite nach dem Nummernschild zu benennen z.B. "), _c('strong', [_vm._v(" OS-MW 123.jpg ")]), _vm._v(" die Rückseite entsprechend z.B. "), _c('strong', [_vm._v(" OS-MW 123-R.jpg ")])]), _c('v-list-item-subtitle', {
    staticClass: "text-wrap ml-2"
  }, [_vm._v(" Erlaubte Dateitypen: .jpg, .jpeg, .png, .webp. Der Antrag kann mit der "), _c('strong', [_vm._v("Enter")]), _vm._v(" Taste abgeschlossen werden. ")])], 1)], 1)], 1)], 1), _c('v-form', {
    ref: "quoteForm",
    staticClass: "ma-4",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.create.apply(null, arguments);
      },
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.create.apply(null, arguments);
      }
    },
    model: {
      value: _vm.isValid,
      callback: function callback($$v) {
        _vm.isValid = $$v;
      },
      expression: "isValid"
    }
  }, [_c('debug', {
    attrs: {
      "debug": _vm.years
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('thg-registration-upload-card', {
    on: {
      "front-uploaded": _vm.uploaded,
      "back-uploaded": _vm.focusNumberplate
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    ref: "numberplate",
    attrs: {
      "rules": _vm.numberplateRule,
      "label": _vm.$t('components.thg.ThgQuoteForm.numberplate'),
      "hint": _vm.$t('report.thgClosing.confirmationPrivacy') + " Mit Enter f\xFCr ".concat(_vm.years.join(', '), " beantragen."),
      "required": "",
      "outlined": "",
      "data-test-form-numberplate": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-icon', _vm._g({}, on), [_vm._v(" mdi-help-circle-outline ")])];
            }
          }])
        }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgQuoteForm.tooltip.numberplate")) + " ")])];
      },
      proxy: true
    }, {
      key: "append-outer",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "disabled": !_vm.canSubmit,
            "loading": _vm.isLoading,
            "text": "",
            "color": "success"
          },
          on: {
            "click": _vm.create
          }
        }, [_vm._v(" Absenden "), _c('v-icon', {
          staticClass: "ml-2"
        }, [_vm._v(" mdi-send ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.numberplate,
      callback: function callback($$v) {
        _vm.numberplate = $$v;
      },
      expression: "numberplate"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("Kennzeichen Kontrolle:")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.hasPreview ? _c('v-img', {
    attrs: {
      "src": _vm.getPreview,
      "min-height": "400"
    }
  }) : _c('v-img', {
    attrs: {
      "min-height": "400",
      "src": _vm.registrationImage
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('span', {
    staticClass: "mr-2 mt-1"
  }, [_vm._v("Beantragt:")]), _vm._l(_vm.numberplates, function (numberplate, i) {
    return _c('v-chip', {
      key: i,
      staticClass: "mr-1 mt-1",
      attrs: {
        "small": ""
      }
    }, [_vm._v(" " + _vm._s(numberplate) + " ")]);
  })], 2)], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }