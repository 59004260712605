var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "800",
      "fullscreen": _vm.$vuetify.breakpoint.xsOnly,
      "transition": "dialog-bottom-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "disabled": _vm.disabled,
            "depressed": "",
            "color": "error",
            "loading": _vm.loading,
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-delete-outline")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    class: _vm.color
  }, [_c('span', [_vm._v(_vm._s(_vm.$t(_vm.title)))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    },
    on: {
      "click": _vm.emitCancel
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-actions', {
    staticClass: "mt-n4"
  }, [_c('v-btn', {
    staticClass: "mx-4 mb-4",
    attrs: {
      "depressed": "",
      "color": "info"
    },
    on: {
      "click": _vm.emitCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.DeleteDialog.cancel")) + " ")]), _c('v-btn', {
    staticClass: "mx-4 mb-4",
    attrs: {
      "depressed": "",
      "color": "error"
    },
    on: {
      "click": _vm.emitDelete
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.dialog.DeleteDialog.delete")) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }