




































import AffiliateDetailInformationCard from "@/components/thg/AffiliateDetailInformationCard.vue";
import { IAffiliateType } from "@/lib/interfaces/affiliate/IAffiliateType";
import { affiliateTypeMap } from "@/lib/utility/affiliateTypeMap";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop } from "vue-property-decorator";
import { AssetRepository, AssetEnum } from "@/lib/AssetRepository";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    AffiliateDetailInformationCard
  }
})
export default class AffiliateDetailCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: true })
  displayToDetailView!: boolean;

  @Prop()
  selectedUser!: IAdminUser;

  affiliateTypeMap = affiliateTypeMap;

  affiliateType(affiliateType: any): IAffiliateType {
    return (
      this.affiliateTypeMap.get(affiliateType) || {
        type: "components.thg.affiliateTypeMap.undefinedType",
        description: "components.thg.affiliateTypeMap.undefinedDescription"
      }
    );
  }

  get selectedAffiliate() {
    return AffiliatePortalModule.selectedAffiliate;
  }

  get isSelectedAffiliate() {
    return "id" in this.selectedAffiliate;
  }

  get fullname() {
    return this.selectedUser?.firstName + " " + this.selectedUser?.lastName;
  }

  /**
   * The no image source. From the @see AssetRepository.
   */
  get emptyDataSrc() {
    return AssetRepository.getAsset(false, AssetEnum.emptyData);
  }

  toDetailView() {
    this.$router.push({ name: "AffiliateDetailView", params: { affiliateId: this.selectedAffiliate.id } });
  }
}
