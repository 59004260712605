


















import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { Component, Vue } from "vue-property-decorator";
import ErrorCard from "../../components/utility/ErrorCard.vue";

@Component({
  components: {
    LayoutSimple,
    ErrorCard
  }
})
export default class ThgPortalErrorPage extends Vue {
  items = [
    {
      text: "Website",
      disabled: false,
      href: "https://www.wirkaufendeinethg.de"
    },
    {
      text: "FAQ",
      disabled: false,
      href: "https://www.wirkaufendeinethg.de/faq/"
    },
    {
      text: "Impressum",
      disabled: false,
      href: "https://www.wirkaufendeinethg.de/impressum/"
    }
  ];

  loading = false;

  async reload() {
    try {
      this.loading = true;
      this.$router.push({ name: "ThgDashboard" });
    } catch (error) {
      this.$log.error(error);
      this.$router.push({ name: "PartnerError" });
    } finally {
      this.loading = false;
    }
  }
}
