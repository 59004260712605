/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgAdminPurchasingPriceControllerFindAllAdminParamsGen,
  ThgExceptionViewmodelGen,
  ThgPageViewModelGen,
  ThgPriceViewModelGen
} from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class AdminPriceService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags Admin Price Service
   * @name AdminPurchasingPriceControllerFindAllAdmin
   * @summary Get all prices
   * @request GET:/price
   * @secure
   * @response `200` `(ThgPageViewModelGen & { data?: (ThgPriceViewModelGen)[] })`
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminPurchasingPriceControllerFindAllAdmin = (
    query: ThgAdminPurchasingPriceControllerFindAllAdminParamsGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgPageViewModelGen & { data?: ThgPriceViewModelGen[] }, ThgExceptionViewmodelGen>({
      path: `/price`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags Admin Price Service
   * @name AdminPurchasingPriceControllerFindAllActiveAdmin
   * @summary (ADMIN) List  all active prices
   * @request GET:/price/active
   * @secure
   * @response `200` `(ThgPriceViewModelGen)[]` The price
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `404` `ThgExceptionViewmodelGen` NotFound.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  adminPurchasingPriceControllerFindAllActiveAdmin = (params: RequestParams = {}) =>
    this.http.request<ThgPriceViewModelGen[], ThgExceptionViewmodelGen>({
      path: `/price/active`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
}
