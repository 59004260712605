

































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { IThg } from "@/models/thg.entity";
import ThgBillingSelectionItemBankingData from "./ThgBillingSelectionItemBankingData.vue";
import ThgBillingSelectionItemPayoutData from "./ThgBillingSelectionItemPayoutData.vue";
import ThgBillingSelectionItemBatchData from "./ThgBillingSelectionItemBatchData.vue";
import ThgBillingSelectionItemContactData from "./ThgBillingSelectionItemContactData.vue";
import ThgBillingSelectionItemRegistrationData from "./ThgBillingSelectionItemRegistrationData.vue";
import ThgBillingSelectionItemRegistrationImage from "./ThgBillingSelectionItemRegistrationImage.vue";
import ThgBillingSelectionItemPartnerData from "./ThgBillingSelectionItemPartnerData.vue";
import ThgBillingSelectionItemBillingResult from "./ThgBillingSelectionItemBillingResult.vue";
import ThgBillingSelectionItemAccountingResult from "./ThgBillingSelectionItemAccountingResult.vue";
import ThgBillingSelectionItemPdfResult from "./ThgBillingSelectionItemPdfResult.vue";
import TemplateDialog from "../template/TemplateDialog.vue";

@Component({
  components: {
    ThgBillingSelectionItemContactData,
    ThgBillingSelectionItemRegistrationData,
    ThgBillingSelectionItemBankingData,
    ThgBillingSelectionItemPayoutData,
    ThgBillingSelectionItemBatchData,
    ThgBillingSelectionItemPartnerData,
    ThgBillingSelectionItemRegistrationImage,
    ThgBillingSelectionItemBillingResult,
    ThgBillingSelectionItemPdfResult,
    ThgBillingSelectionItemAccountingResult,
    TemplateDialog
  }
})
export default class ThgBillingSelectionCreditItem extends DarkModeHighlightMixin {
  @Prop()
  thg!: IThg;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }
}
