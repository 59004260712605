


















import { Component, Vue, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import Card from "@/components/utility/Card.vue";
import { ClipperModule } from "@/store/modules/clipper.store";

@Component({ components: { LatestEntriesCardEmpty, ConfirmActionDialog, Card } })
export default class ThgCroppedImage extends Vue {
  get src() {
    return ClipperModule.src;
  }
  get crop() {
    return ClipperModule.crop;
  }
  get transformation() {
    return ClipperModule.imageStyling;
  }
  get isImages() {
    return ClipperModule.isImages;
  }

  @Watch("src", { deep: true })
  @Watch("crop", { deep: true })
  cropIt() {
    this.$log.debug("Cropping in preview");
    if (!this.isImages) {
      return;
    }
    if (!this.src) {
      throw new Error("No src");
    }
    const outerContainer = this.$refs.outerContainer as HTMLDivElement;
    if (!outerContainer) {
      throw new Error("No outerContainer");
    }
    const container = this.$refs.container as HTMLDivElement;
    if (!container) {
      throw new Error("No Container");
    }
    const canvas = this.$refs.canvas as HTMLCanvasElement;
    if (!canvas) {
      throw new Error("No Canvas");
    }
    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("No Context");
    }

    const image = new Image();
    image.crossOrigin = "Anonymous"; // without this the canvas is tainted and the data url can not be exported
    image.src = this.src;

    const draw = () => {
      const cropX = this.crop?.x || 0; //x-coordinate from which to start cropping the image
      const cropY = this.crop?.y || 0; //y-coordinate from which to start cropping the image
      const cropWidth = this.crop?.width || image.width; //The width of the cropped version, starting from sx and sy.
      const cropHeight = this.crop?.height || image.height; //The height of the cropped version, starting from sx and sy.
      const containerHeight = outerContainer.clientHeight; // The width of the cropped version to be displayed.
      const containerWidth = outerContainer.clientWidth; // The width of the cropped version to be displayed.

      const increaseFactorHeight = containerHeight / cropHeight;
      const increaseFactorWidth = containerWidth / cropWidth;
      let factor = increaseFactorHeight; // how the crop width must be scaled to the container width to fit horizontally and vertically
      if (increaseFactorWidth < factor) {
        factor = increaseFactorWidth;
      }

      const factoredContainerWidth = cropWidth * factor; // how the crop width must be scaled to the container width to fit horizontally
      const factoredContainerHeight = cropHeight * factor; // how the crop width must be scaled to the container width to fit horizontally
      container.style.width = `${factoredContainerWidth}px`;
      container.style.height = `${factoredContainerHeight}px`;

      this.$log.debug("drawing", cropX, cropY, cropWidth, cropHeight);
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      context.drawImage(
        image, //The image object to crop
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );

      const croppedImageDataUrl = canvas.toDataURL();
      const i = new Image(factoredContainerWidth, factoredContainerHeight);
      i.src = croppedImageDataUrl;
      i.setAttribute("margin", "auto");
      container.innerHTML = "";
      container.appendChild(i);
    };

    image.onload = draw;
  }
}
