






















































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import ThgBatchSelectDialog from "@/components/thg/ThgBatchSelectDialog.vue";
import { ThgDuplicateFilter } from "@/lib/thg/ThgDuplicateFilter";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { AnalyticsDateRangeSelectorDialog, ThgBatchSelectDialog }
})
export default class ThgBatchDuplicateCheckDialog extends DarkModeHighlightMixin {
  @Prop()
  thgs!: IThg[];

  dialog = false;

  duplicateFilter = new ThgDuplicateFilter();

  get duplicates(): string[] {
    return this.duplicateFilter.dups;
  }

  selectedDuplicateValue = ThgDuplicateFilter.EMPTY;

  get isHavingDuplicates(): boolean {
    return this.duplicates.length > 0;
  }

  get numberAll() {
    return this.thgs.length;
  }

  get headers() {
    return [
      { text: "timestamp", value: "timestamp.created" },
      { text: "ID", value: "id" },
      { text: "UserID", value: "userId" },
      { text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.numberplate"), value: "numberplate" },
      {
        text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.identificationnumber"),
        value: "registration.identificationnumber",
        filter: (value: string) => {
          if (!value) {
            value = ThgDuplicateFilter.EMPTY;
          }
          return value == this.selectedDuplicateValue;
        }
      },
      { text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.name"), value: "registration.name" },
      { text: this.$t("components.thg.ThgBatchDetailInformationThgTableCard.status"), value: "status" },
      {
        text: this.$t("components.thg.ThgBatchSelectDialog.year"),
        value: "year"
      }
    ];
  }

  /**
   * Initializes the dialog and set defaul values
   */
  async initialize() {
    this.dialog = true;
    this.duplicateFilter = new ThgDuplicateFilter();
    this.duplicateFilter.filter(this.thgs);
    this.selectedDuplicateValue = this.duplicateFilter.dups[0];
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }

  /**
   * Redirects to the detail view of the thg
   * Opens a new broswer tab
   * @param thg
   */
  goToThg(thg: IThg) {
    if (!thg?.id) {
      return;
    }

    const routeData = this.$router.resolve({
      name: "ThgQuoteDetailView",
      params: { partnerId: thg.partnerId, thgId: thg.id }
    });
    return window.open(routeData.href, "_blank");
  }

  /**
   * Redirects to the detail view of the user
   * Opens a new broswer tab
   * @param thg
   */
  goToUser(thg: IThg) {
    if (!thg?.userId) {
      return;
    }

    const routeData = this.$router.resolve({ name: "UserAdministrationDetail", params: { userId: thg.userId } });
    return window.open(routeData.href, "_blank");
  }
}
