










import ThgChargingStationForm from "@/components/thg/ThgChargingStationForm.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { HttpException } from "@/lib/exceptions/http";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ThgUpdateChargingStationDtoGen } from "@/services/thg/v1/data-contracts";
import { ChargingStationUiDto } from "@/store/models/thg/create-charging-station";
import { ChargingStationAdminModule } from "@/store/modules/charging-station-admin.store";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    ThgChargingStationForm
  }
})
export default class ThgChargingStationUpdateView extends PartnerFallbackMixin {
  loading = false;

  get chargingStation() {
    return ThgPortalModule.selectedChargingStation;
  }

  async mounted() {
    this.loading = true;
    try {
      await ThgPortalModule.getSelectedChargingStation(this.$route.params.chargingStationId);
    } catch {
      this.$toast.error("Fehler beim Laden der Thg-Quote");
    } finally {
      this.loading = false;
    }
  }

  async save(item: ChargingStationUiDto) {
    const updateChargingStation: ThgUpdateChargingStationDtoGen = item.toUpdateChargingStation();

    this.$log.debug(updateChargingStation);

    try {
      this.loading = true;
      await ChargingStationAdminModule.updateChargingStation({
        id: this.chargingStation.id,
        data: updateChargingStation
      });

      this.$toast.success("👍");
      this.$router.push({ name: "ThgChargingStation" });
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
