
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { Prop } from "vue-property-decorator";
import PartnerFallbackMixin from "./PartnerFallbackMixin.vue";
import { $t } from "@/lib/utility/t";

export default abstract class ThgPartnerDashboardMixin extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;
  loadingKpis = false;
  search = "";

  thgStatusMap = thgStatusMap;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get from() {
    return simpleDoubleDigitDate(ThgAnalyticsModule.from);
  }

  get to() {
    return simpleDoubleDigitDate(ThgAnalyticsModule.to);
  }

  get subtitle() {
    return `${$t("views.portal.PartnerDashboard.chosenTimeFrame", { from: this.from, to: this.to })}`;
  }

  get partner() {
    return PartnerModule.partner;
  }

  async loadData() {
    this.loading = true;
    try {
      this.foundPartner = await this.trySetByRouteOrDefault();
      await Promise.all([
        ThgAnalyticsModule.getAvailableCountries(),
        ThgAnalyticsModule.getAvailableYears(),
        this.getData()
      ]);
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }

    this.loading = false;
  }

  route(route: string) {
    this.$router.push({ name: route });
  }

  async setDateRange(dateRange: string[]) {
    ThgAnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  async getData() {
    this.setLoading(true);
    for (const key of this.dataLoadingOrder) {
      try {
        if (this.dataHandler[key].permission) {
          await this.dataHandler[key].getData();
        } else {
          this.$log.warn("Missing permission for " + key);
          this.dataHandler[key].isLoading = false;
        }
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    }
  }

  setLoading(value: boolean) {
    for (const key of this.dataLoadingOrder) {
      this.dataHandler[key].isLoading = value;
    }
  }

  dataLoadingOrder = ["thgCustomerData"];
  dataHandler = {
    thgCustomerData: {
      getData: async () => {
        await ThgAnalyticsModule.getThgCustomerDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.CUSTOMER_DATA)
    }
  };

  get dates() {
    return ThgAnalyticsModule.dateRange;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get years() {
    return ThgAnalyticsModule.availableYears;
  }

  get thgCustomerData() {
    return ThgAnalyticsModule.thgCustomerData.data;
  }
}
