var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [_c('thg-meter-reading-list-card-paginated', {
          on: {
            "isLoadingDetail": _vm.setLoadingSelectedMeterReading,
            "user:update": function userUpdate($event) {
              _vm.selectedUser = $event;
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('thg-meter-reading-detail-card', {
          attrs: {
            "loading": _vm.loadingSelectedMeterReading,
            "selectedUser": _vm.selectedUser
          }
        })];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }