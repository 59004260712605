


















































import TheLayoutPortalList from "@/layouts/TheLayoutPortalList.vue";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { CheckEnum } from "@/lib/enum/check.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PaginationListMixin from "@/mixins/PaginationListMixin.vue";
import { ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { BillingModule } from "@/store/modules/billing.store";
import { PartnerBillingModule } from "@/store/modules/partner-billing.store";
import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortalList
  }
})
export default class ThgBillingPartnerList extends mixins(DarkModeHighlightMixin, PaginationListMixin) {
  get CheckEnum() {
    return CheckEnum;
  }
  get billingTypes() {
    return [BillingTypeEnum.CREDIT_PARTNER, BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT];
  }

  billingType = BillingTypeEnum.CREDIT_PARTNER;

  updateBillingType(billingType: BillingTypeEnum) {
    this.$emit("updateBillingType", billingType);
  }

  getBillingTypeText(billingType: BillingTypeEnum) {
    return this.$t("components.thg.thgBillingCreateNewBillingManual")[billingType] || billingType;
  }

  get i18n() {
    return this.$t("components.thg.ThgBillingPartnerList");
  }

  getBillingTypeShortened(billingType: BillingTypeEnum) {
    const full = this.getBillingTypeText(billingType);
    const bits = full.split(" ") || [];
    return bits.map((b: any) => b[0]).join("");
  }

  getModule() {
    return PartnerBillingModule;
  }

  isActive(item: ThgBillingViewmodelGen) {
    return item.billingNumber === BillingModule.createdBilling.billingNumber ? this.color : "";
  }
}
