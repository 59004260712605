import { ThgPartnerPurchasingPriceControllerFindAllParamsGen } from "./../v1/data-contracts";
/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { ThgHttpClientProvider } from "../thg-http-client.provider";
import { PartnerPurchasingPriceService } from "../v1/PartnerPurchasingPriceService";
import {
  ThgCreatePriceDtoGen,
  ThgPageViewModelGen,
  ThgPriceViewModelGen,
  ThgUpdatePriceDtoGen
} from "../v1/data-contracts";

/**
 * Service to talk to the price endpoint.
 */
export class PriceService {
  private readonly price: PartnerPurchasingPriceService;

  constructor(clientProvider: ThgHttpClientProvider) {
    this.price = new PartnerPurchasingPriceService(clientProvider.client());
  }

  /**
   * Creates a price for a given partner
   *
   * @param partnerId
   * @param data
   * @returns
   */
  async create(partnerId: string, data: ThgCreatePriceDtoGen): Promise<ThgPriceViewModelGen> {
    return (await this.price.partnerPurchasingPriceControllerCreate(partnerId, data)).data;
  }

  /**
   * Gets all price configs by partner
   *
   * @param partnerId
   * @param priceId
   * @returns
   */
  async findAllByPartnerId(
    query: ThgPartnerPurchasingPriceControllerFindAllParamsGen
  ): Promise<ThgPageViewModelGen & { data?: ThgPriceViewModelGen[] | undefined }> {
    return (await this.price.partnerPurchasingPriceControllerFindAll(query)).data;
  }

  /**
   * Gets a price config by partner
   *
   * @param partnerId
   * @param priceId
   * @returns
   */
  async findOneByPartnerId(partnerId: string, priceId: string): Promise<ThgPriceViewModelGen> {
    return (await this.price.partnerPurchasingPriceControllerFindOne(partnerId, priceId)).data;
  }

  /**
   * Updates a price config by partner
   *
   * @param partnerId
   * @param priceId
   * @returns
   */
  async updateByPartnerId(
    partnerId: string,
    priceId: string,
    data: ThgUpdatePriceDtoGen
  ): Promise<ThgPriceViewModelGen> {
    return (await this.price.partnerPurchasingPriceControllerUpdate(partnerId, priceId, data)).data;
  }

  /**
   * Removes a price config by partner
   *
   * @param partnerId
   * @param priceId
   * @returns
   */
  async removeByPartnerId(partnerId: string, priceId: string): Promise<ThgPriceViewModelGen> {
    return (await this.price.partnerPurchasingPriceControllerRemove(partnerId, priceId)).data;
  }
}

export default new PriceService(new ThgHttpClientProvider());
