var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "min-width": "250px",
      "title": _vm.$t('report.thgOverview.meterReading.title')
    }
  }, [_c('debug', {
    attrs: {
      "debug": _vm.meterReadings
    }
  }), _c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.meterReadingHeaders,
      "items": _vm.meterReadings
    },
    on: {
      "click:row": _vm.handleClick
    },
    scopedSlots: _vm._u([{
      key: "item.meterReading.startDate",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.meterReading.startDate).toLocaleString("de-DE", _vm.dateOptions)))])];
      }
    }, {
      key: "item.meterReading.endDate",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.meterReading.endDate).toLocaleString("de-DE", _vm.dateOptions)))])];
      }
    }], null, true)
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }