import {
  ThgBankingDtoGen,
  ThgCreateAnonymousThgDtoGen,
  ThgCreateUserDtoGen,
  ThgRegistrationDtoGen
} from "@/services/thg/v1/data-contracts";
import { ImpactTypeEnum } from "@/views/thg/enum/impact-type.enum";

/**
 * @inheritdoc
 */
export class CreateAnonymousThgQuote implements ThgCreateAnonymousThgDtoGen {
  /**
   * @inheritdoc
   */
  impactType: ImpactTypeEnum;

  /**
   * @inheritdoc
   */
  impactFactor: number;

  /**
   * @inheritdoc
   */
  isConfirmed?: boolean;

  /**
   * @inheritdoc
   */
  code?: string;

  /**
   * @inheritdoc
   */
  campaign?: string;

  /**
   * @inheritdoc
   */
  numberplate: string;

  /**
   * @inheritdoc
   */
  registrationImages: string[] = [];

  /**
   * @inheritdoc
   */
  years?: number[] | undefined;

  /**
   * @inheritdoc
   */
  createUserDto: ThgCreateUserDtoGen;

  /**
   * @inheritdoc
   */
  banking?: ThgBankingDtoGen | undefined;

  /**
   * @inheritdoc
   */
  registration?: ThgRegistrationDtoGen | undefined;

  /**
   * Creates an instance
   */
  constructor() {
    this.impactType = ImpactTypeEnum.payout;
    this.impactFactor = 0;
    this.isConfirmed = false;
    this.numberplate = "";
    this.createUserDto = {
      userName: "",
      firstName: "",
      lastName: ""
    };
  }
}
