



















































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import ThgBatchSelectDialog from "@/components/thg/ThgBatchSelectDialog.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { BatchModule } from "@/store/modules/batch.store";
import { Component } from "vue-property-decorator";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: { AnalyticsDateRangeSelectorDialog, ThgBatchSelectDialog }
})
export default class ThgBatchUpdateItemsDialog extends DarkModeHighlightMixin {
  isLoading = false;
  dialog = false;
  reason = "";
  thgsToAdd: IThg[] = [];

  get type() {
    return BatchModule.selectedBatch.batch.type;
  }

  get numberSelected() {
    return this.thgsToAdd.length.toString();
  }

  get loading() {
    return this.isLoading;
  }

  /**
   * Rules for required data
   */
  get requiredRules() {
    const rules = [];

    rules.push(requiredRule());

    return rules;
  }

  /**
   * Disables the Button if required fields are not filled
   */
  get isDisabled() {
    if (!this.reason || this.thgsToAdd.length == 0) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Initializes the dialog and set defaul values
   */
  async initialize() {
    this.dialog = true;
    this.isLoading = true;
    this.reason = "";
    this.isLoading = false;
  }

  /**
   * closes the dialog
   */
  close() {
    this.dialog = false;
  }

  /**
   * Sets THGs to add. Is Processing Emit Event from hg-batch-select-dialog
   */
  setThgsToAdd(thgsToAdd: IThg[]) {
    this.thgsToAdd = thgsToAdd;
  }

  goToOperationDetail(operationId: string) {
    new GoToHelper(this.$router).goToOperationDetail(operationId, undefined, true);
  }

  /**
   * Add Items from batch
   */
  async addItemsToBatch() {
    try {
      this.isLoading = true;
      const items = deepCopy(BatchModule.selectedBatch.batch.itemIds);

      for (const itemToAdd of this.thgsToAdd) {
        if (!items.includes(itemToAdd.id)) {
          items.push(itemToAdd.id);
        }
      }

      const result = await BatchModule.updateItems({
        id: BatchModule.selectedBatch.batch.id,
        dto: { reason: this.reason, itemIds: items }
      });

      this.$toast.success(this.$t("components.thg.ThgBatchUpdateItemsDialog.success").toString(), {
        onClick: () => this.goToOperationDetail(result.operationId)
      });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgBatchUpdateItemsDialog.error").toString());
    } finally {
      this.isLoading = false;
      this.thgsToAdd = [];
      this.close();
    }
  }
}
