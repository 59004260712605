import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { Batch } from "../v1/Batch";
import {
  ThgBatchControllerUpdateStatusAsyncParamsGen,
  ThgBatchDetailViewmodelGen,
  ThgBatchDetailViewModelWithOperationGen,
  ThgBatchDtoGen,
  ThgBatchOperationViewModelGen,
  ThgBatchPartnerDistributionViewmodelGen,
  ThgBatchViewmodelGen,
  ThgUpdateBatchItemsDtoGen,
  ThgUpdatePriceInformationDtoGen,
  ThgUpdateThgStatusDtoGen
} from "../v1/data-contracts";
import { HttpClient } from "../v1/http-client";

/**
 * Communicates with thg backend
 */
class BatchService {
  /**
   * The HttpClient containing the Axios Instance
   */
  client: HttpClient;

  /**
   * The proxy.
   */
  proxy: Batch;

  /**
   * @class Initialize ThgService
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.client = clientProvider.client();
    this.proxy = new Batch(this.client);
  }

  async create(dto: ThgBatchDtoGen): Promise<ThgBatchDetailViewmodelGen> {
    const response = await this.proxy.batchControllerCreateBatch(dto);

    return response.data;
  }

  async findAll(): Promise<ThgBatchViewmodelGen> {
    const response = await this.proxy.batchControllerFindAll();

    return response.data;
  }

  async findbyId(id: string): Promise<ThgBatchDetailViewmodelGen> {
    const response = await this.proxy.batchControllerFindById(id);

    return response.data;
  }

  async updateStatus(
    id: string,
    noUserNotification: boolean,
    dto: ThgUpdateThgStatusDtoGen
  ): Promise<ThgBatchDetailViewmodelGen> {
    const params = {
      userNotification: !noUserNotification,
      noUserNotification: noUserNotification,
      batchId: id
    };

    const response = await this.proxy.batchControllerUpdateStatus(params, dto);

    return response.data;
  }

  async updateStatusAsync(
    id: string,
    noUserNotification: boolean,
    dto: ThgUpdateThgStatusDtoGen
  ): Promise<ThgBatchOperationViewModelGen> {
    const params: ThgBatchControllerUpdateStatusAsyncParamsGen = {
      noUserNotification: noUserNotification,
      userNotification: !noUserNotification,
      batchId: id
    };
    const response = await this.proxy.batchControllerUpdateStatusAsync(params, dto);

    return response.data;
  }

  async updatePriceInformation(id: string, dto: ThgUpdatePriceInformationDtoGen): Promise<ThgBatchDetailViewmodelGen> {
    const response = await this.proxy.batchControllerUpdatePricePerKwh(id, dto);

    return response.data;
  }

  async updateItems(id: string, dto: ThgUpdateBatchItemsDtoGen): Promise<ThgBatchDetailViewModelWithOperationGen> {
    const response = await this.proxy.batchControllerUpdateItemsAsync(id, dto);

    return response.data;
  }

  async partnerDistribution(id: string): Promise<ThgBatchPartnerDistributionViewmodelGen> {
    const response = await this.proxy.batchControllerPartnerDistribution(id);

    return response.data;
  }
}

export default new BatchService(new ThgHttpClientProvider());
