




















































import PartnerSelectionForm from "@/components/thg/PartnerSelectionForm.vue";
import ThgChargingStationForm from "@/components/thg/ThgChargingStationForm.vue";
import ThgCustomerDataForm from "@/components/thg/ThgCustomerDataForm.vue";
import ThgMeterReadingForm from "@/components/thg/ThgMeterReadingForm.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { HttpException } from "@/lib/exceptions/http";
import {
  ThgCreateAnonymousChargingStationDtoGen,
  ThgCreateChargingStationDtoGen,
  ThgCreateThgMeterReadingDtoGen,
  ThgCreateUserDtoGen
} from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Vue } from "vue-property-decorator";
import { handleError } from "@/lib/utility/handleError";

@Component({
  components: {
    LayoutSimple,
    PartnerSelectionForm,
    ThgCustomerDataForm,
    ThgChargingStationForm,
    ThgMeterReadingForm
  }
})
export default class ThgChargingStationCreateView extends Vue {
  loading = false;
  stepper = 1;

  partnerId?: string = undefined;
  user?: ThgCreateUserDtoGen = undefined;
  chargingStation?: ThgCreateChargingStationDtoGen = undefined;
  meterReading?: ThgCreateThgMeterReadingDtoGen = undefined;

  isPartnerSet = false;
  isUserSet = false;
  isChargingStationSet = false;
  isMeterReadingSet = false;

  mounted() {
    this.loading = true;
    try {
      PartnerModule.getPartners();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }

  savePartner(partnerId: string) {
    this.partnerId = partnerId;
    this.isPartnerSet = true;
    this.stepper = 2;

    this.$log.debug(this.partnerId);
  }

  saveUser(user: ThgCreateUserDtoGen) {
    this.user = user;
    this.isUserSet = true;
    this.stepper = 3;

    this.$log.debug(this.user);
  }

  saveChargingStation(chargingStation: ThgCreateChargingStationDtoGen) {
    this.chargingStation = chargingStation;
    this.isChargingStationSet = true;
    this.stepper = 4;

    this.$log.debug(this.chargingStation);
  }

  async saveMeterReading(meterReading: ThgCreateThgMeterReadingDtoGen) {
    this.meterReading = meterReading;
    this.isMeterReadingSet = true;

    this.$log.debug(this.meterReading);

    await this.save();
  }

  async save() {
    if (!this.partnerId) {
      this.$toast.error("Partner not set.");
      return;
    }

    if (!this.user) {
      this.$toast.error("User Data not set.");
      return;
    }

    if (!this.chargingStation) {
      this.$toast.error("Charging Station not set.");
      return;
    }

    if (!this.meterReading) {
      this.$toast.error("Meter reading not set.");
      return;
    }

    const createAnonymousChargingStation: ThgCreateAnonymousChargingStationDtoGen = {
      name: this.chargingStation.name,
      chargingPointOperator: {
        name: this.chargingStation.chargingPointOperator.name,
        bnetzaOperatorId: this.chargingStation.chargingPointOperator.bnetzaOperatorId
      },
      isFromRenewableEnergy: this.chargingStation.isFromRenewableEnergy,
      renewableEnergySourceDescription: this.chargingStation.renewableEnergySourceDescription,
      refs: this.chargingStation.refs || [],
      address: this.chargingStation.address,
      isRegistered: this.chargingStation.isRegistered,
      commissioningDate: new Date(this.chargingStation.commissioningDate).toISOString(),
      registrationDate: new Date(this.chargingStation.registrationDate).toISOString(),
      connectionPowerInKwh: this.chargingStation.connectionPowerInKwh,
      chargingPointCount: this.chargingStation.chargingPointCount,
      createUserDto: {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        userName: this.user.userName
      },
      thgMeterReadings: [this.meterReading.meterReading]
    };

    if (!createAnonymousChargingStation) {
      this.$toast.error("Daten fehlen.");
      return;
    }

    this.$log.debug(JSON.stringify(createAnonymousChargingStation));

    try {
      this.loading = true;

      await ThgPortalModule.createAnonymous({ partnerId: this.partnerId, dto: createAnonymousChargingStation });

      this.$toast.success(this.$t("components.thg.ThgChargingStationCreateView.success"));

      this.$router.push({
        name: "ThgChargingStation"
      });
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
