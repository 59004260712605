






import BatchDetailCard from "@/components/thg/BatchDetailCard.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { BatchModule } from "@/store/modules/batch.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    BatchDetailCard
  }
})
export default class ThgBatchDetailView extends Vue {
  getloadingSelectedBatch = false;

  async mounted() {
    this.getloadingSelectedBatch = true;
    try {
      await BatchModule.find(this.$route.params.batchId);
    } catch (error) {
      this.$toast.error(this.$t("views.ThgBatchView.errorDetail").toString());
      this.$log.error(error);
    }

    try {
      await BatchModule.getPartnerDistribution(this.$route.params.batchId);
    } catch (error) {
      this.$toast.error(this.$t("views.ThgBatchView.errorPartnerDistribution").toString());
      this.$log.error(error);
    } finally {
      this.getloadingSelectedBatch = false;
    }
  }
}
