





















































































































































import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import TableWrapper, { IControlElements, ITableWrapperHeader } from "@/components/utility/TableWrapper.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { getFlagEmoji } from "@/lib/CountryCodeHelper";
import { simpleDate, simpleDoubleDigitDate } from "@/lib/utility/date-helper";
import { IPromotion, Promotion } from "@/models/promotion.entity";
import { ThgPriceViewModelGen } from "@/services/thg/v1/data-contracts";
import { CreatePromotionDto } from "@/store/models/thg/create-promotion.dto";
import { PartnerModule } from "@/store/modules/partner";
import { PromotionModule } from "@/store/modules/promotion.store";
import ThgPriceForm from "@/views/thgPortal/ThgPriceForm.vue";
import ThgPriceImport from "@/views/thgPortal/ThgPriceImport.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import * as XLSX from "xlsx";
import { $t } from "@/lib/utility/t";
import CreateDialog from "@/components/utility/CreateDialog.vue";
import UpdateSideCard from "@/components/utility/UpdateSideCard.vue";
import PartnerSingleImageUpload from "@/components/utility/PartnerSingleImageUpload.vue";
import ThgPromotionListItem from "./ThgPromotionListItem.vue";
import ThgPromotionImport from "@/views/thgPortal/ThgPromotionImport.vue";

@Component({
  components: {
    TheLayoutPortal,
    TableWrapper,
    ConfirmActionDialog,
    ThgPriceForm,
    FilterCardPagination,
    ThgPriceImport,
    PaginatedTable,
    ThgPromotionListItem,
    CreateDialog,
    UpdateSideCard,
    PartnerSingleImageUpload,
    ThgPromotionImport
  },
  filters: {
    simpleDoubleDigitDate,
    getFlagEmoji
  }
})
export default class ThgPromotionTable extends Vue {
  readonly store = PromotionModule;
  readonly CreateDto = CreatePromotionDto;
  readonly UpdateDto = Promotion;

  predefinedFilter = [
    {
      name: "components.FilterCardPagination.predefinedFilter.promotion.active",
      filter: [
        {
          key: "isActive",
          operation: "$eq",
          value: true
        },
        {
          key: "isArchived",
          operation: "$eq",
          value: false
        }
      ]
    },
    {
      name: "components.FilterCardPagination.predefinedFilter.promotion.archived",
      filter: [
        {
          key: "isActive",
          operation: "$eq",
          value: false
        }
      ]
    },
    {
      name: "components.FilterCardPagination.predefinedFilter.promotion.new",
      filter: [
        {
          key: "isArchived",
          operation: "$eq",
          value: true
        }
      ]
    }
  ];

  isCreateDialog = false;

  clickedRow: IPromotion | null = null;

  selected: IPromotion[] = [];

  isDialogLoading = false;

  isActivateDialogActive = false;
  isArchiveDialogActive = false;
  progressCounter = 0;

  get loadingProgressSelected() {
    return (100 / this.selected.length) * this.progressCounter;
  }

  get controlElements(): IControlElements[] {
    const controlElements: IControlElements[] = [];

    controlElements.push({
      icon: "mdi-trash-can",
      action: (p: IPromotion) => {
        this.selected.splice(0);
        this.selected.push(p);
        this.isArchiveDialogActive = true;
      },
      text: $t("thg.PriceTable.delete")
    });
    controlElements.push({
      icon: "mdi-check",
      action: (p: IPromotion) => {
        this.selected.splice(0);
        this.selected.push(p);
        this.isActivateDialogActive = true;
      },
      text: $t("thg.PriceTable.activate")
    });

    return controlElements;
  }

  @Watch("clickedRow.partnerId")
  setPartner() {
    const partner = PartnerModule.partners.find(p => p._id === this.clickedRow?.partnerId);
    if (partner) {
      PartnerModule.setPartner(partner);
    }
  }

  async onRowClick(object: IPromotion) {
    this.clickedRow = null;
    await object.fetch();
    await this.$nextTick(() => {
      this.clickedRow = object;
    });
  }

  simpleDate(date: string, locale?: string) {
    return simpleDate(date, locale);
  }

  promotionStatus(item: ThgPriceViewModelGen) {
    if (item.isArchived) {
      return {
        text: "Archiviert",
        color: "error"
      };
    }

    if (item.isActive) {
      return {
        text: "Aktiv",
        color: "success"
      };
    }

    return {
      text: "Erstellt",
      color: "info"
    };
  }

  get headers(): ITableWrapperHeader[] {
    return [
      {
        text: "",
        value: "isLoading",
        align: "start",
        sortable: false,
        width: "55px"
      },
      {
        text: $t("thg.PriceTable.isActive"),
        align: "start",
        value: "isActive"
      },
      {
        text: $t("thg.PriceTable.partnerId"),
        align: "start",
        value: "partnerId"
      },
      {
        text: "",
        align: "start",
        value: "title",
        width: "100px",
        sortable: false
      },
      {
        text: $t("thg.PriceTable.value"),
        align: "start",
        value: "value"
      },
      { text: $t("thg.PriceTable.currency"), align: "start", value: "currency" },
      { text: $t("thg.PriceTable.period"), align: "start", value: "startDate" },
      { text: $t("thg.PriceTable.id"), align: "start", value: "id" },
      { text: $t("thg.PriceTable.date"), align: "start", value: "timestamp.created" },
      { text: "", value: "controls", align: "end" }
    ];
  }

  get activateHeaders() {
    return [
      {
        text: $t("thg.PriceTable.isActive"),
        align: "start",
        value: "isActive"
      },
      {
        text: $t("thg.PriceTable.partnerId"),
        align: "start",
        value: "partnerId"
      },
      {
        text: $t("thg.PriceTable.value"),
        align: "start",
        value: "value"
      },
      { text: $t("thg.PriceTable.currency"), align: "start", value: "currency" },
      { text: $t("thg.PriceTable.id"), align: "start", value: "id" },
      { text: $t("thg.PriceTable.date"), align: "start", value: "timestamp.created" }
    ];
  }

  /**
   * Download promotions
   */
  downloadPromotions() {
    const exportData = [];
    for (const partner of PartnerModule.partners) {
      const promotions = this.store.paginationList.filter(p => p.partnerId === partner._id);
      let promotion = undefined;
      if (promotions.length) {
        promotion = promotions[0];
      }
      const entry = {
        partnerId: partner._id,
        companyName: partner.companyName,
        companyUsername: partner.companyUsername,
        country: partner.countryCode,
        isActive: promotion?.isActive,
        isArchived: promotion?.isArchived,
        value: promotion?.value,
        currency: promotion?.currency,
        type: promotion?.type,
        startDate: promotion?.startDate,
        endDate: promotion?.endDate,
        title: promotion?.title,
        subtitle: promotion?.subtitle,
        description: promotion?.description,
        hint: promotion?.hint
      };
      exportData.push(entry);
    }

    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Promotions");
    XLSX.writeFile(workbook, "promotions_" + new Date().toISOString() + ".xlsx", { compression: true });
  }

  getPartner(partnerId: string) {
    return PartnerModule.partnersMap.get(partnerId);
  }

  close() {
    this.$log.info("Dialog closed");
  }

  async activatePrice() {
    this.isDialogLoading = true;
    for (const selected of this.selected) {
      await selected.activate();
      this.progressCounter++;
    }
    this.isDialogLoading = false;
    this.isActivateDialogActive = false;
    this.selected.splice(0);
  }

  async archivePrice() {
    this.isDialogLoading = true;
    for (const selected of this.selected) {
      await selected.delete();
      this.progressCounter++;
    }
    this.isDialogLoading = false;
    this.isArchiveDialogActive = false;
    this.selected.splice(0);
  }
}
