













































import ExcelImport from "@/components/utility/ExcelImport.vue";
import { excelDateToJSDate, numberValidation } from "@/lib/excelImportTransformation/transformers";
import { ThgChargingStationViewModelGen } from "@/services/thg/v1/data-contracts";
import { CreateMeterReadingAdmin } from "@/store/models/thg/meter-reading";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Vue } from "vue-property-decorator";
import * as XLSX from "xlsx";
import { IExcelImportConfig } from "@/lib/interfaces/excel-import.interface";

@Component({
  components: {
    ExcelImport
  }
})
export default class ThgMeterReadingCreateBatch extends Vue {
  loading = false;

  config: { [key: string]: IExcelImportConfig } = {
    chargingStationId: {
      label: "chargingStationId",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "chargingStationId",
      transform: value => value.toString()
    },
    userId: {
      label: "userId",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "userId",
      transform: value => value.toString()
    },
    partnerId: {
      label: "partnerId",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "partnerId",
      transform: value => value.toString()
    },
    startDate: {
      label: "startDate",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "startDate",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    endDate: {
      label: "endDate",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "endDate",
      transform: value => excelDateToJSDate(value)?.toISOString()
    },
    amountInKwh: {
      label: "amountInKwh",
      hint: "",
      required: true,
      import: true,
      originColumnNameInExcelFile: "amountInKwh",
      transform: value => numberValidation(value)
    }
  };

  dataImports: ThgChargingStationViewModelGen[] = [];
  failedImports: { reason: string; dto?: CreateMeterReadingAdmin }[] = [];

  generateTemplate() {
    const keys = Object.keys(this.config);
    const headers = {};
    for (const key of keys) {
      const prop = this.config[key].originColumnNameInExcelFile;
      headers[prop] = "";
    }
    const worksheet = XLSX.utils.json_to_sheet([headers]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "meterReadingTemplate");
    XLSX.writeFile(workbook, "meterReadingTemplate" + new Date().toISOString() + ".xlsx", { compression: true });
  }

  createErrorReport() {
    const errorReport = [];
    for (const item of this.failedImports) {
      errorReport.push({
        reason: item.reason,
        dto: item.dto?.userId
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(errorReport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Error Report");
    XLSX.writeFile(workbook, "error_report_import_meterreadings" + new Date().toISOString() + ".xlsx", {
      compression: true
    });
  }

  /**
   *
   * @param items
   */
  async importData(items: { data: any; headers: any }) {
    this.loading = true;
    this.dataImports = [];
    this.failedImports = [];

    for (const item of items.data) {
      let createMeterReadingDto!: CreateMeterReadingAdmin;
      try {
        createMeterReadingDto = new CreateMeterReadingAdmin();
        createMeterReadingDto.userId = item.userId;
        createMeterReadingDto.meterReading.amountInKwh = item.amountInKwh;
        createMeterReadingDto.meterReading.startDate = item.startDate;
        createMeterReadingDto.meterReading.endDate = item.endDate;
        createMeterReadingDto.meterReading.chargingStationId = item.chargingStationId;
        createMeterReadingDto.meterReading.proof = undefined;
      } catch (error) {
        const errorMessage = (error as Error).message;
        this.failedImports.push({ reason: errorMessage });
        continue;
      }

      try {
        this.dataImports.push(await this.save(item.partnerId, item.chargingStationId, createMeterReadingDto));
      } catch (error) {
        const errorMessage = (error as Error).message;
        this.failedImports.push({ reason: errorMessage, dto: createMeterReadingDto });
      }
    }

    this.loading = false;
  }

  async save(partnerId: string, chargingStationId: string, dto: CreateMeterReadingAdmin) {
    this.$log.debug(dto);

    return await ThgPortalModule.createMeterReading({ partnerId, chargingStationId, dto: dto });
  }

  goToChargingStation() {
    this.$router.push({
      name: "ThgChargingStation"
    });
  }
}
