























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import { ThgAffiliateViewmodelGen } from "@/services/thg/v1/data-contracts";
import ThgBillingSelectionItemContactData from "./ThgBillingSelectionItemContactData.vue";
import ThgBillingSelectionItemRegistrationData from "./ThgBillingSelectionItemRegistrationData.vue";
import ThgBillingSelectionItemRegistrationImage from "./ThgBillingSelectionItemRegistrationImage.vue";
import ThgBillingSelectionItemPayoutData from "./ThgBillingSelectionItemPayoutData.vue";
import ThgBillingSelectionItemPartnerData from "./ThgBillingSelectionItemPartnerData.vue";
import ThgBillingSelectionItemBatchData from "./ThgBillingSelectionItemBatchData.vue";
import ThgBillingSelectionItemBankingData from "./ThgBillingSelectionItemBankingData.vue";
import ThgBillingSelectionItemAffiliateData from "./ThgBillingSelectionItemAffiliateData.vue";
import { AffiliatePortalModule } from "@/store/modules/affiliate.portal.store";
import { IThg } from "@/models/thg.entity";

@Component({
  components: {
    ThgBillingSelectionItemContactData,
    ThgBillingSelectionItemRegistrationData,
    ThgBillingSelectionItemRegistrationImage,
    ThgBillingSelectionItemPayoutData,
    ThgBillingSelectionItemPartnerData,
    ThgBillingSelectionItemBatchData,
    ThgBillingSelectionItemBankingData,
    ThgBillingSelectionItemAffiliateData
  }
})
export default class ThgBillingSelectionAffiliateItem extends DarkModeHighlightMixin {
  @Prop()
  thg!: IThg;

  @Prop()
  affiliate?: ThgAffiliateViewmodelGen;

  affiliateLoading = false;
  affiliateData: ThgAffiliateViewmodelGen | undefined = this.affiliate;

  async loadAffiliate(): Promise<void> {
    if (!this.affiliate) {
      try {
        this.affiliateLoading = true;
        if (!this.thg.code) {
          throw Error("Code missing in THG");
        }
        this.affiliateData = await AffiliatePortalModule.getSelectedAffiliateByCode({
          code: this.thg.code,
          partnerId: this.thg.partnerId
        });
      } catch (e) {
        this.$log.error(e);
        this.$toast.error((e as any).message);
      } finally {
        this.affiliateLoading = false;
      }
    }
  }

  async mounted() {
    await this.loadAffiliate();
  }
}
