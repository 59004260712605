
















import ThgChargingStationCreateBatchCard from "@/components/thg/ThgChargingStationCreateBatchCard.vue";
import ThgChargingStationCreateCard from "@/components/thg/ThgChargingStationCreateCard.vue";
import ThgMeterReadingCreateBatch from "@/components/thg/ThgMeterReadingCreateBatch.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { handleError } from "@/lib/utility/handleError";
import { PartnerModule } from "@/store/modules/partner";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    ThgChargingStationCreateBatchCard,
    ThgMeterReadingCreateBatch,
    ThgChargingStationCreateCard
  }
})
export default class ThgChargingStationCreateOverviewView extends Vue {
  loading = false;

  mounted() {
    this.loading = true;
    try {
      PartnerModule.getPartners();
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
