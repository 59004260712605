







import PieChartCard from "@/components/analytics/PieChartCard.vue";
import { BatchModule } from "@/store/modules/batch.store";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: { PieChartCard }
})
export default class ThgBatchDetailInformationPartnerDistributionCard extends Vue {
  get partnerDistributionData() {
    return BatchModule.partnerDistribution.chartData;
  }
}
