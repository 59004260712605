











































































































import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import ThgBillingSelectionCreditItem from "@/components/thg/ThgBillingSelectionCreditItem.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import ThgBillingSelectionAffiliateItem from "@/components/thg/ThgBillingSelectionAffiliateItem.vue";
import { ThgBillingViewmodelGen, ThgThgMeterReadingViewModelGen } from "@/services/thg/v1/data-contracts";
import { BillingModule } from "@/store/modules/billing.store";
import { ConflictException } from "@/lib/exceptions/http";
import ThgBillingSelectionItemBillingResult from "@/components/thg/ThgBillingSelectionItemBillingResult.vue";
import ThgBillingSelectionItemAccountingResult from "@/components/thg/ThgBillingSelectionItemAccountingResult.vue";
import ThgBillingSelectionItemPdfResult from "@/components/thg/ThgBillingSelectionItemPdfResult.vue";
import Card from "@/components/utility/Card.vue";
import ConfirmActionDialog from "@/components/utility/ConfirmActionDialog.vue";
import { UserModule } from "@/store/modules/me-user.store";
import ThgBillingSelectionItemSendMailDialog from "@/components/thg/ThgBillingSelectionItemSendMailDialog.vue";
import { IThg } from "@/models/thg.entity";

@Component({
  components: {
    TheLayoutPortal,
    ThgBillingSelectionCreditItem,
    ThgBillingSelectionAffiliateItem,
    ThgBillingSelectionItemBillingResult,
    ThgBillingSelectionItemAccountingResult,
    ThgBillingSelectionItemPdfResult,
    Card,
    ConfirmActionDialog,
    ThgBillingSelectionItemSendMailDialog
  }
})
/**
 * The View in which all information that is important to create a billing is shown and a user can create a billing
 */
export default class ThgBillingSelectionItemView extends Vue {
  /**
   * The currently selected document page to bill
   */
  index = parseInt(this.$route.params.i);

  /**
   * This dialog is activated when a user tries to generate a billing for a billing that is already there
   */
  isForceBillingRegenerationDialogActive = false;

  /**
   *
   */
  createdBillingLoading = false;

  /**
   * Is forced regeneration loading
   */
  isRegenerationLoading = false;

  /**
   * Is billing being generated
   */
  isGenerationLoading = false;

  /**
   * This has to be true when forcing the recreation. It is there in order to make sure that a user on autopilot doesn't create a billing when there already is one
   */
  isForceRegenerationConfirmed = false;

  /**
   * Is a small screeen device used?
   */
  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  /**
   * The billing that we created when using this view
   */
  get createdBilling(): ThgBillingViewmodelGen {
    return BillingModule.createdBillings[this.index];
  }

  /**
   * The type of the billing that we are trying to create
   */
  get billingType() {
    return this.$route.params.billingType;
  }

  /**
   * All types of billings that there are
   */
  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  /**
   * THe thgs that were picked in the billing selection overview
   */
  get thgsReadyForBilling(): IThg[] | ThgThgMeterReadingViewModelGen[] {
    return BillingModule.selectedThgsForBilling;
  }

  get selectedThg(): IThg | ThgThgMeterReadingViewModelGen {
    return this.thgsReadyForBilling[this.index];
  }

  get name() {
    return `${UserModule.user.firstName} ${UserModule.user.lastName}`;
  }

  /**
   * navigate to another billing creation screen
   *
   * @param amount how many pages to go relative to current
   */
  navigateBackForth(amount: number) {
    this.$router.push({
      name: "ThgBillingSelectionItemView",
      params: { billingType: this.billingType, i: (this.index + amount).toString() }
    });
  }

  /**
   * go to previous billing creation
   */
  goPrevious() {
    this.navigateBackForth(-1);
  }

  /**
   * go to next billing creation
   */
  goNext() {
    this.navigateBackForth(+1);
  }

  /**
   * Remove billing from selection of billings to bill
   */
  removeBilling() {
    const selectedThg = BillingModule.selectedThgsForBilling;
    const created = BillingModule.createdBillings;
    created.splice(this.index, 1);
    selectedThg.splice(this.index, 1);
    BillingModule.setSelectedThgsForBilling(selectedThg);
    BillingModule.setCreatedBillings(created);
  }

  /**
   * Regenerate a billing even if there is already a billing that thg of the billing type
   */
  async forceRegenerateBilling() {
    try {
      this.isRegenerationLoading = true;
      this.createdBillingLoading = true;
      await BillingModule.forceCreateBillingByThgId({
        thgId: this.selectedThg.id,
        billingType: this.billingType as BillingTypeEnum
      });
      this.addToCreatedBillings();
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.isRegenerationLoading = false;
      this.isForceBillingRegenerationDialogActive = false;
    }
  }

  /**
   * Makes sure that all data is saved and complete
   */
  assertBillingCanBeSent(): boolean {
    const alerts = document.getElementsByClassName("billingAlert");
    if (alerts.length !== 0) {
      this.$toast.error(this.$t("views.thgPortal.thgBillingSelectionItem.billingAlert"));
      return false;
    }

    const unsavedData = document.getElementsByClassName("unsavedData");
    if (unsavedData.length !== 0) {
      this.$toast.error(this.$t("views.thgPortal.thgBillingSelectionItem.unsavedData"));
      return false;
    }
    return true;
  }

  /**
   * create the billing
   */
  async generateBilling() {
    if (!this.assertBillingCanBeSent()) {
      return;
    }

    try {
      this.createdBillingLoading = true;
      this.isGenerationLoading = true;

      await BillingModule.createBillingByThgId({
        thgId: this.selectedThg.id,
        billingType: this.billingType as BillingTypeEnum
      });
      this.addToCreatedBillings();
    } catch (e) {
      if (e instanceof ConflictException) {
        this.isForceBillingRegenerationDialogActive = true;

        return;
      }
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.isGenerationLoading = false;
      this.createdBillingLoading = false;
    }
  }

  /**
   * adds newly genereated billing to the list of generated billing. this is important in order go to the next/previous billing
   */
  addToCreatedBillings() {
    const created = BillingModule.createdBillings;
    created.splice(this.index, 1, BillingModule.createdBilling);
    BillingModule.setCreatedBillings(created);
    this.createdBillingLoading = false;
  }

  /**
   * makes sure that the dialog will always ask vor validation once it is reopenend
   */
  @Watch("forceBillingRegenerationDialog")
  resetRecreationConfirmation() {
    if (!this.isForceBillingRegenerationDialogActive) {
      this.isForceRegenerationConfirmed = false;
    }
  }
}
