var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionRegistrationData.image'),
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }])
  }, [!_vm.registrationImagesLoading && _vm.registrationImages.length > 0 ? _c('div', _vm._l(_vm.registrationImages, function (img) {
    return _c('v-card-actions', {
      key: img
    }, [_c('v-img', {
      staticClass: "ma-auto",
      attrs: {
        "contain": "",
        "max-height": "300",
        "width": "400",
        "src": img
      }
    })], 1);
  }), 1) : !_vm.registrationImagesLoading ? _c('v-card-actions', [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": _vm.emptyDataSrc,
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportList.noData")))])])], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }