













import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import ThgAnalyticsVehicleTable from "@/components/thg/ThgAnalyticsVehicleTable.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import ThgPartnerDashboardMixin from "@/mixins/ThgPartnerDashboardMixin.vue";
import { PartnerModule } from "@/store/modules/partner";
import { Component } from "vue-property-decorator";
import { $t } from "@/lib/utility/t";

@Component({
  components: {
    TheLayoutPortal,
    AnalyticsDateRangeSelectorDialog,
    ThgAnalyticsVehicleTable,
    MHeader
  }
})
export default class ThgPartnerVehicles extends ThgPartnerDashboardMixin {
  async mounted() {
    await this.loadData();
  }

  async loadDataForDateRange(dateRange: string[]) {
    await this.setDateRange(dateRange);
  }

  get breadCrumbs() {
    return [
      {
        text: $t("common.nouns.overview"),
        exact: true,
        disabled: false,
        to: {
          name: "ThgPartnerVehicles",
          params: {
            partnerId: PartnerModule.partner._id
          }
        }
      }
    ];
  }
}
