var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": _vm.$t('components.thg.ThgBatchDetailInformationChangeHistoryCard.title')
    }
  }, [_c('v-timeline', {
    attrs: {
      "align-top": "",
      "dense": ""
    }
  }, _vm._l(_vm.batch.batch.changeHistory, function (historyEntry, i) {
    return _c('v-timeline-item', {
      key: i,
      attrs: {
        "color": "primary",
        "small": ""
      }
    }, [_c('v-row', [_c('v-col', {
      attrs: {
        "cols": "5",
        "md": "5",
        "lg": "3"
      }
    }, [_c('strong', [_vm._v(_vm._s(_vm.date(historyEntry.timestamp)))])]), _c('v-col', [_c('strong', [_vm._v(_vm._s(historyEntry.action))]), _c('small', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDetailInformationChangeHistoryCard.status")) + " " + _vm._s(historyEntry.newStatus))]), _vm._l(historyEntry.message, function (message, i) {
      return _c('div', {
        key: i,
        staticClass: "text-caption"
      }, [_vm._v(" " + _vm._s(message) + " ")]);
    }), _vm._l(historyEntry.removedItems, function (item, i) {
      return _c('div', {
        key: i,
        staticClass: "text-caption"
      }, [_c('v-icon', {
        attrs: {
          "small": "",
          "color": "red"
        }
      }, [_vm._v("mdi-receipt-text-minus-outline")]), _vm._v(_vm._s(item) + " ")], 1);
    }), _vm._l(historyEntry.addedItems, function (item, i) {
      return _c('div', {
        key: i,
        staticClass: "text-caption"
      }, [_c('v-icon', {
        attrs: {
          "small": "",
          "color": "green"
        }
      }, [_vm._v("mdi-receipt-text-plus-outline")]), _vm._v(_vm._s(item) + " ")], 1);
    })], 2)], 1)], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }