








































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import { ThgPartnerViewModelGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";

import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { mixins } from "vue-class-component";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop } from "vue-property-decorator";
import ActivityTimeLine from "../partner/ActivityTimeLine.vue";
import ReportActivityBoxCommentComponent from "../partner/ReportActivityBoxCommentComponent.vue";
import ReportActivityBoxMailComponent from "../partner/ReportActivityBoxMailComponent.vue";
import SideCard from "../utility/SideCard.vue";
import { IAdminUser } from "@/models/user.entity";

@Component({
  components: {
    SideCard,
    ActivityTimeLine,
    ReportActivityBoxCommentComponent,
    ReportActivityBoxMailComponent
  }
})
export default class ThgQuoteDetailCardActivityTimeLineSideCard extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin
) {
  @Prop()
  thg!: IThg;

  @Prop()
  partner!: ThgPartnerViewModelGen;

  @Prop()
  selectedUser!: IAdminUser;

  get activityLog() {
    return ActivityLogModule.paginationList;
  }

  tab = 0;
}
