import { PageOrderEnum } from "@/lib/enum/pageOrder.enum";
import advertisementAdminService from "@/services/thg/services/advertisementAdminService";
import store from "@/store/VuexPlugin";
import { Action, getModule, Module } from "vuex-module-decorators";
import {
  ThgAdvertisementAnalyticsControllerGetAllParamsGen,
  ThgAdvertisementViewmodelGen,
  ThgPageViewModelGen
} from "../../services/thg/v1/data-contracts";
import { BasePagination, PaginationFilterListElement } from "./base-pagination.store";

/**
 * Store to get all advertisments paginated
 */
@Module({
  dynamic: true,
  namespaced: true,
  name: "advertisment-admin-paginated-store",
  store
})
export class AdvertisementAdminPaginatedStore extends BasePagination<
  ThgAdvertisementViewmodelGen,
  ThgAdvertisementAnalyticsControllerGetAllParamsGen
> {
  protected _pageOrder: PageOrderEnum = PageOrderEnum.DESCENDING;
  protected _itemsPerPage = 100;
  protected _totalPages = 0;
  protected _paginationList: ThgAdvertisementViewmodelGen[] = [];
  protected _currentPage = 1;
  protected _totalItems = 0;
  protected _isLoadAll = false;
  filterOptions: PaginationFilterListElement[] = [];

  @Action
  protected async loadDocuments(
    query: ThgAdvertisementAnalyticsControllerGetAllParamsGen
  ): Promise<
    ThgPageViewModelGen & {
      data?: ThgAdvertisementViewmodelGen[] | undefined;
    }
  > {
    return await advertisementAdminService.getAll(query);
  }
}

export const AdvertisementPaginatedAdminModule = getModule(AdvertisementAdminPaginatedStore);
