var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "components.thg.ThgQuoteCreateView.title"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-text', [_c('thg-quote-form', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "save": _vm.save
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }