




import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateDialog from "../template/TemplateDialog.vue";
import { AdminUserPaginationModule } from "@/store/modules/admin-user-pagination.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";
import { IAdminUser } from "@/models/user.entity";

@Component({ components: { TemplateDialog } })
export default class ThgBillingSelectionItemSendMailDialog extends Vue {
  @Prop()
  thg!: IThg;

  @Prop()
  billing!: ThgBillingViewmodelGen;

  selectedUser: IAdminUser | null = null;

  async mounted() {
    this.selectedUser = await AdminUserPaginationModule.getSelectedUser(this.billing.userId);
  }

  get to() {
    return this.selectedUser?.userName ? [this.selectedUser.userName] : [];
  }

  get partner() {
    return PartnerModule.partner;
  }

  get context() {
    return {
      partner: this.partner,
      user: { marketingOptIn: false, permission: [], timestamps: {}, ...this.selectedUser },
      thg: this.thg,
      billing: this.billing
    };
  }
}
