var render = function () {
  var _vm$selectedThg$count;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', {
    staticClass: "wrapper"
  }, [_vm.loading ? _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  }) : _vm.isSelectedThg ? _c('v-card', {
    attrs: {
      "flat": "",
      "max-width": "2000"
    }
  }, [_c('v-card-title', {
    staticClass: "pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.getFlag(((_vm$selectedThg$count = _vm.selectedThg.countryCode) === null || _vm$selectedThg$count === void 0 ? void 0 : _vm$selectedThg$count.toString()) || _vm.CountryCodeEnum.germany)) + " " + _vm._s(_vm.selectedThg.numberplate) + " (" + _vm._s(_vm.selectedThg.id) + ") "), _vm.selectedThg.decommissioningDate ? _c('v-chip', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v(" Decommissioned ")]) : _vm._e()], 1), _c('v-spacer')], 1), _c('v-card-subtitle', [_c('v-row', {
    staticClass: "ml-1",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.createdDate) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.displayToDetailView ? _c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": _vm.toDetailView
          }
        }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1) : _vm._e()];
      }
    }])
  }, [_c('span', [_vm._v("Zur Detail Ansicht")])]), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ALL) ? _c('thg-quote-banking-information-dialog') : _vm._e(), _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ALL) ? _c('thg-quote-transfer-dialog', {
    attrs: {
      "value": [_vm.selectedThg]
    }
  }) : _vm._e(), _c('template-dialog', {
    attrs: {
      "loadContext": _vm.loadContext,
      "from": _vm.from,
      "to": _vm.to,
      "partnerId": _vm.partnerId
    }
  }), _c('thg-admin-quick-checkout-dialog', {
    attrs: {
      "thg": _vm.selectedThg,
      "selectedUser": _vm.selectedUser
    }
  }), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.isDecommissionDialog = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-truck-remove")])], 1), _c('v-btn', {
    attrs: {
      "icon": "",
      "loading": _vm.isLoadingActivities
    },
    on: {
      "click": function click($event) {
        _vm.isTimeLineSideCard = !_vm.isTimeLineSideCard;
      }
    }
  }, [_c('v-icon', [_vm._v(" mdi-chart-timeline-variant-shimmer ")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "scrollable virtual-scroller",
    attrs: {
      "flat": ""
    }
  }, [_c('thg-quote-detail-information-card', {
    attrs: {
      "selectedThg": _vm.selectedThg,
      "selectedUser": _vm.selectedUser
    }
  })], 1), _vm.isLoadingActivities ? _c('v-skeleton-loader', {
    attrs: {
      "type": "table"
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "emptyDataContainer"
  }, [_c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-img', _vm._g({
          attrs: {
            "src": require("@/assets/undraw/undraw_no_data_re_kwbl.svg"),
            "height": "100",
            "width": "200",
            "contain": ""
          }
        }, on))];
      }
    }])
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerReportDetail.else.noData")))])])], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDecommissionDialog,
      "title": "Fahrzeug Abmelden?",
      "subtitle": "Fahrzeug abmelden, z.B. für die alliquote Anrechnung der THG Quote",
      "rightLoading": _vm.isLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDecommissionDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDecommissionDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDecommissionDialog = false;
      },
      "rightClick": _vm.decommission
    }
  }, [_c('v-checkbox', {
    attrs: {
      "label": "Abgemeldet"
    },
    model: {
      value: _vm.isDecommissioned,
      callback: function callback($$v) {
        _vm.isDecommissioned = $$v;
      },
      expression: "isDecommissioned"
    }
  }, [_vm._v("?")]), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDecommissioned,
      expression: "isDecommissioned"
    }],
    staticClass: "mt-6 mb-n4",
    attrs: {
      "type": "date",
      "label": "Abmeldung",
      "outlined": "",
      "dense": "",
      "required": ""
    },
    model: {
      value: _vm.decommissioningDate,
      callback: function callback($$v) {
        _vm.decommissioningDate = $$v;
      },
      expression: "decommissioningDate"
    }
  })], 1)], 1), _vm.selectedThg ? _c('thg-quote-detail-card-activity-time-line-side-card', {
    attrs: {
      "selectedUser": _vm.selectedUser,
      "hidden": !_vm.isTimeLineSideCard,
      "thg": _vm.selectedThg,
      "partner": _vm.partner
    },
    on: {
      "close": function close($event) {
        _vm.isTimeLineSideCard = false;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }