
































import { deepCopy } from "@/lib/utility/deep-copy";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgBatchDetailViewmodelGen } from "@/services/thg/v1/data-contracts";
import { ThgStatusEnum } from "@/store/enum/thg/thg-status.enum";
import { BatchModule } from "@/store/modules/batch.store";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({
  components: { Card }
})
export default class ThgBatchDetailInformationStatusCard extends DarkModeHighlightMixin {
  @Prop({ default: undefined })
  batch!: ThgBatchDetailViewmodelGen;

  loading = false;
  disabled = true;
  userNotification = true;

  get isLoading() {
    return this.loading;
  }

  isEditing = false;
  model = deepCopy(this.batch.batch).status;
  stati = Object.keys(ThgStatusEnum).map(k => ThgStatusEnum[k as any]);

  close() {
    this.model = this.batch.batch.status;
    this.disabled = true;
  }

  edit() {
    this.disabled = false;
  }

  async save() {
    this.loading = true;
    try {
      const results = await BatchModule.updateStatusAsync({
        status: this.model as any,
        noUserNotification: !this.userNotification
      });
      this.disabled = true;
      this.$toast.success(this.$t("components.thg.ThgBatchDetailInformationStatusCard.success").toString(), {
        onClick: () => new GoToHelper(this.$router).goToOperationDetail(results.operationId)
      });
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("components.thg.ThgBatchDetailInformationStatusCard.error").toString());
    } finally {
      this.loading = false;
    }
  }
}
