/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ThgCounterSequenceViewmodelGen, ThgCounterViewmodelGen, ThgUpdateCounterValueDtoGen } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Counter<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags counter
   * @name CounterControllerFindAll
   * @summary (ADMIN) Get all counters
   * @request GET:/counter
   * @secure
   * @response `200` `(any)[]` The found record
   * @response `403` `void` Forbidden.
   */
  counterControllerFindAll = (params: RequestParams = {}) =>
    this.http.request<any[], void>({
      path: `/counter`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags counter
   * @name CounterControllerFindOne
   * @summary Get a counter by name
   * @request GET:/counter/{name}
   * @secure
   * @response `200` `ThgCounterViewmodelGen` The found record
   * @response `403` `void` Forbidden.
   */
  counterControllerFindOne = (name: string, params: RequestParams = {}) =>
    this.http.request<ThgCounterViewmodelGen, void>({
      path: `/counter/${name}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags counter
   * @name CounterControllerUpdateCounter
   * @summary (ADMIN) Updates counter value.
   * @request PUT:/counter/{name}
   * @secure
   * @response `200` `ThgCounterSequenceViewmodelGen` The new sequence
   * @response `403` `void` Forbidden.
   */
  counterControllerUpdateCounter = (name: string, data: ThgUpdateCounterValueDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgCounterSequenceViewmodelGen, void>({
      path: `/counter/${name}`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
