





























import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ClipperModule } from "@/store/modules/clipper.store";
import { Component } from "vue-property-decorator";
import ImageDialog from "../utility/ImageDialog.vue";
import ThgCropImage from "./ThgCropImage.vue";
import ThgCroppedImage from "./ThgCroppedImage.vue";

@Component({
  components: {
    ImageDialog,
    ThgCropImage,
    ThgCroppedImage
  }
})
export default class ThgCroppedImageStylingOptions extends DarkModeHighlightMixin {
  currentScale = 1;
  currentTranslationX = 0;
  currentTranslationY = 0;
  get isMultipleImages() {
    return ClipperModule.isMultipleImages;
  }
  get currentRotation() {
    return ClipperModule.rotation;
  }
  set currentRotation(rotation) {
    ClipperModule.setRotation(rotation);
  }

  mounted() {
    document.addEventListener("keyup", this.handleKeyDown);
    this.resetImageTransformation();
  }
  crop() {
    ClipperModule.setIsCroppingActive(true);
  }
  beforeDestroy() {
    document.removeEventListener("keyup", this.handleKeyDown);
    this.resetImageTransformation();
  }

  previousImage() {
    ClipperModule.decrementCurrentImageIndex();
    this.resetImageTransformation();
  }

  nextImage() {
    ClipperModule.incrementCurrentImageIndex();
    this.resetImageTransformation();
  }

  setImageStyling() {
    ClipperModule.setImageStyling(
      `rotate(${this.currentRotation}deg) scale(${this.currentScale}) translate(${this.currentTranslationX}%, ${this.currentTranslationY}%)`
    );
  }

  rotateImage(degree: number) {
    this.currentRotation += degree;
    this.setImageStyling();
    this.$emit("rotation");
  }

  scaleImage(number: number) {
    this.currentScale += number;
    this.setImageStyling();
  }

  translateImageY(number: number) {
    if ((this.currentRotation / 90) % 2 == 0) {
      this.currentTranslationY += number;
    } else {
      this.currentTranslationX += number;
    }
    this.setImageStyling();
  }

  translateImageX(number: number) {
    if ((this.currentRotation / 90) % 2 == 0) {
      this.currentTranslationX += number;
    } else {
      this.currentTranslationY += number;
    }
    this.setImageStyling();
  }

  resetImageTransformation() {
    this.currentScale = 1;
    this.currentTranslationX = 0;
    this.currentTranslationY = 0;
    this.setImageStyling();
  }

  /**
   * navigate the image
   */
  handleKeyDown(event: any) {
    if (event.altKey === true && event.shiftKey === false && event.key === "ArrowUp") {
      event.preventDefault();
      this.scaleImage(0.2);
    }

    if (event.altKey === true && event.shiftKey === false && event.key === "ArrowDown") {
      event.preventDefault();
      this.scaleImage(-0.2);
    }

    if (event.altKey === true && event.shiftKey === false && event.key === "ArrowRight") {
      event.preventDefault();
      this.rotateImage(90);
    }

    if (event.altKey === true && event.shiftKey === false && event.key === "ArrowLeft") {
      event.preventDefault();
      this.rotateImage(-90);
    }

    if (event.altKey === true && event.shiftKey === true && event.key === "ArrowUp") {
      event.preventDefault();
      this.translateImageY(-5);
    }

    if (event.altKey === true && event.shiftKey === true && event.key === "ArrowDown") {
      event.preventDefault();
      this.translateImageY(5);
    }

    if (event.altKey === true && event.shiftKey === true && event.key === "ArrowRight") {
      event.preventDefault();
      this.translateImageX(5);
    }

    if (event.altKey === true && event.shiftKey === true && event.key === "ArrowLeft") {
      event.preventDefault();
      this.translateImageX(-5);
    }
    this.setImageStyling();
  }
}
