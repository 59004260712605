











































import { numberHigherRule } from "@/lib/rules/numberHigherRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { simpleDate } from "@/lib/utility/date-helper";
import { handleError } from "@/lib/utility/handleError";
import { ThgCalculateEmissionSavingsDtoGen } from "@/services/thg/v1/data-contracts";
import { IThg } from "@/models/thg.entity";

import { ThgCalculationMethodEnum } from "@/store/enum/thg/thg-calculation-type.enum";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import ThgAdminUpdatePayoutConfigurationDialog from "./ThgAdminUpdatePayoutConfigurationDialog.vue";

@Component({
  components: { Card, ThgAdminUpdatePayoutConfigurationDialog, ConfirmActionDialog },
  filters: { simpleDate }
})
export default class ThgQuoteDetailInformationSavingCard extends Vue {
  @Prop({ default: undefined })
  thg!: IThg;

  loading = false;
  updateDialog = false;
  form = false;

  calculationMethodEnum = ThgCalculationMethodEnum;
  dto: ThgCalculateEmissionSavingsDtoGen = {
    method: this.calculationMethodEnum.STATISTIC,
    amountInKwh: 1
  };
  methods = [ThgCalculationMethodEnum.STATISTIC, ThgCalculationMethodEnum.EXACT];

  get amountRule() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(numberHigherRule(1));

    return rules;
  }

  openUpdateDialog() {
    this.updateDialog = true;
    this.dto.amountInKwh = this.thg.exactEnergyAmountInKwH || 0;
    this.dto.method = this.thg.calculationMethod as ThgCalculationMethodEnum;
  }

  async calculateEmissionSavings() {
    this.loading = true;

    try {
      const updateDto: ThgCalculateEmissionSavingsDtoGen = {
        method: this.dto.method
      };
      if (this.dto.method === ThgCalculationMethodEnum.EXACT && this.dto.amountInKwh) {
        updateDto.amountInKwh = +this.dto.amountInKwh;
      }

      await ThgPortalModule.calculateEmissionSavings(updateDto);
    } catch (error) {
      handleError(error);
    } finally {
      this.loading = false;
      this.updateDialog = false;
    }
  }
}
