var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-row', {
    staticClass: "ma-4"
  }, [_c('v-col', {
    directives: [{
      name: "col",
      rawName: "v-col",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('thg-charging-station-create-batch-card')], 1), _c('v-col', {
    directives: [{
      name: "col",
      rawName: "v-col",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('thg-meter-reading-create-batch')], 1), _c('v-col', {
    directives: [{
      name: "col",
      rawName: "v-col",
      value: 12,
      expression: "12"
    }],
    attrs: {
      "md": "6",
      "lg": "4"
    }
  }, [_c('thg-charging-station-create-card')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }