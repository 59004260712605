



























































































































































































import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import AnalyticsQueryFilter, {
  AnalyticsQueryFilterEmitChangeData
} from "@/components/analytics/AnalyticsQueryFilter.vue";
import BarChartCard from "@/components/analytics/BarChartCard.vue";
import PieChartCard from "@/components/analytics/PieChartCard.vue";
import TableDataCard from "@/components/analytics/TableDataCard.vue";
import TimeSeriesLineChartCard from "@/components/analytics/TimeSeriesLineChartCard.vue";
import PartnerBillingInformationDialog from "@/components/partner/PartnerBillingInformationDialog.vue";
import ThgAnalyticsCustomerDataTableCard from "@/components/thg/ThgAnalyticsCustomerDataTableCard.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { thgStatusMap } from "@/lib/utility/thgStatusMap";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { ResourceEnum } from "@/store/enum/authResourceEnum";
import { UserModule } from "@/store/modules/me-user.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { Component, Prop } from "vue-property-decorator";
import QrcodeVue from "qrcode.vue";

@Component({
  components: {
    TheLayoutPortal,
    TimeSeriesLineChartCard,
    BarChartCard,
    PieChartCard,
    AnalyticsDateRangeSelectorDialog,
    TableDataCard,
    PartnerBillingInformationDialog,
    ThgAnalyticsCustomerDataTableCard,
    AnalyticsQueryFilter,
    QrcodeVue
  }
})
export default class ThgDashboard extends PartnerFallbackMixin {
  foundPartner = false;
  loading = false;
  loadingKpis = false;
  search = "";
  actionenum = ActionEnum;
  resourceenum = ResourceEnum;

  tabs = 0;

  thgStatusMap = thgStatusMap;

  ActionEnum = ActionEnum;
  ResourceEnum = ResourceEnum;

  @Prop({ default: "blue-grey" })
  loadingColor!: string;

  get partner() {
    return PartnerModule.partner;
  }

  get headerUrl() {
    return (
      this.partner?.settings?.headerImageUrl || "https://mmmint.azureedge.net/wirkaufendeinethg/forest-car-street.jpg"
    );
  }

  get title() {
    return this.partner?.companyName || "Hallo " + UserModule.user?.firstName;
  }

  get url() {
    return this.partner?.settings?.defaultUrl;
  }

  get isAnalyticsCustomerDataEnabled() {
    return UserModule.abilities.can(ActionEnum.READ, ResourceEnum.CUSTOMER_DATA);
  }

  async mounted() {
    this.loading = true;
    try {
      this.foundPartner = await this.trySetByRouteOrDefault();
    } catch {
      this.$toast.error("Unexpected Error");
    } finally {
      this.loading = false;
    }

    await Promise.all([
      ThgAnalyticsModule.getAvailableCountries(),
      ThgAnalyticsModule.getAvailableYears(),
      this.getData()
    ]);

    this.loading = false;
  }

  route(route: string) {
    this.$router.push({ name: route });
  }

  async getData() {
    this.setLoading(true);
    for (const key of this.dataLoadingOrder) {
      try {
        if (this.dataHandler[key].permission) {
          await this.dataHandler[key].getData();
        } else {
          this.$log.warn("Missing Pemermission for " + key);
          this.dataHandler[key].isLoading = false;
        }
      } catch (error) {
        this.$log.error(error);
      } finally {
        this.dataHandler[key].isLoading = false;
      }
    }
  }

  async onYearChange({ filter }: AnalyticsQueryFilterEmitChangeData) {
    ThgAnalyticsModule.setCountryCodeYearFilter(filter);
    await this.getData();
  }

  setLoading(value: boolean) {
    for (const key of this.dataLoadingOrder) {
      this.dataHandler[key].isLoading = value;
    }
  }

  downloadQrLink() {
    const canvas: HTMLCanvasElement = document
      .getElementById("qr")
      ?.getElementsByTagName("canvas")[0] as HTMLCanvasElement;
    const download: HTMLAnchorElement = this.$refs.download as HTMLAnchorElement;
    this.$log.debug(canvas);

    if (!canvas || !download) {
      return;
    }

    const image = canvas.toDataURL("image/png");
    download.setAttribute("href", image);
  }

  get qrCodeName() {
    return `${this.partner.settings?.defaultUrl}.png`;
  }

  dataLoadingOrder = [
    "trendAnalysis",
    "meaterReadingTrendAnalysis",
    "realTimeData",
    "yearAnalysisData",
    "isMultiYearAnalysisData",
    "weekdayDistribution",
    "hourDistribution",
    "vehicleClassDistribution",
    "impactTypeDistribution",
    "statusDistribution",
    "affiliateDistribution",
    "vinAnalysisData"
  ];
  dataHandler = {
    trendAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getTrendAnylsisDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    meaterReadingTrendAnalysis: {
      getData: async () => {
        await ThgAnalyticsModule.getMeterReadingTrendAnylsisDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    realTimeData: {
      getData: async () => {
        await ThgAnalyticsModule.getRealTimeDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    yearAnalysisData: {
      getData: async () => {
        await ThgAnalyticsModule.getYearAnalysisDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    isMultiYearAnalysisData: {
      getData: async () => {
        await ThgAnalyticsModule.getIsMultiYearAnalysisDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    weekdayDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getWeekdayDistributionDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    hourDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.gethourDistributionDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    affiliateDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getAffiliateDistributionDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    statusDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getStatusDistributionDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    impactTypeDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getImpactTypeDistributionDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    vehicleClassDistribution: {
      getData: async () => {
        await ThgAnalyticsModule.getVehicleClassDistributionDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    },
    vinAnalysisData: {
      getData: async () => {
        await ThgAnalyticsModule.getVinAnalysisDataPerPartner();
      },
      isLoading: false,
      permission: UserModule.abilities.can(ActionEnum.READ, ResourceEnum.ANALYTICS)
    }
  };

  async setDateRange(dateRange: string[]) {
    ThgAnalyticsModule.setDateRange(dateRange);
    await this.getData();
  }

  get dates() {
    return ThgAnalyticsModule.dateRange;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  get years() {
    return ThgAnalyticsModule.availableYears;
  }

  get realTimeAnalysisData() {
    return [
      {
        name: "Conversions",
        type: "bar",
        data: ThgAnalyticsModule.realtimeData.data.currentTrend
      }
    ];
  }

  get realTimeTrendKpiGroup() {
    return [
      {
        icon: "mdi-certificate-outline",
        value: ThgAnalyticsModule.realtimeData.data.sum,
        name: "Conversions"
      }
    ];
  }

  get dayTrendKpiGroup() {
    return [
      {
        icon: "mdi-certificate-outline",
        value: ThgAnalyticsModule.trendAnalysisData.data.sum,
        name: "Conversions"
      }
    ];
  }

  get dayTrendData() {
    return [
      {
        name: "Conversions",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.dayTrend,
        markPoint: {
          data: [
            { type: "max", name: "Max" },
            { type: "min", name: "Min" }
          ]
        },
        markLine: {
          data: [{ type: "average", name: "Avg" }]
        }
      }
    ];
  }

  get trendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.trendAnalysisData.data.thirtyDayTrend
      }
    ];
  }

  get meaterReadingTrendData() {
    return [
      {
        name: "1 day",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.meterReadingTrendAnalysisData.data.dayTrend
      },
      {
        name: "7 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.meterReadingTrendAnalysisData.data.sevenDayTend
      },
      {
        name: "30 days",
        type: "line",
        symbol: "none",
        data: ThgAnalyticsModule.meterReadingTrendAnalysisData.data.thirtyDayTrend
      }
    ];
  }

  get partnerDistributionData() {
    return ThgAnalyticsModule.partnerDistributionData.data.partnerDistribution;
  }

  get affiliateDistributionData() {
    return ThgAnalyticsModule.affiliateDistributionData.data;
  }

  get partnerTypeDistribution() {
    return ThgAnalyticsModule.partnerDistributionData.data.partnerTypeDistribution;
  }

  get statusDistributionData() {
    return ThgAnalyticsModule.statusDistributionByPartnerData.data;
  }

  get impactTypeDistributionData() {
    return ThgAnalyticsModule.impactTypeDistributionData.data;
  }

  get vehicleClassDistributionData() {
    return ThgAnalyticsModule.vehicleClassDistributionData.data;
  }

  get weekdayDistributionData() {
    return ThgAnalyticsModule.weekdayDistributionData.data;
  }

  get hourDistributionData() {
    return ThgAnalyticsModule.hourDistributionData.data;
  }

  get vinAnalysisData() {
    return ThgAnalyticsModule.vinAnalysisData.data;
  }

  get yearDistributionData() {
    return ThgAnalyticsModule.yearAnalysisData.data;
  }

  get isMultiYearDistributionData() {
    return ThgAnalyticsModule.isMultiYearAnalysisData.data;
  }

  get thgCustomerData() {
    return ThgAnalyticsModule.thgCustomerData.data;
  }
}
