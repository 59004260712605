
































import { ConflictException } from "@/lib/exceptions/http";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ThgAccountViewmodelGen, ThgUpdateAccountDtoGen } from "@/services/thg/v1/data-contracts";
import { AccountModule } from "@/store/modules/account.store";
import { Component, Prop, Watch } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";
import Debug from "../utility/Debug.vue";
import TableWrapper from "../utility/TableWrapper.vue";
import ThgBillingCreditorSelectionPartnerList from "./ThgBillingCreditorSelectionPartnerList.vue";
import ThgBillingCreditorSelectionUserList from "./ThgBillingCreditorSelectionUserList.vue";
import ThgBillingPlausibilityCheckDialog from "./ThgBillingPlausibilityCheckDialog.vue";

@Component({
  components: {
    Debug,
    ThgBillingCreditorSelectionUserList,
    ThgBillingCreditorSelectionPartnerList,
    ThgBillingPlausibilityCheckDialog,
    TableWrapper,
    ConfirmActionDialog
  }
})
export default class ThgBillingCreditorUpdateDialog extends DarkModeHighlightMixin {
  @Prop()
  isDialogActive!: boolean;
  @Prop()
  account!: ThgAccountViewmodelGen;
  @Prop()
  loadAllAccounts!: Function;

  @Watch("account", { deep: true })
  updateDto(newAccount: ThgAccountViewmodelGen) {
    this.accountDto.userId = newAccount.userId;
    this.accountDto.partnerId = newAccount.partnerId;
  }

  accountDto: ThgUpdateAccountDtoGen = {
    userId: this.account?.userId,
    partnerId: this.account?.partnerId
  };
  creatingCreditor = false;

  get dialogActive() {
    return this.isDialogActive;
  }
  set dialogActive(value: boolean) {
    this.$emit("update:isDialogActive", value);
  }

  loadingDialog = false;

  verifyData = true;

  userIdSelection(userId: string) {
    this.accountDto.userId = userId;
  }

  partnerIdSelection(partnerId: string) {
    this.accountDto.partnerId = partnerId;
  }

  async updateCreditor() {
    try {
      this.creatingCreditor = true;
      await AccountModule.updateAccount({ accountId: this.account.id, dto: this.accountDto });
      this.$toast.success("Success");
    } catch (e) {
      this.$log.error(e);

      if (e instanceof ConflictException) {
        this.$toast.error(this.$t("components.thg.thgCreditorCsvSelection.conflict"));
      } else {
        this.$toast.error((e as any).message);
      }
    } finally {
      this.dialogActive = false;
      this.creatingCreditor = false;
      await this.loadAllAccounts();
    }
  }
}
