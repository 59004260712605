


















import AnalyticsDateRangeSelectorDialog from "@/components/analytics/AnalyticsDateRangeSelectorDialog.vue";
import AnalyticsQueryFilter from "@/components/analytics/AnalyticsQueryFilter.vue";
import TableDataCard from "@/components/analytics/TableDataCard.vue";
import PartnerBillingInformationDialog from "@/components/partner/PartnerBillingInformationDialog.vue";
import ThgAnalyticsCustomerDataTableCard from "@/components/thg/ThgAnalyticsCustomerDataTableCard.vue";
import MHeader from "@/components/utility/mmmint/MHeader.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import ThgPartnerDashboardMixin from "@/mixins/ThgPartnerDashboardMixin.vue";
import { ThgChargingStationViewModelGen, ThgUserBaseViewModelGen } from "@/services/thg/v1/data-contracts";
import { PartnerModule } from "@/store/modules/partner";
import { ThgAnalyticsModule } from "@/store/modules/thg.analytics.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TheLayoutPortal,
    AnalyticsDateRangeSelectorDialog,
    TableDataCard,
    PartnerBillingInformationDialog,
    ThgAnalyticsCustomerDataTableCard,
    AnalyticsQueryFilter,
    MHeader
  }
})
export default class ThgPartnerCustomers extends ThgPartnerDashboardMixin {
  async mounted() {
    await this.loadData();
  }

  async loadDataForDateRange(dateRange: string[]) {
    await this.setDateRange(dateRange);
  }

  get breadCrumbs() {
    return [
      {
        text: "Übersicht",
        exact: true,
        disabled: false,
        to: {
          name: "ThgPartnerCustomers",
          params: {
            partnerId: PartnerModule.partner._id
          }
        }
      }
    ];
  }

  user(id: string): ThgUserBaseViewModelGen | undefined {
    const foundUser = ThgAnalyticsModule.thgCustomerData.data.find(entry => entry.user.id === id);
    if (foundUser) {
      return foundUser.user;
    }
  }

  chargingStation(id: string): ThgChargingStationViewModelGen | undefined {
    const chargingStation = ThgAnalyticsModule.thgCustomerData.chargingStation.find(entry => entry.id === id);
    if (chargingStation) {
      return chargingStation;
    }
  }
}
