import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { ThgAccountingRecordItemGen, ThgCreateBillingThgDtoGen } from "@/services/thg/v1/data-contracts";
import { BillingDataAccessLayer } from "@/store/modules/access-layers/billing.access-layer";
import { Billing, IBilling } from "./billing.entity";
import billingService from "@/services/thg/services/billingService";
import { CreateDto } from "@/lib/utility/data/create-dto.interface";

export class CreateBillingDto extends CreateDto<IBilling> implements ThgCreateBillingThgDtoGen {
  billingNumber?: number | undefined;
  type: BillingTypeEnum = BillingTypeEnum.AFFILIATE;
  isPublished = false;
  thgId?: string | undefined;
  referenceIds?: string[] | undefined;
  partnerId?: string | undefined;
  userId?: string | undefined;
  accountRecord?: ThgAccountingRecordItemGen[] | undefined;
  accountNumber?: number | undefined;

  async create() {
    const createdBilling = await billingService.createBilling({
      billingNumber: this.billingNumber,
      type: this.type,
      isPublished: this.isPublished,
      thgId: this.thgId,
      referenceIds: this.referenceIds,
      partnerId: this.partnerId,
      userId: this.userId,
      accountRecord: this.accountRecord,
      accountNumber: this.accountNumber
    });

    const billing = new Billing(createdBilling);
    BillingDataAccessLayer.set(billing);

    return billing;
  }
}
