var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": _vm.$t('components.thg.ThgBatchDetailInformationPayoutCard.title')
    }
  }, [!_vm.batch.batch.pricePerKwh ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDetailInformationPayoutCard.noConfig")) + " ")]) : _vm._e(), _c('v-card-text', [_vm._v(" " + _vm._s(_vm.$t("thg.PriceTable.commission")) + " " + _vm._s(_vm.commission) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgVehicleCard.payout")) + " " + _vm._s(_vm.totalPayout) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgAnalyticsCustomerDataTableCard.promotion")) + " " + _vm._s(_vm.promotion) + " "), _c('br'), _c('br'), _c('debug', [_vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDetailInformationPayoutCard.fix")) + " " + _vm._s(_vm.payoutConfiguration.fixPayOutSum) + " (" + _vm._s(_vm.payoutConfiguration.fixPayOutCount) + ") "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDetailInformationPayoutCard.atLeast")) + " " + _vm._s(_vm.payoutConfiguration.atLeatsPayOutSum) + " (" + _vm._s(_vm.payoutConfiguration.atLeastPayOutCount) + ") "), _c('br'), _vm._v(" " + _vm._s(_vm.$t("components.thg.ThgBatchDetailInformationPayoutCard.noConfig")) + " " + _vm._s(_vm.payoutConfiguration.noConfig) + " "), _c('br')])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }