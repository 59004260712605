var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": "report.thgChargingStation.title"
    }
  }, [!_vm.loading ? _c('thg-charging-station-form', {
    attrs: {
      "loading": _vm.loading,
      "detail": ""
    },
    on: {
      "save": _vm.save
    },
    model: {
      value: _vm.chargingStation,
      callback: function callback($$v) {
        _vm.chargingStation = $$v;
      },
      expression: "chargingStation"
    }
  }) : _c('v-skeleton-loader', {
    attrs: {
      "type": "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }