/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */

import { IThg } from "@/models/thg.entity";

/**
 * Duplicate filter to get a list of duplicate vins
 */
export class ThgDuplicateFilter {
  public static EMPTY = "empty";

  private vinsMap = new Map<string, IThg[]>();
  private dupsMap = new Map<string, IThg[]>();
  private _emptyVin = 0;
  private _emptyVehicleClass = 0;
  private _emptyName = 0;

  filter(thgs: IThg[]): Map<string, IThg[]> {
    for (const thg of thgs) {
      if (!thg.registration?.identificationnumber) {
        this._emptyVin++;
      }

      if (!thg.registration?.vehicleClass) {
        this._emptyVehicleClass++;
      }

      if (!thg.registration?.name) {
        this._emptyName++;
      }

      const key = thg.registration?.identificationnumber || ThgDuplicateFilter.EMPTY;
      const existingThgsForVin = this.vinsMap.get(key);

      if (existingThgsForVin) {
        existingThgsForVin.push(thg);

        const dupsForVin = this.dupsMap.get(key);
        if (dupsForVin) {
          dupsForVin.push(thg);
        } else {
          this.dupsMap.set(key, [...existingThgsForVin]);
        }
      } else {
        this.vinsMap.set(key, [thg]);
      }
    }

    return this.vinsMap;
  }

  /**
   * Get all unique vins
   */
  get vins(): string[] {
    return Array.from(this.vinsMap.keys());
  }

  /**
   * Get duplicate vins
   */
  get dups(): string[] {
    return Array.from(this.dupsMap.keys());
  }

  /**
   * returns amount of registrations where no vehicleclass is provided
   */
  get emptyVehicleClass() {
    return this._emptyVehicleClass;
  }

  /**
   * returns amount of registrations where no vehicleclass is provided
   */
  get emptyVin() {
    return this._emptyVin;
  }

  /**
   * returns amount of registrations where no vehicleclass is provided
   */
  get emptyName() {
    return this._emptyName;
  }

  /**
   * Returns all thgs for a given vin
   *
   * @param vin the vin to search for
   * @returns the thgs for a VIN
   */
  getByVin(vin: string) {
    return this.vinsMap.get(vin);
  }
}
