




















































import { Component, Prop, Vue } from "vue-property-decorator";
import { ClipperModule } from "@/store/modules/clipper.store";

@Component({ components: {} })
export default class ThgCropImageActions extends Vue {
  /**
   * Align buttons next to each other or underneath each other
   */
  @Prop({ default: false })
  horizontal?: boolean;

  /**
   * Removes clipping -> results in entire image
   */
  unclip() {
    this.$emit("unclip");
  }
  /**
   * Applies last clip that is saved in store
   */
  reclip() {
    this.$emit("reclip");
  }
  /**
   * Crops to selected border
   */
  clip() {
    this.$emit("clip");
  }

  /**
   * If clipping dialog is active
   */
  get isEditActive() {
    return ClipperModule.isCroppingActive;
  }

  mounted() {
    window.addEventListener("keypress", this.keyBoardClipping);
  }
  beforeUnmount() {
    window.removeEventListener("keypress", this.keyBoardClipping);
  }

  /**
   * Keyboard shortcuts
   * CTRL + Enter takes whole image
   * Space applies style
   * Space applies last style
   *
   * @param e
   */
  keyBoardClipping(e: KeyboardEvent) {
    if (this.isEditActive) {
      if (e.code === "Enter") {
        if (!e.ctrlKey) {
          this.unclip();
        } else {
          this.reclip();
        }
      }
      if (e.code === "Space") {
        this.clip();
      }
    }
  }
}
