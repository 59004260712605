var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    staticClass: "ml-3",
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": _vm.$t('components.thg.thgBillingSelectionItemMeterReadingData.title'),
      "margin": 0
    }
  }, [_c('v-card-actions', [_vm.meterReading ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemMeterReadingData.startDate'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.meterReading.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.meterReading, "startDate", $$v);
      },
      expression: "meterReading.startDate"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemMeterReadingData.endDate'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.meterReading.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.meterReading, "endDate", $$v);
      },
      expression: "meterReading.endDate"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemMeterReadingData.amountInKwh'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.meterReading.amountInKwh,
      callback: function callback($$v) {
        _vm.$set(_vm.meterReading, "amountInKwh", $$v);
      },
      expression: "meterReading.amountInKwh"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemMeterReadingData.chargingStationId'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.meterReading.chargingStationId,
      callback: function callback($$v) {
        _vm.$set(_vm.meterReading, "chargingStationId", $$v);
      },
      expression: "meterReading.chargingStationId"
    }
  })], 1) : !_vm.loading ? _c('div', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('latest-entries-card-empty')], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }