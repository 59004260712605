var render = function () {
  var _vm$thg$meterReading, _vm$thg$meterReading2, _vm$thg$meterReading3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('card', {
    attrs: {
      "outlined": "",
      "min-width": "250px",
      "flat": "",
      "title": "CO2-Berechnung",
      "loading": _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "loading": _vm.loading
          },
          on: {
            "click": _vm.openUpdateDialog
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.thg.calculationMethod ? _c('v-card-text', [_c('div', [_vm._v("Method: " + _vm._s(_vm.thg.calculationMethod))]), _c('div', [_vm._v(" Zeitraum: " + _vm._s(_vm._f("simpleDate")((_vm$thg$meterReading = _vm.thg.meterReading) === null || _vm$thg$meterReading === void 0 ? void 0 : _vm$thg$meterReading.startDate)) + " bis " + _vm._s(_vm._f("simpleDate")((_vm$thg$meterReading2 = _vm.thg.meterReading) === null || _vm$thg$meterReading2 === void 0 ? void 0 : _vm$thg$meterReading2.endDate)) + " ")]), _vm.thg.exactEnergyAmountInKwH ? _c('div', [_vm._v("Exakte Einsparung: " + _vm._s(_vm.thg.exactEnergyAmountInKwH))]) : _vm._e(), _c('div', [_vm._v("Berechnete Einsparung: " + _vm._s((_vm$thg$meterReading3 = _vm.thg.meterReading) === null || _vm$thg$meterReading3 === void 0 ? void 0 : _vm$thg$meterReading3.amountInKwh) + " KwH")])]) : _c('v-card-text', [_vm._v("Keine Spezifikation angegeben")])], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.updateDialog,
      "title": "CO2 Berechnung ändern",
      "subtitle": "Hier kannst du die Berechnung der CO2 Einsparung ändern",
      "rightLoading": _vm.loading,
      "rightDisabled": !_vm.form
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.updateDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.updateDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.updateDialog = false;
      },
      "rightClick": _vm.calculateEmissionSavings
    }
  }, [_c('v-form', {
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "Methode",
      "persistent-hint": "",
      "outlined": "",
      "items": _vm.methods,
      "clearable": true
    },
    model: {
      value: _vm.dto.method,
      callback: function callback($$v) {
        _vm.$set(_vm.dto, "method", $$v);
      },
      expression: "dto.method"
    }
  }), _vm.dto.method === _vm.calculationMethodEnum.EXACT ? _c('v-text-field', {
    staticClass: "mt-6 mb-n4",
    attrs: {
      "type": "number",
      "label": "Exakter Wert in kWh",
      "outlined": "",
      "dense": "",
      "required": "",
      "rules": _vm.amountRule
    },
    model: {
      value: _vm.dto.amountInKwh,
      callback: function callback($$v) {
        _vm.$set(_vm.dto, "amountInKwh", $$v);
      },
      expression: "dto.amountInKwh"
    }
  }) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }