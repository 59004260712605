
import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import { Vue } from "vue-property-decorator";

export default abstract class ThgBillingBatchMixin extends Vue {
  get BillingTypeEnum() {
    return BillingTypeEnum;
  }

  /**
   * All billing types that can be batched
   */
  readonly batchableBillingTypes = [
    BillingTypeEnum.AFFILIATE,
    BillingTypeEnum.CREDIT,
    BillingTypeEnum.CREDIT_PARTNER,
    BillingTypeEnum.CREDIT_CHARGING_STATION,
    BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT
  ];

  /**
   * All types of billings that are created for users
   */
  readonly userFocusedBillingTypes = [
    BillingTypeEnum.AFFILIATE,
    BillingTypeEnum.CREDIT,
    BillingTypeEnum.CREDIT_CHARGING_STATION,
    BillingTypeEnum.CREDIT_CUSTOMER_ACCOUNT
  ];

  /**
   * All types of billings that are created for partners
   */
  readonly partnerFocusedBillingTypes = [BillingTypeEnum.CREDIT_PARTNER];

  /**
   * Checks if the given billing type is a billing type that is created for users
   */
  getIsUserFocusedBillingType(billingType: BillingTypeEnum) {
    return this.userFocusedBillingTypes.includes(billingType);
  }

  /**
   * Checks if the given billing type is a billing type that is created for partners
   */
  getIsPartnerFocusedBillingType(billingType: BillingTypeEnum) {
    return this.partnerFocusedBillingTypes.includes(billingType);
  }

  /**
   * checks if a credit partner billing is created
   */
  getIsCreditPartnerBillingType(billingType: BillingTypeEnum) {
    return billingType === BillingTypeEnum.CREDIT_PARTNER;
  }

  /**
   * checks if an affiliate billing is created
   */
  getIsAffiliateBillingType(billingType: BillingTypeEnum) {
    return billingType === BillingTypeEnum.AFFILIATE;
  }

  /**
   * checks if an affiliate billing is created
   */
  getIsCreditBillingType(billingType: BillingTypeEnum) {
    return billingType === BillingTypeEnum.CREDIT;
  }
}
