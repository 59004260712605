
































































import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import FilterCardPagination from "@/components/filter/FilterCardPagination.vue";
import Debug from "@/components/utility/Debug.vue";
import PaginatedTable from "@/components/utility/v2/PaginatedTable.vue";
import TableWrapper from "@/components/utility/TableWrapper.vue";
import TheLayoutPortal from "@/layouts/TheLayoutPortal.vue";
import { ThgBillingControllerGetAllBillingsParamsGen, ThgBillingViewmodelGen } from "@/services/thg/v1/data-contracts";
import { Component, Vue, Ref } from "vue-property-decorator";
import { BillingModule } from "@/store/modules/billing.store";
import { ConfigModule } from "@/store/modules/config";
@Component({
  components: {
    PaginatedTable,
    TheLayoutPortal,
    TableWrapper,
    LatestEntriesCardEmpty,
    FilterCardPagination,
    Debug
  }
})
export default class ThgBillingBccountingSelectionView extends Vue {
  @Ref("paginationTable")
  paginationTable!: PaginatedTable<ThgBillingViewmodelGen, ThgBillingControllerGetAllBillingsParamsGen>;
  predefinedFilter = [];
  get partnerId() {
    return this.$route.params.partnerId;
  }
  get pagination() {
    return BillingModule;
  }
  get headers() {
    const headers = [
      { text: this.$t("components.thg.thgBillingCsvSelection.created"), value: "timestamp.created" },
      { text: this.$t("components.thg.thgBillingCsvSelection.billingNumber"), value: "billingNumber" },
      { text: this.$t("components.thg.thgBillingCsvSelection.type"), value: "type" },
      { text: this.$t("components.thg.thgBillingCsvSelection.thgId"), value: "thgId" },
      {
        text: this.$t("components.thg.thgBillingCsvSelection.relatedEntities"),
        value: "relatedParties",
        sortable: false
      },
      { text: this.$t("components.thg.thgBillingCsvSelection.isPublished"), value: "isPublished" },
      { text: this.$t("components.thg.thgBillingCsvSelection.accountRecord"), value: "accountRecord.length" }
    ];
    if (ConfigModule.debug) {
      headers.unshift({ text: "index", value: "index" }, { text: this.$t("id"), value: "id" });
    }
    return headers;
  }
  goToPartnerDetail(partnerId: string) {
    this.$router.push({ name: "PartnerDetailView", params: { partnerId: partnerId } });
  }
  goToUserDetail(userId: string) {
    this.$router.push({ name: "UserAdministrationDetail", params: { userId: userId } });
  }
  goToBilling(billingNumber: number) {
    this.$router.push({ name: "ThgBillingNumberDetailView", params: { billingNumber: billingNumber.toString() } });
  }
  goToThgDetail(partnerId: string, thgId: string) {
    this.$router.push({
      name: "ThgQuoteDetailView",
      params: { partnerId: partnerId, thgId: thgId }
    });
  }
}
