














import { Component, Vue, Watch } from "vue-property-decorator";
import LatestEntriesCardEmpty from "@/components/cards/LatestEntriesCardEmpty.vue";
import { ClipperModule } from "@/store/modules/clipper.store";
import debounce from "debounce";

@Component({ components: { LatestEntriesCardEmpty } })
export default class ThgCropImage extends Vue {
  debounceResize = debounce(() => this.resize(), 20, false);
  width = "100%";

  originalSize = { width: undefined as undefined | number, height: undefined as undefined | number };

  get src() {
    return ClipperModule.src;
  }

  @Watch("src")
  setOriginalSize(newSrc: string) {
    const img = new Image();

    img.onload = () => {
      this.originalSize.height = img.height;
      this.originalSize.width = img.width;
    };

    img.src = newSrc;
  }

  mounted() {
    this.setOriginalSize(this.src);
  }

  clip() {
    const clippy = this.$refs.clippy as any;
    const drawPosition = clippy?.getDrawPos();

    ClipperModule.setCrop({
      x: drawPosition.pos.sx,
      y: drawPosition.pos.sy,
      width: drawPosition.pos.swidth,
      height: drawPosition.pos.sheight
    });
    ClipperModule.setIsCroppingActive(false);
    this.$emit("clipped");
  }

  unclip() {
    const { width, height } = this.originalSize;
    if (!width || !height) {
      this.$log.error("No original size given");
      return;
    }

    const bufferZone = 1;
    ClipperModule.setCrop({
      x: bufferZone,
      y: bufferZone,
      width: width - bufferZone * 2,
      height: height - bufferZone * 2
    });
    ClipperModule.setIsCroppingActive(false);
    this.$emit("clipped");
  }

  reclip() {
    const drawPosition = ClipperModule.previousEdit?.crop;

    ClipperModule.setCrop(drawPosition);
    ClipperModule.setIsCroppingActive(false);
    this.$emit("clipped");
  }

  // Image breaks out vertically sometimes
  resize() {
    this.$log.debug("resize crop image");
    const imageContainerContainer = this.$refs.imageContainerContainer as HTMLDivElement;
    const imageContainer = this.$refs.imageContainer as HTMLDivElement;

    const imageWidth = imageContainer.clientWidth;
    const imageHeight = imageContainer.clientHeight;

    const containerWidth = imageContainerContainer.clientWidth;
    const containerHeight = imageContainerContainer.clientHeight;
    const increaseFactorWidth = containerWidth / imageWidth;
    const increaseFactorHeight = containerHeight / imageHeight;
    let scalingFactor = increaseFactorHeight;
    if (increaseFactorWidth < scalingFactor) {
      scalingFactor = increaseFactorWidth;
    }

    this.width = `${scalingFactor * imageWidth}px`;
  }
}
