



















































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { PartnerEntity } from "@/models/partnerEntity";
import { PartnerModule } from "@/store/modules/partner";
import Card from "../utility/Card.vue";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemPartnerData extends DarkModeHighlightMixin {
  @Prop()
  partnerId!: string;

  @Prop()
  partner?: PartnerEntity;

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  partnerLoading = true;
  partnerData: PartnerEntity | undefined = this.partner;

  async mounted() {
    if (!this.partner) {
      this.partnerLoading = true;

      await PartnerModule.getPartnerById(this.partnerId);
      this.partnerData = PartnerModule.partner;
    }
    this.partnerLoading = false;
  }
}
