

























import AnalyticsKpiColumn from "@/components/analytics/AnalyticsKpiColumn.vue";
import { IKpi } from "@/lib/interfaces/kpi-interface";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { ConfigModule } from "@/store/modules/config";
import { EChartsOption } from "echarts/types/dist/shared";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import AnalyticsDataTableDialog from "@/components/analytics/AnalyticsDataTableDialog.vue";

@Component({
  components: { AnalyticsKpiColumn, AnalyticsDataTableDialog, Card }
})
export default class ScatterLineChartCard extends DarkModeHighlightMixin {
  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: "" })
  title!: string;

  @Prop({ default: [] })
  data!: [];

  @Prop({ default: [] })
  exportData!: [];

  @Prop({
    default() {
      return [];
    }
  })
  kpiGroup!: IKpi[];

  get isExportEnabled() {
    return this.exportData.length;
  }

  get shopwKpiGroup(): boolean {
    return this.kpiGroup.length != 0;
  }

  get chartOptions(): EChartsOption {
    return this.createEchartOtpions(this.data);
  }

  createEchartOtpions(series: any[]): EChartsOption {
    return {
      color: ConfigModule.color.analyticsColors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross"
        },
        formatter: (params: any) =>
          (params[0].data[1] * 100).toFixed(2) +
          " % der THG-Quoten </br>werden von " +
          (params[0].data[0] * 100).toFixed(2) +
          " % der Nutzer</br>gemeldet. "
      },
      xAxis: {
        type: "value",
        name: "User",
        nameLocation: "middle",
        min: 0,
        max: 1,
        boundaryGap: false
      },
      yAxis: {
        type: "value",
        name: "THG-Quote",
        nameGap: 33,
        nameLocation: "middle",
        min: 0,
        max: 1,
        boundaryGap: false
      },
      series: series
    };
  }
}
