/**
 * Copyright 2021 mmmint.ai info@mmmint.ai - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential to MMM Intelligence UG (haftungsbeschränkt).
 */
export enum EfahrerOrderStatusEnum {
  CREATED = "created",
  FETCHED = "fetched",
  DECLINED = "declined",
  TRANSFERRED_TO_KBA = "transferred_to_kba",
  PAID = "paid"
}
