

















import ThgMeterReadingForm from "@/components/thg/ThgMeterReadingForm.vue";
import LayoutSimple from "@/layouts/LayoutSimple.vue";
import { HttpException } from "@/lib/exceptions/http";
import PartnerFallbackMixin from "@/mixins/PartnerFallbackMixin.vue";
import { ThgThgMeterReadingViewModelGen, ThgUpdateThgMeterReadingDtoGen } from "@/services/thg/v1/data-contracts";
import { ThgPortalModule } from "@/store/modules/thg.portal.store";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    LayoutSimple,
    ThgMeterReadingForm
  }
})
export default class ThgMeterReadingUpdateView extends PartnerFallbackMixin {
  loading = false;

  async mounted() {
    this.loading = true;
    try {
      await ThgPortalModule.getSelectedMeterReading(this.$route.params.meterReadingId);
    } catch {
      this.$toast.error("Fehler beim Laden der Thg-Quote");
    } finally {
      this.loading = false;
    }
  }

  get meterReading() {
    return ThgPortalModule.selectedMeterReading;
  }

  get meterReadings() {
    return ThgPortalModule.meterReadingForChargingStation;
  }

  async save(item: ThgThgMeterReadingViewModelGen) {
    const updatedMeterReading: ThgUpdateThgMeterReadingDtoGen = { ...item } as any;

    this.$log.debug(updatedMeterReading);

    try {
      this.loading = true;

      this.$toast.error("Not implemented, please add a new one instead");
      this.$router.push({ name: "ThgMeterReading" });
    } catch (error) {
      this.$log.error(error);
      if (error instanceof HttpException) {
        this.$toast.error(error.message);
      }
    } finally {
      this.loading = false;
    }
  }
}
