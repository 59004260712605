




































import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { IThgMeterReading } from "@/models/thg-meter-reading.entity";
import { Component, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import { ThgBatchDetailViewmodelGen } from "@/services/thg/v1/data-contracts";
import Debug from "@/components/utility/Debug.vue";

@Component({
  components: { Card, Debug }
})
export default class ThgBatchDetailInformationPayoutCard extends DarkModeHighlightMixin {
  @Prop()
  data!: IThg[];

  @Prop()
  batch!: ThgBatchDetailViewmodelGen;

  get payoutConfiguration() {
    let fixPayOutCount = 0;
    let fixPayOutSum = 0;
    let atLeatsPayOutSum = 0;
    let atLeastPayOutCount = 0;
    let noConfig = 0;
    for (const entry of this.data) {
      if (entry.payoutConfiguration?.isFixed && entry.payoutConfiguration.revenue) {
        fixPayOutSum = fixPayOutSum + +entry.payoutConfiguration.revenue;
        fixPayOutCount = fixPayOutCount + 1;
      } else if (entry.payoutConfiguration?.isFixed === false && +entry.payoutConfiguration.revenue) {
        atLeatsPayOutSum = atLeatsPayOutSum + +entry.payoutConfiguration.revenue;
        atLeastPayOutCount = atLeastPayOutCount + 1;
      } else {
        noConfig = noConfig + 1;
      }
    }

    return {
      fixPayOutSum: fixPayOutSum.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      }),
      fixPayOutCount: fixPayOutCount,
      atLeatsPayOutSum: atLeatsPayOutSum.toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR"
      }),
      atLeastPayOutCount: atLeastPayOutCount,
      noConfig: noConfig
    };
  }

  calculateCommissionForItem(item: IThg | IThgMeterReading) {
    const commissionInPercent = (this.batch.batch.pricePerKwh?.commission ?? 0) / 100;

    return (
      (this.calculatePayoutAmount(item) * commissionInPercent).toFixed(2) +
      " " +
      (this.batch.batch.pricePerKwh?.unit ?? "")
    );
  }

  calculatePayoutAmount(item: IThg | IThgMeterReading) {
    if (item.payoutConfiguration.isFixed) {
      return item.payoutConfiguration.revenue;
    }

    const pricePerKwh = this.batch.batch.pricePerKwh?.value ?? 0;
    let kwh = (item as IThg).exactEnergyAmountInKwH ?? 0;
    if (!kwh) {
      kwh = (item as IThgMeterReading).meterReading.amountInKwh;
    }

    return pricePerKwh * kwh;
  }

  calculatePayoutAmountReadable(item: IThg | IThgMeterReading) {
    return this.calculatePayoutAmount(item).toFixed(2) + " " + (this.batch.batch.pricePerKwh?.unit ?? "");
  }

  get commission() {
    const commissionInPercent = (this.batch.batch.pricePerKwh?.commission ?? 0) / 100;

    return (this.totalPayout * commissionInPercent).toFixed(2) + " " + (this.batch.batch.pricePerKwh?.unit ?? "");
  }

  get totalPayout() {
    let totalPayout = 0;
    for (const entry of this.data) {
      totalPayout = totalPayout + this.calculatePayoutAmount(entry);
    }
    return totalPayout;
  }

  get promotion() {
    let promo = 0;

    for (const item of this.data) {
      if (item.promotionConfiguration?.value) {
        promo = promo + item.promotionConfiguration.value;
      }
    }

    return promo + " " + (this.batch.batch.pricePerKwh?.unit ?? "");
  }
}
