var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('data-card', {
    attrs: {
      "title": _vm.$t('components.thg.thgBillingSelectionContactData.identityData'),
      "locked": _vm.locked,
      "confirmEdit": _vm.confirmEdit
    },
    on: {
      "update:locked": function updateLocked($event) {
        _vm.locked = $event;
      }
    }
  }, [_vm.data ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.id'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": ""
    },
    model: {
      value: _vm.data._id,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "_id", $$v);
      },
      expression: "data._id"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.firstName'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.data.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "firstName", $$v);
      },
      expression: "data.firstName"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionContactData.lastName'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.data.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "lastName", $$v);
      },
      expression: "data.lastName"
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }