var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_vm.index < _vm.thgsReadyForBilling.length ? _c('card', {
    attrs: {
      "title": _vm.$t('views.thgPortal.thgBillingSelectionItem.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "disabled": _vm.index <= 0
                },
                on: {
                  "click": _vm.goPrevious
                }
              }, on), [_c('v-icon', [_vm._v("mdi-arrow-left")])], 1)];
            }
          }], null, false, 129599453)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("views.thgPortal.thgBillingSelectionItem.goPrevious")) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "disabled": _vm.index + 1 === _vm.thgsReadyForBilling.length
                },
                on: {
                  "click": _vm.goNext
                }
              }, on), [_c('v-icon', [_vm._v("mdi-arrow-right")])], 1)];
            }
          }], null, false, 2087770279)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("views.thgPortal.thgBillingSelectionItem.goNext")) + " ")])]), _c('v-spacer'), _c('thg-billing-selection-item-send-mail-dialog', {
          attrs: {
            "billing": _vm.createdBilling,
            "thg": _vm.selectedThg
          }
        }), _c('v-spacer'), _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": _vm.removeBilling
                }
              }, on), [_c('v-icon', [_vm._v("mdi-delete-outline")])], 1)];
            }
          }], null, false, 1543444955)
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("views.thgPortal.thgBillingSelectionItem.removeBilling")) + " ")])]), _c('v-spacer'), _c('v-btn', {
          staticClass: "mr-4",
          attrs: {
            "color": "success",
            "loading": _vm.isGenerationLoading
          },
          on: {
            "click": _vm.generateBilling
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("views.thgPortal.thgBillingSelectionItem.confirmBilling")) + " ")])];
      },
      proxy: true
    }], null, false, 1556430505)
  }, [_c('v-card-actions', {
    key: _vm.selectedThg.id
  }, [_vm.billingType === _vm.BillingTypeEnum.AFFILIATE ? _c('thg-billing-selection-affiliate-item', {
    attrs: {
      "thg": _vm.selectedThg
    }
  }) : _vm.billingType === _vm.BillingTypeEnum.CREDIT ? _c('thg-billing-selection-credit-item', {
    attrs: {
      "thg": _vm.selectedThg
    }
  }) : _c('div', [_vm._v(" An Oopsie occured: "), _c('br'), _vm._v(" We are not prepared to handle a billing of type " + _vm._s(_vm.billingType) + ". "), _c('br'), _vm._v(" Sorry for the inconvenience. Come back soon! ")])], 1), _c('v-card-actions', [_c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "wrap"
    }
  }, [!_vm.createdBillingLoading && _vm.createdBilling ? _c('div', {
    staticClass: "billingContainer flexContainer",
    staticStyle: {
      "flex-grow": "2"
    }
  }, [_c('thg-billing-selection-item-pdf-result', {
    staticClass: "ma-1",
    attrs: {
      "billing": _vm.createdBilling
    }
  }), _c('thg-billing-selection-item-accounting-result', {
    staticClass: "ma-1",
    attrs: {
      "billing": _vm.createdBilling
    }
  }), _c('thg-billing-selection-item-billing-result', {
    staticClass: "ma-1",
    attrs: {
      "billing": _vm.createdBilling
    }
  })], 1) : _vm._e()])])], 1) : _c('card', {
    attrs: {
      "title": "We're done here. Good job."
    }
  }, [_c('v-row', {
    staticClass: "ma-8",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-img', {
    attrs: {
      "align": "center",
      "justify": "center",
      "contain": "",
      "max-width": "700px",
      "src": require("@/assets/undraw/undraw_meditation_re_gll0.svg")
    }
  })], 1)], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('views.thgPortal.thgBillingSelectionItem.forceTitle'),
      "fullscreen": _vm.isMobile,
      "isDialogActive": _vm.isForceBillingRegenerationDialogActive,
      "rightText": _vm.$t('views.thgPortal.thgBillingSelectionItem.force'),
      "rightDisabled": !_vm.isForceRegenerationConfirmed,
      "rightLoading": _vm.isRegenerationLoading,
      "width": 900
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isForceBillingRegenerationDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isForceBillingRegenerationDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isForceBillingRegenerationDialogActive = false;
      },
      "rightClick": _vm.forceRegenerateBilling
    }
  }, [_c('v-card-actions', [_c('v-spacer'), _c('v-switch', {
    attrs: {
      "label": _vm.$t('views.thgPortal.thgBillingSelectionItem.areYouSure', {
        name: _vm.name
      })
    },
    model: {
      value: _vm.isForceRegenerationConfirmed,
      callback: function callback($$v) {
        _vm.isForceRegenerationConfirmed = $$v;
      },
      expression: "isForceRegenerationConfirmed"
    }
  }), _c('v-spacer')], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }