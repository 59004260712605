

















































import { CountryCodeEnum } from "@/lib/enum/country-code.enum";
import { requiredRule } from "@/lib/rules/requiredRule";
import { IThg } from "@/models/thg.entity";
import { Component, Prop, Vue } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";

@Component({
  components: { Debug }
})
export default class ThgAdminUpdateCountryCodeDialog extends Vue {
  @Prop()
  thg!: IThg;

  /**
   * Displays loading animation
   */
  @Prop({ default: false })
  loading!: boolean;

  /**
   * The internal countryCode
   */
  countryCode = this.thg.countryCode;

  /**
   * Notify the user about this change?
   */
  userNotification = true;

  /**
   * Form valid
   */
  isValid = false;

  /**
   * Show Dialog
   */
  isDialogActive = false;

  /**
   * All available status
   */
  items = Object.keys(CountryCodeEnum).map(k => CountryCodeEnum[k as any]);

  openDialog() {
    this.isDialogActive = true;
  }

  get requiredRule() {
    return [requiredRule()];
  }

  async save(): Promise<IThg> {
    this.isDialogActive = false;

    this.$emit("save", this.countryCode);
    this.close();

    this.thg.countryCode = this.countryCode;

    return this.thg;
  }

  async close() {
    this.isDialogActive = false;
    this.countryCode = this.thg.countryCode;
    this.$emit("refresh");
  }
}
