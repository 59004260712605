var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isAdmin ? _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g({
          attrs: {
            "icon": "",
            "loading": _vm.initDialogLoading
          },
          on: {
            "click": _vm.initDialog
          }
        }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)];
      }
    }], null, false, 3811227470)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingList.newBilling")) + " ")])]) : _vm._e(), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.newBillingDialog,
      "title": _vm.$t('components.thg.thgBillingList.newBillingDialog'),
      "rightLoading": _vm.isLoading,
      "leftText": _vm.$t('close'),
      "supressKeyboardActions": true,
      "rightDisabled": _vm.createdBilling && !_vm.isValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.newBillingDialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.newBillingDialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.initDialogLoading = false;
      },
      "rightClick": _vm.onConfirm,
      "close": function close($event) {
        _vm.initDialogLoading = false;
      }
    }
  }, [!_vm.createdBilling && _vm.createBilling ? _c('thg-billing-create-new-billing-manual', {
    on: {
      "created": _vm.onCreated
    },
    model: {
      value: _vm.createBilling,
      callback: function callback($$v) {
        _vm.createBilling = $$v;
      },
      expression: "createBilling"
    }
  }) : _c('thg-billing-create-new-billing-manual-pdf', {
    attrs: {
      "billing": _vm.createdBilling,
      "isValid": _vm.isValid
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.isValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.isValid = $event;
      },
      "added": _vm.onAdded
    },
    model: {
      value: _vm.pdfInput,
      callback: function callback($$v) {
        _vm.pdfInput = $$v;
      },
      expression: "pdfInput"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }