var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "ma-2 mt-8",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('card', {
    attrs: {
      "title": _vm.$t('components.thg.AffiliateDetailInformationCard.title')
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('affiliate-update-dialog')];
      },
      proxy: true
    }])
  }, [_c('v-card-text', [_c('span', [_vm._v(_vm._s(_vm.affiliateUrl))]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$t("components.thg.AffiliateDetailInformationCard.discount", {
    value: _vm.selectedAffiliate.discountForCustomer
  })))]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$t("components.thg.AffiliateDetailInformationCard.note", {
    value: _vm.selectedAffiliate.note
  })))]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.AffiliateDetailInformationCard.provision", {
    value: _vm.selectedAffiliate.provision
  })) + " ")]), _c('br'), _c('span', [_vm._v(" " + _vm._s(_vm.$t("components.thg.AffiliateDetailInformationCard.timesUsed", {
    value: _vm.selectedAffiliate.timesUsed
  })) + " ")]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$t("components.thg.AffiliateDetailInformationCard.isValid", {
    value: _vm.selectedAffiliate.isValid
  })) + " ")]), _c('br'), _c('span', [_vm._v(_vm._s(_vm.$t("components.thg.AffiliateDetailInformationCard.user", {
    value: _vm.userName
  })) + " ")]), _c('br')])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }