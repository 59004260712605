














import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { Component, Prop } from "vue-property-decorator";
import Debug from "../utility/Debug.vue";
import { IThg } from "@/models/thg.entity";
import Card from "../utility/Card.vue";
import { GoToHelper } from "@/lib/utility/goToHelper";

@Component({ components: { Debug, Card } })
export default class ThgBillingSelectionItemThgListData extends DarkModeHighlightMixin {
  @Prop()
  thgs!: IThg[];

  get width() {
    if (this.$vuetify.breakpoint.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }

  /**
   * Opens the Detail view of a GHG document in a new Tab
   */
  goToGhgDetail(ghg: IThg) {
    new GoToHelper(this.$router).goToThgDetail(ghg.partnerId, ghg.id, true);
  }
}
