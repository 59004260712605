/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ThgBatchControllerUpdateStatusAsyncParamsGen,
  ThgBatchControllerUpdateStatusParamsGen,
  ThgBatchDetailViewmodelGen,
  ThgBatchDetailViewModelWithOperationGen,
  ThgBatchDtoGen,
  ThgBatchOperationViewModelGen,
  ThgBatchPartnerDistributionViewmodelGen,
  ThgBatchViewmodelGen,
  ThgExceptionViewmodelGen,
  ThgUpdateBatchItemsDtoGen,
  ThgUpdatePriceInformationDtoGen,
  ThgUpdateThgStatusDtoGen
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Batch<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerCreateBatch
   * @summary Create a new Batch
   * @request POST:/batch
   * @secure
   * @response `200` `ThgBatchDetailViewmodelGen` The created Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerCreateBatch = (data: ThgBatchDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgBatchDetailViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerFindAll
   * @summary Get all Batches
   * @request GET:/batch
   * @secure
   * @response `200` `ThgBatchViewmodelGen` All Batches
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerFindAll = (params: RequestParams = {}) =>
    this.http.request<ThgBatchViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerFindById
   * @summary Get a Batch
   * @request GET:/batch/{batchId}
   * @secure
   * @response `200` `ThgBatchDetailViewmodelGen` Get a specific batch and attached Documents
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerFindById = (batchId: string, params: RequestParams = {}) =>
    this.http.request<ThgBatchDetailViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerUpdateStatus
   * @summary Update Status of batch
   * @request PATCH:/batch/{batchId}/status
   * @secure
   * @response `200` `ThgBatchDetailViewmodelGen` The updated Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerUpdateStatus = (
    { batchId, ...query }: ThgBatchControllerUpdateStatusParamsGen,
    data: ThgUpdateThgStatusDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBatchDetailViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}/status`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerUpdateStatusAsync
   * @summary Update Status of batch
   * @request PATCH:/batch/{batchId}/status/async
   * @secure
   * @response `200` `ThgBatchOperationViewModelGen` The updated Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerUpdateStatusAsync = (
    { batchId, ...query }: ThgBatchControllerUpdateStatusAsyncParamsGen,
    data: ThgUpdateThgStatusDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBatchOperationViewModelGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}/status/async`,
      method: "PATCH",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerUpdatePricePerKwh
   * @summary Update Status of batch
   * @request PATCH:/batch/{batchId}/price
   * @secure
   * @response `200` `ThgBatchDetailViewmodelGen` The updated Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerUpdatePricePerKwh = (
    batchId: string,
    data: ThgUpdatePriceInformationDtoGen,
    params: RequestParams = {}
  ) =>
    this.http.request<ThgBatchDetailViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}/price`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerPartnerDistribution
   * @summary Get partnerdistribution of a batch
   * @request GET:/batch/{batchId}/partnerDistribution
   * @secure
   * @response `200` `ThgBatchPartnerDistributionViewmodelGen` The updated Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerPartnerDistribution = (batchId: string, params: RequestParams = {}) =>
    this.http.request<ThgBatchPartnerDistributionViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}/partnerDistribution`,
      method: "GET",
      secure: true,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerUpdateItems
   * @summary Add or remove items
   * @request PATCH:/batch/{batchId}/items
   * @secure
   * @response `200` `ThgBatchDetailViewmodelGen` The created Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerUpdateItems = (batchId: string, data: ThgUpdateBatchItemsDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgBatchDetailViewmodelGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}/items`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
  /**
   * No description
   *
   * @tags batch
   * @name BatchControllerUpdateItemsAsync
   * @summary Add or remove items through a job
   * @request PATCH:/batch/{batchId}/items/async
   * @secure
   * @response `200` `ThgBatchDetailViewModelWithOperationGen` The created Batch
   * @response `400` `ThgExceptionViewmodelGen` Bad Request
   * @response `401` `ThgExceptionViewmodelGen` Unauthorized request if token is missing or expired.
   * @response `403` `ThgExceptionViewmodelGen` Forbidden.
   * @response `500` `ThgExceptionViewmodelGen` Internal Server Error
   */
  batchControllerUpdateItemsAsync = (batchId: string, data: ThgUpdateBatchItemsDtoGen, params: RequestParams = {}) =>
    this.http.request<ThgBatchDetailViewModelWithOperationGen, ThgExceptionViewmodelGen>({
      path: `/batch/${batchId}/items/async`,
      method: "PATCH",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params
    });
}
