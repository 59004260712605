







































































































































































import { emailRule } from "@/lib/rules/contactRule";
import { isNumberRule } from "@/lib/rules/isNumberRule";
import { numberplateRule } from "@/lib/rules/numberplateRule";
import { requiredRule } from "@/lib/rules/requiredRule";
import { CreateAnonymousThgQuote } from "@/store/models/thg/create-thg-quote";
import { ThgRegistration } from "@/store/models/thg/registration";
import { ThgCreateModule } from "@/store/modules/thg.create.store";
import { Component, Prop, Vue } from "vue-property-decorator";
import ThgRegistrationForm from "./ThgRegistrationForm.vue";
import ThgRegistrationFrontUploadCard from "./ThgRegistrationFrontUploadCard.vue";
import ThgRegistrationUploadCard from "./ThgRegistrationUploadCard.vue";
import { PartnerActiveOfferModule } from "@/store/modules/partner-active-config.store";
import { PartnerModule } from "@/store/modules/partner";
import { ThgVehicleTypes } from "@/store/enum/thg/thg-vehicle-types.enum";

@Component({
  components: { ThgRegistrationFrontUploadCard, ThgRegistrationUploadCard, ThgRegistrationForm }
})
export default class ThgQuoteForm extends Vue {
  /**
   * Blocks the submit button on loading, e.g. parent reacting to `save` event
   */
  @Prop({ default: false })
  loading!: boolean;

  /**
   * User confirmed entries
   */
  isConfirmed = false;

  /**
   * User confirmed terms and conditions
   */
  isTermsAccepted = false;

  /**
   * Did user added registration
   */
  isUserAddRegistration = true;

  /**
   * Is the registration form valid
   */
  isRegistrationValid = false;

  /**
   * Is the form valid
   */
  isValid = false;

  /**
   * New create THG Quote object
   */
  thgQuote = new CreateAnonymousThgQuote();

  /**
   * New registration object.
   */
  registration = new ThgRegistration();

  get requiredRule() {
    return [requiredRule()];
  }

  get numberRule() {
    return [isNumberRule(), requiredRule()];
  }

  get numberplateRule() {
    return [numberplateRule(), requiredRule()];
  }

  get emailRule() {
    return [emailRule(), requiredRule()];
  }

  get years() {
    return ThgCreateModule.years;
  }

  get selected() {
    return ThgCreateModule.selectedYearId;
  }

  set selected(id) {
    ThgCreateModule.setSelectedYearId(id);
  }

  get yearSelection() {
    return ThgCreateModule.yearSelections;
  }

  get bonus() {
    return ThgCreateModule.bonus;
  }

  get prefix() {
    return ThgCreateModule.bonusPrefix;
  }

  get frontImageId() {
    if (ThgCreateModule.registrationImageFront.length <= 0) return "";

    return ThgCreateModule.registrationImageFront[0].uploadId;
  }

  get backImageId() {
    if (ThgCreateModule.registrationImageBack.length <= 0) return "";

    return ThgCreateModule.registrationImageBack[0].uploadId;
  }

  get disabled() {
    return (
      !this.isTermsAccepted ||
      !this.isValid ||
      (this.isUserAddRegistration && !this.isRegistrationValid) ||
      this.frontImageId.length <= 0 ||
      this.backImageId.length <= 0
    );
  }

  updateRegistrationValid(valid: boolean) {
    this.isRegistrationValid = valid;
  }

  save() {
    this.thgQuote.registrationImages = [this.frontImageId, this.backImageId];

    if (this.isUserAddRegistration) {
      this.thgQuote.registration = this.registration;
    }

    this.$emit("save", this.thgQuote);
  }

  get isProductsAvailable() {
    return !!this.availableProducts.length;
  }

  get availableProducts() {
    return PartnerActiveOfferModule.priceConfig.getYearCombinationsByVehicleClass(
      ThgCreateModule.vehicleClass || ThgVehicleTypes.M1
    );
  }

  isLoadingPrices = false;
  async fetchPrices() {
    try {
      this.isLoadingPrices = true;
      await PartnerActiveOfferModule.loadDocuments(PartnerModule.partner.id);
    } catch (error) {
      this.$log.error(error);
      this.$toast.error(this.$t("report.thgProducts.loadingError"));
    }

    this.isLoadingPrices = false;
  }

  transformYearsToString(arr: number[]): string {
    if (arr.length === 0) {
      return "";
    } else if (arr.length === 1) {
      return arr[0].toString();
    } else if (arr.length === 2) {
      return arr.join(" & ");
    } else {
      const lastTwo = arr.slice(-2).join(" & ");
      const remaining = arr.slice(0, -2).join(", ");
      return `${remaining}, ${lastTwo}`;
    }
  }

  async mounted() {
    await this.fetchPrices();
  }
}
