































import { BillingTypeEnum } from "@/lib/enum/billingType.enum";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import { IThg } from "@/models/thg.entity";
import { BillingBatchModule } from "@/store/modules/billing-batch.store";
import { Component, Prop } from "vue-property-decorator";
import ConfirmActionDialog from "../utility/ConfirmActionDialog.vue";

@Component({
  components: {
    ConfirmActionDialog
  }
})
export default class ThgBillingRecreationIndividualDialog extends DarkModeHighlightMixin {
  readonly BillingTypeEnum = BillingTypeEnum;

  @Prop()
  thgs!: IThg[];

  @Prop({ default: BillingTypeEnum.CREDIT })
  billingType!: BillingTypeEnum;

  /**
   * Form valid
   */
  isValid = false;
  dialog = false;
  loading = false;

  pricePerKwh = "";

  get disbledNew() {
    if (!this.thgs.length) {
      return true;
    }
    return false;
  }

  get disabled() {
    if (!this.thgs.length) {
      return true;
    }
    return false;
  }

  async confirmBillingRecreation() {
    this.loading = true;

    const ids: string[] = [];

    for (const thg of this.thgs) {
      ids.push(thg.id);
    }

    if (!ids.length) {
      this.$toast.error("No THG to Bill");
    }

    try {
      const billing = await BillingBatchModule.createBilling({
        query: {},
        data: {
          billingType: this.billingType,
          createAccountingRecord: true,
          createPdf: true,
          force: true,
          ids: ids,
          pricePerKwh: Number(this.pricePerKwh)
        }
      });
      this.$toast.success(this.$t("components.thg.ThgBillingRecreationDialog.successRecreation"));
      if (billing) {
        this.goToBillingDetail(billing.billingNumber);
      }
    } catch (e) {
      this.$toast.error((e as any).message);
      this.$log.error(e);
    } finally {
      this.loading = false;
      this.dialog = false;
    }
  }

  goToBillingDetail(billingNumber: number) {
    this.$router.push({
      name: "ThgBillingNumberDetailView",
      params: { billingNumber: billingNumber.toString() }
    });
  }

  openDialog() {
    this.dialog = true;
  }
}
