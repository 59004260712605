import { ThgHttpClientProvider } from "@/services/thg/thg-http-client.provider";
import { ThgCreateInsuranceDtoGen, ThgInsuranceControllerGetAllParamsGen } from "../v1/data-contracts";
import { Insurance } from "../v1/Insurance";

/**
 * Communicates with thg backend
 */
class AdminInsuranceService {
  /**
   * The proxy.
   */
  proxy: Insurance;

  /**
   * @class Initialize Insurance Service for THG
   */
  constructor(clientProvider: ThgHttpClientProvider) {
    this.proxy = new Insurance(clientProvider.client());
  }

  async getAll(query: ThgInsuranceControllerGetAllParamsGen) {
    return (await this.proxy.insuranceControllerGetAll(query)).data;
  }

  async delete(userId: string, insuranceId: string) {
    return (await this.proxy.insuranceControllerDeleteForUser(userId, insuranceId)).data;
  }

  async create(userId: string, data: ThgCreateInsuranceDtoGen) {
    return (await this.proxy.insuranceControllerCreateForUser(userId, data)).data;
  }
}

export default new AdminInsuranceService(new ThgHttpClientProvider());
