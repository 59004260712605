var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('card', {
    attrs: {
      "outlined": "",
      "width": _vm.width,
      "title": "Affiliate",
      "margin": 0
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": "",
                  "disabled": _vm.locked
                },
                on: {
                  "click": _vm.editAffiliate
                }
              }, on), [_vm.locked ? _c('v-icon', [_vm._v("mdi-check")]) : _c('v-icon', {
                staticClass: "unsavedData",
                attrs: {
                  "color": "red",
                  "x-large": ""
                }
              }, [_vm._v("mdi-check")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionItemAffiliateData.confirmEdit")) + " ")])]), _c('v-tooltip', {
          attrs: {
            "top": "",
            "nudge-right": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.locked = !_vm.locked;
                  }
                }
              }, on), [_c('v-icon', [_vm._v(_vm._s(_vm.locked ? "mdi-lock-outline" : "mdi-lock-open"))])], 1)];
            }
          }])
        }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.thg.thgBillingSelectionBatchData.unlock")) + " ")])])];
      },
      proxy: true
    }])
  }, [_c('v-card-actions', [!_vm.updatingAffiliate ? _c('v-form', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('v-select', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.type'),
      "items": _vm.types,
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.affiliate.type,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "type", $$v);
      },
      expression: "affiliate.type"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.id'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": true
    },
    model: {
      value: _vm.affiliate.id,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "id", $$v);
      },
      expression: "affiliate.id"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.userId'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.affiliate.userId ? _c('v-icon', {
          staticClass: "billingAlert",
          attrs: {
            "color": "red"
          }
        }, [_vm._v("mdi-alert-box")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 2891339481),
    model: {
      value: _vm.affiliate.userId,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "userId", $$v);
      },
      expression: "affiliate.userId"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.code'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.affiliate.code,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "code", $$v);
      },
      expression: "affiliate.code"
    }
  }), _c('v-select', {
    staticClass: "mb-n2",
    attrs: {
      "clearable": "",
      "items": _vm.affiliateTypes,
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.affiliateTypes'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.affiliate.type,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "type", $$v);
      },
      expression: "affiliate.type"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "value": _vm.affiliate.discountForCustomer,
      "type": "number",
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.discountForCustomer'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    on: {
      "change": function change(v) {
        return _vm.affiliate.discountForCustomer = Number(v);
      }
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "value": _vm.affiliate.provision,
      "type": "number",
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.provision'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    on: {
      "change": function change(v) {
        return _vm.affiliate.provision = Number(v);
      }
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "value": _vm.affiliate.timesUsed,
      "type": "number",
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.timesUsed'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": true
    },
    on: {
      "change": function change(v) {
        return _vm.affiliate.timesUsed = Number(v);
      }
    }
  }), _c('v-select', {
    staticClass: "mb-n2",
    attrs: {
      "clearable": "",
      "items": [true, false],
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.isValid'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.affiliate.isValid,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "isValid", $$v);
      },
      expression: "affiliate.isValid"
    }
  }), _c('v-text-field', {
    staticClass: "mb-n2",
    attrs: {
      "label": _vm.$t('components.thg.thgBillingSelectionItemAffiliateData.note'),
      "outlined": "",
      "dense": "",
      "flat": "",
      "disabled": _vm.locked
    },
    model: {
      value: _vm.affiliate.note,
      callback: function callback($$v) {
        _vm.$set(_vm.affiliate, "note", $$v);
      },
      expression: "affiliate.note"
    }
  }), _c('debug', {
    attrs: {
      "debug": _vm.affiliate
    }
  })], 1) : _c('v-skeleton-loader', {
    staticClass: "mx-auto",
    attrs: {
      "type": "list-item-two-line, list-item-two-line, list-item-two-line"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }