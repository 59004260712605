var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "items": _vm.statusItems,
      "clearable": "",
      "outlined": ""
    },
    on: {
      "input": _vm.fetch
    },
    model: {
      value: _vm.status,
      callback: function callback($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.items,
      "item-key": "id",
      "itemsPerPage": 15,
      "loading": _vm.isLoading,
      "loading-text": "Loading... Please wait"
    },
    on: {
      "click:row": _vm.onOrderClick
    },
    scopedSlots: _vm._u([{
      key: "item.created_at",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.created_at).toLocaleString()))])];
      }
    }])
  })], 1)], 1), _c('debug', [_vm._v(_vm._s(_vm.items))]), _vm.selectedOrder ? _c('efahrer-side-card', {
    attrs: {
      "value": _vm.selectedOrder,
      "loading": _vm.isLoading
    },
    on: {
      "close": _vm.closeSideCard
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }