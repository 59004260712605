var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-simple', {
    attrs: {
      "title": _vm.$t('components.thg.thgAdvertismentCreate.title')
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "lg": "3"
    }
  }, [_c('v-form', {
    staticClass: "mx-4",
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-autocomplete', {
    staticClass: "my-4",
    attrs: {
      "loading": _vm.isLoadingPartners,
      "rules": _vm.requiredRules,
      "items": _vm.partners,
      "item-value": "_id",
      "item-text": "companyUsername",
      "label": "Partner",
      "placeholder": "Start typing to Search",
      "outlined": ""
    },
    model: {
      value: _vm.partnerId,
      callback: function callback($$v) {
        _vm.partnerId = $$v;
      },
      expression: "partnerId"
    }
  }), _c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.create
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.thg.thgAdvertismentCreate.confirm")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }