var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.billingData ? _c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4"
  }, [_c('h3', {
    staticClass: "text-truncate"
  }, [_vm._v("#" + _vm._s(_vm.billingData.billingNumber) + " (" + _vm._s(_vm.i18nBilling[_vm.billingData.type]) + ")")])]), _vm.billingData.timestamp.created ? _c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.billingDate) + " "), _c('v-tooltip', {
    attrs: {
      "top": "",
      "nudge-right": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on;
        return [_vm.displayToDetailView ? _c('v-btn', _vm._g({
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.toDetailView(_vm.billingData);
            }
          }
        }, on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1) : _vm._e()];
      }
    }], null, false, 1521597813)
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("components.partner.PartnerFormDetail.tooltip.toDetailView")) + " ")])])], 1) : _vm._e(), _c('v-row', {
    staticClass: "ma-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('card', {
    attrs: {
      "outlined": true,
      "title": _vm.i18n['payoutTitle']
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [_vm.pdf ? _c('v-btn', {
          attrs: {
            "href": _vm.pdf,
            "target": "_blank",
            "icon": ""
          }
        }, [_c('v-icon', [_vm._v(" mdi-open-in-new ")])], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 1761543129)
  }, [_vm.tax ? _c('div', [_c('v-list-item', [_c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.i18n["withoutTax"]))])], 1), _c('v-spacer'), _c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.withoutTaxText))])], 1)], 1), _c('v-list-item', [_c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.i18n["taxPart"]))])], 1), _c('v-spacer'), _c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.taxText))])], 1)], 1)], 1) : _vm._e(), _c('v-list-item', [_c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.i18n["commission"]))])], 1), _c('v-spacer'), _c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.totalText))])], 1)], 1)], 1)], 1), (_vm.billingData.referenceIds || []).length ? _c('Can', {
    attrs: {
      "I": _vm.ActionEnum.READ,
      "a": _vm.ResourceEnum.THG
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('card', {
    attrs: {
      "outlined": true,
      "title": _vm.i18n['billedDocuments']
    }
  }, _vm._l(_vm.billingData.referenceIds, function (referenceId) {
    return _c('v-list-item', {
      key: referenceId
    }, [_c('a', {
      attrs: {
        "text": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toThgDetail(referenceId);
        }
      }
    }, [_vm._v(" " + _vm._s(referenceId) + " ")])]);
  }), 1)], 1)], 1) : _vm._e(), _vm.billingData.userContactData ? _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4"
    }
  }, [_c('card', {
    attrs: {
      "outlined": true,
      "title": _vm.i18n['userContact']
    }
  }, [_c('v-list-item', [_c('v-list-content', [_c('v-icon', [_vm._v(" mdi-account ")])], 1), _c('v-spacer'), _c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.billingData.userContactData.firstName) + " " + _vm._s(_vm.billingData.userContactData.lastName) + " ")])], 1)], 1), _c('v-list-item', [_c('v-list-content', [_c('v-icon', [_vm._v(" mdi-mail ")])], 1), _c('v-spacer'), _c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.billingData.userContactData.contact.email))])], 1)], 1), _c('v-list-item', [_c('v-list-content', [_c('v-icon', [_vm._v(" mdi-phone ")])], 1), _c('v-spacer'), _c('v-list-content', [_c('v-card-subtitle', [_vm._v(" " + _vm._s(_vm.billingData.userContactData.contact.phone))])], 1)], 1)], 1)], 1) : _vm._e()], 1)], 1) : _c('latest-entries-card-empty')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }