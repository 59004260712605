
































import { Component, Vue, Prop } from "vue-property-decorator";
import Card from "../utility/Card.vue";
import Debug from "../utility/Debug.vue";

/**
 * Card compoenent that has a title and a lock button and a confirm changes button
 */
@Component({ components: { Debug, Card } })
export default class DataCard extends Vue {
  @Prop()
  title!: string;

  @Prop({ default: false })
  loading?: boolean;

  @Prop()
  confirmEdit!: () => Promise<void>;

  @Prop()
  locked!: boolean;

  get isLocked() {
    return this.locked;
  }

  set isLocked(isLocked: boolean) {
    this.$emit("update:locked", isLocked);
  }

  updating = false;

  async saveChanges() {
    try {
      this.updating = false;
      await this.confirmEdit();
      this.$toast.success(this.$t("views.thgPortal.thgBillingSelectionItem.success"));
      this.isLocked = true;
    } catch (e) {
      this.$log.error(e);
      this.$toast.error((e as any).message);
    } finally {
      this.updating = false;
    }
  }

  get width() {
    if (this.$vuetify.breakpoint?.smAndDown) {
      return "100%";
    } else {
      return "250px";
    }
  }
}
